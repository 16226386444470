import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { Offer } from 'core/offers/entities';
import { OfferMapper } from 'core/offers/mappers';
import { TaxService } from 'core/offers/services';
import { Locales } from 'core/user/entities';
import { CurrencyService } from 'core/user/services';
import { OfferDto } from './dto';

export class OffersService extends BaseService {
  private taxService: TaxService;
  private currencyService: CurrencyService;

  constructor(
    httpClient: HttpClient,
    logger: Logger,
    services: { taxService: TaxService; currencyService: CurrencyService },
  ) {
    super(httpClient, logger);
    this.taxService = services.taxService;
    this.currencyService = services.currencyService;
  }

  async getOffers(offerIds: string[], locale: Locales): Promise<Offer[]> {
    if (!offerIds.length) {
      return [];
    }

    const { data } = await this.httpClient.get<OfferDto[]>(
      '/products/list?filter[ids]=' + offerIds.join(','),
    );

    const mapper = new OfferMapper(this.taxService, this.currencyService);

    return data.map((dto) => mapper.dtoToEntity(dto, locale));
  }

  async getOfferDetails(offerId: string, locale: Locales): Promise<Offer> {
    const result = await this.getOffers([offerId], locale);

    return result[0];
  }
}
