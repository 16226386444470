import { useCallback } from 'react';
import { config } from 'core/config';
import { appendQueryParams } from '../utils/appendQueryParams';
import { joinUrls } from '../utils/joinUrls';
import { useServices } from './useServices';

export function useRedirectToAskNebula() {
  const { analyticsService, heatmapRecordingService } = useServices();

  const redirectToExternalSignIn = useCallback(
    (
      token: string,
      redirectTo: string,
      params: Partial<{ queryParams: Record<string, unknown> }> = {},
    ) => {
      const { queryParams: passedQueryParams = {} } = params;
      const queryParams = {
        ...passedQueryParams,
        token,
        redirectTo,
        amp_device_id: analyticsService.getDeviceId(),
      };

      const baseUrl = new URL(joinUrls(config.askNebulaUrl, 'app/external'));
      const url = appendQueryParams(baseUrl, queryParams);
      heatmapRecordingService.triggerCustomEvent('user redirect');

      window.location.assign(url);
    },
    [analyticsService, heatmapRecordingService],
  );

  const redirectToQuizCatalogue = useCallback(
    (token: string, params: Partial<{ queryParams: Record<string, unknown> }> = {}) => {
      const { queryParams: passedQueryParams = {} } = params;
      const queryParams = {
        ...passedQueryParams,
        token,
        amp_device_id: analyticsService.getDeviceId(),
      };

      const baseUrl = new URL(joinUrls(config.askNebulaUrl, 'flow/lp/appnebula/experts'));
      const url = appendQueryParams(baseUrl, queryParams);

      window.location.assign(url);
    },
    [analyticsService],
  );

  const redirectToLogin = useCallback(
    (params: Partial<{ queryParams: Record<string, unknown> }> = {}) => {
      const { queryParams: passedQueryParams = {} } = params;
      const queryParams = {
        ...passedQueryParams,
        amp_device_id: analyticsService.getDeviceId(),
      };

      const baseUrl = new URL(joinUrls(config.askNebulaUrl, 'app/login'));
      const url = appendQueryParams(baseUrl, queryParams);

      window.location.assign(url);
    },
    [analyticsService],
  );

  return {
    redirectToQuizCatalogue,
    redirectToLogin,
    redirectToExternalSignIn,
  };
}
