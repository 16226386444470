import { Primitive, Refinement, RefinementContext, ValidatorIssues } from 'core/common/entities';
import { areArrayObjectPrimitivesUnique } from 'core/common/utils/areArrayObjectPrimitivesUnique';

export const createUniqueAttributePropertyRefinement =
  <PropertyName extends string>(propertyName: PropertyName): Refinement =>
  <Value extends Primitive>(
    items: Array<Record<PropertyName, Value>>,
    context: RefinementContext,
  ) => {
    const result = areArrayObjectPrimitivesUnique(propertyName, items);

    if (!result.isUnique) {
      context.addIssue({
        code: ValidatorIssues.custom,
        message:
          `Property '${result.duplicate.key}' must be unique. ` +
          `Duplicate value '${String(result.duplicate.value)}' found.`,
      });
    }
  };
