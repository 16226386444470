import { Languages } from 'core/localization/entities';

export function getLocalization(): Languages {
  if (!window || !window.location) {
    return Languages.EN;
  }

  const localization = window.location.pathname.split('/')[1] as Languages;

  if (Object.values(Languages).includes(localization)) {
    return localization;
  }

  const browserLanguage = Object.values(Languages).find(
    (lang) => window.navigator.language === lang,
  );

  if (browserLanguage) {
    return browserLanguage;
  }

  return Languages.EN;
}
