import { Locales } from './Locales';

export const europeanUnionCountries = [
  Locales.AT,
  Locales.BG,
  Locales.BE,
  Locales.CZ,
  Locales.CY,
  Locales.DE,
  Locales.DK,
  Locales.GR,
  Locales.IE,
  Locales.IT,
  Locales.HR,
  Locales.HU,
  Locales.EE,
  Locales.ES,
  Locales.LT,
  Locales.LU,
  Locales.LV,
  Locales.ME,
  Locales.MK,
  Locales.MT,
  Locales.NL,
  Locales.PL,
  Locales.PT,
  Locales.RO,
  Locales.SI,
  Locales.SK,
  Locales.FI,
  Locales.FR,
  Locales.SE,
];

const europeanOtherCountries = [
  Locales.AL,
  Locales.CH,
  Locales.GB,
  Locales.IS,
  Locales.LI,
  Locales.ME,
  Locales.MK,
  Locales.NO,
];

export const europeanCountries = [...europeanUnionCountries, ...europeanOtherCountries];

export const firstTierCountries = [
  Locales.NZ,
  Locales.CA,
  // TODO: remove after pricing test
  Locales.SG,
  Locales.HK,
  // TODO: remove after pricing test
  Locales.IL,
  Locales.AU,
  Locales.NG,
];

export const countriesWithoutPaypal = [
  Locales.IN,
  Locales.MX,
  Locales.BR,
  Locales.PE,
  Locales.AR,
  Locales.DZ,
  Locales.EG,
  Locales.LY,
  Locales.MA,
  Locales.SD,
  Locales.TN,
  Locales.EH,
  Locales.AO,
  Locales.BJ,
  Locales.BW,
  Locales.IO,
  Locales.BF,
  Locales.BI,
  Locales.CV,
  Locales.CM,
  Locales.CF,
  Locales.TD,
  Locales.KM,
  Locales.CG,
  Locales.CD,
  Locales.CI,
  Locales.DJ,
  Locales.GQ,
  Locales.ER,
  Locales.SZ,
  Locales.ET,
  Locales.TF,
  Locales.GA,
  Locales.GM,
  Locales.GH,
  Locales.GN,
  Locales.GW,
  Locales.KE,
  Locales.LS,
  Locales.LR,
  Locales.MG,
  Locales.MW,
  Locales.ML,
  Locales.MR,
  Locales.MU,
  Locales.YT,
  Locales.MZ,
  Locales.NA,
  Locales.NE,
  Locales.NG,
  Locales.RE,
  Locales.RW,
  Locales.SH,
  Locales.ST,
  Locales.SN,
  Locales.SC,
  Locales.SL,
  Locales.SO,
  Locales.ZA,
  Locales.SS,
  Locales.TZ,
  Locales.TG,
  Locales.UG,
  Locales.ZM,
  Locales.ZW,
  Locales.AI,
  Locales.AG,
  Locales.AW,
  Locales.BS,
  Locales.BB,
  Locales.BZ,
  Locales.BO,
  Locales.BQ,
  Locales.BV,
  Locales.KY,
  Locales.CL,
  Locales.CO,
  Locales.CR,
  Locales.CU,
  Locales.CW,
  Locales.DM,
  Locales.DO,
  Locales.EC,
  Locales.SV,
  Locales.FK,
  Locales.GF,
  Locales.GD,
  Locales.GP,
  Locales.GT,
  Locales.GY,
  Locales.HT,
  Locales.HN,
  Locales.JM,
  Locales.MQ,
  Locales.MS,
  Locales.NI,
  Locales.PA,
  Locales.PY,
  Locales.PR,
  Locales.BL,
  Locales.KN,
  Locales.LC,
  Locales.MF,
  Locales.VC,
  Locales.SX,
  Locales.GS,
  Locales.SR,
  Locales.TT,
  Locales.TC,
  Locales.UY,
  Locales.VE,
  Locales.VG,
  Locales.VI,
  Locales.AN,
  Locales.AQ,
  Locales.KP,
  Locales.RU,
  Locales.BN,
  Locales.KH,
  Locales.LA,
  Locales.MY,
  Locales.MM,
  Locales.PH,
  Locales.TH,
  Locales.TL,
  Locales.VN,
  Locales.AF,
  Locales.BD,
  Locales.BT,
  Locales.IR,
  Locales.MV,
  Locales.NP,
  Locales.PK,
  Locales.LK,
  Locales.PS,
  Locales.BH,
  Locales.IQ,
  Locales.JO,
  Locales.LB,
  Locales.OM,
  Locales.SY,
  Locales.YE,
  Locales.FJ,
  Locales.NC,
  Locales.PG,
  Locales.SB,
  Locales.VU,
  Locales.GU,
  Locales.KI,
  Locales.MH,
  Locales.FM,
  Locales.NR,
  Locales.MP,
  Locales.PW,
  Locales.UM,
  Locales.AS,
  Locales.CK,
  Locales.PF,
  Locales.NU,
  Locales.PN,
  Locales.WS,
  Locales.TK,
  Locales.TO,
  Locales.TV,
  Locales.WF,
];
