import { Log, LogsBatch, LogsBatchId } from '../entities';
import { LogsBatchCache } from '../interfaces';

export class LogsBatchCacheDecorator implements LogsBatchCache {
  constructor(private readonly logsBatchCache: LogsBatchCache) {}

  addPendingLog(log: Log): void {
    return this.logsBatchCache.addPendingLog(log);
  }

  dropBatch(batchId: LogsBatchId): void {
    return this.logsBatchCache.dropBatch(batchId);
  }

  getLastBatch(): LogsBatch | null {
    return this.logsBatchCache.getLastBatch();
  }

  isEmpty(): boolean {
    return this.logsBatchCache.isEmpty();
  }

  saveBatch(batch: LogsBatch): void {
    return this.logsBatchCache.saveBatch(batch);
  }

  getBatches(): Array<LogsBatch> {
    return this.logsBatchCache.getBatches();
  }
}
