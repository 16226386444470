import { normalizeError } from 'core/common/errors';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { Events, FacebookService, PurchaseParams } from '../../interfaces';

export class HttpFacebookService implements FacebookService {
  constructor(private readonly observabilitySystem: ObservabilitySystem) {}

  addToCard(orderId: string) {
    this.track(Events.ADD_TO_CARD, orderId, {});
  }

  purchase({ ltvPredict, orderId, currency }: PurchaseParams) {
    this.track(Events.PURCHASE, orderId, {
      currency: currency,
      value: ltvPredict,
    });
  }

  initiateCheckout(orderId: string) {
    this.track(Events.INITIATE_CHECKOUT, orderId, {});
  }

  track(name: Events, orderId: string, params?: Record<string, unknown>) {
    const span = this.observabilitySystem.startSpan('track_analytics_facebook', {
      attributes: { name, orderId, ...toSpanAttributes(params || {}) },
    });

    try {
      span.addEvent('Pending');
      window.fbq('track', name, params, { eventID: orderId });

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }
}
