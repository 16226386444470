import { GTMData, PurchaseData } from 'core/analytics/entities';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { hash256 } from 'core/common/utils/hash';
import { GTMService } from '../../interfaces';

export class HttpGTMService implements GTMService {
  constructor(private readonly observabilitySystem: ObservabilitySystem) {}

  private get dataLayer() {
    return window.dataLayer || [];
  }

  optOtSellUserData() {
    const span = this.observabilitySystem.startSpan('track_analytics_gtm');

    try {
      span.addEvent('Pending');
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        functionality_storage: 'denied',
        personalization_storage: 'denied',
      });

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }

  purchase({ orderId, productId, price, ltvPredict, email, currency }: PurchaseData) {
    this.track({
      ecommerce: {
        currencyCode: 'USD',
        purchase: {
          actionField: {
            id: orderId,
            revenue: price,
          },
          products: [
            {
              id: productId,
              name: productId,
              price,
              quantity: 1,
            },
          ],
        },
      },
      eventCategory: 'ecommerce',
      eventAction: 'purchase',
      eventValue: 'revenue',
      eventNonInteraction: 'False',
      event: 'autoEvent',
    });
    this.track({
      event: 'purchase_ga4',
      user_params: {
        hashed_email: hash256(email),
      },
      event_params: {
        event_ID: orderId,
      },
      ecommerce: {
        transaction_id: orderId,
        value: ltvPredict,
        currency,
        items: [
          {
            item_name: productId,
            item_id: productId,
            price,
            quantity: 1,
          },
        ],
      },
    });
  }

  addToCart({ price, productId, email, orderId, currency }: GTMData) {
    this.track({
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [
            {
              id: productId,
              name: productId,
              price,
              quantity: 1,
            },
          ],
        },
      },
      eventCategory: 'ecommerce',
      eventAction: 'product',
      eventLabel: 'addToCart',
      eventNonInteraction: 'False',
      event: 'autoEvent',
    });
    this.track({
      event: 'add_to_cart',
      user_params: {
        hashed_email: hash256(email),
      },
      event_params: {
        event_ID: orderId,
      },
      ecommerce: {
        currency,
        items: [
          {
            item_name: productId,
            item_id: productId,
            price,
            quantity: 1,
          },
        ],
      },
    });
  }

  initiateCheckout({ price, productId, email, orderId, currency }: GTMData) {
    this.track({
      ecommerce: {
        currencyCode: 'USD',
        checkout: {
          actionField: { step: 1, option: 'Initiation checkout' },
          products: [
            {
              id: productId,
              name: productId,
              price,
              quantity: 1,
            },
          ],
        },
      },
      event: 'autoEvent',
      eventNonInteraction: 'False',
      eventCategory: 'ecommerce',
      eventAction: 'Initiation checkout',
    });
    this.track({
      event: 'begin_checkout',
      user_params: {
        hashed_email: hash256(email),
      },
      event_params: {
        event_ID: orderId,
      },
      ecommerce: {
        currency,
        items: [
          {
            item_name: productId,
            item_id: productId,
            price,
            quantity: 1,
          },
        ],
      },
    });
  }

  addEmail(email: string, userId: string) {
    this.track({
      ecommerce: null,
      event: 'add_email',
      user_params: {
        hashed_email: hash256(email),
      },
      event_params: {
        event_ID: userId,
      },
    });
  }

  track(data: Record<string, unknown>) {
    const span = this.observabilitySystem.startSpan('track_analytics_gtm', {
      attributes: { ...toSpanAttributes(data) },
    });

    try {
      span.addEvent('Pending');
      this.dataLayer.push(data);

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }
}
