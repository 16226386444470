import validator from 'core/common/utils/validator';

export const UpsellSchema = validator.object({
  offerId: validator.string().optional(),
  price: validator.string(),
  oldPrice: validator.string(),
  discountPercentage: validator.number(),
  discount: validator.string(),
  oldPriceDescription: validator.string().optional(),
  isPrimary: validator.boolean(),
  title: validator.string().optional(),
  emoji: validator.string().optional(),
  description: validator.string().optional(),
  order: validator.number(),
});

export type Upsell = validator.infer<typeof UpsellSchema>;

export const UpsellV2Schema = validator.object({
  offerId: validator.string(),
  price: validator.string(),
  oldPrice: validator.string(),
  backgroundImage: validator
    .object({
      src: validator.string(),
      alt: validator.string(),
    })
    .optional(),
  title: validator.string(),
  subtitle: validator.string(),
  billingCaption: validator.string(),
  details: validator.array(validator.string()),
  discount: validator.object({
    value: validator.number(),
    text: validator.string(),
  }),
});

export type UpsellV2 = validator.infer<typeof UpsellV2Schema>;
