import { useMemo } from 'react';
import { useLocation } from 'react-router';

export default function usePreviousLocation() {
  const location = useLocation();

  const lastLocation = useMemo(() => {
    if (location.state) {
      return location.state.from;
    }
    return '';
  }, [location]);

  return lastLocation;
}
