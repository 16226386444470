import { useEffect } from 'react';
import { SECOND } from '../constants';
import { useLocalStorage } from './useLocalStorage';

export const useCountdown = (key: string, duration: number): { specialPlanCountdown: number } => {
  const [specialPlanCountdown, setSpecialPlanCountdown] = useLocalStorage(key, duration);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | null = null;
    if (specialPlanCountdown > 0) {
      timer = setTimeout(() => {
        setSpecialPlanCountdown(specialPlanCountdown - SECOND);
      }, SECOND);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [specialPlanCountdown, setSpecialPlanCountdown]);

  return { specialPlanCountdown };
};
