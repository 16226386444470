import { AppEnvironment } from './AppEnvironment';

export class AppServerEnvironment implements AppEnvironment {
  isTestingEnv() {
    return false;
  }

  getSplitId() {
    return '';
  }

  isSafari() {
    return false;
  }

  isAppleMobile() {
    return false;
  }

  isApple() {
    return false;
  }

  isApplePaySupported() {
    return false;
  }

  isMobileDevice() {
    return false;
  }
}
