import { removeCharactersBetween } from './string';

export function removeCharactersBetweenPlusAndAt(email: string) {
  return removeCharactersBetween({ value: email, start: '+', end: '@' });
}
export function removePeriodsBeforeAt(email: string) {
  const atIndex = email.indexOf('@');

  return email.slice(0, atIndex).replace(/\./g, '') + email.slice(atIndex);
}
