import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { AppThunk } from 'core/common/store';
import { UserSubscription } from '../../entities';

export const fetchUserSubscriptionsPending = createAction(
  'subscriptions/FETCH_SUBSCRIPTIONS/pending',
);
export const fetchUserSubscriptionsSucceed = createAction<UserSubscription[]>(
  'subscriptions/FETCH_SUBSCRIPTIONS/succeed',
);
export const fetchUserSubscriptionsFailed = createAction(
  'subscriptions/FETCH_SUBSCRIPTIONS/failed',
);

export const fetchUserSubscriptionsRequest =
  (email: string): AppThunk =>
  async (dispatch, _, extra) => {
    const { subscriptionsService, loggingService: logger } = extra;

    dispatch(fetchUserSubscriptionsPending());

    try {
      const subscriptions = await subscriptionsService.getSubscriptions(email);

      dispatch(fetchUserSubscriptionsSucceed(subscriptions));
    } catch (error) {
      const err = normalizeError(error);
      logger.error(err);

      dispatch(fetchUserSubscriptionsFailed());
    }
  };

export const cancelUserSubscriptionPending = createAction(
  'subscriptions/CANCEL_SUBSCRIPTION/pending',
);
export const cancelUserSubscriptionSucceed = createAction<string>(
  'subscriptions/CANCEL_SUBSCRIPTION/succeed',
);
export const cancelUserSubscriptionFailed = createAction(
  'subscriptions/CANCEL_SUBSCRIPTION/failed',
);

export const cancelUserSubscriptionRequest =
  (id: string, paymentType: string): AppThunk =>
  async (dispatch, _, extra) => {
    const { subscriptionsService, loggingService: logger } = extra;

    dispatch(cancelUserSubscriptionPending());

    try {
      await subscriptionsService.unsubscribe(id, paymentType);

      dispatch(cancelUserSubscriptionSucceed(id));
    } catch (error) {
      const err = normalizeError(error);
      logger.error(err);

      dispatch(cancelUserSubscriptionFailed());
    }
  };
