export enum BASE_ROUTES {
  APP_SUBSCRIPTION_4_7D_TRIAL_PER_DAY = '/app-subscription-4-7d-trial-per-day',
  APP_SUB_INTRO = '/app-sub-intro',
  ATTACHMENT_STYLE = '/attachment-style',
  PALMISTRY = '/palmistry',
  PALMISTRY_INTRO = '/palmistry-intro',
  NATAL_CHART = '/natal-chart',
  WITCH_POWER = '/witch-power',
  WITCH_POWER_INTRO = '/witch-power-intro',
  SHAMANS = '/shamans',
  MOON_COMPATIBILITY = '/moon-compatibility',
  FEMININE_ARCHETYPES = '/feminine-archetypes',
  TECH_TERMS = '/tech/terms',
  TECH_PRIVACY_POLICY = '/tech/privacy-policy',
  TECH_FAQ = '/tech/faq',
  TECH_CONTACT_US = '/tech/contact-us',
  TECH_PAYMENT_TERMS = '/tech/payment-terms',
  TECH_PERSONAL_INFO_POLICY = '/tech/personal-info-policy',
  TECH_MONEY_BACK_POLICY = '/tech/money-back-policy',
  TECH_SUBSCRIPTION_TERMS = '/tech/subscription-terms',
  TECH_DELIVERY_POLICY = '/tech/delivery-policy',
  CANCEL_SUBSCRIPTION = '/cancel-subscription',
  EMAIL_CONFIRMATION = '/email/confirmation',
  EMAIL_MARKETING = '/email/marketing-landing-0',
  EMAIL_MARKETING_1 = '/email/marketing-landing-1',
  EMAIL_MARKETING_2 = '/email/marketing-landing-2',
  EMAIL_MARKETING_3 = '/email/marketing-landing-3',
  EMAIL_MARKETING_4 = '/email/marketing-landing-4',
  EMAIL_MARKETING_TRIAL_PAYMENT = '/email/marketing-trial-payment',
  EMAIL_NUMEROLOGY_ANALYSIS = '/email/numerology-analysis',
  EMAIL_TAROT_READING = '/email/tarot-reading',
  EMAIL_PALMISTRY_GUIDE = '/email/palmistry-guide',
  EMAIL_ULTRA_PACK = '/email/ultra-pack',
  EMAIL_CONSULTATION = '/email/consultation',
  DRAFT = '/draft',
}

export enum LANDINGS {
  PRODUCT_OVERVIEW = '/productOverview',
  TRIAL_CHOICE = '/trialChoice',
  TRIAL_PAYMENT = '/trialPayment',
  TRIAL_CREDITS = '/trialCredits',
  BILLING = '/billing',
  ADDITIONAL_DISCOUNT = '/additionalDiscount',
  UPSELLS = '/upsells',
  ECOM_UPSELL = '/ecomUpsell',
  ONBOARDING = '/askOnboarding',
  PAYMENT = '/payment',
  PAYMENT_WITH_DISCOUNT = '/paymentWithDiscount',
  TRIAL_PAYMENT_WITH_DISCOUNT = '/trialPaymentWithDiscount',
  TALK_WITH_EXPERT = '/talkWithExpert',
  QUESTIONS = '/questions',
  CREDITS = '/credits',
  LOADING = '/loading',
  MATCHING = '/matching',
  UNLIMITED_READINGS = '/unlimitedReadings',
  OVERVIEW = '/overview',
  PHONE_NUMBER = '/phoneNumber',
}

export enum QUIZ_ROUTES {
  GENDER = '/gender',
}
