import { normalizeError } from 'core/common/errors';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { PinterestParams } from '../../entities';
import { PinterestService } from '../../interfaces';

export class HttpPinterestService implements PinterestService {
  constructor(private readonly observabilitySystem: ObservabilitySystem) {}

  lead(orderId: string) {
    this.track('lead', {
      event_id: orderId,
      lead_type: 'Newsletter',
    });
  }

  checkout({ price, orderId, currency }: PinterestParams) {
    this.track('checkout', {
      event_id: orderId,
      currency: currency,
      value: price,
    });
  }

  track(name: string, params?: Record<string, unknown>) {
    const span = this.observabilitySystem.startSpan('track_analytics_pinterest', {
      attributes: { name, ...toSpanAttributes(params || {}) },
    });

    try {
      span.addEvent('Pending');
      window.pintrk('track', name, params);

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }
}
