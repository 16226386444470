// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decimalAdjust(type: 'round' | 'floor' | 'ceil', rawValue: any, rawExp: number) {
  // If the exp is undefined or zero...
  let value = rawValue;
  let exp = rawExp;

  if (typeof exp === 'undefined' || +exp === 0) {
    return Math[type](value);
  }
  value = +value;
  exp = +exp;
  // If the value is not a number or the exp is not an integer...
  if (Number.isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  // Shift
  value = value.toString().split('e');
  value = Math[type](+`${value[0]}e${value[1] ? +value[1] - exp : -exp}`);
  // Shift back
  value = value.toString().split('e');
  return +`${value[0]}e${value[1] ? +value[1] + exp : exp}`;
}

export function roundNumber(value: number | string, exp: number) {
  return decimalAdjust('round', value, exp);
}

export function roundToNearestDecimal(value: number, decimal = 1) {
  return +value.toFixed(decimal);
}
