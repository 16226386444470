import { Logger } from 'core/common/services/logger/interfaces';
import { HttpClient } from '../httpСlient';

export abstract class BaseService {
  protected httpClient: HttpClient;
  protected logger: Logger;

  constructor(httpClient: HttpClient, logger: Logger) {
    this.httpClient = httpClient;
    this.logger = logger;
  }
}
