import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { stringifyQueryParams } from 'core/common/utils/getQueryParams';
import { HumanDesignReportDtoMapper } from 'core/human-design/mappers';
import { HumanDesignReportDto } from './dto';

type GenerateReportParams = {
  name: string;
  birthDate: string;
  birthTime: string;
  birthPlace: {
    location: string;
    longitude: number;
    latitude: number;
  };
};

export class HumanDesignReportService extends BaseService {
  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient, logger);
  }

  async generateReport({ name, birthDate, birthTime, birthPlace }: GenerateReportParams) {
    const queryParams = stringifyQueryParams({
      name,
      birth_date: birthDate,
      birth_time: birthTime,
      birth_place: birthPlace.location,
      longitude: birthPlace.longitude,
      latitude: birthPlace.latitude,
    });

    const dtoMapper = new HumanDesignReportDtoMapper();

    const { data } = await this.httpClient.get<HumanDesignReportDto>(
      `/human-design/chart?${queryParams}`,
    );

    return dtoMapper.toEntity(data);
  }
}
