import { createReducer } from '@reduxjs/toolkit';
import { saveExperiment } from './actions';

type InitialState = {
  experiments: Record<string, string>;
};

export const featureFlagsInitialState: InitialState = {
  experiments: {},
};

export const featureFlagsReducer = createReducer(featureFlagsInitialState, (builder) => {
  builder.addCase(saveExperiment, (state, { payload }) => {
    state.experiments = { ...state.experiments, [payload.name]: payload.group };
  });
});
