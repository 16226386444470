import i18next, { t as translate, TFunction } from 'i18next';
import { Languages } from '../entities';

type TFunctionKeys = Parameters<TFunction>[0];

export const getCurrentLanguage = () => {
  return i18next.language as Languages;
};

export type TranslationOptions = Record<string, string | number>;

export const tArray = (
  localArray: TFunctionKeys,
  variables?: TranslationOptions,
): ReturnType<TFunction> => {
  return translate(localArray, { returnObjects: true, ...variables });
};

export const t = (local: TFunctionKeys): string => {
  return translate(local);
};
