import { formatPrice } from 'core/common/utils/formatPrice';
import {
  getOfferByCategory,
  getOffersByCategory,
  Offer,
  OffersByCategory,
  StrictOffersMapByCategory,
} from 'core/offers/entities';
import { TaxService } from 'core/offers/services/tax';
import { Locales } from 'core/user/entities';
import { CurrencyService } from 'core/user/services';
import { OfferDto } from '../services/offers/dto';

export class OfferMapper {
  private taxService: TaxService;
  private currencyService: CurrencyService;
  constructor(taxService: TaxService, currencyService: CurrencyService) {
    this.taxService = taxService;
    this.currencyService = currencyService;
  }

  dtoToEntity(offerDto: OfferDto, locale: Locales): Offer {
    const currencySymbol = this.currencyService.getCurrencySymbol(offerDto.currency);
    const currency = offerDto.currency;

    const offerWithoutPrices = {
      id: offerDto.id,
      offerId: offerDto.name,
      description: offerDto.description,
      trial: offerDto.trial,
      period: offerDto.period,
      trialPeriod: offerDto.trialPeriod,
      currency,
      currencySymbol,
    };

    const isCountryWithVAT = this.taxService.checkIsCountryWithVAT(locale);

    const priceValues = isCountryWithVAT
      ? this.calculatePricesWithVAT(offerDto)
      : this.calculatePricesWithoutVAT(offerDto);

    return {
      ...offerWithoutPrices,
      prices: {
        trialPrice: priceValues.trialPrice,
        vatOfTrialPrice: priceValues.vatOfTrialPrice,

        trialPriceWithVAT: priceValues.trialPriceWithVAT,
        price: priceValues.price,
        vatOfPrice: priceValues.vatOfPrice,
        priceWithVAT: priceValues.priceWithVAT,
      },
      pricesWithCurrencySymbol: {
        trialPrice: `${currencySymbol}${formatPrice(priceValues.trialPrice)}`,
        vatOfTrialPrice: `${currencySymbol}${formatPrice(priceValues.vatOfTrialPrice)}`,
        trialPriceWithVAT: `${currencySymbol}${formatPrice(priceValues.trialPriceWithVAT)}`,
        price: `${currencySymbol}${formatPrice(priceValues.price)}`,
        vatOfPrice: `${currencySymbol}${formatPrice(priceValues.vatOfPrice)}`,
        priceWithVAT: `${currencySymbol}${formatPrice(priceValues.priceWithVAT)}`,
      },
      pricesWithCurrency: {
        trialPrice: `${formatPrice(priceValues.trialPrice)} ${currency}`,
        vatOfTrialPrice: `${formatPrice(priceValues.vatOfTrialPrice)} ${currency}`,
        trialPriceWithVAT: `${formatPrice(priceValues.trialPriceWithVAT)} ${currency}`,
        price: `${formatPrice(priceValues.price)} ${currency}`,
        vatOfPrice: `${formatPrice(priceValues.vatOfPrice)} ${currency}`,
        priceWithVAT: `${formatPrice(priceValues.priceWithVAT)} ${currency}`,
      },
    };
  }

  private calculatePricesWithVAT(offerDto: OfferDto): Record<keyof Offer['prices'], number> {
    return {
      trialPrice: this.taxService.getPriceWithoutVAT(offerDto.trialPrice),
      vatOfTrialPrice: this.taxService.getValueAddedTax(offerDto.trialPrice),
      trialPriceWithVAT: offerDto.trialPrice,
      price: this.taxService.getPriceWithoutVAT(offerDto.price),
      vatOfPrice: this.taxService.getValueAddedTax(offerDto.price),
      priceWithVAT: offerDto.price,
    };
  }

  private calculatePricesWithoutVAT(offerDto: OfferDto): Record<keyof Offer['prices'], number> {
    return {
      trialPriceWithVAT: offerDto.trialPrice,
      trialPrice: offerDto.trialPrice,
      vatOfTrialPrice: 0,
      price: offerDto.price,
      priceWithVAT: offerDto.price,
      vatOfPrice: 0,
    };
  }
}

export const fetchedOffersToOffersMap = (
  fetchedOffers: Offer[],
  offersByCategory: OffersByCategory,
): StrictOffersMapByCategory => {
  const {
    defaultOffers,
    timeUpOffers,
    discountDefaultOffers,
    discountOffer,
    numerologyAnalysisUpsell,
    tarotReadingUpsell,
    palmistryGuideUpsell,
    ultraPackUpsell,
    trialCreditsUpsell,
    premiumUpsell,
    natalChartUpsell,
    blackMoonUpsell,
    discountNumerologyAnalysisUpsell,
    discountUltraPackUpsell,
    discountPalmistryUpsell,
    discountTarotReadingUpsell,
    consultationUpsell,
    discountConsultationUpsell,
    readingsPackUpsellOffer,
  } = offersByCategory;

  return {
    defaultOffers: getOffersByCategory(fetchedOffers, defaultOffers),
    timeUpOffers: getOffersByCategory(fetchedOffers, timeUpOffers),
    discountDefaultOffers: getOffersByCategory(fetchedOffers, discountDefaultOffers),
    discountOffer: getOfferByCategory(fetchedOffers, discountOffer),
    numerologyAnalysisUpsell: getOfferByCategory(fetchedOffers, numerologyAnalysisUpsell),
    tarotReadingUpsell: getOfferByCategory(fetchedOffers, tarotReadingUpsell),
    palmistryGuideUpsell: getOfferByCategory(fetchedOffers, palmistryGuideUpsell),
    ultraPackUpsell: getOfferByCategory(fetchedOffers, ultraPackUpsell),
    discountUltraPackUpsell: getOfferByCategory(fetchedOffers, discountUltraPackUpsell),
    trialCreditsUpsell: getOfferByCategory(fetchedOffers, trialCreditsUpsell),
    premiumUpsell: getOfferByCategory(fetchedOffers, premiumUpsell),
    natalChartUpsell: getOfferByCategory(fetchedOffers, natalChartUpsell),
    blackMoonUpsell: getOfferByCategory(fetchedOffers, blackMoonUpsell),
    discountNumerologyAnalysisUpsell: getOfferByCategory(
      fetchedOffers,
      discountNumerologyAnalysisUpsell,
    ),
    discountPalmistryUpsell: getOfferByCategory(fetchedOffers, discountPalmistryUpsell),
    discountTarotReadingUpsell: getOfferByCategory(fetchedOffers, discountTarotReadingUpsell),
    consultationUpsell: getOfferByCategory(fetchedOffers, consultationUpsell),
    discountConsultationUpsell: getOfferByCategory(fetchedOffers, discountConsultationUpsell),
    readingsPackUpsellOffer: getOfferByCategory(fetchedOffers, readingsPackUpsellOffer),
  };
};
