import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { BackendAnalyticsService } from '../../interfaces';
import { BackendAnalyticsServiceDecorator } from './BackendAnalyticsServiceDecorator';

export class BackendAnalyticsWithObservability extends BackendAnalyticsServiceDecorator {
  constructor(
    backendAnalyticsService: BackendAnalyticsService,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    super(backendAnalyticsService);
  }

  async setSplitExperimentProperties(props: {
    experimentName: string;
    groupName: string;
  }): Promise<void> {
    const span = this.observabilitySystem.startSpan('track_analytics_back_end', {
      attributes: props,
    });

    try {
      span.addEvent('Pending');
      await super.setSplitExperimentProperties(props);

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();

      throw err;
    }
  }
}
