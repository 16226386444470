import { createContext, ReactNode } from 'react';
import { AnalyticsService } from 'core/analytics/interfaces';
import {
  AnalyticsClientService,
  AnalyticsServerService,
  BackendAnalyticsWithObservability,
  ConversionsAPIService,
  HttpAmplitudeService,
  HttpBackendAnalyticsService,
  HttpBingAdsService,
  HttpFacebookService,
  HttpGTMService,
  HttpLtvPredictService,
  HttpPinterestService,
  HttpTikTokService,
  LtvPredictServiceWithObservability,
} from 'core/analytics/services';
import { AuthService } from 'core/auth/services';
import { BrowserAppResource } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import {
  OTelObservabilitySystem,
  OTelObservabilitySystemBuilder,
} from 'core/common/observability/services';
import {
  AppClientEnvironment,
  AppEnvironment,
  AppServerEnvironment,
  ChatService,
  CookieConsentService,
  CookiesService,
  createHttpClient,
  HeatmapRecording,
  HeatmapRecordingClientService,
  HeatmapRecordingServerService,
  OneTrustCookieConsentService,
} from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { buildLogger } from 'core/common/services/logger/utils/buildLogger';
import { checkIsClientSide } from 'core/common/utils/checkIsClientSide';
import { ExpertService } from 'core/experts/services';
import { featureFlagsConfig } from 'core/feature-flags/config';
import {
  CookiesIdFeatureFlagsDecorator,
  GrowthBookFeatureFlags,
  QuerySettingFeatureFlagsDecorator,
} from 'core/feature-flags/services';
import { FunnelServiceBuilder } from 'core/funnel/builders';
import { FunnelService } from 'core/funnel/interfaces';
import { HandlebarsTemplateEngine } from 'core/funnel/services';
import { HumanDesignReportService } from 'core/human-design/services';
import { OffersService, TaxService } from 'core/offers/services';
import { PalmDetectionService } from 'core/palmistry/services';
import { PurchaseService } from 'core/payments/services';
import {
  AutocompleteService,
  CurrencyService,
  LocationClientService,
  LocationServerService,
  LocationService,
  SettingsService,
  SubscriptionsService,
  UserService,
  UtmTagsService,
} from 'core/user/services';
import { UtmTagsClientService, UtmTagsServerService } from 'core/user/services/utmTags';
import { ZodiacService } from 'core/zodiac/services';
import { Config } from './ConfigContext';

export interface Services {
  appEnvironment: AppEnvironment;
  utmTagsService: UtmTagsService;
  purchaseService: PurchaseService;
  zodiacService: ZodiacService;
  cookiesService: CookiesService;
  loggingService: Logger;
  analyticsService: AnalyticsService;
  locationService: LocationService;
  subscriptionsService: SubscriptionsService;
  settingsService: SettingsService;
  authService: AuthService;
  taxService: TaxService;
  userService: UserService;
  autocompleteService: AutocompleteService;
  expertService: ExpertService;
  chatService: ChatService;
  currencyService: CurrencyService;
  offersService: OffersService;
  palmDetectionService: PalmDetectionService;
  templateEngine: HandlebarsTemplateEngine;
  cookieConsentService: CookieConsentService;
  funnelService: FunnelService;
  humanDesignReportService: HumanDesignReportService;
  observabilitySystem: ObservabilitySystem;
  heatmapRecordingService: HeatmapRecording;
}

type AppDependencies = {
  services: Services;
  featureFlags: QuerySettingFeatureFlagsDecorator;
};

export const initServerDependencies = (config: Config): AppDependencies => {
  const cookiesService = new CookiesService({
    domain: config.rootDomain,
  });

  const { logger: loggingService } = buildLogger();

  const httpClient = createHttpClient(config.apiUrl);

  const observabilitySystem = new OTelObservabilitySystem(config);

  const utmTagsService = UtmTagsServerService.crateFromQueryParams(loggingService);
  const analyticsService = new AnalyticsServerService();
  const appEnvironment = new AppServerEnvironment();

  const growthBookFeatureFlags = new GrowthBookFeatureFlags(
    featureFlagsConfig,
    appEnvironment,
    config,
    loggingService,
  );
  const featureFlagsWithIdentify = new CookiesIdFeatureFlagsDecorator(
    growthBookFeatureFlags,
    cookiesService,
    config,
    loggingService,
  );
  const featureFlags = new QuerySettingFeatureFlagsDecorator(featureFlagsWithIdentify);

  const purchaseService = new PurchaseService(httpClient, loggingService);
  const zodiacService = new ZodiacService(httpClient, loggingService);
  const locationService = new LocationServerService();
  const subscriptionsService = new SubscriptionsService(httpClient, loggingService);
  const taxService = new TaxService(httpClient, loggingService);
  const userService = new UserService(httpClient, loggingService);
  const settingsService = new SettingsService(httpClient, loggingService);
  const authService = new AuthService(httpClient, loggingService);
  const autocompleteService = new AutocompleteService(config.geocodeUrl, config.geocodeApiKey);
  const expertService = new ExpertService(httpClient, loggingService);
  const currencyService = new CurrencyService();
  const offersService = new OffersService(httpClient, loggingService, {
    taxService,
    currencyService,
  });
  const chatService = new ChatService(httpClient, loggingService);
  const palmClient = createHttpClient(config.palmApiUrl);
  const palmDetectionService = new PalmDetectionService(palmClient, loggingService);
  const templateEngine = new HandlebarsTemplateEngine();
  const cookieConsentService = new OneTrustCookieConsentService(config);
  const funnelService = FunnelServiceBuilder.create(config);
  const humanDesignReportService = new HumanDesignReportService(httpClient, loggingService);
  const heatmapRecordingService = new HeatmapRecordingServerService();

  return {
    featureFlags,
    services: {
      appEnvironment,
      loggingService,
      utmTagsService,
      purchaseService,
      zodiacService,
      cookiesService,
      analyticsService,
      locationService,
      subscriptionsService,
      settingsService,
      authService,
      taxService,
      userService,
      autocompleteService,
      expertService,
      chatService,
      palmDetectionService,
      currencyService,
      offersService,
      templateEngine,
      cookieConsentService,
      funnelService,
      humanDesignReportService,
      observabilitySystem,
      heatmapRecordingService,
    },
  };
};

export const initClientDependencies = (config: Config): AppDependencies => {
  const cookiesService = new CookiesService({
    domain: config.rootDomain,
  });
  const appEnvironment = new AppClientEnvironment(cookiesService, config);

  const { logger: loggingService } = buildLogger();

  const resource = BrowserAppResource.create();

  resource.setAppName(config.appName).setEnv(config.appEnvironment);

  const observabilitySystemBuilder = OTelObservabilitySystemBuilder.create(config)
    .addBrowserTraceProvider({ resource })
    .withSplitId(appEnvironment)
    .addXMLHttpRequestInstrumentation({
      ignoreUrls: config.observability.httpInstrumentationIgnoreUrls,
      spanRate: config.observability.httpInstrumentationSpanRate,
    })
    .addFetchInstrumentation({
      ignoreUrls: config.observability.httpInstrumentationIgnoreUrls,
      spanRate: config.observability.httpInstrumentationSpanRate,
    })
    .addDocumentLoadedInstrumentation()
    .withHttpSpanExporter({
      url: config.observability.tracesUrl,
      headers: {},
    });

  const observabilitySystem = observabilitySystemBuilder.getResult();

  const httpClient = createHttpClient(config.apiUrl);

  const utmTagsService = UtmTagsClientService.crateFromQueryParams(cookiesService, loggingService);
  const amplitudeService = new HttpAmplitudeService(
    observabilitySystem,
    config.amplitudeApiKey,
    config.amplitudeApiEndpoint,
  );
  const conversionsAPIService = new ConversionsAPIService(cookiesService);
  const facebookService = new HttpFacebookService(observabilitySystem);
  const pinterestService = new HttpPinterestService(observabilitySystem);
  const gtmService = new HttpGTMService(observabilitySystem);
  const ltvPredictService = new LtvPredictServiceWithObservability(
    new HttpLtvPredictService(httpClient, loggingService, utmTagsService),
    observabilitySystem,
  );
  const backendAnalyticsService = new BackendAnalyticsWithObservability(
    new HttpBackendAnalyticsService(httpClient, loggingService),
    observabilitySystem,
  );
  const tikTokService = new HttpTikTokService(cookiesService, observabilitySystem);
  const bingAdsService = new HttpBingAdsService(observabilitySystem);

  const analyticsService = new AnalyticsClientService(
    amplitudeService,
    conversionsAPIService,
    tikTokService,
    facebookService,
    pinterestService,
    gtmService,
    utmTagsService,
    ltvPredictService,
    backendAnalyticsService,
    loggingService,
    bingAdsService,
  );

  const growthBookFeatureFlags = new GrowthBookFeatureFlags(
    featureFlagsConfig,
    appEnvironment,
    config,
    loggingService,
  );
  const featureFlagsWithIdentify = new CookiesIdFeatureFlagsDecorator(
    growthBookFeatureFlags,
    cookiesService,
    config,
    loggingService,
  );
  const featureFlags = new QuerySettingFeatureFlagsDecorator(featureFlagsWithIdentify);

  const purchaseService = new PurchaseService(httpClient, loggingService);
  const zodiacService = new ZodiacService(httpClient, loggingService);
  const locationService = new LocationClientService(httpClient, loggingService);
  const subscriptionsService = new SubscriptionsService(httpClient, loggingService);
  const taxService = new TaxService(httpClient, loggingService);
  const userService = new UserService(httpClient, loggingService);
  const settingsService = new SettingsService(httpClient, loggingService);
  const authService = new AuthService(httpClient, loggingService);
  const autocompleteService = new AutocompleteService(config.geocodeUrl, config.geocodeApiKey);
  const expertService = new ExpertService(httpClient, loggingService);
  const chatService = new ChatService(httpClient, loggingService);
  const palmistryClient = createHttpClient(config.palmApiUrl);
  const palmDetectionService = new PalmDetectionService(palmistryClient, loggingService);
  const currencyService = new CurrencyService();
  const offersService = new OffersService(httpClient, loggingService, {
    taxService,
    currencyService,
  });
  const templateEngine = new HandlebarsTemplateEngine();
  const cookieConsentService = new OneTrustCookieConsentService(config);
  const funnelService = FunnelServiceBuilder.create(config);
  const humanDesignReportService = new HumanDesignReportService(httpClient, loggingService);
  const heatmapRecordingService = new HeatmapRecordingClientService(
    loggingService,
    analyticsService,
  );

  return {
    featureFlags,
    services: {
      appEnvironment,
      loggingService,
      utmTagsService,
      purchaseService,
      zodiacService,
      cookiesService,
      analyticsService,
      locationService,
      subscriptionsService,
      settingsService,
      authService,
      taxService,
      userService,
      autocompleteService,
      expertService,
      chatService,
      palmDetectionService,
      currencyService,
      offersService,
      templateEngine,
      cookieConsentService,
      funnelService,
      humanDesignReportService,
      observabilitySystem,
      heatmapRecordingService,
    },
  };
};

export const initDependencies = (config: Config): AppDependencies => {
  const appDependencies = checkIsClientSide()
    ? initClientDependencies(config)
    : initServerDependencies(config);

  const { featureFlags, services } = appDependencies;

  featureFlags.onTrackExperiment((experiment, result) => {
    services.analyticsService.setUserExperimentGroupName(experiment.key, result.key);
  });

  return { ...appDependencies };
};

export const ServicesContext = createContext<Services>(undefined!);

type ServicesProviderProps = {
  value: Services;
  children: ReactNode;
};

export const ServicesProvider = ({ value, children }: ServicesProviderProps) => {
  return <ServicesContext.Provider value={value}>{children}</ServicesContext.Provider>;
};
