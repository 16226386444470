import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { PaymentError, ResignFormData } from '../../entities';

export const initializeResignFormPending = createAction(`payments/INITIALIZE_RESIGN_FORM/pending`);

export const initializeResignFormSucceed = createAction<ResignFormData>(
  `payments/INITIALIZE_RESIGN_FORM/succeed`,
);

export const initializeResignFormFailed = createAction<PaymentError>(
  `payments/INITIALIZE_RESIGN_FORM/failed`,
);

export const initializeResignFormRequest =
  (offerId: string, tokenId: string): AppThunk =>
  async (dispatch, _, context) => {
    const {
      purchaseService,
      loggingService: logger,
      observabilitySystem,
      analyticsService,
    } = context;

    dispatch(initializeResignFormPending());

    const span = observabilitySystem.startSpan('initialize_resign_form', {
      attributes: { offerId, tokenId },
    });
    span.addEvent('Pending');

    try {
      const resignFormData = await purchaseService.getResignForm(offerId, tokenId);

      dispatch(initializeResignFormSucceed(resignFormData));
      analyticsService.trackEvent('initialize_resign_form_succeed');

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);
      logger.error(error);

      dispatch(initializeResignFormFailed(error));
      analyticsService.trackEvent('initialize_resign_form_failed', {
        offerId,
      });

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  };

export const resignFormSucceed = createAction(`payments/RESIGN_FORM/succeed`);

export const resignFormFailed = createAction(`payments/RESIGN_FORM/failed`);
