export type AutocompleteFeature = {
  geometry: {
    coordinates: number[];
  };
  properties: {
    id: string;
    country: string;
    name: string;
    region: string;
  };
};

export class AutocompleteService {
  private readonly url: string;

  private readonly apiKey: string;

  constructor(url: string, apiKey: string) {
    this.url = url;
    this.apiKey = apiKey;
  }

  async getFeatures(text: string): Promise<AutocompleteFeature[]> {
    const url = new URL('v1/autocomplete', this.url);
    url.searchParams.append('text', text);
    const response = await fetch(url.href);
    const { features } = await response.json();
    return features;
  }
}
