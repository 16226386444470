import qs from 'qs';
import { checkIsClientSide } from './checkIsClientSide';

export function getQueryParams<T extends { [key: string]: undefined | string | string[] }>(): T {
  if (!checkIsClientSide()) return {} as T;

  return qs.parse(window.location.search, { ignoreQueryPrefix: true }) as T;
}

export function parseQueryParams<T extends { [key: string]: undefined | string | string[] }>(
  str: string,
) {
  return qs.parse(str, { ignoreQueryPrefix: true }) as T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function stringifyQueryParams(obj: any) {
  return qs.stringify(obj, { arrayFormat: 'comma' });
}
