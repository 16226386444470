import { useEffect } from 'react';
import { useConfig } from './useConfig';

export const usePostMessageToFunnelBuilderOnRouteChange = (pageId: string) => {
  const config = useConfig();
  const origins = config.funnelBuilderTrustedOrigins;

  useEffect(() => {
    if (origins.length) {
      origins.forEach((origin) => {
        window.parent.postMessage({ message: 'routeChanged', pageId: pageId }, origin);
      });
    }
  }, [pageId, origins]);
};
