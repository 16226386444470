import { BaseService } from 'core/common/services';
import { RelationshipTypes } from 'core/funnel/entities';

export class SettingsService extends BaseService {
  async setEmailConsent(emailConsent: boolean): Promise<void> {
    const { data } = await this.httpClient.put(`/user/settings`, {
      data: {
        email_consent: emailConsent,
      },
    });

    return data;
  }

  async setReportType(reportType: RelationshipTypes): Promise<void> {
    const { data } = await this.httpClient.put(`/user/settings`, {
      data: {
        report_type: reportType,
      },
    });

    return data;
  }

  async optOutSellData() {
    const { data } = await this.httpClient.put('/user/settings', {
      data: {
        do_not_sell_data: true,
      },
    });

    return data;
  }
}
