import { AnalyticsClientService } from 'core/analytics/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { HeatmapRecording, HeatmapRecordingIdentifyUserOptions } from './HeatmapRecording';

export class HeatmapRecordingClientService implements HeatmapRecording {
  constructor(private logger: Logger, private analyticsService: AnalyticsClientService) {}

  private get track() {
    if (!window.clarity) {
      this.logger.warn('Clarity is not initialized');
      return () => {};
    }

    return window.clarity;
  }

  startRecording() {
    const deviceId = this.analyticsService.getDeviceId();

    this.giveCookieConsent();
    this.identifyUser({ customId: deviceId });

    if (deviceId) this.addCustomTag('device id', deviceId);
  }

  triggerCustomEvent(value: string) {
    this.track('event', value);
  }

  addCustomTag(key: string, value: string | [string, string]) {
    this.track('set', key, value);
  }

  identify(email: string) {
    const deviceId = this.analyticsService.getDeviceId();

    this.identifyUser({ friendlyName: email, customId: deviceId });
    this.addCustomTag('email', email);
  }

  markImportantSession(reason: string) {
    this.track('upgrade', reason);
  }

  private identifyUser(options: HeatmapRecordingIdentifyUserOptions) {
    this.track(
      'identify',
      options.customId,
      options.customSessionId,
      options.customPageId,
      options.friendlyName,
    );
  }

  private giveCookieConsent() {
    this.track('consent');
  }
}
