import { useEffect, useState } from 'react';

type Params = {
  callback?: () => void;
  timeout?: number;
  max?: number;
};

export const useProgress = (data: Params = {}) => {
  const { max = 100, timeout = 300, callback } = data;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProgress((prev) => prev + 1);
    }, timeout);

    if (progress >= max) {
      clearTimeout(timer);

      if (callback) {
        callback();
      }
    }

    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);

  return {
    progress,
  };
};
