import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  fetchUserLocationFailed,
  fetchUserLocationPending,
  fetchUserLocationSucceed,
} from './actions';
import { userLocationInitialState } from './state';

export const userLocationReducer = createReducer(userLocationInitialState, (builder) => {
  builder
    .addCase(fetchUserLocationPending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchUserLocationSucceed, (state, { payload }) => {
      state.location = payload;
      state.loadingStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(fetchUserLocationFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    });
});
