import { BatchDestination } from 'core/common/services/logger/batch-logger-transport';
import { LogsBatch } from 'core/common/services/logger/entities';
import { mapLogsBatchToGrafanaLoggerLogs } from '../mappers';
import { GrafanaLoggerService } from './GrafanaLoggerService';

export class GrafanaLoggerBatchDestination implements BatchDestination {
  constructor(private readonly lokiService: GrafanaLoggerService) {}

  async sendBatch(logsBatch: LogsBatch): Promise<void> {
    const logs = mapLogsBatchToGrafanaLoggerLogs(logsBatch);

    await this.lokiService.pushLogs(logs);
  }
}
