import dayJs, { Dayjs, OptionType } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import { shortMonthsListEn } from 'core/common/constants';
import { Languages } from 'core/localization/entities';
import { DateParts } from '../entities';
import { addLeadingZero } from './addLeadingZero';

dayJs.extend(localizedFormat);
dayJs.extend(advancedFormat);

export const createDate = (
  date?: string | number | Date | Dayjs | null | undefined,
  format?: OptionType,
  locale?: string,
  strict?: boolean,
) => {
  return dayJs(date, format, locale, strict);
};

export const formatDateByLocale = (date: string, locale: Languages) => {
  const dateTemplate = locale === Languages.EN ? 'MMM D YYYY' : 'D MMMM YYYY';

  return createDate(date).locale(locale).format(dateTemplate);
};

export const extractDateParts = (date = '1990-01-01'): DateParts => {
  const formattedDate = createDate(date, 'MM-DD-YYYY');
  const defaultDate = {
    day: 1,
    month: 0,
    year: 1990,
  };

  const day = formattedDate.get('date') || defaultDate.day;
  const month = formattedDate.get('month') || defaultDate.month;
  const year = formattedDate.get('year') || defaultDate.year;

  return {
    day: addLeadingZero(day),
    month: shortMonthsListEn[month],
    year: addLeadingZero(year),
  };
};

export const normalizeDate = (date: DateParts, datePattern: OptionType) => {
  const newDate = createDate(`${date.month} ${date.day} ${date.year}`, datePattern).format(
    'YYYY-MM-DD',
  );

  if (newDate === 'Invalid Date') {
    return createDate(`Jan 01 1990`, 'MMM D YYYY').format('YYYY-MM-DD');
  }
  return newDate;
};
