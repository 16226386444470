/* eslint-disable max-len */
/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import { useMemo } from 'react';
import { AppProps as ApplicationProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { withTranslation } from 'react-i18next';
import '../index.css';
import 'normalize.css';
import App from 'src/app';
import { palmistryConfig } from 'src/app/palmistryConfig';
import sprite from 'core/common/assets/images/sprites/sprite.svg';
import { prepareHtml } from 'core/common/utils/prepareHtml';
import { FunnelAnalyticsType, isTikTok } from 'core/funnel/entities';
import TikTokScripts from 'core/scripts/components/TikTokScripts';
import { getAnalyticsIds } from 'core/scripts/utils/getAnalyticsIds';

const Application = ({
  Component,
  pageProps,
}: ApplicationProps & { analytics: FunnelAnalyticsType }) => {
  const tikTokPixelIds = useMemo(
    () => getAnalyticsIds(isTikTok, pageProps.analytics),
    [pageProps.analytics],
  );

  return (
    <>
      <Head>
        <title>NEBULA</title>
        <meta property="og:title" content="NEBULA"></meta>
        <meta
          name="trustpilot-one-time-domain-verification-id"
          content="ea688f7d-9420-4940-b046-f90ff48348a7"
        />
        <meta name="facebook-domain-verification" content="ld6fauq8wysgqi8nxb014927mbq37x" />
        <link href={sprite} rel="preload" as="image" type="image/svg+xml" />
      </Head>

      <App>
        <Component {...pageProps} />
      </App>

      {/* _AutofillCallbackHandler for in-app browsers */}
      <Script
        strategy="beforeInteractive"
        id="autofill-callback-handler"
        type="text/javascript"
        dangerouslySetInnerHTML={prepareHtml(
          'window._AutofillCallbackHandler = window._AutofillCallbackHandler || function () {};',
        )}
      />
      {/* End _AutofillCallbackHandler for in-app browsers */}

      {/* OneTrust Cookies consent */}
      <Script
        id="one-trust-cookie-consent"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={palmistryConfig.oneTrust.key}
      />

      <Script
        id="one-trust-cookie-consent-init"
        type="text/javascript"
        dangerouslySetInnerHTML={prepareHtml('function OptanonWrapper() {}')}
      />
      {/* End OneTrust Cookies consent */}

      <TikTokScripts ids={tikTokPixelIds} />

      <Script
        id="page-view-gtm"
        dangerouslySetInnerHTML={prepareHtml(`window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            originalLocation: document.location.protocol + '//' +
            document.location.hostname +
            document.location.pathname +
            document.location.search
            })`)}
      />

      {/* Google Tag Manager */}
      <Script
        id="gtm"
        dangerouslySetInnerHTML={prepareHtml(`(function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
            var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', 'GTM-WFRV4R6');`)}
      />
      {/* End Google Tag Manager */}

      {/* Pinterest */}
      <Script
        id="pinterest"
        dangerouslySetInnerHTML={prepareHtml(`
          !function(e){if(!window.pintrk){window.pintrk = function () {
            window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
            n=window.pintrk;n.queue=[],n.version="3.0";var
            t=document.createElement("script");t.async=!0,t.src=e;var
            r=document.getElementsByTagName("script")[0];
            r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
          pintrk('load', ${palmistryConfig.pinterestPixelId});
          pintrk('page')
        `)}
      />
      {/* End Pinterest */}

      {/* Solid Payment Form */}
      <Script defer src="https://cdn.solidgate.com/js/solid-form.js" />
      {/* End Solid Payment Form */}

      {/* Microsoft Clarity - HeatMap and SessionRecording */}
      <Script
        id="clarityHeatmap"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: palmistryConfig.clarityId
            ? `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            t.id="clarityScript";y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "${palmistryConfig.clarityId}");`
            : '',
        }}
      />
      {/* End Microsoft Clarity - HeatMap and SessionRecording */}

      <noscript>
        <iframe
          title="gtm"
          src="https://www.googletagmanager.com/ns.html?id=GTM-WFRV4R6"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
      <noscript>You need to enable JavaScript to run this app.</noscript>
    </>
  );
};

export default withTranslation()(Application);
