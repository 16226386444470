import { useSelector } from 'react-redux';
import { getUserLocation, getUserLocationLoadingStatus } from 'core/user/store/selectors';
import { europeanCountries } from '../entities';

export const useUserLocation = () => {
  const location = useSelector(getUserLocation);
  const loadingStatus = useSelector(getUserLocationLoadingStatus);

  const isEuropean = europeanCountries.includes(location.locale);

  return {
    country: location.country,
    locale: location.locale,
    region: location.region,
    isEuropean,
    loadingStatus,
  };
};
