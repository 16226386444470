type AreArrayObjectPrimitivesUniqueReturnType<
  TObj extends Record<string, unknown>,
  TKey extends keyof TObj,
> =
  | { isUnique: true; duplicate: null }
  | { isUnique: false; duplicate: { key: TKey; value: TObj[TKey] } };

export const areArrayObjectPrimitivesUnique = <
  TObj extends Record<string, unknown>,
  TKey extends keyof TObj,
>(
  key: TKey,
  array: Array<TObj>,
): AreArrayObjectPrimitivesUniqueReturnType<TObj, TKey> => {
  const valueSet = new Set<TObj[TKey]>();
  let duplicateValue: { key: TKey; value: TObj[TKey] } | null = null;

  const isUnique = !array.some((obj) => {
    if (!(key in obj)) {
      return false;
    }

    const value = obj[key];

    if (valueSet.has(value)) {
      duplicateValue = { key, value };
      return true;
    }

    valueSet.add(value);
    return false;
  });

  if (!isUnique && duplicateValue) {
    return {
      isUnique: false,
      duplicate: duplicateValue as unknown as { key: TKey; value: TObj[TKey] },
    };
  }

  // this should not be possible, exists as a fallback for edge cases
  if (!isUnique && !duplicateValue) {
    return {
      isUnique: false,
      duplicate: { key: 'unknown', value: 'unknown' } as { key: TKey; value: TObj[TKey] },
    };
  }

  return { isUnique: true, duplicate: null };
};
