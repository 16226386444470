import validator from 'core/common/utils/validator';
import { Currency, CurrencySymbol } from 'core/user/entities';

export const OfferSchema = validator.object({
  id: validator.string(),
  offerId: validator.string(),
  description: validator.string(),
  currency: validator.nativeEnum(Currency),
  currencySymbol: validator.nativeEnum(CurrencySymbol),
  period: validator.string(),
  trial: validator.boolean(),
  trialPeriod: validator.union([validator.string(), validator.null()]),
  prices: validator.object({
    price: validator.number(),
    priceWithVAT: validator.number(),
    vatOfPrice: validator.number(),
    trialPrice: validator.number(),
    trialPriceWithVAT: validator.number(),
    vatOfTrialPrice: validator.number(),
  }),
  pricesWithCurrencySymbol: validator.object({
    price: validator.string(),
    priceWithVAT: validator.string(),
    vatOfPrice: validator.string(),
    trialPrice: validator.string(),
    trialPriceWithVAT: validator.string(),
    vatOfTrialPrice: validator.string(),
  }),
  pricesWithCurrency: validator.object({
    price: validator.string(),
    priceWithVAT: validator.string(),
    vatOfPrice: validator.string(),
    trialPrice: validator.string(),
    trialPriceWithVAT: validator.string(),
    vatOfTrialPrice: validator.string(),
  }),
});

export type Offer = validator.infer<typeof OfferSchema>;

export enum CreditsAmount {
  PLAN_150 = '150',
  PLAN_210 = '210',
  PLAN_300 = '300',
  PLAN_360 = '360',
  PLAN_900 = '900',
}

export const sortOffers = (offers?: Array<Offer>) => {
  if (!offers) {
    return [];
  }

  return [...offers].sort((offer1, offer2) => offer1.prices.trialPrice - offer2.prices.trialPrice);
};
