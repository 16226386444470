import { de } from './de';
import { en } from './en';
import { es } from './es';
import { fr } from './fr';

const resources = {
  en,
  es,
  fr,
  de,
};

export default resources;
