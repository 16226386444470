export enum PaymentOrderStatus {
  CREATED = 'created', //	The order was created
  PROCESSING = 'processing', //	The order is in processing
  THREE_DS_VERIFY = '3ds_verify', //	The order goes through 3DS verification
  APPROVED = 'approved', //	The order is approved and the payment is successful
  DECLINED = 'declined', //	The order was declined
  REFUNDED = 'refunded', //	Funds by the order were transferred back to the cardholder
  AUTH_OK = 'auth_ok', //	Funds were successfully reserved
  AUTH_FAILED = 'auth_failed', //	Reservation of the funds is failed
  SETTLE_OK = 'settle_ok', //	Funds were successfully settled
  VOID_OK = 'void_ok', //	Operation of fund reservation was voided
  PARTIAL_SETTLED = 'partial_settled', //	Part of the funds was successfully settled
}

export enum PaymentType {
  CARD = 'card',
  PAYPAL = 'paypal',
  APPLE_PAY = 'apple_pay',
  GOOGLE_PAY = 'google_pay',
}

export enum CardPaymentEntities {
  FORM = 'form',
  APPLE_BTN = 'applebtn',
  GOOGLE_BTN = 'googlebtn',
}

export type PaymentFormMerchantData = {
  merchant: string;
  paymentIntent: string;
  signature: string;
};

export type PaymentFormPaypalData = string;

export type PaymentFormData = {
  formMerchantData: PaymentFormMerchantData;
  paypalPaymentData: PaymentFormPaypalData;
};

export type PaymentOrderDescription = {
  status: PaymentOrderStatus;
  order_id: string;
  amount: number;
  currency: string;
  subscription_id?: string;
};
