import { UtmSource, UtmTags } from 'core/user/entities';

export const POSSIBLE_UTM_TAGS: Array<keyof UtmTags> = [
  'utm_source',
  'utm_medium',
  'utm_content',
  'utm_term',
  'utm_campaign_id',
  'utm_campaignid',
  'utm_campaign',
  'utm_adset_id',
  'utm_adsetid',
  'utm_adset',
  'utm_ad_id',
  'utm_adid',
  'utm_ad',
  'gclid',
  'iterable_message_id',
  'fbp',
  'fbc',
  'fbclid',
  'ga',
  'everflow_transaction_id',
  'utm_source_retention',
  'utm_adset_name',
  'utm_placement',
  'utm_ad_name',
  'ttclid',
  'quizz',
  'oid',
  '_ef_transaction_id',
  'ad_group',
  'fb_test_event_code',
];

export interface UtmTagsService {
  getUtmTags: () => Partial<UtmTags>;

  getUtmSource: () => UtmSource;

  getUtmAdName: () => string;

  updateUtmTags: (utmTags: UtmTags) => UtmTags;
}
