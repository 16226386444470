import { Logger } from 'core/common/services/logger/interfaces';
import { UtmSource } from 'core/user/entities';
import { UtmTagsService } from './UtmTagsService';

export class UtmTagsServerService implements UtmTagsService {
  private readonly logger: Logger;

  private constructor(logger: Logger) {
    this.logger = logger;
  }

  getUtmTags() {
    return {};
  }

  getUtmSource(): UtmSource {
    return UtmSource.NO_SOURCE;
  }

  getUtmAdName(): string {
    return '';
  }

  static crateFromQueryParams(logger: Logger) {
    return new UtmTagsServerService(logger);
  }

  updateUtmTags() {
    return {};
  }
}
