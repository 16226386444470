import { BaseService } from '../base/BaseService';

export class ChatService extends BaseService {
  async createChat(id: string): Promise<{ id: string }> {
    const { data } = await this.httpClient.post('/chats', {
      data: {
        expert_id: id,
      },
    });

    return data;
  }
}
