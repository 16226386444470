import { Config } from 'core/common/contexts';
import { ConsolaLogger, createHttpClient } from 'core/common/services';
import { HttpFunnelService } from '../services';

export class FunnelServiceBuilder {
  static create(config: Config) {
    const httpClient = createHttpClient(config.funnelBuilder.apiHost, {
      headers: {
        'api-key': config.funnelBuilder.apiKey,
      },
    });
    const loggingService = new ConsolaLogger();

    return new HttpFunnelService(httpClient, loggingService);
  }
}
