import { createContext, ReactNode } from 'react';
import { CreditsAmount, PurchaseProductName } from 'core/offers/entities';

export type Config = {
  sentryRelease: string;
  appEnvironment: string;
  geocodeUrl: string;
  geocodeApiKey: string;
  apiUrl: string;
  palmApiUrl: string;
  apiPrefix: string;
  applePayContainerId: string;
  googlePayContainerId: string;
  appName: string;
  mainAppUrl: string;
  rootDomain: string;
  amplitudeApiKey: string;
  amplitudeApiEndpoint: string;
  supportCenter: string;
  dateOfTerms: string;
  dateOfMoneyBack: string;
  appleSupport: string;
  supportEmail: string;
  googleSupport: string;
  askNebulaUrl: string;
  testingTokenCookieKey: string;
  smartlookApiKey: string;
  pinterestPixelId: string;
  shopifyCardTarotDeck: string;
  expensivePriceTreshold: number;
  palmPhotoLongSide: number;
  palmPhotoShortSide: number;
  palmAnimationDuration: number;
  isProduction: boolean;
  isStage: boolean;
  isDevelopment: boolean;
  isBuildPhase: boolean;
  sentryDsn: string;
  addressUS: string;
  addressAE: string;
  address: string;
  cdn: string;
  growthbook: {
    apiHost: string;
    clientKey: string;
  };
  oneTrust: {
    key: string;
  };
  creditsOfferIds: Record<CreditsAmount, Array<PurchaseProductName>>;
  funnelBuilder: {
    apiHost: string;
    apiKey: string;
  };
  SVGSprite: {
    inputPath: string;
    outputPath: string;
    typesOutputPath: string;
    outputName: `${string}.svg`;
  };
  domainUrl: string;
  grafanaLogger: {
    url: string;
  };
  observability: {
    defaultTracerName: string;
    tracesUrl: string;
    httpInstrumentationIgnoreUrls: Array<string>;
    httpInstrumentationSpanRate: number;
  };
  buildCache: {
    path: string;
  };
  ebanxUrl: string;
  clarityId: string;
  supportBot: {
    url: string;
    tags: Array<string>;
  };
  funnelBuilderTrustedOrigins: string[];
  companyName: string;
  supportPhoneNumber: {
    alias: string;
    original: string;
  };
};

export const ConfigContext = createContext<Config>(null!);

export type ConfigProviderProps = {
  children?: ReactNode;
  config: Config;
};

export const ConfigProvider = ({ config, children }: ConfigProviderProps) => {
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};
