import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  createAskNebulaAccountFailed,
  createAskNebulaAccountPending,
  createAskNebulaAccountSucceed,
  signInByTokenFailed,
  signInByTokenPending,
  signInByTokenSucceed,
} from './actions';
import { authInitialState } from './state';

export const authReducer = createReducer(authInitialState, (builder) => {
  builder
    .addCase(signInByTokenPending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
      state.token = '';
    })
    .addCase(signInByTokenSucceed, (state, { payload: token }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.token = token;
    })
    .addCase(signInByTokenFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(createAskNebulaAccountPending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
      state.token = '';
    })
    .addCase(createAskNebulaAccountSucceed, (state, { payload: token }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.token = token;
    })
    .addCase(createAskNebulaAccountFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    });
});
