import { ToEntity } from 'core/common/mappers';
import { AuthData } from '../entities';
import { AuthDataDto } from '../services/dto';

export class AuthDtoMapper implements ToEntity<AuthData, AuthDataDto> {
  toEntity(dto: AuthDataDto): AuthData {
    return {
      authenticationToken: dto.authentication_token,
      accountId: dto.account_id,
    };
  }
}
