import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store/types';
import { fetchUserInformation, syncUtmTags } from 'core/user/store';

export const signInByTokenPending = createAction('auth/SIGN_IN_BY_TOKEN/pending');
export const signInByTokenSucceed = createAction<string>('auth/SIGN_IN_BY_TOKEN/succeed');
export const signInByTokenFailed = createAction('auth/SIGN_IN_BY_TOKEN/failed');

export const signInByToken =
  (token: string): AppThunk =>
  async (dispatch, _, context) => {
    const { authService, loggingService, observabilitySystem } = context;

    const span = observabilitySystem.startSpan('sign_in_by_token');
    span.addEvent('Pending');

    try {
      dispatch(signInByTokenPending());

      await authService.signInByToken(token);
      const { authenticationToken } = await authService.getAskNebulaAuthenticationToken();

      dispatch(fetchUserInformation());
      dispatch(signInByTokenSucceed(authenticationToken));
      dispatch(syncUtmTags());

      span.addEvent('Succeed');
      span.end();
    } catch (e) {
      const err = normalizeError(e);
      loggingService.error(err);

      dispatch(signInByTokenFailed());

      span.addEvent('Failed');
      span.recordException(err);
      span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
      span.end();
    }
  };

export const createAskNebulaAccountPending = createAction('auth/CREATE_ASKNEBULA_ACCOUNT/pending');
export const createAskNebulaAccountSucceed = createAction<string>(
  'auth/CREATE_ASKNEBULA_ACCOUNT/succeed',
);
export const createAskNebulaAccountFailed = createAction('auth/CREATE_ASKNEBULA_ACCOUNT/failed');

export const createAskNebulaAccountRequest = (): AppThunk => async (dispatch, _, context) => {
  const { loggingService: logger, authService, observabilitySystem, analyticsService } = context;

  const span = observabilitySystem.startSpan('create_asknebula_account');
  span.addEvent('Pending');

  try {
    dispatch(createAskNebulaAccountPending());

    const result = await authService.getAskNebulaAuthenticationToken();

    dispatch(createAskNebulaAccountSucceed(result.authenticationToken));
    analyticsService.updateUserProperties({ user_id: result.accountId });

    span.addEvent('Succeed');
    span.end();
  } catch (e) {
    const err = normalizeError(e);
    logger.error(err);

    dispatch(createAskNebulaAccountFailed());

    span.addEvent('Failed');
    span.recordException(err);
    span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    span.end();
  }
};
