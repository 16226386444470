import { useCallback } from 'react';
import { useDispatch, useSelector } from 'core/common/hooks';
import { getUserInformation, updateUserPreferences } from '../store';

export function useUser() {
  const dispatch = useDispatch();

  const userPreferences = useSelector(getUserInformation);

  const doUpdateUserPreferences = useCallback(
    (data: Record<string, unknown>) => {
      dispatch(updateUserPreferences(data));
    },
    [dispatch],
  );

  return {
    updateUserPreferences: doUpdateUserPreferences,
    userPreferences,
  };
}
