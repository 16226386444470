export enum MoonPhases {
  NEW_MOON = 'newMoon',
  FULL_MOON = 'fullMoon',
  QUARTER_MOON = 'quarterMoon',
  LAST_QUARTER_MOON = 'lastQuarterMoon',
  WANING_GIBBOUS_MOON = 'waningGibbousMoon',
  WAXING_GIBBOUS_MOON = 'waxingGibbousMoon',
  WANING_CRESCENT_MOON = 'waningCrescentMoon',
  WAXING_CRESCENT_MOON = 'waxingCrescentMoon',
}
