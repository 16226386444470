import { useCallback } from 'react';
import { useDispatch, useSelector } from 'core/common/hooks';
import { cancelUserSubscriptionRequest, fetchUserSubscriptionsRequest } from '../store/actions';
import {
  getUserSubscriptions,
  getUserSubscriptionsCancelLoadingStatus,
  getUserSubscriptionsLoadingStatus,
} from '../store/selectors';

export function useUserSubscriptions() {
  const dispatch = useDispatch();

  const loadingStatus = useSelector(getUserSubscriptionsLoadingStatus);
  const subscriptions = useSelector(getUserSubscriptions);
  const cancelLoadingStatus = useSelector(getUserSubscriptionsCancelLoadingStatus);

  const fetchSubscriptions = useCallback(
    (email: string) => {
      dispatch(fetchUserSubscriptionsRequest(email));
    },
    [dispatch],
  );

  const cancelSubscriptionRequest = useCallback(
    (id: string, paymentType: string) => {
      dispatch(cancelUserSubscriptionRequest(id, paymentType));
    },
    [dispatch],
  );

  return {
    subscriptions,
    loadingStatus,
    fetchSubscriptions,
    cancelSubscriptionRequest,
    cancelLoadingStatus,
  };
}
