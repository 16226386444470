import { Log } from './entities';
import { LoggerTransport } from './interfaces';

export class ConsoleLoggerTransport implements LoggerTransport {
  send(log: Log): void {
    // eslint-disable-next-line no-console
    const logMethod = console[log.getLevel()] || console.log;

    logMethod(log.getMessage(), log.getExtra(), log.getCreatedAt());
  }
}
