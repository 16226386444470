import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import { Expert } from 'core/experts/entities';
import { fetchRandomExpert } from './actions';

type InitialState = {
  expert: Expert;
  loadingStatus: LoadingStatuses;
};

export const expertsInitialState: InitialState = {
  expert: {} as Expert,
  loadingStatus: LoadingStatuses.IDLE,
};

export const expertsReducer = createReducer(expertsInitialState, (builder) => {
  builder
    .addCase(fetchRandomExpert.pending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchRandomExpert.fulfilled, (state, { payload: expert }) => {
      if (!expert) return;

      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.expert = expert;
    })
    .addCase(fetchRandomExpert.rejected, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    });
});
