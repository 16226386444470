import { createAsyncThunk } from '@reduxjs/toolkit';
import { Services } from 'core/common/contexts';
import { normalizeError } from 'core/common/errors';
import { Expert } from 'core/experts/entities';

export const fetchRandomExpert = createAsyncThunk<Expert | null, void, { extra: Services }>(
  'experts/FETCH_RANDOM_EXPERT',
  async (_, { extra }) => {
    const { expertService, loggingService } = extra;

    try {
      const expert = await expertService.getRandomExpert();
      return expert;
    } catch (e) {
      const err = normalizeError(e);
      loggingService.error(err);
      return null;
    }
  },
);
