export enum Languages {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  DE = 'de',
  PT = 'pt',
  JA = 'ja',
  TR = 'tr',
  AR = 'ar',
  PL = 'pl',
  HE = 'he',
  ZH = 'zh',
  HI = 'hi',
  ID = 'id',
  IT = 'it',
  SV = 'sv',
}
