import { useState } from 'react';
import { normalizeError } from '../errors';

import { checkIsClientSide } from '../utils/checkIsClientSide';
import { useLogger } from './useLogger';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (s: T) => void] {
  const logger = useLogger();
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      if (!checkIsClientSide()) {
        return initialValue;
      }
      const item = window.localStorage.getItem(key);
      if (typeof item === 'string') {
        return item;
      }
      return item ? JSON.parse(item) : initialValue;
    } catch (err) {
      const error = normalizeError(err);
      logger.error(error);
      return initialValue;
    }
  });

  const setValue = (value: T) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err) {
      const error = normalizeError(err);
      logger.error(error);
    }
  };

  return [storedValue, setValue];
}
