import { ReactNode } from 'react';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Config, ConfigProvider, Services, ServicesProvider } from 'core/common/contexts';
import { FeatureFlagsProvider } from 'core/feature-flags/context';
import { FeatureFlags } from 'core/feature-flags/services';
import i18n from 'core/i18n';
import ThemeContext from 'core/theme/contexts';
import { ThemeTypes } from 'core/theme/entities';
import ErrorBoundary from '../ErrorBoundary';
import { Block, Container, Content, Wrapper } from './styled';

type PageLayoutProps = {
  children: ReactNode;
  store: ToolkitStore;
  services: Services;
  featureFlags: FeatureFlags;
  config: Config;
  theme: ThemeTypes;
};

const PageLayout = ({
  children,
  store,
  featureFlags,
  services,
  config,
  theme,
}: PageLayoutProps) => {
  return (
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary fallback={<div />}>
        <ConfigProvider config={config}>
          <Provider store={store}>
            <ServicesProvider value={services}>
              <FeatureFlagsProvider featureFlags={featureFlags}>
                <ThemeContext themeType={theme}>
                  <Block>
                    <Container>
                      <Content>
                        <Wrapper>{children}</Wrapper>
                      </Content>
                    </Container>
                  </Block>
                </ThemeContext>
              </FeatureFlagsProvider>
            </ServicesProvider>
          </Provider>
        </ConfigProvider>
      </ErrorBoundary>
    </I18nextProvider>
  );
};

export default PageLayout;
