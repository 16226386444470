/* eslint-disable max-len */
export const advertisementData: Record<string, string> = {
  '3311': 'How to see your future husband in your birth chart?',
  'Black with bright icons — Копия — Копия — Копия — Копия — Копия': 'Are we sexually compatible?',
  'Black with bright icons — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Are we sexually compatible?',
  'Green-Orange SexComp — Копия — Копия — Копия — Копия — Копия': 'Are we sexually compatible?',
  'Green-Orange SexComp — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Are we sexually compatible?',
  muzhyk: "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk_PAUSE - Copy':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'New Sahar': 'Check your toxic compatibility',
  'New Sahar — Копия': 'Check your toxic compatibility',
  'New Sahar — Копия — Копия': 'Check your toxic compatibility',
  '08-11-22-Illustration_Var_topzodiac-05.jpg — Копия — Копия — Копия — Копия':
    'Are we sexually compatible?',
  '10-07-2022 —Variation_(TOP_45)_Ti_Re_TV-01.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Are we sexually compatible?',
  '10.03.2023 Nebula_TWOP_RE_Etno-headings-exp-1_VO_NN_2.jpg': 'Top couples in sex. Check now!',
  '10.03.2023 Nebula_TWOP_RE_Etno-headings-exp-1_VO_NN_2.jpg — Копия — Копия':
    'Top couples in sex. Check now!',
  '10.03.2023 Nebula_TWOP_RE_Etno-headings-exp-1_VO_NN_2.jpg — Копия — Копия — Копия':
    'Top couples in sex. Check now!',
  '10.03.2023 Nebula_TWOP_RE_Etno-headings-exp-1_VO_NN_2.jpg — Копия — Копия — Копия — Копия':
    'Top couples in sex. Check now!',
  '10.03.2023 Nebula_TWOP_RE_Etno-headings-exp-1_VO_NN_2.jpg — Копия — Копия — Копия — Копия — Копия':
    'Top couples in sex. Check now!',
  '13-12-22-TIP-1-02.jpg': 'The type of partner you deserve based on natal chart',
  '14.03.2022_HotComp_Vika-02.jpg': 'Happy or toxic together?',
  '17.03.2023 — Nebula_Co_Re_Black Collage Mexican 2_DK_NN_1.jpg — Копия':
    'Check your toxic compatibility',
  '17.03.2023 — Nebula_Co_Re_Black Collage Mexican 2_DK_NN_1.jpg — Копия — Копия — Копия':
    'Check your toxic compatibility',
  '20-12-TopRenew_5-05.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Happy or toxic together?',
  '27-12-22-Hands_V1-02': 'Marriage or breakup in 2023?',
  '27-12-22-Hands_V1-02 - Copy': 'Marriage or breakup in 2023?',
  '27-12-22-Hands_V1-02_PAUSE_START - Copy': 'Marriage or breakup in 2023?',
  '27-12-22-Hands_V1-02.jpg - Copy': 'Marriage or breakup in 2023?',
  Anna_video_TT_2: 'How to see your future husband in your birth chart?',
  'AskPurch_SexComp_UPD_20-12-22-03': 'Are we sexually compatible?',
  'AskPurch_SexComp_UPD_20-12-22-03_PAUSE - Copy 3': 'Are we sexually compatible?',
  'AskPurch_SexComp_UPD_20-12-22-03_PAUSE_START - Copy': 'Are we sexually compatible?',
  'AskPurch_SexComp_UPD_20-12-22-03_PAUSE_START - Copy 2': 'Are we sexually compatible?',
  'AskPurch_SexComp_UPD_20-12-22-03_PAUSE_START - Copy 3': 'Are we sexually compatible?',
  'CoupleEyes_Renew_4_Монтажная область 1.jpg_PAUSE_START - Copy – Copy':
    'Are we sexually compatible?',
  'CoupleEyes_Renew_4_Монтажная область 1.jpg_PAUSE_START - Copy 2': 'Are we sexually compatible?',
  'CoupleEyes_Renew_4_Монтажная область 1.jpg_PAUSE_START - Copy 3': 'Are we sexually compatible?',
  'Inhouse 3311 — Копия — Копия': 'How to see your future husband in your birth chart?',
  LERA_1: 'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4': 'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4 — Копия': 'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4 — Копия — Копия': 'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4 — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4 — Копия — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'male_newface_v1_3.mp4 — Копия — Копия — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'mob_yellowtoxic - Copy 2_PAUSE_START': 'Check your toxic compatibility',
  'muzhyk - Copy 2': "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk - Copy 2_PAUSE_START – Copy':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk - Copy 3_PAUSE_START':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk - Copy 3_PAUSE_START – Copy':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk - Copy 5_PAUSE_START':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk_PAUSE_START - Copy 2':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'muzhyk_PAUSE_START - Copy 3':
    "Things a guy does when he's not interested in you based on your zodiac sign",
  'NewHead_Jamar-04.jpg - Copy 2': 'Which sex will you enjoy the most?',
  'OldPeople_Var_ZodiacCouples_13-09-22_TV-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Longest Lasting Relationship',
  'renew_illustration_var_topzodiac_Монтажная область 1.jpg — Копия — Копия — Копия':
    'Are we sexually compatible?',
  'renew_illustration_var_topzodiac-03.jpg — Копия — Копия — Копия': 'Are we sexually compatible?',
  'renew_Top_45-05.jpg': 'Are we sexually compatible?',
  'renew_Top_45-05.jpg - Copy': 'Are we sexually compatible?',
  'renew_top_rings_05-03.jpg — Копия — Копия': 'Happy or toxic together?',
  'renew_top_rings_05-03.jpg — Копия — Копия — Копия': 'Happy or toxic together?',
  Serg_video_TT_3: 'How to see your future husband in your birth chart?',
  'Sviat_TT_video_2.mp4 — Копия': 'How to see your future husband in your birth chart?',
  'Sviat_TT_video_2.mp4 — Копия — Копия': 'How to see your future husband in your birth chart?',
  'Sviat_TT_video_2.mp4 — Копия — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'Tiktok_NewFace_remakes_1_1.mp4 — eromi — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'TT_newface_2_Lera_1.mp4 — 08/02 — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'TT_newface_2_Lera_1.mp4 — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'TT_newface_2_Lera_1.mp4 — Копия — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'TT_newface_2_Lera_1.mp4 — Копия — Копия — Копия — Копия — Копия — Копия':
    'How to see your future husband in your birth chart?',
  'Variation_(TOP_45)_sp_27.10.22-03.jpg': 'Are we sexually compatible?',
  'Variation_MM_LoveMeans_Vika-04 - Copy': 'What love means to you?',
  'variations_calendar_SP_08.03-02.jpg': 'Check your compatability based on zodiac sign',
  'variations_calendar_SP_08.03-02.jpg — Копия': 'Check your compatability based on zodiac sign',
  'variations_calendar_SP_08.03-02.jpg — Копия — Копия':
    'Check your compatability based on zodiac sign',
  'variations_calendar_SP_08.03-02.jpg — Копия — Копия — Копия':
    'Check your compatability based on zodiac sign',
  'variations_Color-TopSexComp_sp_07.01.23-03.jpg - Copy': 'Are we sexually compatible?',
  'variations_jaydan2_sp_13_Монтажная область 1.jpg — Копия — Копия':
    'Predict your next relationship',
  'variations_jaydan2_sp_13_Монтажная область 1.jpg —TOP — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'variations_text_sex_NK-03 — Копия — Копия': 'Are we sexually compatible?',
  'Variations_top_CoupleEyes_MV_13.03.2023 - 17.03.2023-05.jpg - Copy':
    'Check your toxic compatibility',
  'variations_top_head_jaydan_MV_13.03.2023 - 17.03.2023-02.jpg — Копия — Копия':
    'Predict your next relationship',
  'variations_top_head_jaydan_MV_13.03.2023 - 17.03.2023-02.jpg — Копия — Копия — Копия':
    'Predict your next relationship',
  'variations_top_head_jaydan_MV_13.03.2023 - 17.03.2023-02.jpg — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'variations_top_HotSeason_MV_09.01.2023 - 13.01.2023 2-03.jpg - Copy':
    'Marriage or breakup in 2023?',
  'Variations_top_HotSeason_SP_12.01.23-05.jpg - Copy': 'Marriage or breakup in 2023?',
  'variations_top_jamar_MV_19.12.2022 - 23.12.2022-02.jpg - Copy': "What's your relationship type?",
  'variations_top_jamar_MV_19.12.2022 - 23.12.2022-02.jpg - Copy 2':
    "What's your relationship type?",
  'Variations_top_Jaydan_MV_27.02.2023 - 03.03.2023 3-02.jpg - Copy':
    'Predict your next relationship',
  'Variations_top_Jaydan_MV_27.02.2023 - 03.03.2023 3-02.jpg - Copy 2':
    'Predict your next relationship',
  'Variations_top_Jaydan_SP_11.01.23-02.jpg — 10/03': 'Predict your next relationship',
  'Variations_top_Jaydan_SP_11.01.23-02.jpg — Копия': 'Predict your next relationship',
  'Variations_top_Jaydan_SP_11.01.23-02.jpg — Копия — Копия': 'Predict your next relationship',
  'Variations_top_Jaydan_SP_11.01.23-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan_SP_11.01.23-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_09.01.2023 - 13.01.2023-02.jpg': 'Predict your next relationship',
  'Variations_top_Jaydan2_MV_09.01.2023 - 13.01.2023-02.jpg — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_09.01.2023 - 13.01.2023-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_09.01.2023 - 13.01.2023-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_09.01.2023 - 13.01.2023-02.jpg — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия — Копия':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_16.01.2023 - 20.01.2023-05.jpg - Copy 2':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_16.01.2023 - 20.01.2023-05.jpg - Copy 3':
    'Predict your next relationship',
  'Variations_top_Jaydan2_MV_23.01.2023 - 27.01.2023-02.jpg - Copy 3':
    'Predict your next relationship',
  'variations_top_jayden_MV_24.10.2022 - 28.10.2022-05.jpg — Копия — Копия — Копия':
    'Predict your next relationship',
  'variations_top_MV_new_sahar_20.02.2023 - 24.02.2023.mp4': 'Check your toxic compatibility',
  'variations_top_MV_new_sahar_20.02.2023 - 24.02.2023.mp4 — Копия — Копия':
    'Check your toxic compatibility',
  'variations_top_MV_new_sahar_20.02.2023 - 24.02.2023.mp4 — Копия — Копия — Копия':
    'Check your toxic compatibility',
  'variations_top_MV_new_sahar_20.02.2023 - 24.02.2023.mp4 — Копия — Копия — Копия — Копия':
    'Check your toxic compatibility',
  'variations_top_MV_new_sahar_20.02.2023 - 24.02.2023.mp4 — Копия — Копия — Копия — Копия — Копия':
    'Check your toxic compatibility',
  'variations_top_OldPeople_MV_06.02.2023 - 10.02.2023-02.jpg - Copy 2':
    'Longest Lasting Relationship',
  'variations_top_OldPeople_MV_06.02.2023 - 10.02.2023-02.jpg - Copy 2_PAUSE':
    'Longest Lasting Relationship',
  'variations_top_OldPeople_MV_06.02.2023 - 10.02.2023-02.jpg - Copy 3_PAUSE':
    'Longest Lasting Relationship',
  'variations_top_OldPeople_MV_06.02.2023 - 10.02.2023-02.jpg - Copy 4_PAUSE':
    'Longest Lasting Relationship',
  'Variations_top_renew_tops_10_MV_27.02.2023 - 03.03.2023-02.jpg - Copy 3':
    'Are we sexually compatible?',
  'Variations_top_renew_tops_10_MV_30.01.2023 - 04.01.2023-02.jpg — Копия':
    'Are we sexually compatible?',
  'Variations_top_renew_tops_10_MV_30.01.2023 - 04.01.2023-02.jpg — Копия — Копия':
    'Are we sexually compatible?',
  'Variations_top_renew_tops_10_MV_30.01.2023 - 04.01.2023-02.jpg — Копия — Копия — Копия':
    'Are we sexually compatible?',
  'Variations_top_renew_tops_10_MV_30.01.2023 - 04.01.2023-02.jpg — Копия — Копия — Копия — Копия — Копия':
    'Are we sexually compatible?',
  'Variations_top_sexcomp_MV_09.01.2023 - 13.01.2023 2-02.jpg - Copy 2':
    'Are we sexually compatible?',
  'variations_top_sexcomp_MV_31.10.2022 - 04.11.2022-04.jpg': 'Are we sexually compatible?',
  'variations_top_TOP_19_MV_07.11.2022 - 11.11.2022-02.jpg - Copy': 'Are we sexually compatible?',
  'variations_top_СoupleEyes_MV_27.03.2023 - 31.03.2023-02.jpg': 'Are we sexually compatible?',
  'variations_topSexComp_3 - Copy 4_PAUSE': 'Are we sexually compatible?',
  'variations_topSexComp_3 - Copy 5_PAUSE': 'Are we sexually compatible?',
  'variations_topSexComp_3 - Copy 6_PAUSE': 'Are we sexually compatible?',
  'variations_topSexComp_3_PAUSE_START - Copy': 'Are we sexually compatible?',
  'variations_topSexComp_SP_10.02.23-04.jpg - Copy': 'Are we sexually compatible?',
  'weird_ideas_2_Монтажная область 1.jpg - Copy': 'Reveal your love and sex patterns',
  'weird_ideas_2_Монтажная область 1.jpg - Copy 2': 'Reveal your love and sex patterns',
};
