import { ToEntity } from 'core/common/mappers';
import { PaymentFormData } from '../entities';
import { PaymentFormDataDto } from '../services/dto';

export class PaymentFormDataDtoMapper implements ToEntity<PaymentFormData, PaymentFormDataDto> {
  toEntity(dto: PaymentFormDataDto): PaymentFormData {
    return {
      formMerchantData: {
        merchant: dto.merchant_data.merchant_id,
        paymentIntent: dto.merchant_data.payment_intent,
        signature: dto.merchant_data.signature,
      },
      paypalPaymentData: dto.paypal_payment_script,
    };
  }
}
