import { BaseService } from 'core/common/services';
import { countriesWithVAT } from 'core/offers/constants';
import { Locales } from 'core/user/entities';

export class TaxService extends BaseService {
  private tax = 0.2;

  getValueAddedTax(value: number) {
    return +(value - this.getPriceWithoutVAT(value)).toFixed(2);
  }

  getPriceWithoutVAT(value: number) {
    return +(value / (1 + this.tax)).toFixed(2);
  }

  getTax() {
    return this.tax;
  }

  checkIsCountryWithVAT(locale: Locales) {
    return countriesWithVAT.includes(locale);
  }
}
