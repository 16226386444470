import validator from 'core/common/utils/validator';

export const PalmCoordinatesSchema = validator.object({
  x: validator.string(),
  y: validator.string(),
});

export type PalmCoordinates = validator.infer<typeof PalmCoordinatesSchema>;

export const PalmPhotoSchema = validator.object({
  photoId: validator.string(),
  photo: validator.string(),
  coordinates: validator.object({
    lifeLinePts: validator.array(PalmCoordinatesSchema),
    heartLinePts: validator.array(PalmCoordinatesSchema),
    marriageLinePts: validator.array(PalmCoordinatesSchema),
    headLinePts: validator.array(PalmCoordinatesSchema),
    moneyLinePts: validator.array(PalmCoordinatesSchema),
    thumbFingerTip: PalmCoordinatesSchema,
    indexFingerTip: PalmCoordinatesSchema,
    middleFingerTip: PalmCoordinatesSchema,
    ringFingerTip: PalmCoordinatesSchema,
    pinkyFingerTip: PalmCoordinatesSchema,
  }),
});

export type PalmPhoto = validator.infer<typeof PalmPhotoSchema>;

export const LineCoordinatesSchema = validator.record(
  validator.string(),
  validator.object({
    coordinates: validator.array(PalmCoordinatesSchema),
    color: validator.string(),
  }),
);

export type LineCoordinates = validator.infer<typeof LineCoordinatesSchema>;

export enum PalmLine {
  FATE = 'fate',
  LIFE = 'life',
  LOVE = 'love',
  HEAD = 'head',
}

export const PalmLinesSchema = validator.nativeEnum(PalmLine);
