import { HeatmapRecording } from './HeatmapRecording';

export class HeatmapRecordingServerService implements HeatmapRecording {
  startRecording() {
    return null;
  }

  triggerCustomEvent() {
    return null;
  }

  addCustomTag() {
    return null;
  }

  markImportantSession() {
    return null;
  }

  identify() {
    return null;
  }
}
