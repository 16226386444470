import { LoadingStatuses } from 'core/common/entities';
import { UserSubscription } from '../../entities';

export type UserSubscriptionsState = {
  entities: Record<string, UserSubscription>;
  loadingStatus: LoadingStatuses;
  cancelLoadingStatus: LoadingStatuses;
};

export const userSubscriptionsInitialState: UserSubscriptionsState = {
  entities: {},
  loadingStatus: LoadingStatuses.IDLE,
  cancelLoadingStatus: LoadingStatuses.IDLE,
};
