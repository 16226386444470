import { BaseService } from 'core/common/services';
import { UserSubscription } from '../../entities';
import { SubscriptionsDtoMapper } from '../../mappers';
import { SubscriptionDto } from '../dto';

export class SubscriptionsService extends BaseService {
  async getSubscriptions(email: string): Promise<UserSubscription[]> {
    const { data } = await this.httpClient.post<{ collection: Array<SubscriptionDto> }>(
      '/subscriptions/list-by-email',
      {
        data: { email },
      },
    );

    const dtoMapper = new SubscriptionsDtoMapper();

    return data.collection.map((subscription) => dtoMapper.toEntity(subscription));
  }

  async unsubscribe(subscriptionId: string, paymentType: string): Promise<void> {
    const { data } = await this.httpClient.post(`/subscriptions/${subscriptionId}/cancel`, {
      data: {
        payment_type: paymentType,
      },
    });

    return data;
  }

  async switchSubscription(subscriptionId: string, newProduct: string): Promise<void> {
    const { data } = await this.httpClient.post(`/subscriptions/${subscriptionId}/switch`, {
      data: { new_product: newProduct },
    });

    return data;
  }

  async getSubscriptionById(orderId: string): Promise<string> {
    const { data } = await this.httpClient.get(`/subscriptions/by-order?order_id=${orderId}`);

    return data.subscription_id;
  }
}
