import { useSelector } from 'react-redux';
import { getUserCurrency } from '../store/userLocation/selectors';

export const useUserCurrency = () => {
  const { currency, currencySymbol } = useSelector(getUserCurrency);

  return {
    currencySymbol,
    currency,
  };
};
