import { UnionConditionsSchema } from 'core/common/entities';
import validator from 'core/common/utils/validator';
import { Offer } from './Offer';

const StrictOfferIdsMapByCategorySchema = validator.object({
  defaultOffers: validator.array(validator.string()),
  timeUpOffers: validator.array(validator.string()),
  discountDefaultOffers: validator.array(validator.string()).optional(),
  discountOffer: validator.string(),
  numerologyAnalysisUpsell: validator.string(),
  tarotReadingUpsell: validator.string(),
  palmistryGuideUpsell: validator.string(),
  ultraPackUpsell: validator.string(),
  discountUltraPackUpsell: validator.string(),
  trialCreditsUpsell: validator.string(),
  premiumUpsell: validator.string().optional(),
  natalChartUpsell: validator.string().optional(),
  blackMoonUpsell: validator.string().optional(),
  discountNumerologyAnalysisUpsell: validator.string().optional(),
  discountTarotReadingUpsell: validator.string().optional(),
  discountPalmistryUpsell: validator.string().optional(),
  consultationUpsell: validator.string().optional(),
  discountConsultationUpsell: validator.string().optional(),
  readingsPackUpsellOffer: validator.string().optional(),
});

export type StrictOffersByCategory = validator.infer<typeof StrictOfferIdsMapByCategorySchema>;

const OffersConditionSchema = validator.object({
  condition: validator.record(validator.string(), UnionConditionsSchema),
});

export const OffersByCategorySchema = validator.intersection(
  StrictOfferIdsMapByCategorySchema,
  OffersConditionSchema.partial(),
);

export type OffersAttributes = validator.infer<typeof OffersByCategorySchema>;

export type OffersByCategory = StrictOffersByCategory;

export type OfferCategory = keyof StrictOffersByCategory;

type TransformToOffersMap<T> = [T] extends [Array<string>]
  ? Array<Offer>
  : [T] extends [Array<string> | undefined]
  ? Array<Offer> | undefined
  : Offer | null;

export type StrictOffersMapByCategory = {
  [K in OfferCategory]: TransformToOffersMap<StrictOffersByCategory[K]>;
};

export const getOfferByCategory = (offers: Offer[], offerId?: string): Offer | null => {
  if (!offerId) {
    return null;
  }

  return offers.find((offer) => offer.offerId === offerId) || null;
};
export const getOffersByCategory = (offers: Offer[], offerIds?: string[]): Offer[] => {
  if (!offerIds) {
    return [];
  }

  return offers.filter((offer) => offerIds.includes(offer.offerId));
};
