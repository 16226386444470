import { RootInitialState } from '../rootReducer';

export const getIsTestingEnv = (state: RootInitialState) => {
  return state.system.isTestingEnv;
};

export const getSystemLanguage = (state: RootInitialState) => {
  return state.system.language;
};

export const getIsAppleMobile = (state: RootInitialState) => {
  return state.system.isAppleMobile;
};

export const getIsMobileDevice = (state: RootInitialState) => {
  return state.system.isMobileDevice;
};

export const getIsApplePaySupported = (state: RootInitialState) => {
  return state.system.isApplePaySupported;
};

export const getUtmSource = (state: RootInitialState) => {
  return state.system.source;
};
