import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { PalmPhoto } from 'core/palmistry/entities';
import { palmDtoToEntity } from 'core/palmistry/mappers';

export class PalmDetectionService extends BaseService {
  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient, logger);
  }

  async detectPalm(imageBlob: Blob): Promise<PalmPhoto> {
    const palmPhoto = new FormData();

    palmPhoto.append('photo', imageBlob, 'image.png');

    const { data } = await this.httpClient.post('/palmistry/detect-palmistry', {
      data: palmPhoto,
      headers: {
        'Content-type': 'multipart/form-data',
      },
    });

    return palmDtoToEntity(data);
  }
}
