export enum RevalidationErrorReason {
  UNKNOWN = 'Unknown error while revalidating',
  EMPTY_FLOW = 'Required flow data is missing',
  EMPTY_FUNNEL = 'Required funnel data is missing',
  INVALID_TOKEN = 'Invalid page revalidation token',
  UNKNOWN_REVALIDATION_ACTION = 'Unknown revalidation action',
  MALFORMED_FUNNEL_CONFIG = 'Malformed funnel config to revalidate',
  MALFORMED_FLOW_CONFIG = 'Malformed flow config to revalidate',
  NO_MATCHING_SIGNATURES_FOUND = 'No matching signature found during verification',
}

export class RevalidationError extends Error {
  public reason: RevalidationErrorReason;

  constructor(public error: RevalidationErrorReason) {
    super(error);
    this.reason = error;
  }
}
