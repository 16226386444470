import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { AppThunk } from 'core/common/store';
import { UserLocation } from '../../entities';

export const fetchUserLocationPending = createAction('userLocation/FETCH_USER_LOCATION/pending');
export const fetchUserLocationSucceed = createAction<UserLocation>(
  'userLocation/FETCH_USER_LOCATION/succeed',
);
export const fetchUserLocationFailed = createAction('userLocation/FETCH_USER_LOCATION/failed');

export const fetchUserLocationRequest = (): AppThunk => async (dispatch, _, context) => {
  const { locationService, loggingService } = context;
  dispatch(fetchUserLocationPending());

  try {
    const userLocation = await locationService.getLocation();

    dispatch(fetchUserLocationSucceed(userLocation));
  } catch (e) {
    const err = normalizeError(e);
    loggingService.error(err);

    dispatch(fetchUserLocationFailed());
  }
};
