import { ProxyTracerProvider, trace, TracerProvider } from '@opentelemetry/api';
import { Context } from '@opentelemetry/api/build/src/context/types';
import { BasicTracerProvider, SpanProcessor } from '@opentelemetry/sdk-trace-base';
import { Config } from 'core/common/contexts';
import { mergeDeep } from 'core/common/utils/mergeDeep';
import { Span, SpanAttribute, SpanOptions } from '../entities';
import { ObservabilitySystem } from '../interfaces';

export class OTelObservabilitySystem implements ObservabilitySystem {
  private attributes = {};

  constructor(private readonly config: Config) {}

  setAttributes(attributes: Record<string, SpanAttribute>): ObservabilitySystem {
    this.attributes = mergeDeep(this.attributes, attributes);
    return this;
  }

  getAttributes(): Record<string, SpanAttribute> {
    return this.attributes;
  }

  setGlobalTracerProvider(provider: TracerProvider): boolean {
    return trace.setGlobalTracerProvider(provider);
  }

  // TODO prevent from calling before set global tracer
  getTracerProvider(): TracerProvider {
    return trace.getTracerProvider();
  }

  addSpanProcessor(spanProcessor: SpanProcessor) {
    const tracerProvider = this.getTracerProvider();

    if (tracerProvider instanceof ProxyTracerProvider) {
      const tracer = tracerProvider.getDelegate() as BasicTracerProvider;

      tracer.addSpanProcessor(spanProcessor);
    }
  }

  startSpan(name: string, options?: SpanOptions, context?: Context): Span {
    const tracer = trace.getTracer(this.config.observability.defaultTracerName);

    const span = tracer.startSpan(name, options, context);

    span.setAttributes(this.getAttributes());

    return span;
  }

  startActiveSpan<F extends (span: Span) => ReturnType<F>>(
    name: string,
    fn: F,
    options?: SpanOptions,
    context?: Context,
  ): ReturnType<F> {
    const tracer = trace.getTracer(this.config.observability.defaultTracerName);

    if (options) {
      return tracer.startActiveSpan<F>(name, options, fn);
    }

    if (!!options && !!context) {
      return tracer.startActiveSpan<F>(name, options, context, fn);
    }

    return tracer.startActiveSpan<F>(name, fn);
  }
}
