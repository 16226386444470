import { Config } from 'core/common/contexts';
import { CookiesService } from '../cookie/CookiesService';
import { CookieConsentService } from './CookieConsentService';

export class OneTrustCookieConsentService implements CookieConsentService {
  private readonly config: Config;

  private readonly cookies: CookiesService;

  constructor(config: Config) {
    this.config = config;

    this.cookies = new CookiesService({
      domain: config.rootDomain,
    });
  }

  toggleReconsent() {
    window.OneTrust?.ToggleInfoDisplay();
  }
}
