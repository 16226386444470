import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import { fetchReportFailed, fetchReportPending, fetchReportSucceed } from './actions';
import { humanDesignInitialState } from './state';

export const humanDesignReducer = createReducer(humanDesignInitialState, (builder) => {
  builder
    .addCase(fetchReportPending, (state) => {
      state.reportLoadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchReportSucceed, (state, { payload }) => {
      state.report = payload;
      state.reportLoadingStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(fetchReportFailed, (state) => {
      state.reportLoadingStatus = LoadingStatuses.FAILED;
    });
});
