import { PalmPhotoDTO } from '../services/palmDetection/dto';

export const palmDtoToEntity = (palmDto: PalmPhotoDTO) => {
  return {
    photoId: palmDto.palmistry_photo_id,
    photo: `https://${palmDto.palmistry_photo_template}`,
    coordinates: {
      lifeLinePts: palmDto.palmistry_coordinate.life_line_pts,
      heartLinePts: palmDto.palmistry_coordinate.heart_line_pts,
      marriageLinePts: palmDto.palmistry_coordinate.marriedge_line_pts,
      headLinePts: palmDto.palmistry_coordinate.head_line_pts,
      moneyLinePts: palmDto.palmistry_coordinate.money_line_pts,
      thumbFingerTip: palmDto.palmistry_coordinate.thumb_finger_tip,
      indexFingerTip: palmDto.palmistry_coordinate.index_finger_tip,
      middleFingerTip: palmDto.palmistry_coordinate.middle_finger_tip,
      ringFingerTip: palmDto.palmistry_coordinate.ring_finger_tip,
      pinkyFingerTip: palmDto.palmistry_coordinate.pinky_finger_tip,
    },
  };
};
