import { BaseService } from 'core/common/services';
import { FunnelName } from 'core/funnel/entities';
import { UserProfile, UtmTags } from 'core/user/entities';

export type UserUpdateDTO = Partial<{
  phone_number: string;
  quiz: FunnelName;
  utm_params: UtmTags;
}>;

export class UserService extends BaseService {
  async isValidEmail(email: string): Promise<boolean> {
    const { data } = await this.httpClient.post<{ valid: boolean }>(`/user/email/valid`, {
      data: { email },
    });

    return data.valid;
  }

  async confirmEmail(token: string) {
    const { data } = await this.httpClient.post('/user/confirm/email', {
      data: { email_confirm_token: token },
    });

    return data;
  }

  async getNebulaAppDownloadLink(): Promise<{ link: string }> {
    const { data } = await this.httpClient.get('/user/download-link');

    return data;
  }

  async getAskNebulaAccountToken(): Promise<{ token: string }> {
    const { data } = await this.httpClient.get('/user/asknebula/authentication-token');

    return data;
  }

  async getUserInformation() {
    const { data } = await this.httpClient.get<UserProfile>('/user');

    return data;
  }

  async updateUtmTags(utmTags: UtmTags): Promise<void> {
    await this.httpClient.put('/user/utm', {
      data: utmTags,
    });
  }

  async updateUser(data: UserUpdateDTO): Promise<void> {
    await this.httpClient.put('/user', { data });
  }
}
