import { ToEntity } from 'core/common/mappers';
import { HumanDesignReport } from 'core/human-design/entities';
import { HumanDesignReportDto } from 'core/human-design/services/humanDesignReport/dto';

export class HumanDesignReportDtoMapper
  implements ToEntity<HumanDesignReport, HumanDesignReportDto>
{
  toEntity(dto: HumanDesignReportDto): HumanDesignReport {
    return {
      chartData: {
        type: dto.chart_data.Properties.Type,
        profile: dto.chart_data.Properties.Profile,
        authority: dto.chart_data.Properties.InnerAuthority,
        strategy: dto.chart_data.Properties.Strategy,
        notSelfTheme: dto.chart_data.Properties.NotSelfTheme,
        signature: dto.chart_data.Properties.Signature,
      },
      chartUrl: dto.chart_url,
    };
  }
}
