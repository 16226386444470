import {
  GrafanaLoggerBatchDestination,
  GrafanaLoggerService,
} from 'core/common/services/grafana-logger/services';
import { createHttpClient } from 'core/common/services/httpСlient';
import { checkIsClientSide } from 'core/common/utils/checkIsClientSide';
import { config } from 'core/config';
import { LoggerBuilder } from '../LoggerBuilder';
import { BaseLogsBatchCache, LogsBatchCacheWithLocalStorage } from '../batch-logger-transport';

export const buildLogger = () => {
  const loggerBuilder = new LoggerBuilder();

  if (!checkIsClientSide()) {
    const serverSideLogger = loggerBuilder.getResult();

    return { logger: serverSideLogger };
  }

  const logger = loggerBuilder
    .addConsoleTransport()
    .addBatchTransport(
      new GrafanaLoggerBatchDestination(
        new GrafanaLoggerService(createHttpClient(config.grafanaLogger.url)),
      ),
      new LogsBatchCacheWithLocalStorage(new BaseLogsBatchCache()),
    )
    .addContext({ context: config.appName })
    .getResult();

  return { logger };
};
