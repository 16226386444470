import { SECOND } from 'core/common/constants';
import { Log, LogsBatch } from '../entities';
import { LoggerTransport, LogsBatchCache } from '../interfaces';
import { BatchDestination } from './BatchDestination';

type BatchLoggerTransportConfig = {
  debounceTime: number;
  maxRetryAttempt: number;
};

export class BatchLoggerTransport implements LoggerTransport {
  private readonly config: BatchLoggerTransportConfig;

  private readonly batchCache: LogsBatchCache;

  private nextCheckTimer?: ReturnType<typeof setTimeout>;

  private sending = false;

  private readonly batchDestination: BatchDestination;

  constructor(
    batchDestination: BatchDestination,
    batchCache: LogsBatchCache,
    config: Partial<BatchLoggerTransportConfig> = {},
  ) {
    const defaultConfig: BatchLoggerTransportConfig = {
      maxRetryAttempt: 5,
      debounceTime: 2 * SECOND,
    };

    this.batchDestination = batchDestination;
    this.batchCache = batchCache;
    this.config = { ...defaultConfig, ...config };

    this.checkPendingBatches();
  }

  async send(log: Log) {
    this.batchCache.addPendingLog(log);

    if (this.sending) return;

    const lastBatch = this.batchCache.getLastBatch();

    if (lastBatch && lastBatch.isOverflowed()) {
      await this.processBatch(lastBatch);
      return;
    }

    this.setupNextCheck();
  }

  private checkPendingBatches() {
    const lastBatch = this.batchCache.getLastBatch();

    if (!lastBatch) return;

    this.processBatch(lastBatch);
  }

  private async processBatch(batch: LogsBatch) {
    const nextCheckDelay = this.getBatchNextCheckDelay(batch);
    const shouldDelay = nextCheckDelay > 0;

    this.clearNextCheckTimeout();

    if (shouldDelay) {
      this.setupNextCheck(nextCheckDelay);
      return;
    }

    await this.sendBatch(batch);

    this.setupNextCheck();
  }

  private async sendBatch(batch: LogsBatch) {
    this.sending = true;

    batch.increaseRetryAttempt();
    batch.setSentAt(Date.now());

    try {
      await this.batchDestination.sendBatch(batch);

      this.batchCache.dropBatch(batch.getId());
    } catch (err) {
      if (this.shouldRetryBatch(batch)) {
        this.batchCache.saveBatch(batch);
      } else {
        this.batchCache.dropBatch(batch.getId());
      }
    } finally {
      this.sending = false;
    }
  }

  private setupNextCheck(nextCheckDelay?: number) {
    const delay = nextCheckDelay || this.getDefaultCheckDelay();

    if (this.nextCheckTimer || this.batchCache.isEmpty()) return;

    this.nextCheckTimer = setTimeout(() => {
      this.checkPendingBatches();
    }, delay);
  }

  private getBatchNextCheckDelay(batch: LogsBatch) {
    const nextCheckDate =
      batch.getSentAt() + this.getDefaultCheckDelay() * Math.pow(2, batch.getRetryAttempt());

    return nextCheckDate - Date.now();
  }

  private clearNextCheckTimeout() {
    clearTimeout(this.nextCheckTimer);
    this.nextCheckTimer = undefined;
  }

  private shouldRetryBatch(batch: LogsBatch) {
    return batch.getRetryAttempt() < this.config.maxRetryAttempt;
  }

  private getDefaultCheckDelay() {
    return this.config.debounceTime;
  }
}
