import { useServices } from './useServices';

export const useCookieConsent = () => {
  const { cookieConsentService } = useServices();

  const toggleReconsent = () => cookieConsentService.toggleReconsent();

  return {
    toggleReconsent,
  };
};
