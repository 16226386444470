import { ApiError } from 'core/common/errors/ApiError';

export class ResignError extends ApiError {
  public tokenId = '';

  constructor(error: ApiError) {
    super(error);
    this.tokenId = (error.originalError.data?.tokenId as string) || '';
  }
}
