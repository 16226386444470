import { LoadingStatuses } from 'core/common/entities';
import { Offer, StrictOffersMapByCategory } from '../entities';

export type OffersState = {
  loadingStatus: LoadingStatuses;
  selectedOffer: Offer | null;
  offersMapByCurrency: StrictOffersMapByCategory;
  offerDetailsLoadingStatus: LoadingStatuses;
};

export const offersInitialState: OffersState = {
  loadingStatus: LoadingStatuses.IDLE,
  offerDetailsLoadingStatus: LoadingStatuses.IDLE,
  selectedOffer: null,
  offersMapByCurrency: {
    defaultOffers: [],
    timeUpOffers: [],
    discountDefaultOffers: [],
    numerologyAnalysisUpsell: null,
    tarotReadingUpsell: null,
    palmistryGuideUpsell: null,
    discountOffer: null,
    ultraPackUpsell: null,
    discountUltraPackUpsell: null,
    trialCreditsUpsell: null,
    premiumUpsell: null,
    discountNumerologyAnalysisUpsell: null,
    discountTarotReadingUpsell: null,
    discountPalmistryUpsell: null,
    natalChartUpsell: null,
    blackMoonUpsell: null,
    consultationUpsell: null,
    discountConsultationUpsell: null,
    readingsPackUpsellOffer: null,
  },
};
