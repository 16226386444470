import { ReactNode } from 'react';
import { LoadingStatuses, UnionConditionsSchema } from 'core/common/entities';
import validator from 'core/common/utils/validator';
import { FlowAttributes } from 'core/flow/entities';
import { FunnelAnalyticsSchema } from 'core/funnel/entities/FunnelAnalyticsType';
import {
  EmailFormHelpers,
  FunnelImage,
  FunnelImageSchema,
  FunnelReviewSchema,
  LoaderContentDataSchema,
  PalmGuideItemSchema,
  PalmistryDiscountPlan,
  PalmistryDiscountPlanSchema,
  PalmistryPlan,
  PalmistryUpsell,
  ProgressBarSchema,
  TextWithEmojiSchema,
  UnlimitedReadingsSlideImagesSchema,
} from 'core/funnel/entities/FunnelData';
import {
  PageSectionSchema,
  SectionAttributesUnionSchema,
  SectionAttributesUnionType,
} from 'core/funnel/entities/Section';
import { HumanDesignReport } from 'core/human-design/entities';
import { Languages } from 'core/localization/entities';
import {
  Offer,
  OffersByCategorySchema,
  Upsell,
  UpsellSchema,
  UpsellV2,
} from 'core/offers/entities';
import { ProductPlan } from 'core/offers/entities/ProductPlan';
import { LineCoordinates, PalmCoordinates, PalmPhoto } from 'core/palmistry/entities';
import { PaymentError } from 'core/payments/entities';
import { OrderDTO } from 'core/payments/services/dto';
import { IconNames } from 'core/svg-sprites/entities';
import { Currency, CurrencySymbol } from 'core/user/entities';
import { AutocompleteFeature } from 'core/user/services';

export enum QuizStepTypes {
  TIME_V2 = 'TIME_V2',
  TIME_V3 = 'TIME_V3',
  DATE_PICKER = 'DATE_PICKER',
  DATE_PICKER_V2 = 'DATE_PICKER_V2',
  SPLIT = 'SPLIT',
  PRELANDING_PALMISTRY = 'PRELANDING_PALMISTRY',
  GENDER = 'GENDER',
  DYNAMIC_ANSWER = 'DYNAMIC_ANSWER',
  DYNAMIC_ANSWER_V2 = 'DYNAMIC_ANSWER_V2',
  GENDER_MOON_COMPATIBILITY = 'GENDER_MOON_COMPATIBILITY',
  GENDER_PALMISTRY = 'GENDER_PALMISTRY',
  GOAL = 'GOAL',
  WHICH_FIGURE = 'WHICH_FIGURE',
  GOAL_SET = 'GOAL_SET',
  DATE = 'DATE',
  DATE_PALMISTRY = 'DATE_PALMISTRY',
  DATE_NATAL_CHART = 'DATE_NATAL_CHART',
  DATE_NATAL_CHART_V2 = 'DATE_NATAL_CHART_V2',
  PLACE = 'PLACE',
  PLACE_NATAL_CHART = 'PLACE_NATAL_CHART',
  PLACE_HUMAN_DESIGN = 'PLACE_HUMAN_DESIGN',
  NAME = 'NAME',
  TIME = 'TIME',
  TIME_NATAL_CHART = 'TIME_NATAL_CHART',
  INFORMATIONAL = 'INFORMATIONAL',
  INFORMATIONAL_V2 = 'INFORMATIONAL_V2',
  INFORMATIONAL_PALMISTRY = 'INFORMATIONAL_PALMISTRY',
  INFORMATIONAL_NATAL_CHART = 'INFORMATIONAL_NATAL_CHART',
  IMAGE_INFORMATIONAL = 'IMAGE_INFORMATIONAL',
  COMPOUNDED_TITLE_INFORMATIONAL = 'COMPOUNDED_TITLE_INFORMATIONAL',
  ANSWER = 'ANSWER',
  ANSWER_PALMISTRY = 'ANSWER_PALMISTRY',
  ANSWER_NATAL_CHART = 'ANSWER_NATAL_CHART',
  ANSWER_NATAL_CHART_V2 = 'ANSWER_NATAL_CHART_V2',
  ANSWER_HORIZONTAL_ORIENTATION = 'ANSWER_HORIZONTAL_ORIENTATION',
  ANSWER_HORIZONTAL_ORIENTATION_V2 = 'ANSWER_HORIZONTAL_ORIENTATION_V2',
  ANSWER_HORIZONTAL_ORIENTATION_V3 = 'ANSWER_HORIZONTAL_ORIENTATION_V3',
  MULTIPLE_ANSWERS = 'MULTIPLE_ANSWERS',
  MULTIPLE_ANSWERS_NATAL_CHART = 'MULTIPLE_ANSWERS_NATAL_CHART',
  MULTIPLE_ANSWERS_NATAL_CHART_V2 = 'MULTIPLE_ANSWERS_NATAL_CHART_V2',
  PROBLEM = 'PROBLEM',
  EMAIL = 'EMAIL',
  USER_ANSWER = 'USER_ANSWER',
  EMAIL_SECURED = 'EMAIL_SECURED',
  EMAIL_PALMISTRY = 'EMAIL_PALMISTRY',
  EMAIL_SECURED_PALMISTRY = 'EMAIL_SECURED_PALMISTRY',
  EMAIL_NATAL_CHART = 'EMAIL_NATAL_CHART',
  EMAIL_CONFIRM = 'EMAIL_CONFIRM',
  EMAIL_CONFIRM_PALMISTRY = 'EMAIL_CONFIRM_PALMISTRY',
  EMAIL_CONFIRM_NATAL_CHART = 'EMAIL_CONFIRM_NATAL_CHART',
  PROCESSING = 'PROCESSING',
  PROCESSING_PALMISTRY = 'PROCESSING_PALMISTRY',
  PROCESSING_NATAL_CHART = 'PROCESSING_NATAL_CHART',
  PROCESSING_LOADER = 'PROCESSING_LOADER',
  REVIEW = 'REVIEW',
  ONBOARDING = 'ONBOARDING',
  ONBOARDING_INTRO_OFFER = 'ONBOARDING_INTRO_OFFER',
  ONBOARDING_PALMISTRY = 'ONBOARDING_PALMISTRY',
  ONBOARDING_READING = 'ONBOARDING_READING',
  SCAN_PREVIEW = 'SCAN_PREVIEW',
  SCAN = 'SCAN',
  SCAN_V2 = 'SCAN_V2',
  SCAN_ANALYSING = 'SCAN_ANALYSING',
  REPORT = 'REPORT',
  SINGLE_ZODIAC_INFO = 'SINGLE_ZODIAC_INFO',
  RELATIONSHIP_ZODIAC_INFO = 'RELATIONSHIP_ZODIAC_INFO',
  LIMITED_CHOICE = 'LIMITED_CHOICE',
  MENTIONED_IN = 'MENTIONED_IN',
  UPSELLS = 'UPSELLS',
  UPSELLS_V2 = 'UPSELLS_V2',
  UPSELLS_V3 = 'UPSELLS_V3',
  SOCIAL_PROOF = 'SOCIAL_PROOF',
  UPSELL_CONSULTATION = 'UPSELL_CONSULTATION',
  UPSELL_CONSULTATION_WITH_DISCOUNT = 'UPSELL_CONSULTATION_WITH_DISCOUNT',
  UPSELL_READINGS_PACK = 'UPSELL_READINGS_PACK',
  UPSELL_READINGS_PACK_WITH_DISCOUNT = 'UPSELL_READINGS_PACK_WITH_DISCOUNT',
  SUMMARY = 'SUMMARY',
  SUMMARY_V2 = 'SUMMARY_V2',
  TIMELINE = 'TIMELINE',
  LOADER_WITH_REVIEWS = 'LOADER_WITH_REVIEWS',
  /** @deprecated **/
  UPSELLS_PALMISTRY = 'UPSELLS_PALMISTRY',
  UPSELLS_PALMISTRY_V2 = 'UPSELLS_PALMISTRY_V2',
  UPSELLS_NATAL_CHART = 'UPSELLS_NATAL_CHART',
  UPSELLS_INTRO_OFFER = 'UPSELLS_INTRO_OFFER',
  UPSELLS_EMAIL_MARKETING = 'UPSELLS_EMAIL_MARKETING',
  UPSELLS_WITH_ADDITIONAL_DISCOUNT_UPSELLS = 'UPSELLS_WITH_ADDITIONAL_DISCOUNT_UPSELLS',
  UNLIMITED_READINGS = 'UNLIMITED_READINGS',
  UNLIMITED_READINGS_PALMISTRY = 'UNLIMITED_READINGS_PALMISTRY',
  UNLIMITED_READINGS_PALMISTRY_V2 = 'UNLIMITED_READINGS_PALMISTRY_V2',
  UNLIMITED_READINGS_EMAIL_MARKETING = 'UNLIMITED_READINGS_EMAIL_MARKETING',
  TRIAL_CREDITS = 'TRIAL_CREDITS',
  TRIAL_CREDITS_V2 = 'TRIAL_CREDITS_V2',
  TRIAL_CREDITS_PALMISTRY = 'TRIAL_CREDITS_PALMISTRY',
  TRIAL_CREDITS_PALMISTRY_V2 = 'TRIAL_CREDITS_PALMISTRY_V2',
  TRIAL_CREDITS_NATAL_CHART = 'TRIAL_CREDITS_NATAL_CHART',
  TRIAL_CREDITS_EMAIL_MARKETING = 'TRIAL_CREDITS_EMAIL_MARKETING',
  ADDITIONAL_DISCOUNT = 'ADDITIONAL_DISCOUNT',
  ADDITIONAL_DISCOUNT_PALMISTRY = 'ADDITIONAL_DISCOUNT_PALMISTRY',
  ADDITIONAL_DISCOUNT_PALMISTRY_V2 = 'ADDITIONAL_DISCOUNT_PALMISTRY_V2',
  ADDITIONAL_DISCOUNT_INTRO_OFFER = 'ADDITIONAL_DISCOUNT_INTRO_OFFER',
  CREDITS = 'CREDITS',
  CREDITS_PALMISTRY = 'CREDITS_PALMISTRY',
  CREDITS_NATAL_CHART = 'CREDITS_NATAL_CHART',
  CREDITS_INTRO_OFFER = 'CREDITS_INTRO_OFFER',
  ECOM_UPSELL = 'ECOM_UPSELL',
  LOADING = 'LOADING',
  LOADING_ASK = 'LOADING_ASK',
  LOADING_PALMISTRY = 'LOADING_PALMISTRY',
  LOADING_ASK_PALMISTRY = 'LOADING_ASK_PALMISTRY',
  LOADING_ASK_NATAL_CHART = 'LOADING_ASK_NATAL_CHART',
  ONBOARDING_ASK = 'ONBOARDING_ASK',
  ONBOARDING_ASK_V2 = 'ONBOARDING_ASK_V2',
  ONBOARDING_ASK_PALMISTRY = 'ONBOARDING_ASK_PALMISTRY',
  /** @deprecated **/
  ONBOARDING_ASK_PALMISTRY_V2 = 'ONBOARDING_ASK_PALMISTRY_V2',
  ONBOARDING_ASK_NATAL_CHART = 'ONBOARDING_ASK_NATAL_CHART',
  QUESTIONS = 'QUESTIONS',
  QUESTIONS_PALMISTRY = 'QUESTIONS_PALMISTRY',
  QUESTIONS_NATAL_CHART = 'QUESTIONS_NATAL_CHART',
  TALK_WITH_EXPERT = 'TALK_WITH_EXPERT',
  TALK_WITH_EXPERT_PALMISTRY = 'TALK_WITH_EXPERT_PALMISTRY',
  TALK_WITH_EXPERT_NATAL_CHART = 'TALK_WITH_EXPERT_NATAL_CHART',
  TRIAL_CHOICE = 'TRIAL_CHOICE',
  TRIAL_CHOICE_PALMISTRY = 'TRIAL_CHOICE_PALMISTRY',
  TRIAL_CHOICE_NATAL_CHART = 'TRIAL_CHOICE_NATAL_CHART',
  TRIAL_CHOICE_PALMISTRY_V2 = 'TRIAL_CHOICE_PALMISTRY_V2',
  TRIAL_PAYMENT_WITH_DISCOUNT = 'TRIAL_PAYMENT_WITH_DISCOUNT',
  TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY = 'TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY',
  TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V2 = 'TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V2',
  TRIAL_PAYMENT_WITH_DISCOUNT_INTRO_OFFER = 'TRIAL_PAYMENT_WITH_DISCOUNT_INTRO_OFFER',
  TRIAL_PAYMENT = 'TRIAL_PAYMENT',
  TRIAL_PAYMENT_HUMAN_DESIGN = 'TRIAL_PAYMENT_HUMAN_DESIGN',
  TRIAL_PAYMENT_HUMAN_DESIGN_V2 = 'TRIAL_PAYMENT_HUMAN_DESIGN_V2',
  TRIAL_PAYMENT_ONE_TIME_OFFER_HUMAN_DESIGN = 'TRIAL_PAYMENT_ONE_TIME_OFFER_HUMAN_DESIGN',
  TRIAL_PAYMENT_PALMISTRY = 'TRIAL_PAYMENT_PALMISTRY',
  TRIAL_PAYMENT_WITCH_POWER = 'TRIAL_PAYMENT_WITCH_POWER',
  TRIAL_PAYMENT_PALMISTRY_V2 = 'TRIAL_PAYMENT_PALMISTRY_V2',
  TRIAL_PAYMENT_PALMISTRY_V3 = 'TRIAL_PAYMENT_PALMISTRY_V3',
  TRIAL_PAYMENT_MOON_COMPATIBILITY = 'TRIAL_PAYMENT_MOON_COMPATIBILITY',
  TRIAL_PAYMENT_NATAL_CHART = 'TRIAL_PAYMENT_NATAL_CHART',
  TRIAL_PAYMENT_NATAL_CHART_V2 = 'TRIAL_PAYMENT_NATAL_CHART_V2',
  TRIAL_PAYMENT_INTRO_OFFER = 'TRIAL_PAYMENT_INTRO_OFFER',
  TRIAL_PAYMENT_INTRO_OFFER_V2 = 'TRIAL_PAYMENT_INTRO_OFFER_V2',
  PHONE_NUMBER = 'PHONE_NUMBER',
  PHONE_NUMBER_PALMISTRY = 'PHONE_NUMBER_PALMISTRY',
  PHONE_NUMBER_EMAIL_MARKETING = 'PHONE_NUMBER_EMAIL_MARKETING',
  MOON_PHASE = 'MOON_PHASE',
  PARTNER_MOON_PHASE = 'PARTNER_MOON_PHASE',
  THANK_YOU = 'THANK_YOU',
  THANK_YOU_WITH_REDIRECT = 'THANK_YOU_WITH_REDIRECT',
  PRELANDING_MOON_COMPATIBILITY = 'PRELANDING_MOON_COMPATIBILITY',
  NAME_MOON_COMPATIBILITY = 'NAME_MOON_COMPATIBILITY',
  NAME_MOON_COMPATIBILITY_V2 = 'NAME_MOON_COMPATIBILITY_V2',
  LOADING_MOON_COMPATIBILITY = 'LOADING_MOON_COMPATIBILITY',
  EMAIL_MARKETING_MOON_COMPATIBILITY_PAYWALL = 'EMAIL_MARKETING_MOON_COMPATIBILITY_PAYWALL',
  UPSELL_BLACK_MOON_SINGLE = 'UPSELL_BLACK_MOON_SINGLE',
  UPSELL_NATAL_CHART_SINGLE = 'UPSELL_NATAL_CHART_SINGLE',
  EMAIL_MARKETING_WITCH_POWER = 'EMAIL_MARKETING_WITCH_POWER',
  EMAIL_MARKETING_WITCH_POWER_V2 = 'EMAIL_MARKETING_WITCH_POWER_V2' /** @deprecated */,
  EMAIL_MARKETING_WITCH_POWER_INTRO_PAYWALL = 'EMAIL_MARKETING_WITCH_POWER_INTRO_PAYWALL' /** @deprecated */,
  EMAIL_MARKETING_SPIRIT_ANIMAL_PAYWALL = 'EMAIL_MARKETING_SPIRIT_ANIMAL_PAYWALL' /** @deprecated */,
  EMAIL_MARKETING_FEMININE_ARCHETYPES_PAYWALL = 'EMAIL_MARKETING_FEMININE_ARCHETYPES_PAYWALL' /** @deprecated */,
  EMAIL_MARKETING_NATAL_CHART = 'EMAIL_MARKETING_NATAL_CHART',
  EMAIL_MARKETING_NATAL_CHART_V2 = 'EMAIL_MARKETING_NATAL_CHART_V2' /** @deprecated */,
  EMAIL_MARKETING_PALMISTRY = 'EMAIL_MARKETING_PALMISTRY',
  EMAIL_MARKETING_PALMISTRY_PAYWALL = 'EMAIL_MARKETING_PALMISTRY_PAYWALL' /** @deprecated */,
  EMAIL_MARKETING_SHAMANS_PAYWALL = 'EMAIL_MARKETING_SHAMANS_PAYWALL' /** @deprecated */,
  EMAIL_MARKETING_COMPATIBILITY = 'EMAIL_MARKETING_COMPATIBILITY',
  EMAIL_MARKETING_COMPATIBILITY_V2 = 'EMAIL_MARKETING_COMPATIBILITY_V2' /** @deprecated */,
  HELLO_MOON_COMPATIBILITY = 'HELLO_MOON_COMPATIBILITY',
  EMAIL_BLURRED = 'EMAIL_BLURRED',
  USER_TYPE_HUMAN_DESIGN = 'USER_TYPE_HUMAN_DESIGN',
  NAME_HUMAN_DESIGN = 'NAME_HUMAN_DESIGN',
  APP_DOWNLOAD = 'APP_DOWNLOAD',
  RELATIONSHIP_PROGRESS = 'RELATIONSHIP_PROGRESS',
  TRIAL_PAYMENT_ATTACHMENT_STYLE = 'TRIAL_PAYMENT_ATTACHMENT_STYLE' /** @deprecated */,
  TRIAL_PAYMENT_ATTACHMENT_STYLE_V2 = 'TRIAL_PAYMENT_ATTACHMENT_STYLE_V2' /** @deprecated */,
  TRIAL_PAYMENT_ATTACHMENT_STYLE_V3 = 'TRIAL_PAYMENT_ATTACHMENT_STYLE_V3',
  TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V3 = 'TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V3',
  EMAIL_MARKETING_ATTACHMENT_STYLE_PAYWALL = 'EMAIL_MARKETING_ATTACHMENT_STYLE_PAYWALL',
  GENDER_V2 = 'GENDER_V2',
  TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT = 'TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT',
  TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V2 = 'TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V2',
  TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V3 = 'TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V3',
  ANSWER_IMAGE = 'ANSWER_IMAGE',
  ANSWER_IMAGE_MULTIPLE = 'ANSWER_IMAGE_MULTIPLE',
  TRIAL_PAYMENT_PALMISTRY_V4 = 'TRIAL_PAYMENT_PALMISTRY_V4',
  TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V4 = 'TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V4',
  EMAIL_MARKETING_PAYWALL = 'EMAIL_MARKETING_PAYWALL',
  W2A_PROMOTION = 'W2A_PROMOTION',
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

const QuizStepBackgroundSchema = validator.object({
  desktopImage: FunnelImageSchema,
  mobileImage: FunnelImageSchema,
});

export type QuizStepBackground = validator.infer<typeof QuizStepBackgroundSchema>;

export enum AnimatedImages {
  COMETA = 'COMETA',
  CLOCK = 'CLOCK',
  KEY = 'KEY',
  UMBRELLA = 'UMBRELLA',
  SUN = 'SUN',
  LUPA = 'LUPA',
  AIM = 'AIM',
  CLOUD = 'CLOUD',
  SCALES_BRAIN = 'SCALES_BRAIN',
  SCALES_HEART = 'SCALES_HEART',
  HEART_BRAIN_EQUAL = 'HEART_BRAIN_EQUAL',
  COMPASS = 'COMPASS',
  HELLO = 'HELLO',
  CONFETTI = 'CONFETTI',
  CONFETTI_WITH_FIREWORK = 'CONFETTI_WITH_FIREWORK',
  CLARITY = 'CLARITY',
  COMMUNICATION = 'COMMUNICATION',
}

export enum RelationshipTypes {
  PARTNER = 'partner',
  SINGLE = 'single',
}

export enum FunnelScreenType {
  QUIZ_STEP = 'QUIZ_STEP',
  PAGE = 'PAGE',
}

const ConditionSchema = validator.record(validator.string(), UnionConditionsSchema);

const BaseQuizScreenAttributesSchema = validator.object({
  experiment: ConditionSchema.optional(),
  condition: ConditionSchema.optional(),
  index: validator.number(),
  screenType: validator.nativeEnum(QuizStepTypes),
  supportWidget: validator
    .object({
      text: validator.string(),
    })
    .optional(),
  content: validator.record(validator.string(), validator.unknown()),
  progressBarName: validator.enum(['profile', 'personality', 'relationship']).optional(),
  totalProfileSteps: validator.number().optional(),
  totalPersonalitySteps: validator.number().optional(),
  totalRelationshipSteps: validator.number().optional(),
  progressBarTitle: validator.string().optional(),
  totalSteps: validator.number().optional(),
  progressBar: ProgressBarSchema.optional(),
  analytic: validator
    .object({
      baseEvent: validator.string().optional(),
      baseEventProperties: validator.record(validator.string(), validator.unknown()).optional(),
      clickEvent: validator.string().optional(),
    })
    .optional(),
  next: validator.string(),
  extraNext: validator.string().optional(),
  prev: validator.union([validator.string(), validator.null()]),
});

export type BaseQuizAttributes = validator.infer<typeof BaseQuizScreenAttributesSchema>;

const BasePageAttributesSchema = validator.object({
  experiment: ConditionSchema.optional(),
  condition: ConditionSchema.optional(),
  index: validator.number(),
  screenType: validator.nativeEnum(QuizStepTypes),
  supportWidget: validator
    .object({
      text: validator.string(),
    })
    .optional(),
  sections: validator.array(PageSectionSchema),
  analytic: validator
    .object({
      baseEvent: validator.string().optional(),
      baseEventProperties: validator.record(validator.string(), validator.unknown()).optional(),
      clickEvent: validator.string().optional(),
    })
    .optional(),
  next: validator.string(),
  extraNext: validator.string().optional(),
  prev: validator.union([validator.string(), validator.null()]),
});

export type BasePageAttributes = validator.infer<typeof BasePageAttributesSchema>;

export type BasePageAttributesWithMappedSections = Omit<BasePageAttributes, 'sections'> & {
  sections: Array<SectionAttributesUnionType>;
};

const BaseQuizStepDataSchema = validator.object({
  id: validator.string(),
  attributes: validator.array(BaseQuizScreenAttributesSchema),
  type: validator.literal(FunnelScreenType.QUIZ_STEP).optional(),
});

const BasePageDataSchema = validator.object({
  id: validator.string(),
  attributes: validator.array(BasePageAttributesSchema),
  type: validator.literal(FunnelScreenType.PAGE).optional(),
});

const PhoneNumberStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        placeholder: validator.string(),
        guarantee: validator.string(),
        approve: validator.string(),
        accept: validator.string(),
        decline: validator.string(),
        notValidNumberMessage: validator.string(),
        image: validator.object({
          desktop: FunnelImageSchema,
          mobile: FunnelImageSchema,
        }),
        secureIcon: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.PHONE_NUMBER),
    }),
  ),
});

export type PhoneNumberStepData = validator.infer<typeof PhoneNumberStepDataSchema>;

const PhoneNumberPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        placeholder: validator.string(),
        guarantee: validator.string(),
        approve: validator.string(),
        accept: validator.string(),
        decline: validator.string(),
        notValidNumberMessage: validator.string(),
        image: validator.object({
          desktop: FunnelImageSchema,
          mobile: FunnelImageSchema,
        }),
        secureIcon: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.PHONE_NUMBER_PALMISTRY),
    }),
  ),
});

export type PhoneNumberPalmistryStepData = validator.infer<
  typeof PhoneNumberPalmistryStepDataSchema
>;

const PhoneNumberEmailMarketingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        placeholder: validator.string(),
        guarantee: validator.string(),
        approve: validator.string(),
        accept: validator.string(),
        decline: validator.string(),
        notValidNumberMessage: validator.string(),
        image: validator.object({
          desktop: FunnelImageSchema,
          mobile: FunnelImageSchema,
        }),
        secureIcon: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.PHONE_NUMBER_EMAIL_MARKETING),
    }),
  ),
});

export type PhoneNumberEmailMarketingStepData = validator.infer<
  typeof PhoneNumberEmailMarketingStepDataSchema
>;

const UpsellsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        bannerTitle: validator.string(),
        bannerText: validator.string(),
        bannerEmoji: validator.string(),
        footerText: validator.string(),
        button: validator.string(),
        link: validator.string(),
        tarotFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
        ultraPackFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS),
    }),
  ),
});

export type UpsellsStepData = validator.infer<typeof UpsellsStepDataSchema>;

const UpsellsV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        banner: validator.object({
          title: validator.string(),
          text: validator.string(),
          emoji: validator.string(),
        }),
        subtitle: validator.string(),
        footerText: validator.string(),
        button: validator.string(),
        link: validator.string(),
        tarotFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
        ultraPackFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS_V2),
    }),
  ),
});

export type UpsellsV2StepData = validator.infer<typeof UpsellsV2StepDataSchema>;

const UpsellsPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        banner: validator.object({
          title: validator.string(),
          text: validator.string(),
          emoji: validator.string(),
        }),
        footer: validator.object({
          disclaimer: validator.string(),
          addressUS: validator.string(),
        }),
        button: validator.string(),
        skipButton: validator.string(),
        tarotFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
        ultraPackFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS_PALMISTRY_V2),
    }),
  ),
});

const UpsellsPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        banner: validator.object({
          title: validator.string(),
          text: validator.string(),
          emoji: validator.string(),
        }),
        footer: validator.object({
          disclaimer: validator.string(),
          addressUS: validator.string(),
        }),
        button: validator.string(),
        skipButton: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS_PALMISTRY),
    }),
  ),
});

export type UpsellsPalmistryStepData = validator.infer<typeof UpsellsPalmistryStepDataSchema>;

export type UpsellsPalmistryV2StepData = validator.infer<typeof UpsellsPalmistryV2StepDataSchema>;

const UpsellsNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        plans: validator.object({
          numerologyAnalysis: UpsellSchema.omit({ offerId: true }),
          tarotReading: UpsellSchema.omit({ offerId: true }),
          palmistryGuide: UpsellSchema.omit({ offerId: true }),
          ultraPack: UpsellSchema.omit({ offerId: true }),
        }),
        header: validator.object({
          buyStep: validator.string(),
          consultationStep: validator.string(),
          accessProductStep: validator.string(),
          bannerEmoji: validator.string(),
          bannerTitle: validator.string(),
          bannerText: validator.string(),
          title: TextWithEmojiSchema,
          subtitle: validator.string(),
        }),
        footer: validator.object({
          disclaimer: validator.string(),
          addressUS: validator.string(),
        }),
        button: validator.string(),
        skipButton: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS_NATAL_CHART),
    }),
  ),
});

export type UpsellsNatalChartStepData = validator.infer<typeof UpsellsNatalChartStepDataSchema>;

const UpsellsIntroOfferStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.UPSELLS_INTRO_OFFER),
    }),
  ),
});

export type UpsellsIntroOfferStepData = validator.infer<typeof UpsellsIntroOfferStepDataSchema>;

const UpsellsEmailMarketingDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        bannerTitle: validator.string(),
        bannerText: validator.string(),
        bannerEmoji: validator.string(),
        footerText: validator.string(),
        button: validator.string(),
        link: validator.string(),
        tarotFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
        ultraPackFirstUpsells: validator.record(
          validator.string(),
          UpsellSchema.omit({ offerId: true }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELLS_EMAIL_MARKETING),
    }),
  ),
});

export type UpsellsEmailMarketingStepData = validator.infer<typeof UpsellsEmailMarketingDataSchema>;

const UnlimitedReadingsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        infoSlide: validator.object({
          image: FunnelImageSchema,
          benefits: validator.array(TextWithEmojiSchema),
        }),
        imageSlides: validator.array(UnlimitedReadingsSlideImagesSchema),
        title: validator.string(),
        benefits: validator.array(TextWithEmojiSchema),
        button: validator.string(),
        skipButton: validator.string(),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.UNLIMITED_READINGS),
    }),
  ),
});

export type UnlimitedReadingsStepData = validator.infer<typeof UnlimitedReadingsStepDataSchema>;

const UnlimitedReadingsPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        infoSlide: validator.object({
          image: FunnelImageSchema,
          benefits: validator.array(TextWithEmojiSchema),
        }),
        imageSlides: validator.array(UnlimitedReadingsSlideImagesSchema),
        title: validator.string(),
        benefits: validator.array(TextWithEmojiSchema),
        button: validator.string(),
        skipButton: validator.string(),
        banner: validator
          .object({
            title: validator.string(),
            text: validator.string(),
            emoji: validator.string(),
          })
          .optional(),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.UNLIMITED_READINGS_PALMISTRY),
    }),
  ),
});

const UnlimitedReadingsPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        infoSlide: validator.object({
          image: FunnelImageSchema,
          benefits: validator.array(TextWithEmojiSchema),
        }),
        imageSlides: validator.array(UnlimitedReadingsSlideImagesSchema),
        title: validator.string(),
        benefits: validator.array(TextWithEmojiSchema),
        button: validator.string(),
        skipButton: validator.string(),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.UNLIMITED_READINGS_PALMISTRY_V2),
    }),
  ),
});

export type UnlimitedReadingsPalmistryStepData = validator.infer<
  typeof UnlimitedReadingsPalmistryStepDataSchema
>;

export type UnlimitedReadingsPalmistryV2StepData = validator.infer<
  typeof UnlimitedReadingsPalmistryV2StepDataSchema
>;

const UnlimitedReadingsEmailMarketingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        infoSlide: validator.object({
          image: FunnelImageSchema,
          benefits: validator.array(TextWithEmojiSchema),
        }),
        imageSlides: validator.array(UnlimitedReadingsSlideImagesSchema),
        title: validator.string(),
        benefits: validator.array(TextWithEmojiSchema),
        button: validator.string(),
        skipButton: validator.string(),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.UNLIMITED_READINGS_EMAIL_MARKETING),
    }),
  ),
});

export type UnlimitedReadingsEmailMarketingStepData = validator.infer<
  typeof UnlimitedReadingsEmailMarketingStepDataSchema
>;

const TrialCreditsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        offer: validator.object({
          title: validator.string(),
          consultation: validator.string(),
          description: validator.string(),
          priceTitle: validator.string(),
          originalPriceTitle: validator.string(),
          discountTitle: validator.string(),
        }),
        button: validator.string(),
        link: validator.string(),
        footer: validator.object({
          description: validator.string(),
          addressUS: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS),
    }),
  ),
});

export type TrialCreditsStepData = validator.infer<typeof TrialCreditsStepDataSchema>;

const TrialCreditsV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        offer: validator.object({
          title: validator.string(),
          consultation: validator.string(),
          description: validator.string(),
          priceTitle: validator.string(),
          originalPriceTitle: validator.string(),
          discountTitle: validator.string(),
        }),
        button: validator.string(),
        link: validator.string(),
        footer: validator.object({
          description: validator.string(),
          addressUS: validator.string(),
          address: validator.string(),
        }),
        upsellsSteps: validator.array(
          validator.object({
            title: validator.string(),
            step: validator.string(),
          }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS_V2),
    }),
  ),
});

export type TrialCreditsV2StepData = validator.infer<typeof TrialCreditsV2StepDataSchema>;

const TrialCreditsPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        banner: validator.object({
          title: validator.string(),
          text: validator.string(),
          emoji: validator.string(),
        }),
        offer: validator.object({
          title: validator.string(),
          consultation: validator.string(),
          description: validator.string(),
          priceTitle: validator.string(),
          originalPriceTitle: validator.string(),
          discountTitle: validator.string(),
        }),
        button: validator.string(),
        link: validator.string(),
        footer: validator.object({
          description: validator.string(),
          addressUS: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS_PALMISTRY),
    }),
  ),
});

const TrialCreditsPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        offer: validator.object({
          title: validator.string(),
          consultation: validator.string(),
          description: validator.string(),
          priceTitle: validator.string(),
          originalPriceTitle: validator.string(),
          discountTitle: validator.string(),
        }),
        button: validator.string(),
        link: validator.string(),
        footer: validator.object({
          description: validator.string(),
          addressUS: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS_PALMISTRY_V2),
    }),
  ),
});

export type TrialCreditsPalmistryStepData = validator.infer<
  typeof TrialCreditsPalmistryStepDataSchema
>;

export type TrialCreditsPalmistryV2StepData = validator.infer<
  typeof TrialCreditsPalmistryV2StepDataSchema
>;

const TrialCreditsNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          buyStep: validator.string(),
          consultationStep: validator.string(),
          accessProductStep: validator.string(),
          title: validator.string(),
          subtitle: validator.string(),
        }),
        offer: validator.object({
          title: validator.string(),
          consultationText: validator.object({
            beforeHighlighted: validator.string(),
            highlighted: validator.string(),
            afterHighlighted: validator.string(),
          }),
          description: validator.string(),
          priceText: validator.string(),
          originalPriceText: validator.string(),
          saveMoneyText: validator.string(),
        }),
        footer: validator.object({
          disclaimer: validator.string(),
          addressUS: validator.string(),
        }),
        button: validator.string(),
        skipButton: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS_NATAL_CHART),
    }),
  ),
});

export type TrialCreditsNatalChartStepData = validator.infer<
  typeof TrialCreditsNatalChartStepDataSchema
>;

const TrialCreditsEmailMarketingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        offer: validator.object({
          title: validator.string(),
          consultation: validator.string(),
          description: validator.string(),
          priceTitle: validator.string(),
          originalPriceTitle: validator.string(),
          discountTitle: validator.string(),
        }),
        button: validator.string(),
        link: validator.string(),
        footer: validator.object({
          description: validator.string(),
          addressUS: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CREDITS_EMAIL_MARKETING),
    }),
  ),
});

export type TrialCreditsEmailMarketingStepData = validator.infer<
  typeof TrialCreditsEmailMarketingStepDataSchema
>;

const AppDownloadStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        skipButtonText: validator.string(),
        iosSkipText: validator.string(),
        iosSmallText: validator.string(),
        ratingAmount: validator.number(),
        ratingFooterText: validator.string(),
        appStoreText: validator.string(),
        footerText: validator.string(),
        download: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.APP_DOWNLOAD),
    }),
  ),
});

export type AppDownloadStepData = validator.infer<typeof AppDownloadStepDataSchema>;

const CreditsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.CREDITS),
    }),
  ),
});

export type CreditsStepData = validator.infer<typeof CreditsStepDataSchema>;

const CreditsPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.CREDITS_PALMISTRY),
    }),
  ),
});

export type CreditsPalmistryStepData = validator.infer<typeof CreditsPalmistryStepDataSchema>;

const CreditsNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.CREDITS_NATAL_CHART),
    }),
  ),
});

export type CreditsNatalChartStepData = validator.infer<typeof CreditsNatalChartStepDataSchema>;

const CreditsIntroOfferStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.CREDITS_INTRO_OFFER),
    }),
  ),
});

export type CreditsIntroOfferStepData = validator.infer<typeof CreditsIntroOfferStepDataSchema>;

const EcomUpsellStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.ECOM_UPSELL),
    }),
  ),
});

export type EcomUpsellStepData = validator.infer<typeof EcomUpsellStepDataSchema>;

const LoadingAskStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        list: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.LOADING_ASK),
    }),
  ),
});

export type LoadingAskStepData = validator.infer<typeof LoadingAskStepDataSchema>;

const LoadingAskPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        list: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.LOADING_ASK_PALMISTRY),
    }),
  ),
});

export type LoadingAskPalmistryStepData = validator.infer<typeof LoadingAskPalmistryStepDataSchema>;

const LoadingAskNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        list: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.LOADING_ASK_NATAL_CHART),
    }),
  ),
});

export type LoadingAskNatalChartStepData = validator.infer<
  typeof LoadingAskNatalChartStepDataSchema
>;

const OnboardingAskStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        welcomeText: validator.string(),
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_ASK),
    }),
  ),
});

export type OnboardingAskStepData = validator.infer<typeof OnboardingAskStepDataSchema>;

const OnboardingAskV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        reading: validator.object({
          title: validator.string(),
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        intro: validator.object({
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        chatTopics: validator.object({
          title: validator.string(),
          tip: validator.string(),
          message: FunnelImageSchema,
          list: validator.array(
            validator.object({
              text: validator.string(),
              avatar: FunnelImageSchema,
            }),
          ),
          animationIndex: validator.number(),
        }),
        credits: validator.object({
          title: validator.string(),
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        progressBarText: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_ASK_V2),
    }),
  ),
});

export type OnboardingAskV2StepData = validator.infer<typeof OnboardingAskV2StepDataSchema>;

const OnboardingAskPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        welcomeText: validator.string(),
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_ASK_PALMISTRY),
    }),
  ),
});

export type OnboardingAskPalmistryStepData = validator.infer<
  typeof OnboardingAskPalmistryStepDataSchema
>;

const OnboardingAskPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        reading: validator.object({
          title: validator.string(),
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        intro: validator.object({
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        chatTopics: validator.object({
          title: validator.string(),
          tip: validator.string(),
          message: FunnelImageSchema,
          list: validator.array(
            validator.object({
              text: validator.string(),
              avatar: FunnelImageSchema,
            }),
          ),
          animationIndex: validator.number(),
        }),
        credits: validator.object({
          title: validator.string(),
          description: validator.string(),
          image: FunnelImageSchema,
          message: FunnelImageSchema,
          animationIndex: validator.number(),
        }),
        progressBarText: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_ASK_PALMISTRY_V2),
    }),
  ),
});

export type OnboardingAskPalmistryV2StepData = validator.infer<
  typeof OnboardingAskPalmistryV2StepDataSchema
>;

const OnboardingAskNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        welcomeText: validator.string(),
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_ASK_NATAL_CHART),
    }),
  ),
});

export type OnboardingAskNatalChartStepData = validator.infer<
  typeof OnboardingAskNatalChartStepDataSchema
>;

const QuestionsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        firstMessage: validator.string(),
        secondMessage: validator.string(),
        thirdMessage: validator.string(),
        tip: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.QUESTIONS),
    }),
  ),
});

export type QuestionsStepData = validator.infer<typeof QuestionsStepDataSchema>;

const QuestionsPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        firstMessage: validator.string(),
        secondMessage: validator.string(),
        thirdMessage: validator.string(),
        tip: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.QUESTIONS_PALMISTRY),
    }),
  ),
});

export type QuestionsPalmistryStepData = validator.infer<typeof QuestionsPalmistryStepDataSchema>;

const QuestionsNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        firstMessage: validator.string(),
        secondMessage: validator.string(),
        thirdMessage: validator.string(),
        tip: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.QUESTIONS_NATAL_CHART),
    }),
  ),
});

export type QuestionsNatalChartStepData = validator.infer<typeof QuestionsNatalChartStepDataSchema>;

const TalkWithExpertStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TALK_WITH_EXPERT),
    }),
  ),
});

export type TalkWithExpertStepData = validator.infer<typeof TalkWithExpertStepDataSchema>;

const TalkWithExpertPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TALK_WITH_EXPERT_PALMISTRY),
    }),
  ),
});

export type TalkWithExpertPalmistryStepData = validator.infer<
  typeof TalkWithExpertPalmistryStepDataSchema
>;

const TalkWithExpertNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        text: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TALK_WITH_EXPERT_NATAL_CHART),
    }),
  ),
});

export type TalkWithExpertNatalChartStepData = validator.infer<
  typeof TalkWithExpertNatalChartStepDataSchema
>;

const TrialChoiceStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        helpedPeopleText: validator.string(),
        chooseAmountText: validator.string(),
        costText: validator.string(),
        tipText: validator.string(),
        information: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
        }),
        button: validator.string(),
        note: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CHOICE),
    }),
  ),
});

export type TrialChoiceStepData = validator.infer<typeof TrialChoiceStepDataSchema>;

const TrialChoiceNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        satisfactionTitle: validator.string(),
        satisfactionText: validator.string(),
        savingsTitle: validator.string(),
        savingsText: validator.string(),
        tipText: validator.string(),
        button: validator.string(),
        note: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CHOICE_NATAL_CHART),
    }),
  ),
});

export type TrialChoiceNatalChartStepData = validator.infer<
  typeof TrialChoiceNatalChartStepDataSchema
>;

const TrialChoicePalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        satisfactionTitle: validator.string(),
        satisfactionText: validator.string(),
        savingsTitle: validator.string(),
        savingsText: validator.string(),
        tipText: validator.string(),
        button: validator.string(),
        note: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CHOICE_PALMISTRY),
    }),
  ),
});

const TrialChoicePalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        satisfactionTitle: validator.string(),
        satisfactionText: validator.string(),
        savingsTitle: validator.string(),
        savingsText: validator.string(),
        tipText: validator.string(),
        button: validator.string(),
        note: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_CHOICE_PALMISTRY_V2),
    }),
  ),
});

export type TrialChoicePalmistryStepData = validator.infer<
  typeof TrialChoicePalmistryStepDataSchema
>;

export type TrialChoicePalmistryV2StepData = validator.infer<
  typeof TrialChoicePalmistryV2StepDataSchema
>;

const TrialPaymentWithDiscountStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.object({
          text: validator.string(),
          emoji: validator.string(),
        }),
        offerContent: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          discount: validator.object({
            text: validator.string(),
            emoji: validator.string(),
            oldDiscountPercent: validator.string(),
            discountPercent: validator.string(),
          }),
          perMonthCostText: validator.string(),
          moneySaveText: validator.string(),
          totalText: validator.string(),
        }),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT),
    }),
  ),
});

export type TrialPaymentWithDiscountStepData = validator.infer<
  typeof TrialPaymentWithDiscountStepDataSchema
>;

const TrialPaymentWithDiscountPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: TextWithEmojiSchema,
        offer: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          discountApplied: TextWithEmojiSchema,
          saveMoney: validator.object({
            beforePrice: validator.string(),
            afterPrice: validator.string(),
          }),
          discountPercent: validator.string(),
          oldDiscountPercent: validator.string(),
          perMonthCostText: validator.string(),
          totalText: validator.string(),
        }),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V2),
    }),
  ),
});

const TrialPaymentWithDiscountPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        planUSBucket: PalmistryDiscountPlanSchema,
        planFirstTierBucket: PalmistryDiscountPlanSchema,
        planEuropeanBucket: PalmistryDiscountPlanSchema,
        planSecondTierBucket: PalmistryDiscountPlanSchema,
        title: TextWithEmojiSchema,
        offer: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          discountApplied: TextWithEmojiSchema,
          cost: validator.object({
            beforePeriod: validator.string(),
            afterPeriod: validator.string(),
          }),
          saveMoney: validator.object({
            beforePrice: validator.string(),
            afterPrice: validator.string(),
          }),
          totalText: validator.string(),
        }),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY),
    }),
  ),
});

export type TrialPaymentWithDiscountPalmistryStepData = validator.infer<
  typeof TrialPaymentWithDiscountPalmistryStepDataSchema
>;

export type TrialPaymentWithDiscountPalmistryV2StepData = validator.infer<
  typeof TrialPaymentWithDiscountPalmistryV2StepDataSchema
>;

const TrialPaymentWithDiscountIntroOfferStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_INTRO_OFFER),
    }),
  ),
});

export type TrialPaymentWithDiscountIntroOfferStepData = validator.infer<
  typeof TrialPaymentWithDiscountIntroOfferStepDataSchema
>;

const TrialPaymentStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
          min: validator.string(),
          sec: validator.string(),
        }),
        userDetails: validator.object({
          zodiacTitle: validator.string(),
          zodiacSign: validator.literal('{{translate zodiac}}'),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          birthPlaceTitle: validator.string(),
          birthPlace: validator.string(),
          partnerZodiacSign: validator.literal('{{translate partnerZodiac}}').optional(),
          partnerGender: validator.literal('{{translate partnerGender}}').optional(),
          partnerBirthDate: validator.string().optional(),
          partnerBirthPlace: validator.string().optional(),
          youImageText: validator.string().optional(),
          partnerImageText: validator.string().optional(),
        }),
        title: validator.string().optional(),
        goal: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costPerMonthText: validator.string(),
          costPerMonthTextPaidTraffic: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string().optional(),
          secondaryGuaranteeText: validator.string().optional(),
        }),
        report: validator.object({
          title: validator.string(),
          contentTitle: validator.string(),
          contentPoints: validator.array(validator.string()),
          personalityTitle: validator.string(),
          personalityDescription: validator.string(),
          accessDescription: validator.string(),
          getAccess: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        benefits: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              question: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT),
    }),
  ),
});

export type TrialPaymentStepData = validator.infer<typeof TrialPaymentStepDataSchema>;

const TrialPaymentPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        hero: validator.object({
          title: validator.string(),
        }),
        title: validator.string(),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costText: validator.string(),
          costTextOrganic: validator.string(),
          button: validator.string(),
          guranteeText: validator.string(),
        }),
        disclaimer: validator.object({
          beforePriceText: validator.string(),
          afterPriceText: validator.string(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        palmGuide: validator.object({
          title: validator.string(),
          description: validator.string(),
          lifeLine: PalmGuideItemSchema,
          headLine: PalmGuideItemSchema,
          marriageLine: PalmGuideItemSchema,
          loveLine: PalmGuideItemSchema,
          fateLine: PalmGuideItemSchema,
          fingers: PalmGuideItemSchema,
        }),
        userDetails: validator.object({
          title: validator.string(),
          zodiacTitle: validator.string(),
          zodiac: validator.literal('{{translate zodiac}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_PALMISTRY),
    }),
  ),
});

export type TrialPaymentPalmistryStepData = validator.infer<
  typeof TrialPaymentPalmistryStepDataSchema
>;

const TrialPaymentPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        hero: validator.object({
          title: validator.string(),
        }),
        title: validator.string(),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costText: validator.string(),
          costTextOrganic: validator.string(),
          button: validator.string(),
          guranteeText: validator.string().optional(),
          secondaryGuaranteeText: validator.string().optional(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        palmGuide: validator.object({
          title: validator.string(),
          description: validator.string(),
          lifeLine: PalmGuideItemSchema,
          headLine: PalmGuideItemSchema,
          marriageLine: PalmGuideItemSchema,
          loveLine: PalmGuideItemSchema,
          fateLine: PalmGuideItemSchema,
          fingers: PalmGuideItemSchema,
        }),
        userDetails: validator.object({
          title: validator.string(),
          zodiacTitle: validator.string(),
          zodiac: validator.literal('{{translate zodiac}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V2),
    }),
  ),
});

export type TrialPaymentPalmistryV2StepData = validator.infer<
  typeof TrialPaymentPalmistryV2StepDataSchema
>;

const TrialPaymentPalmistryV3StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V3),
    }),
  ),
});

export type TrialPaymentPalmistryV3StepData = validator.infer<
  typeof TrialPaymentPalmistryV3StepDataSchema
>;

const TrialPaymentPalmistryV4StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_PALMISTRY_V4),
    }),
  ),
});

export type TrialPaymentPalmistryV4StepData = validator.infer<
  typeof TrialPaymentPalmistryV4StepDataSchema
>;

const EmailMarketingCompatibilityStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.EMAIL_MARKETING_COMPATIBILITY),
    }),
  ),
});

export type EmailMarketingCompatibilityStepData = validator.infer<
  typeof EmailMarketingCompatibilityStepDataSchema
>;

const EmailMarketingNatalChartStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.EMAIL_MARKETING_NATAL_CHART),
    }),
  ),
});

export type EmailMarketingNatalChartStepData = validator.infer<
  typeof EmailMarketingNatalChartStepDataSchema
>;

const EmailMarketingPalmistryStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.EMAIL_MARKETING_PALMISTRY),
    }),
  ),
});

export type EmailMarketingPalmistryStepData = validator.infer<
  typeof EmailMarketingPalmistryStepDataSchema
>;

const EmailMarketingWitchPowerStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.EMAIL_MARKETING_WITCH_POWER),
    }),
  ),
});

export type EmailMarketingWitchPowerStepData = validator.infer<
  typeof EmailMarketingWitchPowerStepDataSchema
>;

const TrialPaymentWithDiscountPalmistryV3StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V3),
    }),
  ),
});

export type TrialPaymentWithDiscountPalmistryV3StepData = validator.infer<
  typeof TrialPaymentWithDiscountPalmistryV3StepDataSchema
>;

const TrialPaymentWithDiscountPalmistryV4StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITH_DISCOUNT_PALMISTRY_V4),
    }),
  ),
});

export type TrialPaymentWithDiscountPalmistryV4StepData = validator.infer<
  typeof TrialPaymentWithDiscountPalmistryV4StepDataSchema
>;

const TrialPaymentMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        preview: validator.object({
          title: validator.string(),
          moonPhase: validator.object({
            title: validator.string(),
          }),
          birthDate: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          moonSign: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          partner: validator.object({
            title: validator.string(),
            moonPhase: validator.object({
              title: validator.string(),
              fallbackImage: FunnelImageSchema,
            }),
            birthDate: validator.object({
              title: validator.string(),
              value: validator.string(),
            }),
            birthTime: validator.object({
              title: validator.string(),
              value: validator.string(),
            }),
            moonSign: validator.object({
              title: validator.string(),
              value: validator.string(),
              fallbackImage: FunnelImageSchema,
            }),
          }),
          mainText: validator.string(),
          secondaryText: validator.string(),
          button: validator.string(),
        }),
        reading: validator.object({
          title: validator.string(),
          mainImage: FunnelImageSchema,
          match: validator.object({
            title: validator.string(),
            list: validator.array(
              validator.object({
                title: validator.object({
                  text: validator.string(),
                  percents: validator.number(),
                  blurred: validator.boolean().optional(),
                }),
                description: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
          }),
          offer: validator.object({
            sectionTitle: validator.string(),
            title: validator.string(),
            header: validator.string(),
            totalText: validator.string(),
            costText: validator.string(),
            costTextOrganic: validator.string(),
            button: validator.string(),
            guranteeText: validator.string(),
            moneyBackGuarantee: validator.object({
              title: validator.string(),
              description: validator.string(),
            }),
          }),
        }),
        questions: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              title: validator.string(),
              description: validator.string(),
              icon: FunnelImageSchema,
            }),
          ),
          button: validator.string(),
        }),
        challenges: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
          progressBarLabels: validator.object({
            start: validator.string(),
            end: validator.string(),
          }),
          button: validator.string(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        palmGuide: validator.object({
          title: validator.string(),
          description: validator.string(),
          lifeLine: PalmGuideItemSchema,
          headLine: PalmGuideItemSchema,
          marriageLine: PalmGuideItemSchema,
          loveLine: PalmGuideItemSchema,
          fateLine: PalmGuideItemSchema,
          fingers: PalmGuideItemSchema,
        }),
        userDetails: validator.object({
          title: validator.string(),
          zodiacTitle: validator.string(),
          zodiac: validator.literal('{{translate zodiac}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_MOON_COMPATIBILITY),
    }),
  ),
});

export type TrialPaymentMoonCompatibilityStepData = validator.infer<
  typeof TrialPaymentMoonCompatibilityStepDataSchema
>;

const TrialPaymentWitchPowerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        hero: validator.object({
          title: validator.string(),
          rating: validator.number(),
          guideList: validator.array(
            validator.object({
              image: FunnelImageSchema,
              title: validator.string(),
              description: validator.string(),
            }),
          ),
        }),
        title: validator.string(),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costText: validator.string(),
          costTextOrganic: validator.string(),
          button: validator.string(),
          guranteeText: validator.string(),
        }),
        personalizedGuide: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
          guideHeaderContent: validator.array(
            validator.object({
              title: validator.string(),
              text: validator.string(),
            }),
          ),
          guideContent: validator.array(
            validator.object({
              title: validator.string(),
              text: validator.string(),
            }),
          ),
          lockImage: FunnelImageSchema,
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        palmGuide: validator.object({
          title: validator.string(),
          description: validator.string(),
          lifeLine: PalmGuideItemSchema,
          headLine: PalmGuideItemSchema,
          marriageLine: PalmGuideItemSchema,
          loveLine: PalmGuideItemSchema,
          fateLine: PalmGuideItemSchema,
          fingers: PalmGuideItemSchema,
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_WITCH_POWER),
    }),
  ),
});

export type TrialPaymentWitchPowerStepData = validator.infer<
  typeof TrialPaymentWitchPowerStepDataSchema
>;

const AdditionalDiscountStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),

        plan: validator.object({
          text: validator.string(),
          emoji: validator.string(),
        }),
        trial: validator.object({
          text: validator.string(),
          emoji: validator.string(),
        }),
        priceText: validator.string(),
        button: validator.string(),
        image: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.ADDITIONAL_DISCOUNT),
    }),
  ),
});

export type AdditionalDiscountStepData = validator.infer<typeof AdditionalDiscountStepDataSchema>;

const AdditionalDiscountPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.object({
          beforePercent: validator.string(),
          afterPercent: validator.string(),
        }),
        list: validator.array(
          validator.intersection(
            TextWithEmojiSchema,
            validator.object({ includePercent: validator.boolean().optional() }),
          ),
        ),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ADDITIONAL_DISCOUNT_PALMISTRY),
    }),
  ),
});

const AdditionalDiscountPalmistryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        list: validator.array(TextWithEmojiSchema),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ADDITIONAL_DISCOUNT_PALMISTRY_V2),
    }),
  ),
});

export type AdditionalDiscountPalmistryStepData = validator.infer<
  typeof AdditionalDiscountPalmistryStepDataSchema
>;

export type AdditionalDiscountPalmistryV2StepData = validator.infer<
  typeof AdditionalDiscountPalmistryV2StepDataSchema
>;

const TrialPaymentNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          min: validator.string().optional(),
          sec: validator.string().optional(),
          image: FunnelImageSchema,
        }),
        benefits: validator.object({
          title: validator.string(),
          list: validator.array(TextWithEmojiSchema),
        }),
        purchase: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        guarantee: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
        }),
        details: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              title: validator.string(),
              text: validator.string(),
              image: FunnelImageSchema,
            }),
          ),
        }),
        report: validator.object({
          title: validator.string(),
          contentTitle: validator.string(),
          contentPoints: validator.array(validator.string()),
          personalityTitle: validator.string(),
          personalityPoints: validator.string(),
          accessDescription: validator.string(),
          button: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              question: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        moneyBack: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
          firstText: validator.string(),
          secondText: validator.string(),
        }),
        featuredIn: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_NATAL_CHART),
    }),
  ),
});

export type TrialPaymentNatalChartStepData = validator.infer<
  typeof TrialPaymentNatalChartStepDataSchema
>;

const TrialPaymentNatalChartV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          min: validator.string(),
          sec: validator.string(),
          image: FunnelImageSchema,
        }),
        benefits: validator.object({
          title: validator.string(),
          list: validator.array(TextWithEmojiSchema),
        }),
        purchase: validator.object({
          title: validator.string(),
          button: validator.string(),
        }),
        guarantee: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
        }),
        details: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              title: validator.string(),
              text: validator.string(),
              image: FunnelImageSchema,
            }),
          ),
        }),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costText: validator.string(),
          costTextOrganic: validator.string(),
          button: validator.string(),
          guranteeText: validator.string(),
        }),
        report: validator.object({
          title: validator.string(),
          contentTitle: validator.string(),
          contentPoints: validator.array(validator.string()),
          personalityTitle: validator.string(),
          personalityPoints: validator.string(),
          accessDescription: validator.string(),
          button: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              question: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        moneyBack: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
          firstText: validator.string(),
          secondText: validator.string(),
        }),
        featuredIn: validator.object({
          title: validator.string(),
          image: FunnelImageSchema,
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_NATAL_CHART_V2),
    }),
  ),
});

export type TrialPaymentNatalChartV2StepData = validator.infer<
  typeof TrialPaymentNatalChartV2StepDataSchema
>;

const TrialPaymentIntroOfferStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
          min: validator.string(),
          sec: validator.string(),
        }),
        userDetails: validator.object({
          zodiacTitle: validator.string(),
          zodiacSign: validator.literal('{{translate zodiac}}'),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          birthPlaceTitle: validator.string(),
          birthPlace: validator.string(),
          partnerZodiacSign: validator.literal('{{translate partnerZodiac}}').optional(),
          partnerGender: validator.literal('{{translate partnerGender}}').optional(),
          partnerBirthDate: validator.string().optional(),
          partnerBirthPlace: validator.string().optional(),
          youImageText: validator.string().optional(),
          partnerImageText: validator.string().optional(),
        }),
        title: validator.string().optional(),
        goal: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        subscriptionBlock: validator.object({
          analytics: validator.object({
            text: validator.string(),
            caption: validator.string(),
          }),
          getAccess: validator.string(),
          terms: validator.string(),
        }),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costPerMonthText: validator.string(),
          costPerMonthTextPaidTraffic: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string(),
        }),
        report: validator.object({
          title: validator.string(),
          contentTitle: validator.string(),
          contentPoints: validator.array(validator.string()),
          personalityTitle: validator.string(),
          personalityDescription: validator.string(),
          accessDescription: validator.string(),
          getAccess: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
          reviewPolicy: validator.string(),
        }),
        benefits: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              question: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_INTRO_OFFER),
    }),
  ),
});

export type TrialPaymentIntroOfferStepData = validator.infer<
  typeof TrialPaymentIntroOfferStepDataSchema
>;

const TrialPaymentIntroOfferV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          title: validator.string(),
          button: validator.string(),
          min: validator.string(),
          sec: validator.string(),
        }),
        userDetails: validator.object({
          zodiacTitle: validator.string(),
          zodiacSign: validator.literal('{{translate zodiac}}'),
          genderTitle: validator.string(),
          gender: validator.literal('{{translate gender}}'),
          birthDateTitle: validator.string(),
          birthDate: validator.string(),
          birthPlaceTitle: validator.string(),
          birthPlace: validator.string(),
          partnerZodiacSign: validator.literal('{{translate partnerZodiac}}').optional(),
          partnerGender: validator.literal('{{translate partnerGender}}').optional(),
          partnerBirthDate: validator.string().optional(),
          partnerBirthPlace: validator.string().optional(),
          youImageText: validator.string().optional(),
          partnerImageText: validator.string().optional(),
        }),
        title: validator.string().optional(),
        goal: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        subscriptionBlock: validator.object({
          analytics: validator.object({
            text: validator.string(),
            caption: validator.string(),
          }),
          getAccess: validator.string(),
          terms: validator.string(),
        }),
        specialOffer: validator.object({
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costPerMonthText: validator.string(),
          costPerMonthTextPaidTraffic: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string(),
        }),
        report: validator.object({
          title: validator.string(),
          contentTitle: validator.string(),
          contentPoints: validator.array(validator.string()),
          personalityTitle: validator.string(),
          personalityDescription: validator.string(),
          accessDescription: validator.string(),
          getAccess: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
          reviewPolicy: validator.string(),
        }),
        benefits: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              question: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_INTRO_OFFER_V2),
    }),
  ),
});

export type TrialPaymentIntroOfferV2StepData = validator.infer<
  typeof TrialPaymentIntroOfferV2StepDataSchema
>;

const PrelandingMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        quizList: validator.array(
          validator.object({
            text: validator.string(),
            icon: validator.string(),
          }),
        ),
        advantagesList: validator.array(
          validator.object({
            title: validator.string(),
            text: validator.string(),
            icon: validator.string(),
          }),
        ),
        dataSecuredBadge: validator
          .object({
            title: validator.string(),
            icon: FunnelImageSchema,
          })
          .optional(),
        image: FunnelImageSchema,
        title: validator.string(),
        description: validator.string(),
        button: validator.string(),
        signInButton: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.PRELANDING_MOON_COMPATIBILITY),
    }),
  ),
});

export type PrelandingMoonCompatibilityStepData = validator.infer<
  typeof PrelandingMoonCompatibilityStepDataSchema
>;

const PrelandingPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean(),
      content: validator.object({
        advantagesList: validator.array(
          validator.object({
            title: validator.string(),
            text: validator.string(),
            icon: validator.string(),
          }),
        ),
        image: validator.string(),
        animatedTitle: validator.array(validator.string()).optional(),
        title: validator.string(),
        list: validator.array(
          validator.object({
            icon: validator.string(),
            text: validator.string(),
          }),
        ),
        dataSecuredBadge: validator
          .object({
            title: validator.string(),
            icon: FunnelImageSchema,
          })
          .optional(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.PRELANDING_PALMISTRY),
    }),
  ),
});

export type PrelandingPalmistryStepData = validator.infer<typeof PrelandingPalmistryStepDataSchema>;

const GenderStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      hasPolicy: validator.boolean().optional(),
      dataKey: validator.string(),
      content: validator.object({
        help: validator.string(),
        title: validator.string(),
        subtitle: validator.string().optional(),
        actionTitle: validator.string().optional(),
        female: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
        }),
        male: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
        }),
        dataSecuredBadge: validator
          .object({
            title: validator.string(),
            icon: FunnelImageSchema,
          })
          .optional(),
        signIn: validator.string().optional(),
        address: validator.string().optional(),
        disclaimer: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.GENDER),
    }),
  ),
});

export type GenderStepData = validator.infer<typeof GenderStepDataSchema>;

const GenderV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      hasPolicy: validator.boolean().optional(),
      dataKey: validator.string(),
      content: validator.object({
        help: validator.string(),
        title: validator.string(),
        subtitle: validator.string().optional(),
        actionTitle: validator.string().optional(),
        female: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
          image: validator.string(),
        }),
        male: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
          image: validator.string(),
        }),
        dataSecuredBadge: validator
          .object({
            title: validator.string(),
            icon: FunnelImageSchema,
          })
          .optional(),
        signIn: validator.string().optional(),
        address: validator.string().optional(),
        disclaimer: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.GENDER_V2),
    }),
  ),
});

export type GenderV2StepData = validator.infer<typeof GenderV2StepDataSchema>;

const GenderMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        female: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
        }),
        male: validator.object({
          text: validator.string(),
          emoji: validator.string().optional(),
          value: validator.nativeEnum(Gender),
        }),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.GENDER_MOON_COMPATIBILITY),
    }),
  ),
});

export type GenderMoonCompatibilityStepData = validator.infer<
  typeof GenderMoonCompatibilityStepDataSchema
>;

const GenderPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        actionTitle: validator.string().optional(),
        female: validator.object({
          text: validator.string(),
          value: validator.nativeEnum(Gender),
        }),
        male: validator.object({
          text: validator.string(),
          value: validator.nativeEnum(Gender),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.GENDER_PALMISTRY),
    }),
  ),
});

export type GenderPalmistryStepData = validator.infer<typeof GenderPalmistryStepDataSchema>;

const SplitStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.record(validator.string(), validator.unknown()),
      forceGetStep: validator.boolean().optional(),
      screenType: validator.literal(QuizStepTypes.SPLIT),
    }),
  ),
});

const DateStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string().optional(),
        labelDay: validator.string().optional() /** @deprecated Use placeholderDay instead */,
        labelMonth: validator.string().optional() /** @deprecated Use placeholderDay instead */,
        labelYear: validator.string().optional() /** @deprecated Use placeholderDay instead */,
        placeholderDay: validator.string().optional(),
        placeholderMonth: validator.string().optional(),
        placeholderYear: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE),
    }),
  ),
});

export type DateStepData = validator.infer<typeof DateStepDataSchema>;

const DatePalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string().optional(),
        next: validator.string(),
        placeholderDay: validator.string().optional(),
        placeholderMonth: validator.string().optional(),
        placeholderYear: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE_PALMISTRY),
    }),
  ),
});

export type DatePalmistryStepData = validator.infer<typeof DatePalmistryStepDataSchema>;

const DateNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string().optional(),
        placeholderDay: validator.string().optional(),
        placeholderMonth: validator.string().optional(),
        placeholderYear: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE_NATAL_CHART),
    }),
  ),
});

export type DateNatalChartStepData = validator.infer<typeof DateNatalChartStepDataSchema>;

const DateNatalChartV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string().optional(),
        placeholderDay: validator.string().optional(),
        placeholderMonth: validator.string().optional(),
        placeholderYear: validator.string().optional(),
        progressBarTitle: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE_NATAL_CHART_V2),
    }),
  ),
});

export type DateNatalChartV2StepData = validator.infer<typeof DateNatalChartV2StepDataSchema>;

const PlaceStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      locationDataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        placeholder: validator.string(),
        image: FunnelImageSchema.optional(),
        cancel: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      skipData: validator
        .object({
          text: validator.string(),
          defaultPlace: validator.string(),
          defaultCoordinates: validator.array(validator.number()),
        })
        .optional(),
      screenType: validator.literal(QuizStepTypes.PLACE),
    }),
  ),
});

export type PlaceStepData = validator.infer<typeof PlaceStepDataSchema>;

const NameMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        placeholder: validator.string(),
        skipButton: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.NAME_MOON_COMPATIBILITY),
    }),
  ),
});

export type NameMoonCompatibilityStepData = validator.infer<
  typeof NameMoonCompatibilityStepDataSchema
>;

const NameMoonCompatibilityV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        placeholder: validator.string(),
        skipButton: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.NAME_MOON_COMPATIBILITY_V2),
    }),
  ),
});

export type NameMoonCompatibilityV2StepData = validator.infer<
  typeof NameMoonCompatibilityV2StepDataSchema
>;

const PlaceNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      locationDataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        placeholder: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        cancel: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      skipData: validator
        .object({
          text: validator.string(),
          defaultPlace: validator.string(),
          defaultCoordinates: validator.array(validator.number()),
        })
        .optional(),
      screenType: validator.literal(QuizStepTypes.PLACE_NATAL_CHART),
    }),
  ),
});

export type PlaceNatalChartStepData = validator.infer<typeof PlaceNatalChartStepDataSchema>;

const PlaceHumanDesignStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      locationDataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        image: FunnelImageSchema.optional(),
        cancel: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      skipData: validator
        .object({
          text: validator.string(),
          defaultPlace: validator.string(),
          defaultCoordinates: validator.array(validator.number()),
        })
        .optional(),
      screenType: validator.literal(QuizStepTypes.PLACE_HUMAN_DESIGN),
    }),
  ),
});

export type PlaceHumanDesignStepData = validator.infer<typeof PlaceHumanDesignStepDataSchema>;

const NameStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string().optional(),
        placeholder: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        cancel: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.NAME),
    }),
  ),
});

export type NameStepData = validator.infer<typeof NameStepDataSchema>;

const NameHumanDesignStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        image: FunnelImageSchema.optional(),
        cancel: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.NAME_HUMAN_DESIGN),
    }),
  ),
});

export type NameHumanDesignStepData = validator.infer<typeof NameHumanDesignStepDataSchema>;

const TimeStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasCheckbox: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        label: validator.string().optional(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      skipData: validator.string().optional(),
      screenType: validator.literal(QuizStepTypes.TIME),
    }),
  ),
});

export type TimeStepData = validator.infer<typeof TimeStepDataSchema>;

const TimeNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasCheckbox: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        label: validator.string().optional(),
        next: validator.string(),
        skip: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.TIME_NATAL_CHART),
    }),
  ),
});

export type TimeNatalChartStepData = validator.infer<typeof TimeNatalChartStepDataSchema>;

const AnswerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        overtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            emoji: validator.string().optional(),
            value: validator.string(),
          }),
        ),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER),
    }),
  ),
});

export type AnswerStepData = validator.infer<typeof AnswerStepDataSchema>;

const AnswerPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        overtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            emoji: validator.string().optional(),
            value: validator.string(),
          }),
        ),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_PALMISTRY),
    }),
  ),
});

export type AnswerPalmistryStepData = validator.infer<typeof AnswerPalmistryStepDataSchema>;

const AnswerNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            emoji: validator.string().optional(),
            value: validator.string(),
          }),
        ),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_NATAL_CHART),
    }),
  ),
});

export type AnswerNatalChartStepData = validator.infer<typeof AnswerNatalChartStepDataSchema>;

const AnswerNatalChartV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            emoji: validator.string().optional(),
            value: validator.string(),
          }),
        ),
        progressBarTitle: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_NATAL_CHART_V2),
    }),
  ),
});

export type AnswerNatalChartV2StepData = validator.infer<typeof AnswerNatalChartV2StepDataSchema>;

const AnswerHorizontalOrientationStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      hasPolicy: validator.boolean().optional(),
      itemsInRow: validator.number().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            icon: FunnelImageSchema.optional(),
            value: validator.string(),
          }),
        ),
        label: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION),
    }),
  ),
});

export type AnswerHorizontalOrientationStepData = validator.infer<
  typeof AnswerHorizontalOrientationStepDataSchema
>;

const AnswerHorizontalOrientationV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      hasPolicy: validator.boolean().optional(),
      itemsInRow: validator.number().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            icon: FunnelImageSchema.optional(),
            value: validator.string(),
          }),
        ),
        progressBarTitle: validator.string().optional(),
        label: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION_V2),
    }),
  ),
});

export type AnswerHorizontalOrientationV2StepData = validator.infer<
  typeof AnswerHorizontalOrientationV2StepDataSchema
>;

const AnswerHorizontalOrientationV3StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasProgressBar: validator.boolean().optional(),
      hasPolicy: validator.boolean().optional(),
      itemsInRow: validator.number().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            icon: FunnelImageSchema.optional(),
            value: validator.string(),
          }),
        ),
        label: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_HORIZONTAL_ORIENTATION_V3),
    }),
  ),
});

export type AnswerHorizontalOrientationV3StepData = validator.infer<
  typeof AnswerHorizontalOrientationV3StepDataSchema
>;

const ProblemStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            value: validator.string(),
            emoji: validator.string().optional(),
          }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.PROBLEM),
    }),
  ),
});

export type ProblemStepData = validator.infer<typeof ProblemStepDataSchema>;

const MultipleAnswerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            value: validator.string(),
            emoji: validator.string().optional(),
          }),
        ),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.MULTIPLE_ANSWERS),
    }),
  ),
});

export type MultipleAnswerStepData = validator.infer<typeof MultipleAnswerStepDataSchema>;

const DynamicAnswerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        answers: validator.array(
          validator.object({
            emoji: validator.string(),
            text: validator.string(),
            value: validator.string(),
            descriptionTitle: validator.string(),
            descriptionTitleEmoji: validator.string(),
            description: validator.string(),
          }),
        ),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.DYNAMIC_ANSWER),
    }),
  ),
});

export type DynamicAnswerStepData = validator.infer<typeof DynamicAnswerStepDataSchema>;

const DynamicAnswerV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        answers: validator.array(
          validator.object({
            emoji: validator.string(),
            text: validator.string(),
            value: validator.string(),
            descriptionTitle: validator.string(),
            descriptionTitleEmoji: validator.string(),
            description: validator.string(),
          }),
        ),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.DYNAMIC_ANSWER_V2),
    }),
  ),
});

export type DynamicAnswerV2StepData = validator.infer<typeof DynamicAnswerV2StepDataSchema>;

const MultipleAnswerNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            value: validator.string(),
          }),
        ),
        next: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.MULTIPLE_ANSWERS_NATAL_CHART),
    }),
  ),
});

export type MultipleAnswerNatalChartStepData = validator.infer<
  typeof MultipleAnswerNatalChartStepDataSchema
>;

const MultipleAnswerNatalChartV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            value: validator.string(),
          }),
        ),
        progressBarTitle: validator.string().optional(),
        next: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.MULTIPLE_ANSWERS_NATAL_CHART_V2),
    }),
  ),
});

export type MultipleAnswerNatalChartV2StepData = validator.infer<
  typeof MultipleAnswerNatalChartV2StepDataSchema
>;

const LimitedChoiceStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            icon: FunnelImageSchema,
            value: validator.string(),
          }),
        ),
        next: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.LIMITED_CHOICE),
    }),
  ),
});

export type LimitedChoiceStepData = validator.infer<typeof LimitedChoiceStepDataSchema>;

const EmailStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        text: validator.string(),
        placeholder: validator.string().optional(),
        error: validator.string().optional(),
        button: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL),
    }),
  ),
});

export type EmailStepData = validator.infer<typeof EmailStepDataSchema>;

const UserAnswerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        placeholder: validator.string(),
        error: validator.string(),
        button: validator.string(),
        skip: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.USER_ANSWER),
    }),
  ),
});

export type UserAnswerStepData = validator.infer<typeof UserAnswerStepDataSchema>;

const EmailBlurredStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        text: validator.string(),
        placeholder: validator.string().optional(),
        error: validator.string().optional(),
        button: validator.string(),
        image: FunnelImageSchema,
        blurred: validator.boolean().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
        emailCaption: validator.object({
          icon: FunnelImageSchema.optional(),
          text: validator.string(),
        }),
        reportTypeIcon: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_BLURRED),
    }),
  ),
});

export type EmailBlurredStepData = validator.infer<typeof EmailBlurredStepDataSchema>;

const EmailSecuredStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.object({
          text: validator.string(),
          icon: FunnelImageSchema,
        }),
        placeholder: validator.string().optional(),
        inputCaption: validator.string(),
        error: validator.string().optional(),
        button: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_SECURED),
    }),
  ),
});

export type EmailSecuredStepData = validator.infer<typeof EmailSecuredStepDataSchema>;

const EmailSecuredPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.object({
          text: validator.string(),
          icon: FunnelImageSchema,
        }),
        placeholder: validator.string().optional(),
        inputCaption: validator.string(),
        error: validator.string().optional(),
        button: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_SECURED_PALMISTRY),
    }),
  ),
});

export type EmailSecuredPalmistryStepData = validator.infer<
  typeof EmailSecuredPalmistryStepDataSchema
>;

const EmailPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        text: validator.string(),
        placeholder: validator.string(),
        error: validator.string(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_PALMISTRY),
    }),
  ),
});

export type EmailPalmistryStepData = validator.infer<typeof EmailPalmistryStepDataSchema>;

const EmailNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      productName: validator.string(),
      getGoal: validator.function().args(validator.string()).optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string().optional(),
        text: validator.string(),
        placeholder: validator.string(),
        error: validator.string(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_NATAL_CHART),
    }),
  ),
});

export type EmailNatalChartStepData = validator.infer<typeof EmailNatalChartStepDataSchema>;

const EmailConfirmStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        button: validator.string(),
        skip: validator.string(),
        image: FunnelImageSchema.optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_CONFIRM),
    }),
  ),
});

export type EmailConfirmStepData = validator.infer<typeof EmailConfirmStepDataSchema>;

const EmailConfirmPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        button: validator.string(),
        skip: validator.string(),
        image: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_CONFIRM_PALMISTRY),
    }),
  ),
});

export type EmailConfirmPalmistryStepData = validator.infer<
  typeof EmailConfirmPalmistryStepDataSchema
>;

const EmailConfirmNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        button: validator.string(),
        skip: validator.string(),
        image: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.EMAIL_CONFIRM_NATAL_CHART),
    }),
  ),
});

export type EmailConfirmNatalChartStepData = validator.infer<
  typeof EmailConfirmNatalChartStepDataSchema
>;

const ProcessingLoaderStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        initialText: validator.string(),
        profile: LoaderContentDataSchema,
        personality: LoaderContentDataSchema,
        relationship: LoaderContentDataSchema,
        text: validator.string(),
        profileText: validator.string(),
        personalityText: validator.string(),
        relationshipText: validator.string(),
        no: validator.string(),
        yes: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.PROCESSING_LOADER),
    }),
  ),
});

export type ProcessingLoaderStepData = validator.infer<typeof ProcessingLoaderStepDataSchema>;

const ProcessingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      showPartnerSign: validator.boolean().optional(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string().optional(),
        subtitle: validator.string(),
        list: validator.array(validator.string()),
        description: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.PROCESSING),
    }),
  ),
});

export type ProcessingStepData = validator.infer<typeof ProcessingStepDataSchema>;

const ProcessingPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      showPartnerSign: validator.boolean().optional(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        description: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.PROCESSING_PALMISTRY),
    }),
  ),
});

export type ProcessingPalmistryStepData = validator.infer<typeof ProcessingPalmistryStepDataSchema>;

const ProcessingNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      showPartnerSign: validator.boolean().optional(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        list: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.PROCESSING_NATAL_CHART),
    }),
  ),
});

export type ProcessingNatalChartStepData = validator.infer<
  typeof ProcessingNatalChartStepDataSchema
>;

const ReviewStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        logo: FunnelImageSchema.optional(),
        title: validator.string(),
        subtitle: validator.string().optional(),
        description: validator.string().optional(),
        feedback: validator.object({
          name: validator.string(),
          text: validator.string(),
        }),
        text: validator.string(),
        tooltip: validator.string().optional(),
        next: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.REVIEW),
    }),
  ),
});

export type ReviewStepData = validator.infer<typeof ReviewStepDataSchema>;

const GoalStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        animatedImage: validator.nativeEnum(AnimatedImages).optional(),
        title: validator.string(),
        text: validator.string(),
        next: validator.string(),
        back: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.GOAL),
    }),
  ),
});

export type GoalStepData = validator.infer<typeof GoalStepDataSchema>;

const WhichFigureStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        text: validator.string(),
        answers: validator.array(
          validator.object({
            text: validator.string(),
            emoji: validator.string(),
            value: validator.string(),
          }),
        ),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      dataKey: validator.string(),
      screenType: validator.literal(QuizStepTypes.WHICH_FIGURE),
    }),
  ),
});

export type WhichFigureStepData = validator.infer<typeof WhichFigureStepDataSchema>;

const GoalSetStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        subtitle: validator.string(),
        button: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.GOAL_SET),
    }),
  ),
});

export type GoalSetStepData = validator.infer<typeof GoalSetStepDataSchema>;

const InformationalStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        animatedImage: validator.nativeEnum(AnimatedImages).optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
        title: validator.string(),
        optionalText: validator.string().optional(),
        text: validator.string(),
        next: validator.string(),
        back: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.INFORMATIONAL),
    }),
  ),
});

export type InformationalStepData = validator.infer<typeof InformationalStepDataSchema>;

const InformationalPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        backgroundImage: QuizStepBackgroundSchema.optional(),
        title: validator.string(),
        text: validator.string().optional(),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.INFORMATIONAL_PALMISTRY),
    }),
  ),
});

export type InformationalPalmistryStepData = validator.infer<
  typeof InformationalPalmistryStepDataSchema
>;

const InformationalNatalChartStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        backgroundImage: QuizStepBackgroundSchema.optional(),
        title: validator.string(),
        text: validator.string(),
        next: validator.string(),
        optionalText: validator.string().optional(),
        back: validator.string().optional(),
        animatedImage: validator.nativeEnum(AnimatedImages).optional(),
      }),
      screenType: validator.literal(QuizStepTypes.INFORMATIONAL_NATAL_CHART),
    }),
  ),
});

export type InformationalNatalChartStepData = validator.infer<
  typeof InformationalNatalChartStepDataSchema
>;

const ImageInformationalStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        image: FunnelImageSchema,
        isHorizontalImage: validator.boolean().optional(),
        title: validator.string(),
        text: validator.string(),
        next: validator.string().optional(),
      }),
      screenType: validator.literal(QuizStepTypes.IMAGE_INFORMATIONAL),
    }),
  ),
});

export type ImageInformationalStepData = validator.infer<typeof ImageInformationalStepDataSchema>;

const CompoundedTitleInformationalStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        animatedImage: validator.nativeEnum(AnimatedImages).optional(),
        image: FunnelImageSchema.optional(),
        title: validator.string(),
        subtitle: validator.string().optional(),
        next: validator.string().optional(),
        back: validator.string().optional(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.COMPOUNDED_TITLE_INFORMATIONAL),
    }),
  ),
});

export type CompoundedTitleInformationalStepData = validator.infer<
  typeof CompoundedTitleInformationalStepDataSchema
>;

const OnboardingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      animationDuration: validator.number().optional(),
      content: validator.object({
        list: validator.array(validator.string()),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING),
    }),
  ),
});

export type OnboardingStepData = validator.infer<typeof OnboardingStepDataSchema>;

const LoadingMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      animationDuration: validator.number().optional(),
      content: validator.object({
        animatedText: validator.array(validator.string()),
        caption: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.LOADING_MOON_COMPATIBILITY),
    }),
  ),
});

export type LoadingMoonCompatibilityStepData = validator.infer<
  typeof LoadingMoonCompatibilityStepDataSchema
>;

const OnboardingIntroOfferStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      animationDuration: validator.number().optional(),
      content: validator.object({
        list: validator.array(validator.string()),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_INTRO_OFFER),
    }),
  ),
});

export type OnboardingIntroOfferStepData = validator.infer<
  typeof OnboardingIntroOfferStepDataSchema
>;

const OnboardingPalmistryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      animationDuration: validator.number().optional(),
      content: validator.object({
        list: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_PALMISTRY),
    }),
  ),
});

export type OnboardingPalmistryStepData = validator.infer<typeof OnboardingPalmistryStepDataSchema>;

const ScanPreviewStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        text: validator.string().optional(),
        next: validator.string(),
        guarantee: validator.string(),
        image: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.SCAN_PREVIEW),
    }),
  ),
});

export type ScanPreviewStepData = validator.infer<typeof ScanPreviewStepDataSchema>;

const ScanStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        take: validator.string(),
        upload: validator.string(),
        guarantee: validator.string(),
        loading: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
          question: validator.object({
            adventurous: validator.string(),
            alone: validator.string(),
            remedies: validator.string(),
          }),
        }),
        scanMaker: validator.object({
          button: validator.string(),
        }),
        error: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          button: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.SCAN),
    }),
  ),
});

export type ScanStepData = validator.infer<typeof ScanStepDataSchema>;

const ScanV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      content: validator.object({
        title: validator.string(),
        take: validator.string(),
        upload: validator.string(),
        guarantee: validator.string(),
        correctScanImage: FunnelImageSchema,
        wrongScanImage: FunnelImageSchema,
        loading: validator.object({
          title: validator.string(),
          list: validator.array(validator.string()),
          question: validator.object({
            adventurous: validator.string(),
            alone: validator.string(),
            remedies: validator.string(),
          }),
          confirmButtonText: validator.string(),
          discardButtonText: validator.string(),
        }),
        scanMaker: validator.object({
          button: validator.string(),
        }),
        error: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          button: validator.string(),
          skipButton: validator.string().optional(),
          image: FunnelImageSchema,
        }),
        cameraPermission: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          topImage: FunnelImageSchema,
          mainImage: FunnelImageSchema,
          caption: validator.string(),
          buttonText: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.SCAN_V2),
    }),
  ),
});

export type ScanV2StepData = validator.infer<typeof ScanV2StepDataSchema>;

const ScanAnalysingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        steps: validator.array(validator.string()),
      }),
      screenType: validator.literal(QuizStepTypes.SCAN_ANALYSING),
    }),
  ),
});

export type ScanAnalysingStepData = validator.infer<typeof ScanAnalysingStepDataSchema>;

const ReportStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.REPORT),
    }),
  ),
});

export type ReportStepData = validator.infer<typeof ReportStepDataSchema>;

const SingleZodiacInfoStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string(),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.SINGLE_ZODIAC_INFO),
    }),
  ),
});

export type SingleZodiacInfoStepData = validator.infer<typeof SingleZodiacInfoStepDataSchema>;

const MoonPhaseStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.MOON_PHASE),
    }),
  ),
});

export type MoonPhaseStepData = validator.infer<typeof MoonPhaseStepDataSchema>;

const PartnerMoonPhaseStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.PARTNER_MOON_PHASE),
    }),
  ),
});

export type PartnerMoonPhaseStepData = validator.infer<typeof PartnerMoonPhaseStepDataSchema>;

const RelationshipZodiacInfoStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        descriptionOptions: validator.array(validator.string()),
        next: validator.string(),
        captionTitle: validator.string(),
        captionDescription: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.RELATIONSHIP_ZODIAC_INFO),
    }),
  ),
});

export type RelationshipZodiacInfoStepData = validator.infer<
  typeof RelationshipZodiacInfoStepDataSchema
>;

const MentionedInStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        testimonial: validator.string(),
        quotesImage: FunnelImageSchema,
        testifierImage: FunnelImageSchema,
        mentionedInImage: FunnelImageSchema,
        mentionedInText: validator.string(),
        next: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.MENTIONED_IN),
    }),
  ),
});

export type MentionedInStepData = validator.infer<typeof MentionedInStepDataSchema>;

const ThankYouStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        confetti: validator.boolean().optional(),
        title: validator.string(),
        description: validator.string(),
        list: validator.array(
          validator.object({
            icon: validator.string(),
            title: validator.string(),
            text: validator.string(),
          }),
        ),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.THANK_YOU),
    }),
  ),
});

export type ThankYouStepData = validator.infer<typeof ThankYouStepDataSchema>;

const ThankYouWithRedirectStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string(),
        confetti: validator.boolean().optional(),
        timer: validator.number(),
      }),
      screenType: validator.literal(QuizStepTypes.THANK_YOU_WITH_REDIRECT),
    }),
  ),
});

export type ThankYouWithRedirectStepData = validator.infer<
  typeof ThankYouWithRedirectStepDataSchema
>;

const UpsellNatalChartSingleStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string(),
        slides: validator.array(
          validator.object({
            image: FunnelImageSchema,
            caption: validator.string(),
            strikethroughCaption: validator.string().optional(),
          }),
        ),
        list: validator.array(
          validator.object({
            title: validator.string(),
            icon: FunnelImageSchema,
          }),
        ),
        button: validator.string(),
        skipButton: validator.string(),
        disclaimer: validator.string(),
        upsellsSteps: validator.array(
          validator.object({
            title: validator.string(),
            step: validator.string(),
          }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELL_NATAL_CHART_SINGLE),
    }),
  ),
});

export type UpsellNatalChartSingleStepData = validator.infer<
  typeof UpsellNatalChartSingleStepDataSchema
>;

const HelloMoonCompatibilityStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        animatedImage: validator.nativeEnum(AnimatedImages),
        title: validator.string(),
        firstText: validator.string(),
        secondText: validator.string(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.HELLO_MOON_COMPATIBILITY),
    }),
  ),
});

export type HelloMoonCompatibilityStepData = validator.infer<
  typeof HelloMoonCompatibilityStepDataSchema
>;

const UpsellBlackMoonSingleStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        description: validator.string(),
        list: validator.array(
          validator.object({
            title: validator.string(),
            icon: FunnelImageSchema,
          }),
        ),
        button: validator.string(),
        skipButton: validator.string(),
        disclaimer: validator.string(),
        offer: validator.object({
          title: validator.string(),
          price: validator.string(),
          oldPrice: validator.string(),
          discount: validator.string(),
        }),
        upsellsSteps: validator.array(
          validator.object({
            title: validator.string(),
            step: validator.string(),
          }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.UPSELL_BLACK_MOON_SINGLE),
    }),
  ),
});

export type UpsellBlackMoonSingleStepData = validator.infer<
  typeof UpsellBlackMoonSingleStepDataSchema
>;

const OnboardingReadingStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      animationDuration: validator.number().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        description: validator.string(),
        reading: validator.object({
          title: validator.string(),
          text: validator.string(),
        }),
        questions: validator.object({
          title: validator.string(),
          image: validator.object({
            src: validator.string(),
            alt: validator.string(),
            width: validator.number(),
            height: validator.number(),
          }),
        }),
        appRating: validator.object({
          reviews: validator.string(),
          rating: validator.number(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              name: validator.string(),
              date: validator.string(),
              text: validator.string(),
              image: validator.string(),
            }),
          ),
        }),
        bonus: validator.object({
          title: validator.string(),
          text: validator.string(),
        }),
        footer: validator.string(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ONBOARDING_READING),
    }),
  ),
});

export type OnboardingReadingStepData = validator.infer<typeof OnboardingReadingStepDataSchema>;

const TrialPaymentHumanDesignStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        loaderText: validator.string(),
        header: validator.object({
          discountValue: validator.string(),
          discountExpiresIn: validator.string(),
        }),
        heroSection: validator.object({
          title: validator.string(),
          text: validator.string(),
          rating: validator.number(),
        }),
        userProfile: validator.object({
          title: validator.string(),
          birthDate: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
        }),
        ctaButtonText: validator.string(),
        specialOffer: validator.object({
          mainTitle: validator.string(),
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costPerMonthText: validator.string(),
          costPerMonthTextPaidTraffic: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string(),
        }),
        lifeStrategy: validator.object({
          title: validator.string(),
          withoutStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          withStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          button: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        partners: validator.object({
          title: validator.string(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_HUMAN_DESIGN),
    }),
  ),
});

export type TrialPaymentHumanDesignStepData = validator.infer<
  typeof TrialPaymentHumanDesignStepDataSchema
>;
const TrialPaymentOneTimeOfferHumanDesignStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        loaderText: validator.string(),
        header: validator.object({
          discountValue: validator.string(),
          discountExpiresIn: validator.string(),
        }),
        heroSection: validator.object({
          title: validator.string(),
          text: validator.string(),
          rating: validator.number(),
        }),
        userProfile: validator.object({
          title: validator.string(),
          birthDate: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
        }),
        buttonText: validator.string(),
        oneTimeOffer: validator.object({
          mainTitle: validator.string(),
          header: validator.string(),
          title: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string(),
          oneTimeOfferDescription: validator.string(),
        }),
        disclaimer: validator.string(),
        lifeStrategy: validator.object({
          title: validator.string(),
          withoutStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          withStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          button: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        partners: validator.object({
          title: validator.string(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
          image: FunnelImageSchema,
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ONE_TIME_OFFER_HUMAN_DESIGN),
    }),
  ),
});

export type TrialPaymentOneTimeOfferHumanDesignStepData = validator.infer<
  typeof TrialPaymentOneTimeOfferHumanDesignStepDataSchema
>;

const BaseReportItemDataSchema = validator.object({
  id: validator.string(),
  name: validator.string(),
  description: validator.string(),
  link: validator.string(),
  option: validator.string(),
});

const TrialPaymentHumanDesignV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        defaultReport: validator.object({
          chartData: validator.object({
            type: BaseReportItemDataSchema,
            profile: BaseReportItemDataSchema,
            authority: BaseReportItemDataSchema,
            strategy: BaseReportItemDataSchema,
            notSelfTheme: BaseReportItemDataSchema,
            signature: BaseReportItemDataSchema,
          }),
          chartUrl: validator.string(),
        }),
        loaderText: validator.string(),
        header: validator.object({
          discountValue: validator.string(),
          discountExpiresIn: validator.string(),
        }),
        heroSection: validator.object({
          title: validator.string(),
          text: validator.string(),
          rating: validator.number(),
        }),
        userProfile: validator.object({
          title: validator.string(),
          birthDate: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
          }),
        }),
        ctaButtonText: validator.string(),
        specialOffer: validator.object({
          mainTitle: validator.string(),
          header: validator.string(),
          title: validator.string(),
          totalText: validator.string(),
          costPerMonthText: validator.string(),
          costPerMonthTextPaidTraffic: validator.string(),
          button: validator.string(),
          guaranteeText: validator.string(),
        }),
        lifeStrategy: validator.object({
          title: validator.string(),
          withoutStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          withStrategy: validator.object({
            title: validator.string(),
            emoji: validator.string(),
            list: validator.array(
              validator.object({
                text: validator.string(),
                icon: FunnelImageSchema,
              }),
            ),
            image: FunnelImageSchema,
          }),
          button: validator.string(),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(FunnelReviewSchema),
        }),
        partners: validator.object({
          title: validator.string(),
        }),
        moneyBackGuarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_HUMAN_DESIGN),
    }),
  ),
});

export type TrialPaymentHumanDesignV2StepData = validator.infer<
  typeof TrialPaymentHumanDesignV2StepDataSchema
>;

const UserTypeHumanDesignStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        humanDesignType: validator.string(),
        humanDesignDescription: validator.string(),
        ctaText: validator.string(),
        button: validator.string(),
        image: FunnelImageSchema,
      }),
      screenType: validator.literal(QuizStepTypes.USER_TYPE_HUMAN_DESIGN),
    }),
  ),
});

export type UserTypeHumanDesignStepData = validator.infer<typeof UserTypeHumanDesignStepDataSchema>;

const RelationshipProgressStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        chart: validator.object({
          title: validator.string(),
          caption: validator.string(),
          image: FunnelImageSchema,
          currentMonth: validator.string(),
          futureMonth: validator.string(),
        }),
        advantages: validator.object({
          title: validator.string().optional(),
          list: validator.array(
            validator.object({
              icon: validator.string(),
              text: validator.string(),
            }),
          ),
          listDecorationEmoji: validator.string().optional(),
        }),
        highlights: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              backgroundImage: FunnelImageSchema,
              title: validator.string(),
              text: validator.string(),
            }),
          ),
        }),
        progress: validator.object({
          title: validator.string(),
          caption: validator.string(),
          image: FunnelImageSchema,
        }),
        footer: validator.object({
          privacyButton: validator.string(),
          termsButton: validator.string(),
        }),
        fixedButton: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.RELATIONSHIP_PROGRESS),
    }),
  ),
});

export type RelationshipProgressStepData = validator.infer<
  typeof RelationshipProgressStepDataSchema
>;

const TrialPaymentAttachmentStyleStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          discountValue: validator.string().optional(),
          buttonText: validator.string(),
          minLabel: validator.string(),
          secLabel: validator.string(),
        }),
        rating: validator.object({
          text: validator.string(),
          rating: validator.number(),
        }),
        title: validator.string().optional(),
        discountModal: validator.object({
          title: validator.string(),
          benefitList: validator.array(validator.string()),
          withNebulaTitle: validator.string(),
          withNebulaIcons: validator.array(validator.string()),
          withoutNebulaTitle: validator.string(),
          withoutNebulaIcons: validator.array(validator.string()),
          footer: validator.string(),
          ctaButtonText: validator.string(),
        }),
        profile: validator.object({
          title: validator.string(),
          gender: validator.string(),
          zodiac: validator.string().optional(),
          birthDate: validator
            .object({
              title: validator.string(),
              value: validator.string(),
              icon: validator.string(),
            })
            .optional(),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          trauma: validator.object({
            title: validator.string(),
            value: validator.string(),
            image: FunnelImageSchema,
            icon: validator.string(),
          }),
          genderContent: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
        }),
        plan: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          withPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
          withoutPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
        }),
        advantages: validator.object({
          title: validator.string().optional(),
          list: validator.array(
            validator.object({
              icon: validator.string(),
              text: validator.string(),
            }),
          ),
          listDecorationEmoji: validator.string().optional(),
        }),
        introOffer: validator.object({
          title: validator.string(),
          purchaseButtonText: validator.string(),
          guaranteeText: validator.string(),
        }),
        highlights: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              backgroundImage: FunnelImageSchema,
              title: validator.string(),
              text: validator.string(),
            }),
          ),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          questions: validator.array(
            validator.object({
              title: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              date: validator.string(),
              review: validator.string(),
              userAvatar: FunnelImageSchema,
              userName: validator.string(),
            }),
          ),
        }),
        appRating: validator
          .object({
            rating: validator.number(),
            list: validator.array(
              validator.object({
                image: FunnelImageSchema.optional(),
                supText: validator.string().optional(),
                subText: validator.string(),
              }),
            ),
          })
          .optional(),
        guarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE),
    }),
  ),
});

export type TrialPaymentAttachmentStyleStepData = validator.infer<
  typeof TrialPaymentAttachmentStyleStepDataSchema
>;

const TrialPaymentAttachmentStyleV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          discountValue: validator.string().optional(),
          buttonText: validator.string(),
          minLabel: validator.string(),
          secLabel: validator.string(),
        }),
        rating: validator.object({
          text: validator.string(),
          rating: validator.number(),
        }),
        title: validator.string().optional(),
        discountModal: validator.object({
          title: validator.string(),
          benefitList: validator.array(validator.string()),
          withNebulaTitle: validator.string(),
          withNebulaIcons: validator.array(validator.string()),
          withoutNebulaTitle: validator.string(),
          withoutNebulaIcons: validator.array(validator.string()),
          footer: validator.string(),
          ctaButtonText: validator.string(),
        }),
        profile: validator.object({
          title: validator.string(),
          gender: validator.string(),
          zodiac: validator.string().optional(),
          birthDate: validator
            .object({
              title: validator.string(),
              value: validator.string(),
              icon: validator.string(),
            })
            .optional(),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          trauma: validator.object({
            title: validator.string(),
            value: validator.string(),
            image: FunnelImageSchema,
            icon: validator.string(),
          }),
          genderContent: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
        }),
        plan: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          withPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
          withoutPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
        }),
        advantages: validator.object({
          title: validator.string().optional(),
          list: validator.array(
            validator.object({
              icon: validator.string(),
              text: validator.string(),
            }),
          ),
          listDecorationEmoji: validator.string().optional(),
        }),
        introOffer: validator.object({
          title: validator.string(),
          purchaseButtonText: validator.string(),
          guaranteeText: validator.string(),
          caption: validator
            .object({
              icon: FunnelImageSchema,
              mainText: validator.string(),
              secondaryText: validator.string(),
            })
            .optional(),
        }),
        highlights: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              backgroundImage: FunnelImageSchema,
              title: validator.string(),
              text: validator.string(),
            }),
          ),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          questions: validator.array(
            validator.object({
              title: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              date: validator.string(),
              review: validator.string(),
              userAvatar: FunnelImageSchema,
              userName: validator.string(),
            }),
          ),
        }),
        appRating: validator
          .object({
            rating: validator.number(),
            list: validator.array(
              validator.object({
                image: FunnelImageSchema.optional(),
                supText: validator.string().optional(),
                subText: validator.string(),
              }),
            ),
          })
          .optional(),
        guarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_V2),
    }),
  ),
});

export type TrialPaymentAttachmentStyleV2StepData = validator.infer<
  typeof TrialPaymentAttachmentStyleV2StepDataSchema
>;

const TrialPaymentAttachmentStyleV3StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_V3),
    }),
  ),
});

export type TrialPaymentAttachmentStyleV3StepData = validator.infer<
  typeof TrialPaymentAttachmentStyleV3StepDataSchema
>;

const TrialPaymentAttachmentStyleWithDiscountV3StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V3),
    }),
  ),
});

export type TrialPaymentAttachmentStyleWithDiscountV3StepData = validator.infer<
  typeof TrialPaymentAttachmentStyleWithDiscountV3StepDataSchema
>;

const TrialPaymentAttachmentStyleWithDiscountStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          discountValue: validator.string().optional(),
          buttonText: validator.string(),
          minLabel: validator.string(),
          secLabel: validator.string(),
        }),
        rating: validator.object({
          text: validator.string(),
          rating: validator.number(),
        }),
        title: validator.string().optional(),
        profile: validator.object({
          title: validator.string(),
          gender: validator.string(),
          zodiac: validator.string().optional(),
          discountTicket: validator.object({
            discountValue: validator.string(),
            discountText: validator.string(),
          }),
          birthDate: validator
            .object({
              title: validator.string(),
              value: validator.string(),
              icon: validator.string(),
            })
            .optional(),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          trauma: validator.object({
            title: validator.string(),
            value: validator.string(),
            image: FunnelImageSchema,
            icon: validator.string(),
          }),
          genderContent: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
        }),
        plan: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          withPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
          withoutPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
        }),
        advantages: validator.object({
          title: validator.string().optional(),
          list: validator.array(
            validator.object({
              icon: validator.string(),
              text: validator.string(),
            }),
          ),
          listDecorationEmoji: validator.string().optional(),
        }),
        introOffer: validator.object({
          title: validator.string(),
          purchaseButtonText: validator.string(),
          guaranteeText: validator.string(),
        }),
        highlights: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              backgroundImage: FunnelImageSchema,
              title: validator.string(),
              text: validator.string(),
            }),
          ),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          questions: validator.array(
            validator.object({
              title: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              date: validator.string(),
              review: validator.string(),
              userAvatar: FunnelImageSchema,
              userName: validator.string(),
            }),
          ),
        }),
        appRating: validator
          .object({
            rating: validator.number(),
            list: validator.array(
              validator.object({
                image: FunnelImageSchema.optional(),
                supText: validator.string().optional(),
                subText: validator.string(),
              }),
            ),
          })
          .optional(),
        guarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT),
    }),
  ),
});

export type TrialPaymentAttachmentWithDiscountStyleStepData = validator.infer<
  typeof TrialPaymentAttachmentStyleWithDiscountStepDataSchema
>;

const TrialPaymentAttachmentStyleWithDiscountV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        header: validator.object({
          discountValue: validator.string().optional(),
          buttonText: validator.string(),
          minLabel: validator.string(),
          secLabel: validator.string(),
        }),
        rating: validator.object({
          text: validator.string(),
          rating: validator.number(),
        }),
        title: validator.string().optional(),
        profile: validator.object({
          title: validator.string(),
          gender: validator.string(),
          zodiac: validator.string().optional(),
          discountTicket: validator.object({
            discountValue: validator.string(),
            discountText: validator.string(),
          }),
          birthDate: validator
            .object({
              title: validator.string(),
              value: validator.string(),
              icon: validator.string(),
            })
            .optional(),
          birthTime: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          birthPlace: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
          trauma: validator.object({
            title: validator.string(),
            value: validator.string(),
            image: FunnelImageSchema,
            icon: validator.string(),
          }),
          genderContent: validator.object({
            title: validator.string(),
            value: validator.string(),
            icon: validator.string(),
          }),
        }),
        plan: validator.object({
          title: validator.string(),
          subtitle: validator.string(),
          withPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
          withoutPlan: validator.object({
            label: validator.string(),
            title: validator.string(),
            icon: FunnelImageSchema,
            image: FunnelImageSchema,
            keyPoints: validator.array(
              validator.object({
                text: validator.string(),
                icon: validator.string(),
              }),
            ),
          }),
        }),
        advantages: validator.object({
          title: validator.string().optional(),
          list: validator.array(
            validator.object({
              icon: validator.string(),
              text: validator.string(),
            }),
          ),
          listDecorationEmoji: validator.string().optional(),
        }),
        introOffer: validator.object({
          title: validator.string(),
          purchaseButtonText: validator.string(),
          guaranteeText: validator.string(),
          caption: validator
            .object({
              icon: FunnelImageSchema,
              mainText: validator.string(),
              secondaryText: validator.string(),
            })
            .optional(),
        }),
        highlights: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              backgroundImage: FunnelImageSchema,
              title: validator.string(),
              text: validator.string(),
            }),
          ),
        }),
        featuredIn: validator.object({
          title: validator.string(),
        }),
        faq: validator.object({
          title: validator.string(),
          questions: validator.array(
            validator.object({
              title: validator.string(),
              answer: validator.string(),
            }),
          ),
        }),
        reviews: validator.object({
          title: validator.string(),
          list: validator.array(
            validator.object({
              date: validator.string(),
              review: validator.string(),
              userAvatar: FunnelImageSchema,
              userName: validator.string(),
            }),
          ),
        }),
        appRating: validator
          .object({
            rating: validator.number(),
            list: validator.array(
              validator.object({
                image: FunnelImageSchema.optional(),
                supText: validator.string().optional(),
                subText: validator.string(),
              }),
            ),
          })
          .optional(),
        guarantee: validator.object({
          title: validator.string(),
          description: validator.string(),
        }),
        footer: validator.object({
          addressUS: validator.string(),
          addressAE: validator.string(),
          address: validator.string(),
        }),
      }),
      screenType: validator.literal(QuizStepTypes.TRIAL_PAYMENT_ATTACHMENT_STYLE_WITH_DISCOUNT_V2),
    }),
  ),
});

export type TrialPaymentAttachmentStyleWithDiscountV2StepData = validator.infer<
  typeof TrialPaymentAttachmentStyleWithDiscountV2StepDataSchema
>;

const InformationalV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        description: validator.string().optional(),
        image: FunnelImageSchema.optional(),
        list: validator
          .object({
            image: FunnelImageSchema.optional(),
            title: validator.string(),
            items: validator.array(
              validator.object({
                icon: FunnelImageSchema,
                text: validator.string(),
              }),
            ),
          })
          .optional(),
        caption: validator.string().optional(),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.INFORMATIONAL_V2),
    }),
  ),
});

export type InformationalV2StepData = validator.infer<typeof InformationalV2StepDataSchema>;

const SocialProofStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      content: validator.object({
        title: validator.string(),
        review: validator.object({
          image: FunnelImageSchema.optional(),
          text: validator.string(),
          publishedAt: validator.string(),
        }),
        user: validator.object({
          name: validator.string(),
          caption: validator.string(),
          avatar: FunnelImageSchema,
        }),
        ratingImage: FunnelImageSchema,
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.SOCIAL_PROOF),
    }),
  ),
});

export type SocialProofStepData = validator.infer<typeof SocialProofStepDataSchema>;

const AnswerImageStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        description: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string().optional(),
            value: validator.string(),
            image: FunnelImageSchema,
          }),
        ),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_IMAGE),
    }),
  ),
});

export type AnswerImageStepData = validator.infer<typeof AnswerImageStepDataSchema>;

const AnswerImageMultipleStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        description: validator.string().optional(),
        answers: validator.array(
          validator.object({
            text: validator.string().optional(),
            value: validator.string(),
            image: FunnelImageSchema,
          }),
        ),
        button: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.ANSWER_IMAGE_MULTIPLE),
    }),
  ),
});

export type AnswerImageMultipleStepData = validator.infer<typeof AnswerImageMultipleStepDataSchema>;

const UpsellConsultationStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      upsellsSteps: validator.array(
        validator.object({
          title: validator.string(),
          step: validator.string(),
        }),
      ),
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.UPSELL_CONSULTATION),
    }),
  ),
});

export type UpsellConsultationStepData = validator.infer<typeof UpsellConsultationStepDataSchema>;

const UpsellConsultationWithDiscountStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      upsellsSteps: validator.array(
        validator.object({
          title: validator.string(),
          step: validator.string(),
        }),
      ),
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.UPSELL_CONSULTATION_WITH_DISCOUNT),
    }),
  ),
});

export type UpsellConsultationWithDiscountStepData = validator.infer<
  typeof UpsellConsultationWithDiscountStepDataSchema
>;

const UpsellReadingsPackStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      upsellsSteps: validator.array(
        validator.object({
          title: validator.string(),
          step: validator.string(),
        }),
      ),
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.UPSELL_READINGS_PACK),
    }),
  ),
});

export type UpsellReadingsPackStepData = validator.infer<typeof UpsellReadingsPackStepDataSchema>;

const UpsellReadingsPackWithDiscountStepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      upsellsSteps: validator.array(
        validator.object({
          title: validator.string(),
          step: validator.string(),
        }),
      ),
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.UPSELL_READINGS_PACK_WITH_DISCOUNT),
    }),
  ),
});

export type UpsellReadingsPackWithDiscountStepData = validator.infer<
  typeof UpsellReadingsPackWithDiscountStepDataSchema
>;

const UpsellsV3StepDataSchema = BasePageDataSchema.extend({
  attributes: validator.array(
    BasePageAttributesSchema.extend({
      upsellsSteps: validator.array(
        validator.object({
          title: validator.string(),
          step: validator.string(),
        }),
      ),
      sections: validator.array(SectionAttributesUnionSchema),
      screenType: validator.literal(QuizStepTypes.UPSELLS_V3),
    }),
  ),
});

export type UpsellsV3StepData = validator.infer<typeof UpsellsV3StepDataSchema>;

const TimelineStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        image: FunnelImageSchema,
        title: validator.string(),
        subtitle: validator.string(),
        steps: validator.array(
          validator.object({
            title: validator.string(),
            value: validator.string(),
            checked: validator.boolean(),
          }),
        ),
        caption: validator.string(),
        buttonText: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.TIMELINE),
    }),
  ),
});

export type TimelineStepData = validator.infer<typeof TimelineStepDataSchema>;

const LoaderWithReviewsStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        progress: validator.object({
          duration: validator.number().optional(),
          title: validator.string(),
          items: validator.array(validator.string()),
        }),
        questions: validator
          .array(
            validator.object({
              text: validator.string(),
              image: FunnelImageSchema,
              confirmButtonText: validator.string(),
              denyButtonText: validator.string(),
            }),
          )
          .optional(),
        reviews: validator.array(
          validator.object({
            date: validator.string(),
            review: validator.string(),
            userName: validator.string(),
            verified: validator.object({
              icon: FunnelImageSchema,
              text: validator.string(),
            }),
          }),
        ),
        carouselSpeed: validator.number(),
      }),
      screenType: validator.literal(QuizStepTypes.LOADER_WITH_REVIEWS),
    }),
  ),
});

export type LoaderWithReviewsStepData = validator.infer<typeof LoaderWithReviewsStepDataSchema>;

const SummaryStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        toxicLevel: validator.object({
          title: validator.string(),
          defaultProgress: validator
            .object({
              value: validator.number(),
              caption: validator.string(),
            })
            .optional(),
          progress: validator.object({
            value: validator.number(),
            caption: validator.string(),
          }),
          disclaimer: validator.object({
            icon: FunnelImageSchema,
            text: validator.string(),
          }),
        }),
        generalInfo: validator.object({
          image: FunnelImageSchema,
          items: validator.array(
            validator.object({
              icon: FunnelImageSchema,
              title: validator.string(),
              value: validator.string(),
            }),
          ),
        }),
        buttonText: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.SUMMARY),
    }),
  ),
});

export type SummaryStepData = validator.infer<typeof SummaryStepDataSchema>;

const SummaryV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        image: FunnelImageSchema,
        points: validator.array(
          validator.object({
            icon: FunnelImageSchema,
            title: validator.string(),
            bad: validator.string(),
            neutral: validator.string(),
            good: validator.string(),
            randomizeRange: validator
              .object({
                min: validator.number(),
                max: validator.number(),
              })
              .optional(),
          }),
        ),
        buttonText: validator.string(),
      }),
      screenType: validator.literal(QuizStepTypes.SUMMARY_V2),
    }),
  ),
});

export type SummaryV2StepData = validator.infer<typeof SummaryV2StepDataSchema>;

const EmailMarketingPaywallDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.EMAIL_MARKETING_PAYWALL),
      analyticPaywall: validator.object({
        page: validator.string(),
        view: validator.string(),
        source: validator.string(),
      }),
    }),
  ),
});

export type EmailMarketingPaywallStepData = validator.infer<typeof EmailMarketingPaywallDataSchema>;

const W2APromotionDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      screenType: validator.literal(QuizStepTypes.W2A_PROMOTION),
      content: validator.object({
        image: FunnelImageSchema.extend({
          width: validator.number(),
          height: validator.number(),
        }),
        title: validator.string(),
        promotionList: validator.object({
          title: validator.string(),
          emoji: validator.string().optional(),
          points: validator.array(validator.string()),
        }),
        nextButtonText: validator.string(),
      }),
    }),
  ),
});

export type W2APromotionStepData = validator.infer<typeof W2APromotionDataSchema>;

// TODO add more efficient
export type GenderScreenAttributes = GenderStepData['attributes'][number];
export type GenderV2ScreenAttributes = GenderV2StepData['attributes'][number];
export type GenderMoonCompatibilityScreenAttributes =
  GenderMoonCompatibilityStepData['attributes'][number];
export type GenderScreenPalmistryAttributes = GenderPalmistryStepData['attributes'][number];
export type PrelandingMoonCompatibilityScreenAttributes =
  PrelandingMoonCompatibilityStepData['attributes'][number];
export type PrelandingScreenPalmistryAttributes = PrelandingPalmistryStepData['attributes'][number];
export type GoalScreenAttributes = GoalStepData['attributes'][number];
export type WhichFigureScreenAttributes = WhichFigureStepData['attributes'][number];
export type GoalSetScreenAttributes = GoalSetStepData['attributes'][number];
export type InformationalScreenAttributes = InformationalStepData['attributes'][number];
export type InformationalScreenPalmistryAttributes =
  InformationalPalmistryStepData['attributes'][number];
export type InformationalScreenNatalChartAttributes =
  InformationalNatalChartStepData['attributes'][number];
export type ImageInformationalScreenAttributes = ImageInformationalStepData['attributes'][number];
export type DateScreenAttributes = DateStepData['attributes'][number];
export type EmailMarketingPaywallScreenAttributes =
  EmailMarketingPaywallStepData['attributes'][number];
export type W2APromotionScreenAttributes = W2APromotionStepData['attributes'][number];
export type DatePalmistryScreenAttributes = DatePalmistryStepData['attributes'][number];
export type DateNatalChartScreenAttributes = DateNatalChartStepData['attributes'][number];
export type DateNatalChartV2ScreenAttributes = DateNatalChartV2StepData['attributes'][number];
export type TimeScreenAttributes = TimeStepData['attributes'][number];
export type TimeNatalChartScreenAttributes = TimeNatalChartStepData['attributes'][number];
export type PlaceScreenAttributes = PlaceStepData['attributes'][number];
export type NameMoonCompatibilityScreenAttributes =
  NameMoonCompatibilityStepData['attributes'][number];
export type NameMoonCompatibilityV2ScreenAttributes =
  NameMoonCompatibilityV2StepData['attributes'][number];
export type PlaceNatalChartScreenAttributes = PlaceNatalChartStepData['attributes'][number];
export type PlaceHumanDesignScreenAttributes = PlaceHumanDesignStepData['attributes'][number];
export type NameScreenAttributes = NameStepData['attributes'][number];
export type NameHumanDesignScreenAttributes = NameHumanDesignStepData['attributes'][number];
export type ProcessingScreenAttributes = ProcessingStepData['attributes'][number];
export type ProcessingPalmistryScreenAttributes = ProcessingPalmistryStepData['attributes'][number];
export type ProcessingNatalChartScreenAttributes =
  ProcessingNatalChartStepData['attributes'][number];
export type ProcessingLoaderScreenAttributes = ProcessingLoaderStepData['attributes'][number];
export type AnswerScreenAttributes = AnswerStepData['attributes'][number];
export type AnswerPalmistryScreenAttributes = AnswerPalmistryStepData['attributes'][number];
export type AnswerNatalChartScreenAttributes = AnswerNatalChartStepData['attributes'][number];
export type AnswerNatalChartV2ScreenAttributes = AnswerNatalChartV2StepData['attributes'][number];
export type AnswerHorizontalOrientationScreenAttributes =
  AnswerHorizontalOrientationStepData['attributes'][number];
export type AnswerHorizontalOrientationV2ScreenAttributes =
  AnswerHorizontalOrientationV2StepData['attributes'][number];
export type AnswerHorizontalOrientationV3ScreenAttributes =
  AnswerHorizontalOrientationV3StepData['attributes'][number];
export type MultipleAnswerScreenAttributes = MultipleAnswerStepData['attributes'][number];
export type DynamicAnswerScreenAttributes = DynamicAnswerStepData['attributes'][number];
export type DynamicAnswerV2ScreenAttributes = DynamicAnswerV2StepData['attributes'][number];
export type MultipleAnswerNatalChartScreenAttributes =
  MultipleAnswerNatalChartStepData['attributes'][number];
export type MultipleAnswerNatalChartV2ScreenAttributes =
  MultipleAnswerNatalChartV2StepData['attributes'][number];
export type ProblemScreenAttributes = ProblemStepData['attributes'][number];
export type CompoundedTitleInformationalScreenAttributes =
  CompoundedTitleInformationalStepData['attributes'][number];
export type ReviewScreenAttributes = ReviewStepData['attributes'][number];
export type ScanPreviewScreenAttributes = ScanPreviewStepData['attributes'][number];
export type ScanScreenAttributes = ScanStepData['attributes'][number];
export type ScanV2ScreenAttributes = ScanV2StepData['attributes'][number];
export type ScanAnalysingScreenAttributes = ScanAnalysingStepData['attributes'][number];
export type ReportScreenAttributes = ReportStepData['attributes'][number];
export type SingleZodiacInfoScreenAttributes = SingleZodiacInfoStepData['attributes'][number];
export type MoonPhaseScreenAttributes = MoonPhaseStepData['attributes'][number];
export type PartnerMoonPhaseScreenAttributes = PartnerMoonPhaseStepData['attributes'][number];
export type RelationshipZodiacInfoScreenAttributes =
  RelationshipZodiacInfoStepData['attributes'][number];
export type MentionedInScreenAttributes = MentionedInStepData['attributes'][number];
export type EmailScreenAttributes = EmailStepData['attributes'][number];
export type UserAnswerScreenAttributes = UserAnswerStepData['attributes'][number];
export type EmailBlurredScreenAttributes = EmailBlurredStepData['attributes'][number];
export type EmailSecuredScreenAttributes = EmailSecuredStepData['attributes'][number];
export type EmailSecuredPalmistryScreenAttributes =
  EmailSecuredPalmistryStepData['attributes'][number];
export type EmailPalmistryScreenAttributes = EmailPalmistryStepData['attributes'][number];
export type EmailNatalChartScreenAttributes = EmailNatalChartStepData['attributes'][number];
export type EmailConfirmScreenAttributes = EmailConfirmStepData['attributes'][number];
export type EmailConfirmPalmistryScreenAttributes =
  EmailConfirmPalmistryStepData['attributes'][number];
export type EmailConfirmNatalChartScreenAttributes =
  EmailConfirmNatalChartStepData['attributes'][number];
export type LimitedChoiceScreenAttributes = LimitedChoiceStepData['attributes'][number];
export type OnboardingIntroOfferScreenAttributes =
  OnboardingIntroOfferStepData['attributes'][number];
export type OnboardingScreenAttributes = OnboardingStepData['attributes'][number];
export type OnboardingReadingScreenAttributes = OnboardingReadingStepData['attributes'][number];
export type LoadingMoonCompatibilityScreenAttributes =
  LoadingMoonCompatibilityStepData['attributes'][number];
export type OnboardingPalmistryScreenAttributes = OnboardingPalmistryStepData['attributes'][number];
export type UpsellsScreenAttributes = UpsellsStepData['attributes'][number];
export type UpsellsV2ScreenAttributes = UpsellsV2StepData['attributes'][number];
export type UpsellsPalmistryScreenAttributes = UpsellsPalmistryStepData['attributes'][number];
export type UpsellsPalmistryV2ScreenAttributes = UpsellsPalmistryV2StepData['attributes'][number];
export type UpsellsNatalChartScreenAttributes = UpsellsNatalChartStepData['attributes'][number];
export type TrialChoiceScreenAttributes = TrialChoiceStepData['attributes'][number];
export type TrialChoiceNatalChartScreenAttributes =
  TrialChoiceNatalChartStepData['attributes'][number];
export type TrialChoicePalmistryScreenAttributes =
  TrialChoicePalmistryStepData['attributes'][number];
export type TrialChoicePalmistryV2ScreenAttributes =
  TrialChoicePalmistryV2StepData['attributes'][number];
export type TrialPaymentScreenAttributes = TrialPaymentStepData['attributes'][number];
export type TrialPaymentHumanDesignScreenAttributes =
  TrialPaymentHumanDesignStepData['attributes'][number];
export type TrialPaymentOneTimeOfferHumanDesignScreenAttributes =
  TrialPaymentOneTimeOfferHumanDesignStepData['attributes'][number];
export type TrialPaymentHumanDesignV2ScreenAttributes =
  TrialPaymentHumanDesignV2StepData['attributes'][number];
export type TrialPaymentIntroOfferScreenAttributes =
  TrialPaymentIntroOfferStepData['attributes'][number];
export type TrialPaymentIntroOfferV2ScreenAttributes =
  TrialPaymentIntroOfferV2StepData['attributes'][number];
export type TrialPaymentPalmistryScreenAttributes =
  TrialPaymentPalmistryStepData['attributes'][number];
export type TrialPaymentWitchPowerScreenAttributes =
  TrialPaymentWitchPowerStepData['attributes'][number];
export type TrialPaymentPalmistryV2ScreenAttributes =
  TrialPaymentPalmistryV2StepData['attributes'][number];
export type TrialPaymentPalmistryV3ScreenAttributes =
  TrialPaymentPalmistryV3StepData['attributes'][number];
export type TrialPaymentPalmistryV4ScreenAttributes =
  TrialPaymentPalmistryV3StepData['attributes'][number];
export type EmailMarketingCompatibilityScreenAttributes =
  EmailMarketingCompatibilityStepData['attributes'][number];
export type EmailMarketingNatalChartScreenAttributes =
  EmailMarketingNatalChartStepData['attributes'][number];
export type EmailMarketingPalmistryScreenAttributes =
  EmailMarketingPalmistryStepData['attributes'][number];
export type EmailMarketingWitchPowerScreenAttributes =
  EmailMarketingWitchPowerStepData['attributes'][number];
export type TrialPaymentMoonCompatibilityScreenAttributes =
  TrialPaymentMoonCompatibilityStepData['attributes'][number];
export type TrialPaymentNatalChartScreenAttributes =
  TrialPaymentNatalChartStepData['attributes'][number];
export type TrialPaymentNatalChartV2ScreenAttributes =
  TrialPaymentNatalChartV2StepData['attributes'][number];
export type AdditionalDiscountScreenAttributes = AdditionalDiscountStepData['attributes'][number];
export type AdditionalDiscountPalmistryScreenAttributes =
  AdditionalDiscountPalmistryStepData['attributes'][number];
export type AdditionalDiscountPalmistryV2ScreenAttributes =
  AdditionalDiscountPalmistryV2StepData['attributes'][number];
export type TrialPaymentWithDiscountScreenAttributes =
  TrialPaymentWithDiscountStepData['attributes'][number];
export type TrialPaymentWithDiscountPalmistryScreenAttributes =
  TrialPaymentWithDiscountPalmistryStepData['attributes'][number];
export type TrialPaymentWithDiscountPalmistryV2ScreenAttributes =
  TrialPaymentWithDiscountPalmistryV2StepData['attributes'][number];
export type UnlimitedReadingsScreenAttributes = UnlimitedReadingsStepData['attributes'][number];
export type UnlimitedReadingsPalmistryScreenAttributes =
  UnlimitedReadingsPalmistryStepData['attributes'][number];
export type UnlimitedReadingsPalmistryV2ScreenAttributes =
  UnlimitedReadingsPalmistryV2StepData['attributes'][number];
export type TrialCreditsScreenAttributes = TrialCreditsStepData['attributes'][number];
export type TrialCreditsV2ScreenAttributes = TrialCreditsV2StepData['attributes'][number];
export type TrialCreditsNatalChartScreenAttributes =
  TrialCreditsNatalChartStepData['attributes'][number];
export type TrialCreditsPalmistryScreenAttributes =
  TrialCreditsPalmistryStepData['attributes'][number];
export type TrialCreditsPalmistryV2ScreenAttributes =
  TrialCreditsPalmistryV2StepData['attributes'][number];
export type OnboardingAskScreenAttributes = OnboardingAskStepData['attributes'][number];
export type OnboardingAskV2ScreenAttributes = OnboardingAskV2StepData['attributes'][number];
export type OnboardingAskNatalChartScreenAttributes =
  OnboardingAskNatalChartStepData['attributes'][number];
export type OnboardingAskPalmistryScreenAttributes =
  OnboardingAskPalmistryStepData['attributes'][number];
export type OnboardingAskPalmistryV2ScreenAttributes =
  OnboardingAskPalmistryV2StepData['attributes'][number];
export type TalkWithExpertScreenAttributes = TalkWithExpertStepData['attributes'][number];
export type TalkWithExpertNatalChartScreenAttributes =
  TalkWithExpertNatalChartStepData['attributes'][number];
export type TalkWithExpertPalmistryScreenAttributes =
  TalkWithExpertPalmistryStepData['attributes'][number];
export type QuestionsScreenAttributes = QuestionsStepData['attributes'][number];
export type QuestionsNatalChartScreenAttributes = QuestionsNatalChartStepData['attributes'][number];
export type QuestionsPalmistryScreenAttributes = QuestionsPalmistryStepData['attributes'][number];
export type AppDownloadScreenAttributes = AppDownloadStepData['attributes'][number];
export type CreditsScreenAttributes = CreditsStepData['attributes'][number];
export type CreditsNatalChartScreenAttributes = CreditsNatalChartStepData['attributes'][number];
export type CreditsPalmistryScreenAttributes = CreditsPalmistryStepData['attributes'][number];
export type LoadingAskScreenAttributes = LoadingAskStepData['attributes'][number];
export type LoadingAskNatalChartScreenAttributes =
  LoadingAskNatalChartStepData['attributes'][number];
export type LoadingAskPalmistryScreenAttributes = LoadingAskPalmistryStepData['attributes'][number];
export type PhoneNumberScreenAttributes = PhoneNumberStepData['attributes'][number];
export type PhoneNumberPalmistryScreenAttributes =
  PhoneNumberPalmistryStepData['attributes'][number];
export type ThankYouScreenAttributes = ThankYouStepData['attributes'][number];
export type ThankYouWithRedirectScreenAttributes =
  ThankYouWithRedirectStepData['attributes'][number];
export type UpsellNatalChartSingleScreenAttributes =
  UpsellNatalChartSingleStepData['attributes'][number];
export type UpsellBlackMoonScreenAttributes = UpsellBlackMoonSingleStepData['attributes'][number];
export type HelloMoonCompatibilityScreenAttributes =
  HelloMoonCompatibilityStepData['attributes'][number];
export type UserTypeHumanDesignScreenAttributes = UserTypeHumanDesignStepData['attributes'][number];
export type RelationshipProgressScreenAttributes =
  RelationshipProgressStepData['attributes'][number];
export type TrialPaymentAttachmentStyleScreenAttributes =
  TrialPaymentAttachmentStyleStepData['attributes'][number];
export type TrialPaymentAttachmentStyleWithDiscountScreenAttributes =
  TrialPaymentAttachmentWithDiscountStyleStepData['attributes'][number];
export type TrialPaymentWithDiscountPalmistryV3ScreenAttributes =
  TrialPaymentWithDiscountPalmistryV3StepData['attributes'][number];
export type TrialPaymentWithDiscountPalmistryV4ScreenAttributes =
  TrialPaymentWithDiscountPalmistryV4StepData['attributes'][number];
export type InformationalV2ScreenAttributes = InformationalV2StepData['attributes'][number];
export type SocialProofScreenAttributes = SocialProofStepData['attributes'][number];
export type AnswerImageScreenAttributes = AnswerImageStepData['attributes'][number];
export type AnswerImageMultipleScreenAttributes = AnswerImageMultipleStepData['attributes'][number];
export type UpsellConsultationScreenAttributes = UpsellConsultationStepData['attributes'][number];
export type UpsellConsultationWithDiscountScreenAttributes =
  UpsellConsultationWithDiscountStepData['attributes'][number];
export type UpsellReadingsPackScreenAttributes = UpsellReadingsPackStepData['attributes'][number];
export type UpsellReadingsPackWithDiscountScreenAttributes =
  UpsellReadingsPackWithDiscountStepData['attributes'][number];
export type UpsellsV3ScreenAttributes = UpsellsV3StepData['attributes'][number];
export type SummaryScreenAttributes = SummaryStepData['attributes'][number];
export type SummaryV2ScreenAttributes = SummaryV2StepData['attributes'][number];
export type TimelineScreenAttributes = TimelineStepData['attributes'][number];
export type LoaderWithReviewsScreenAttributes = LoaderWithReviewsStepData['attributes'][number];
export type TrialPaymentAttachmentStyleV2ScreenAttributes =
  TrialPaymentAttachmentStyleV2StepData['attributes'][number];
export type TrialPaymentAttachmentStyleV3ScreenAttributes =
  TrialPaymentAttachmentStyleV3StepData['attributes'][number];
export type TrialPaymentAttachmentStyleWithDiscountV2ScreenAttributes =
  TrialPaymentAttachmentStyleWithDiscountV2StepData['attributes'][number];
export type TrialPaymentAttachmentStyleWithDiscountV3ScreenAttributes =
  TrialPaymentAttachmentStyleWithDiscountV3StepData['attributes'][number];

export type GenderScreenProps = {
  isDisabled?: boolean;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  onGenderClick: (value: Gender) => void;
  onPolicyClick: (accepted: boolean) => void;
  onBack: () => void;
  step: number;
  signIn: () => void;
} & GenderScreenAttributes;

export type GenderV2ScreenProps = {
  isDisabled?: boolean;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  onGenderClick: (value: Gender) => void;
  onPolicyClick: (accepted: boolean) => void;
  onBack: () => void;
  step: number;
  signIn: () => void;
} & GenderV2ScreenAttributes;

export type GenderMoonCompatibilityScreenProps = {
  onGenderClick: (value: Gender) => void;
  onBack: () => void;
  step: number;
} & GenderMoonCompatibilityScreenAttributes;

export type GenderPalmistryScreenProps = {
  isDisabled?: boolean;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  onGenderClick: (value: Gender) => void;
  onPolicyClick: (accepted: boolean) => void;
  onBack: () => void;
  step: number;
} & GenderScreenPalmistryAttributes;

export type PrelandingMoonCompatibilityScreenProps = {
  onStart: () => void;
  signIn: () => void;
} & PrelandingMoonCompatibilityScreenAttributes;

export type PrelandingPalmistryScreenProps = {
  onBack: () => void;
  onStart: () => void;
} & PrelandingScreenPalmistryAttributes;

export type GoalScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & GoalScreenAttributes;

export type WhichFigureScreenProps = {
  onBack: () => void;
  goNext: (value: string) => void;
} & WhichFigureScreenAttributes;

export type GoalSetScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & GoalSetScreenAttributes;

export type InformationalScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & InformationalScreenAttributes;

export type InformationalPalmistryScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & InformationalScreenPalmistryAttributes;

export type InformationalNatalChartScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & InformationalScreenNatalChartAttributes;

export type ImageInformationalScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & ImageInformationalScreenAttributes;

export type DateScreenProps = {
  onBack: () => void;
  onSubmit: (date: string) => void;
  getInitialDate: () => {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  step: number;
} & DateScreenAttributes;

export type DatePalmistryScreenProps = {
  onBack: () => void;
  onSubmit: (date: string) => void;
  getInitialDate: () => {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  step: number;
} & DatePalmistryScreenAttributes;

export type W2APromotionScreenProps = {
  goNext: () => void;
} & W2APromotionScreenAttributes;

export type DateNatalChartScreenProps = {
  onBack: () => void;
  onSubmit: (date: string) => void;
  getInitialDate: () => {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  step: number;
} & DateNatalChartScreenAttributes;

export type DateNatalChartV2ScreenProps = {
  onBack: () => void;
  onSubmit: (date: string) => void;
  getInitialDate: () => {
    day: number | null;
    month: number | null;
    year: number | null;
  };
  step: number;
} & DateNatalChartV2ScreenAttributes;

export type TimeScreenProps = {
  onBack: () => void;
  onContinue: () => void;
  onSkip: () => void;
  time: { hours: string; minutes: string; part: string };
  setTime: (time: { hours: string; minutes: string; part: string }) => void;
  step: number;
} & TimeScreenAttributes;

export type TimeNatalChartScreenProps = {
  onBack: () => void;
  onContinue: () => void;
  onSkip: () => void;
  time: { hours: string; minutes: string; part: string };
  setTime: (time: { hours: string; minutes: string; part: string }) => void;
  step: number;
} & TimeNatalChartScreenAttributes;

export type PlaceScreenProps = {
  onBack: () => void;
  onSubmit: (coordinates: number[], location: string) => void;
  initialLocation: string;
  initialCoordinates: number[];
  step: number;
} & PlaceScreenAttributes;

export type PlaceNatalChartScreenProps = {
  onBack: () => void;
  onSubmit: (coordinates: number[], location: string) => void;
  initialLocation: string;
  initialCoordinates: number[];
  step: number;
} & PlaceNatalChartScreenAttributes;

export type PlaceHumanDesignScreenProps = {
  onBack: () => void;
  onSubmit: (coordinates: number[], location: string) => void;
  getAvailableLocations: (value: string) => Promise<Array<AutocompleteFeature>>;
  initialLocation: string;
  initialCoordinates: number[];
  step: number;
} & PlaceHumanDesignScreenAttributes;

export type NameScreenProps = {
  onBack: () => void;
  onSubmit: (name: string) => void;
  initialName: string;
  step: number;
} & NameScreenAttributes;

export type NameHumanDesignScreenProps = {
  onBack: () => void;
  onSubmit: (name: string) => void;
  initialName: string;
  step: number;
} & NameHumanDesignScreenAttributes;

export type NameMoonCompatibilityScreenProps = {
  onBack: () => void;
  onSubmit: (userName: string) => void;
  onSkip: () => void;
  initialUserName: string;
  step: number;
} & NameMoonCompatibilityScreenAttributes;

export type NameMoonCompatibilityV2ScreenProps = {
  onBack: () => void;
  onSubmit: (userName: string) => void;
  onSkip: () => void;
  initialUserName: string;
  step: number;
} & NameMoonCompatibilityV2ScreenAttributes;

export type LoadingScreenProps = {
  onLoadingFinished: () => void;
} & ProcessingScreenAttributes;

export type LoadingPalmistryScreenProps = {
  onLoadingFinished: () => void;
} & ProcessingPalmistryScreenAttributes;

export type LoadingNatalChartScreenProps = {
  onLoadingFinished: () => void;
} & ProcessingNatalChartScreenAttributes;

export type ProcessingLoaderScreenProps = {
  title: string;
  question: string;
  onClose: (value: string) => void;
  shouldShowModal: boolean;
  personalityValue: number;
  profileValue: number;
  relationshipValue: number;
} & ProcessingLoaderScreenAttributes;

export type AnswerScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  step: number;
} & AnswerScreenAttributes;

export type AnswerPalmistryScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  step: number;
} & AnswerPalmistryScreenAttributes;

export type AnswerNatalChartScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  step: number;
} & AnswerNatalChartScreenAttributes;

export type AnswerNatalChartV2ScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  step: number;
} & AnswerNatalChartV2ScreenAttributes;

export type AnswerHorizontalOrientationScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onPolicyClick: (accepted: boolean) => void;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  step: number;
} & AnswerHorizontalOrientationScreenAttributes;

export type AnswerHorizontalOrientationV2ScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onPolicyClick: (accepted: boolean) => void;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  step: number;
} & AnswerHorizontalOrientationV2ScreenAttributes;

export type AnswerHorizontalOrientationV3ScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onPolicyClick: (accepted: boolean) => void;
  isPolicyAccepted: boolean;
  displayPolicyError: boolean;
  step: number;
} & AnswerHorizontalOrientationV3ScreenAttributes;

export type MultipleAnswersScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onContinue: () => void;
  chosenList: string[];
  step: number;
} & MultipleAnswerScreenAttributes;

export type DynamicAnswerScreenProps = {
  onBack: () => void;
  onContinue: (value: string) => void;
  step: number;
} & DynamicAnswerScreenAttributes;

export type DynamicAnswerV2ScreenProps = {
  onBack: () => void;
  onContinue: (value: string) => void;
  step: number;
} & DynamicAnswerV2ScreenAttributes;

export type MultipleAnswersNatalChartScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onContinue: () => void;
  chosenList: string[];
  step: number;
} & MultipleAnswerNatalChartScreenAttributes;

export type MultipleAnswersNatalChartV2ScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onContinue: () => void;
  chosenList: string[];
  step: number;
} & MultipleAnswerNatalChartV2ScreenAttributes;

export type ProblemScreenProps = {
  onBack: () => void;
  onProblemClick: (value: string) => void;
  step: number;
} & ProblemScreenAttributes;

export type CompoundedTitleInformationalScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & CompoundedTitleInformationalScreenAttributes;

export type ReviewScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & ReviewScreenAttributes;

export type ScanPreviewScreenProps = {
  tryScan: () => void;
  onBack: () => void;
  step: number;
} & ScanPreviewScreenAttributes;

export type ScanScreenProps = {
  onBack: () => void;
  goNext: () => void;
  step: number;
} & ScanScreenAttributes;

export type ScanV2ScreenProps = {
  onBack: () => void;
  goNext: () => void;
  step: number;
} & ScanV2ScreenAttributes;

export type ScanAnalysingScreenProps = {
  onBack: () => void;
  goNext: () => void;
  photo: string;
  fingersCoordinates: PalmCoordinates[];
  linesCoordinates: LineCoordinates;
  width: number;
  height: number;
} & ScanAnalysingScreenAttributes;

export type ReportScreenProps = {
  onBack: () => void;
  goNext: () => void;
  scan: PalmPhoto;
  width: number;
  height: number;
  fingersCoordinates: PalmCoordinates[];
  linesCoordinates: LineCoordinates;
  isMobileDevice: boolean;
} & ReportScreenAttributes;

export type SingleZodiacInfoScreenProps = {
  onBack: () => void;
  goNext: () => void;
  zodiacImage: string;
} & SingleZodiacInfoScreenAttributes;

export type MoonPhaseScreenProps = {
  onBack: () => void;
  goNext: () => void;
  moonImage: FunnelImage;
} & MoonPhaseScreenAttributes;

export type PartnerMoonPhaseScreenProps = {
  onBack: () => void;
  goNext: () => void;
  moonImage: FunnelImage;
} & PartnerMoonPhaseScreenAttributes;

export type RelationshipZodiacInfoScreenProps = {
  onBack: () => void;
  goNext: () => void;
  zodiacImage: string;
  partnerZodiacImage: string;
  description: string;
} & RelationshipZodiacInfoScreenAttributes;

export type MentionedInScreenProps = {
  onBack: () => void;
  goNext: () => void;
} & MentionedInScreenAttributes;

export type EmailScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailScreenAttributes;

export type UserAnswerScreenProps = {
  onBack: () => void;
  onSubmit: (email: string) => void;
  onSkip: () => void;
  initialValue: string;
  step: number;
  totalStep?: number;
} & UserAnswerScreenAttributes;

export type EmailBlurredScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailBlurredScreenAttributes;

export type EmailSecuredScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailSecuredScreenAttributes;

export type EmailSecuredPalmistryScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailSecuredPalmistryScreenAttributes;

export type EmailPalmistryScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailPalmistryScreenAttributes;

export type EmailNatalChartScreenProps = {
  onBack: () => void;
  onSubmit: (email: string, helpers: EmailFormHelpers) => void;
  initialValue: string;
  step?: number;
  totalStep?: number;
} & EmailNatalChartScreenAttributes;

export type EmailConfirmScreenProps = {
  onBack: () => void;
  onUserAgree: () => void;
  onUserDisagree: () => void;
} & EmailConfirmScreenAttributes;

export type EmailConfirmPalmistryScreenProps = {
  onBack: () => void;
  onUserAgree: () => void;
  onUserDisagree: () => void;
} & EmailConfirmPalmistryScreenAttributes;

export type EmailConfirmNatalChartScreenProps = {
  onBack: () => void;
  onUserAgree: () => void;
  onUserDisagree: () => void;
} & EmailConfirmNatalChartScreenAttributes;

export type LimitedChoiceScreenProps = {
  onBack: () => void;
  onAnswerClick: (value: string) => void;
  onContinue: () => void;
  getIsAnswerChoosen: (value: string) => boolean;
  chosenList: string[];
  step: number;
} & LimitedChoiceScreenAttributes;

export type UpsellsScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  selectProduct: (product: string) => void;
  selectedProduct: string;
  isPurchaseLoading: boolean;
  resignFormLoaded: boolean;
  onClearPaymentStatus: () => void;
  upsells: Upsell[];
  paymentError: PaymentError | null;
} & UpsellsScreenAttributes;

export type UpsellsV2ScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  selectProduct: (product: string) => void;
  isPurchaseLoading: boolean;
  resignFormLoaded: boolean;
  selectedProduct: string;
  paymentError: PaymentError | null;
  upsells: Upsell[];
} & UpsellsV2ScreenAttributes;

export type UpsellsNatalChartScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  selectProduct: (product: string) => void;
  onClearPaymentStatus: () => void;
  resignFormLoaded: boolean;
  upsells: Array<Upsell>;
  isPurchaseLoading: boolean;
  selectedProduct: string;
  paymentError: PaymentError | null;
} & UpsellsNatalChartScreenAttributes;

export type UpsellsPalmistryV2ScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  selectProduct: (product: string) => void;
  isPurchaseLoading: boolean;
  resignFormLoaded: boolean;
  selectedProduct: string;
  paymentError: PaymentError | null;
  upsells: Array<Upsell>;
} & UpsellsPalmistryV2ScreenAttributes;

export type UpsellsPalmistryScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  selectProduct: (product: string) => void;
  isLoading: boolean;
  resignFormLoaded: boolean;
  selectedProduct: string;
  paymentError: PaymentError | null;
  plans: {
    numerologyAnalysis: PalmistryUpsell;
    tarotReading: PalmistryUpsell;
    ultraPack: PalmistryUpsell;
  };
} & UpsellsPalmistryScreenAttributes;

export type OnboardingScreenProps = OnboardingScreenAttributes;

export type LoadingMoonCompatibilityScreenProps = {
  onLoadingFinished: () => void;
} & LoadingMoonCompatibilityScreenAttributes;

export type OnboardingPalmistryScreenProps = OnboardingPalmistryScreenAttributes;

export type TrialChoiceScreenProps = {
  selectedOffer: Offer | null;
  offers: Offer[];
  loadingStatus: LoadingStatuses;
  onSubmit: () => void;
  changeSelectedOffer: (offer: Offer) => void;
} & TrialChoiceScreenAttributes;

export type TrialChoiceNatalChartScreenProps = {
  offers: Array<Offer>;
  selectedOffer: Offer | null;
  loadingStatus: LoadingStatuses;
  onSubmit: () => void;
  changeSelectedOffer: (plan: Offer) => void;
} & TrialChoiceNatalChartScreenAttributes;

export type TrialChoicePalmistryV2ScreenProps = {
  offers: Array<Offer>;
  selectedOffer: Offer | null;
  loadingStatus: LoadingStatuses;
  onSubmit: () => void;
  changeSelectedOffer: (plan: Offer) => void;
} & TrialChoicePalmistryV2ScreenAttributes;

export type TrialChoicePalmistryScreenProps = {
  plans: Array<ProductPlan>;
  plan: ProductPlan | null;
  onSubmit: () => void;
  onChangePlan: (plan: ProductPlan) => void;
} & TrialChoicePalmistryScreenAttributes;

export type TrialPaymentV2ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  paymentDialogClose: () => void;
  relationshipType: RelationshipTypes;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  zodiacImage: string;
  partnerZodiacImage: string;
} & TrialPaymentScreenAttributes;

export type TrialPaymentScreenProps = {
  goNext: () => void;
  getReport: () => void;
  paymentDialogClose: () => void;
  relationshipType: RelationshipTypes;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  zodiacImage: string;
  partnerZodiacImage: string;
} & TrialPaymentScreenAttributes;

export type TrialPaymentHumanDesignScreenProps = {
  goNext: () => void;
  getReport: () => void;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  report: HumanDesignReport | null;
} & TrialPaymentHumanDesignScreenAttributes;

export type TrialPaymentOneTimeOfferHumanDesignScreenProps = {
  goNext: () => void;
  getReport: () => void;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  report: HumanDesignReport | null;
} & TrialPaymentOneTimeOfferHumanDesignScreenAttributes;

export type TrialPaymentHumanDesignV2ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  report: HumanDesignReport | null;
} & TrialPaymentHumanDesignV2ScreenAttributes;

export type TrialPaymentIntroOfferScreenProps = {
  goNext: () => void;
  getReport: () => void;
  paymentDialogClose: () => void;
  relationshipType: RelationshipTypes;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  zodiacImage: string;
  partnerZodiacImage: string;
  onOfferChanged: (plan: Offer) => void;
  onAllOffersLoaded: () => void;
  isAllOffersLoaded: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
} & TrialPaymentIntroOfferScreenAttributes;

export type TrialPaymentIntroOfferV2ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  paymentDialogClose: () => void;
  relationshipType: RelationshipTypes;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  zodiacImage: string;
  partnerZodiacImage: string;
  onOfferChanged: (plan: Offer) => void;
  onAllOffersLoaded: VoidFunction;
  isAllOffersLoaded: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
} & TrialPaymentIntroOfferV2ScreenAttributes;

export type TrialPaymentAttachmentStyleScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onClosePaymentModal: () => void;
  onCloseDiscountModal: () => void;
  navigateToDiscountPage: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleScreenAttributes;

export type TrialPaymentAttachmentStyleV2ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onClosePaymentModal: () => void;
  onCloseDiscountModal: () => void;
  navigateToDiscountPage: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleV2ScreenAttributes;

export type TrialPaymentAttachmentStyleV3ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onClosePaymentModal: () => void;
  onCloseDiscountModal: () => void;
  navigateToDiscountPage: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleV3ScreenAttributes;

export type TrialPaymentAttachmentStyleWithDiscountScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleWithDiscountScreenAttributes;

export type TrialPaymentAttachmentStyleWithDiscountV2ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleWithDiscountV2ScreenAttributes;

export type TrialPaymentAttachmentStyleWithDiscountV3ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onOfferChanged: (plan: Offer) => void;
  zodiacIcon: IconNames;
  timeLeft: number;
  isTimeUp: boolean;
  selectedOffer: Offer | null;
  defaultOffers: Offer[];
  timeUpOffers: Offer[];
  offer: Offer | null;
  traumaScore: number;
} & TrialPaymentAttachmentStyleWithDiscountV3ScreenAttributes;

export type TrialPaymentPalmistryScreenProps = {
  zodiacImage: string;
  email: string;
  timeLeft: number;
  trialPriceWithoutVAT: number;
  trialPrice: number;
  fullPlan: number;
  discountPlan: number;
  discountAmount: number;
  vat: number;
  currencySymbol: CurrencySymbol;
  currency: Currency;
  productPlan: {
    displayPrice: string;
    productId: string;
    price?: number;
  };
  getReport: () => void;
  onSucceed: () => void;
  onPaymentDialogClose: () => void;
} & TrialPaymentPalmistryScreenAttributes;

export type TrialPaymentWitchPowerScreenProps = {
  email: string;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  getReport: () => void;
  onSucceed: () => void;
  onPaymentDialogClose: () => void;
} & TrialPaymentWitchPowerScreenAttributes;

export type TrialPaymentPalmistryV2ScreenProps = {
  zodiacImage: string;
  email: string;
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  getReport: () => void;
  onSucceed: () => void;
  onPaymentDialogClose: () => void;
} & TrialPaymentPalmistryV2ScreenAttributes;

export type TrialPaymentPalmistryV3ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onClosePaymentModal: () => void;
  onCloseDiscountModal: () => void;
  navigateToDiscountPage: () => void;
  onOfferChanged: (plan: Offer) => void;
  timeLeft: number;
  isTimeUp: boolean;
  offer: Offer | null;
  timeUpOffers: Offer[];
  defaultOffers: Offer[];
  selectedOffer: Offer | null;
} & TrialPaymentPalmistryV3ScreenAttributes;

export type TrialPaymentPalmistryV4ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onClosePaymentModal: () => void;
  navigateToDiscountPage: () => void;
  timeLeft: number;
  isTimeUp: boolean;
  offer: Offer | null;
} & TrialPaymentPalmistryV4ScreenAttributes;

export type EmailMarketingCompatibilityScreenProps = {
  offer: Offer;
  onGetReport: (value: string) => void;
  paymentSucceed: () => void;
} & EmailMarketingCompatibilityScreenAttributes;

export type EmailMarketingNatalChartScreenProps = {
  offer: Offer;
  sectionEnd: ReactNode;
  onGetReport: (value: string) => void;
  paymentSucceed: () => void;
} & EmailMarketingNatalChartScreenAttributes;

export type EmailMarketingPalmistryScreenProps = {
  offer: Offer;
  sectionEnd: ReactNode;
  onGetReport: (value: string) => void;
  paymentSucceed: () => void;
} & EmailMarketingPalmistryScreenAttributes;

export type EmailMarketingWitchPowerScreenProps = {
  offer: Offer;
  onGetReport: (value: string) => void;
  paymentSucceed: () => void;
  order?: OrderDTO;
} & EmailMarketingWitchPowerScreenAttributes;

export type TrialPaymentWithDiscountPalmistryV3ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onOfferChanged: (plan: Offer) => void;
  timeLeft: number;
  isTimeUp: boolean;
  offer: Offer | null;
  timeUpOffers: Offer[];
  defaultOffers: Offer[];
  selectedOffer: Offer | null;
} & TrialPaymentWithDiscountPalmistryV3ScreenAttributes;

export type TrialPaymentWithDiscountPalmistryV4ScreenProps = {
  goNext: () => void;
  getReport: () => void;
  timeLeft: number;
  isTimeUp: boolean;
  offer: Offer | null;
} & TrialPaymentWithDiscountPalmistryV4ScreenAttributes;

export type TrialPaymentMoonCompatibilityScreenProps = {
  timeLeft: number;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  moonImage: FunnelImage;
  moonSignImage: FunnelImage;
  partnerMoonImage: FunnelImage;
  partnerMoonSignImage: FunnelImage;
  getReport: () => void;
  onSucceed: () => void;
  onPaymentDialogClose: () => void;
} & TrialPaymentMoonCompatibilityScreenAttributes;

export type TrialPaymentNatalChartScreenProps = {
  timeLeft: number;
  isPaymentModalOpen: boolean;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  onGetReport: () => void;
  onClose: () => void;
  onSucceed: () => void;
} & TrialPaymentNatalChartScreenAttributes;

export type TrialPaymentNatalChartV2ScreenProps = {
  timeLeft: number;
  isPaymentModalOpen: boolean;
  offer: Offer | null;
  timeUpOffer: Offer | null;
  onGetReport: () => void;
  onClose: () => void;
  onSucceed: () => void;
} & TrialPaymentNatalChartV2ScreenAttributes;

export type AdditionalDiscountScreenProps = {
  onContinue: () => void;
  timeUpOffer: Offer | null;
  discountOffer: Offer | null;
} & AdditionalDiscountScreenAttributes;

export type AdditionalDiscountPalmistryScreenProps = {
  onGetReport: () => void;
  currencySymbol: string;
  priceData: { price: number; oldPrice: number; discountPercent: number };
} & AdditionalDiscountPalmistryScreenAttributes;

export type AdditionalDiscountPalmistryV2ScreenProps = {
  onGetReport: () => void;
  timeUpOffer: Offer | null;
  discountOffer: Offer | null;
} & AdditionalDiscountPalmistryV2ScreenAttributes;

export type TrialPaymentWithDiscountScreenProps = {
  goNext: () => void;
  getReport: () => void;
  onGetReport: () => void;
  onPaymentModalClose: () => void;
  timeUpOffer: Offer | null;
  discountOffer: Offer | null;
  selectedOffer: Offer | null;
  isPaymentModalOpen: boolean;
} & TrialPaymentWithDiscountScreenAttributes;

export type TrialPaymentWithDiscountPalmistryV2ScreenProps = {
  timeUpOffer: Offer | null;
  discountOffer: Offer | null;
  selectedOffer: Offer | null;
  isModalOpen: boolean;
  onGetReport: () => void;
  onModalClose: () => void;
  paymentSucceed: () => void;
} & TrialPaymentWithDiscountPalmistryV2ScreenAttributes;

export type TrialPaymentWithDiscountPalmistryScreenProps = {
  price: number;
  currencySymbol: CurrencySymbol;
  productPlan: PalmistryDiscountPlan;
  vat: number;
  priceWithoutVAT: number;
  period: string;
  secretPrice: number;
  oldPrice: number;
  isModalOpen: boolean;
  onGetReport: () => void;
  onModalClose: () => void;
  paymentSucceed: () => void;
} & TrialPaymentWithDiscountPalmistryScreenAttributes;

export type UnlimitedReadingsScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  plan: Offer | null;
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UnlimitedReadingsScreenAttributes;

export type UpsellNatalChartSingleScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UpsellNatalChartSingleScreenAttributes;

export type UpsellBlackMoonScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UpsellBlackMoonScreenAttributes;

export type UnlimitedReadingsPalmistryV2ScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  plan: Offer | null;
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UnlimitedReadingsPalmistryV2ScreenAttributes;

export type UnlimitedReadingsPalmistryScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  isLoading: boolean;
  resignFormLoaded: boolean;
  plan: PalmistryPlan;
  paymentError: PaymentError | null;
} & UnlimitedReadingsPalmistryScreenAttributes;

export type TrialCreditsScreenProps = {
  onBuyCredits: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  trialCreditsUpsell: Upsell;
  resignFormLoaded: boolean;
  isLoading: boolean;
  paymentError: PaymentError | null;
} & TrialCreditsScreenAttributes;

export type TrialCreditsV2ScreenProps = {
  onBuyCredits: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  trialCreditsUpsell: Upsell;
  resignFormLoaded: boolean;
  isLoading: boolean;
  paymentError: PaymentError | null;
} & TrialCreditsV2ScreenAttributes;

export type TrialCreditsNatalChartScreenProps = {
  onBuyCredits: () => void;
  onSkip: () => void;
  isLoading: boolean;
  resignFormLoaded: boolean;
  trialCreditsUpsell: Upsell;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & TrialCreditsNatalChartScreenAttributes;

export type TrialCreditsPalmistryV2ScreenProps = {
  isLoading: boolean;
  plan: Upsell;
  resignFormLoaded: boolean;
  onBuyCredits: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & TrialCreditsPalmistryV2ScreenAttributes;

export type TrialCreditsPalmistryScreenProps = {
  isLoading: boolean;
  plan: PalmistryPlan;
  currencySymbol: CurrencySymbol;
  price: string;
  resignFormLoaded: boolean;
  onBuyCredits: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & TrialCreditsPalmistryScreenAttributes;

export type OnboardingAskScreenProps = OnboardingAskScreenAttributes;

export type OnboardingAskV2ScreenProps = {
  goNext: () => void;
} & OnboardingAskV2ScreenAttributes;

export type OnboardingAskNatalChartScreenProps = OnboardingAskNatalChartScreenAttributes;

export type OnboardingAskPalmistryScreenProps = OnboardingAskPalmistryScreenAttributes;

export type OnboardingAskPalmistryV2ScreenProps = {
  goNext: () => void;
} & OnboardingAskPalmistryV2ScreenAttributes;

export type TalkWithExpertScreenProps = TalkWithExpertScreenAttributes;

export type TalkWithExpertNatalChartScreenProps = TalkWithExpertNatalChartScreenAttributes;

export type TalkWithExpertPalmistryScreenProps = TalkWithExpertPalmistryScreenAttributes;

export type QuestionsScreenProps = QuestionsScreenAttributes;

export type QuestionsNatalChartScreenProps = QuestionsNatalChartScreenAttributes;

export type QuestionsPalmistryScreenProps = QuestionsPalmistryScreenAttributes;

export type AppDownloadScreenProps = {
  linkLoadingStatus: LoadingStatuses;
  onOpenLink: () => void;
  onSkip: () => void;
} & AppDownloadScreenAttributes;

export type CreditsScreenProps = CreditsScreenAttributes;

export type CreditsNatalChartScreenProps = CreditsNatalChartScreenAttributes;

export type CreditsPalmistryScreenProps = CreditsPalmistryScreenAttributes;

export type LoadingAskScreenProps = {
  onLoadingFinished: () => void;
} & LoadingAskScreenAttributes;

export type LoadingAskNatalChartScreenProps = {
  onLoadingFinished: () => void;
} & LoadingAskNatalChartScreenAttributes;

export type LoadingAskPalmistrykScreenProps = {
  onLoadingFinished: () => void;
} & LoadingAskPalmistryScreenAttributes;

export type PhoneNumberScreenProps = {
  goNext: () => void;
} & PhoneNumberScreenAttributes;

export type PhoneNumberPalmistryScreenProps = {
  goNext: () => void;
} & PhoneNumberPalmistryScreenAttributes;

export type ThankYouScreenProps = {
  goNext: () => void;
} & ThankYouScreenAttributes;

export type ThankYouWithRedirectScreenProps = ThankYouWithRedirectScreenAttributes;

export type HelloMoonCompatibilityScreenProps = {
  goNext: () => void;
  onBack: () => void;
} & HelloMoonCompatibilityScreenAttributes;

export type UserTypeHumanDesignScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & UserTypeHumanDesignScreenAttributes;

export type OnboardingReadingScreenProps = {
  goNext: () => void;
} & OnboardingReadingScreenAttributes;

export type RelationshipProgressScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & RelationshipProgressScreenAttributes;

export type InformationalV2ScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & InformationalV2ScreenAttributes;

export type SocialProofScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & SocialProofScreenAttributes;

export type AnswerImageScreenProps = {
  step: number;
  goBack: () => void;
  onAnswerClick: (value: string) => void;
} & AnswerImageScreenAttributes;

export type AnswerImageMultipleScreenProps = {
  step: number;
  defaultAnswers: Array<string>;
  goBack: () => void;
  onContinue: (checkedAnswers: Array<string>) => void;
} & AnswerImageMultipleScreenAttributes;

export type UpsellConsultationScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  displayDiscountModal: boolean;
  plan: Offer | null;
  price: string;
  trialPrice: string;
  onGetOffer: () => void;
  onSkipOffer: () => void;
  onGetSecretDiscount: () => void;
  onCloseDiscountModal: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UpsellConsultationScreenAttributes;

export type UpsellConsultationWithDiscountScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  price: string;
  trialPrice: string;
  onGetOffer: () => void;
  onSkipOffer: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UpsellConsultationWithDiscountScreenAttributes;

export type UpsellReadingsPackScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  plan: Offer | null;
  price: string;
  trialPrice: string;
  displayDiscountModal: boolean;
  onGetOffer: () => void;
  onSkipOffer: () => void;
  paymentError: PaymentError | null;
  onClearPaymentStatus: () => void;
  onGetSecretDiscount: () => void;
  onCloseDiscountModal: () => void;
} & UpsellReadingsPackScreenAttributes;

export type UpsellReadingsPackWithDiscountScreenProps = {
  isLoading: boolean;
  resignFormLoaded: boolean;
  price: string;
  trialPrice: string;
  onGetOffer: () => void;
  onSkipOffer: () => void;
  onClearPaymentStatus: () => void;
  paymentError: PaymentError | null;
} & UpsellReadingsPackWithDiscountScreenAttributes;

export type UpsellsV3ScreenProps = {
  onGetReport: () => void;
  onSkip: () => void;
  onClearPaymentStatus: () => void;
  onOfferSelect: (id: string) => void;
  isPurchaseLoading: boolean;
  resignFormLoaded: boolean;
  selectedOfferId: string;
  paymentError: PaymentError | null;
  upsells: Array<UpsellV2>;
} & UpsellsV3ScreenAttributes;

export type SummaryScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & SummaryScreenAttributes;

export type SummaryV2ScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & SummaryV2ScreenAttributes;

const DatePickerStepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasZodiacWheel: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        next: validator.string(),
        day: validator.string(),
        month: validator.string(),
        year: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE_PICKER),
    }),
  ),
});

export type DatePickerStepData = validator.infer<typeof DatePickerStepDataSchema>;

export type DatePickerScreenAttributes = DatePickerStepData['attributes'][number];

export type DatePickerScreenProps = {
  onBack: () => void;
  onSubmit: (value: string) => void;
  step: number;
  userDate: string;
} & DatePickerScreenAttributes;

const DatePickerV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      hasZodiacWheel: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        next: validator.string(),
        day: validator.string(),
        month: validator.string(),
        year: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.DATE_PICKER_V2),
    }),
  ),
});

export type DatePickerV2StepData = validator.infer<typeof DatePickerV2StepDataSchema>;

export type DatePickerV2ScreenAttributes = DatePickerV2StepData['attributes'][number];

export type DatePickerV2ScreenProps = {
  onBack: () => void;
  onSubmit: (value: string) => void;
  step: number;
  userDate: string;
} & DatePickerV2ScreenAttributes;

const TimeV2StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.TIME_V2),
    }),
  ),
});

export type TimeV2StepData = validator.infer<typeof TimeV2StepDataSchema>;

export type TimeV2ScreenAttributes = TimeV2StepData['attributes'][number];

export type TimeV2ScreenProps = {
  onBack: () => void;
  onSubmit: (value: string) => void;
  step: number;
  userTime: string;
} & TimeV2ScreenAttributes;

const TimeV3StepDataSchema = BaseQuizStepDataSchema.extend({
  attributes: validator.array(
    BaseQuizScreenAttributesSchema.extend({
      dataKey: validator.string(),
      hasButtonBack: validator.boolean().optional(),
      content: validator.object({
        title: validator.string(),
        subtitle: validator.string(),
        next: validator.string(),
        backgroundImage: QuizStepBackgroundSchema.optional(),
      }),
      screenType: validator.literal(QuizStepTypes.TIME_V3),
    }),
  ),
});

export type TimeV3StepData = validator.infer<typeof TimeV3StepDataSchema>;

export type TimeV3ScreenAttributes = TimeV3StepData['attributes'][number];

export type TimeV3ScreenProps = {
  onBack: () => void;
  onSubmit: (value: string) => void;
  step: number;
  userTime: string;
} & TimeV3ScreenAttributes;

export type TimelineScreenProps = {
  goNext: () => void;
  goBack: () => void;
} & TimelineScreenAttributes;

export type LoaderWithReviewsScreenProps = {
  onLoadingFinished: () => void;
} & LoaderWithReviewsScreenAttributes;

export const QuizStepSchema = validator.union([
  TimeV2StepDataSchema,
  TimeV3StepDataSchema,
  DatePickerStepDataSchema,
  DatePickerV2StepDataSchema,
  SplitStepDataSchema,
  GenderStepDataSchema,
  GenderPalmistryStepDataSchema,
  DateStepDataSchema,
  DatePalmistryStepDataSchema,
  DateNatalChartStepDataSchema,
  PlaceStepDataSchema,
  PlaceNatalChartStepDataSchema,
  TimeStepDataSchema,
  TimeNatalChartStepDataSchema,
  AnswerStepDataSchema,
  AnswerPalmistryStepDataSchema,
  AnswerNatalChartStepDataSchema,
  AnswerHorizontalOrientationStepDataSchema,
  ProblemStepDataSchema,
  MultipleAnswerStepDataSchema,
  DynamicAnswerStepDataSchema,
  DynamicAnswerV2StepDataSchema,
  MultipleAnswerNatalChartStepDataSchema,
  MultipleAnswerNatalChartV2StepDataSchema,
  EmailStepDataSchema,
  UserAnswerStepDataSchema,
  EmailPalmistryStepDataSchema,
  EmailNatalChartStepDataSchema,
  EmailConfirmStepDataSchema,
  EmailConfirmPalmistryStepDataSchema,
  EmailConfirmNatalChartStepDataSchema,
  ProcessingStepDataSchema,
  ProcessingPalmistryStepDataSchema,
  ProcessingNatalChartStepDataSchema,
  ProcessingLoaderStepDataSchema,
  ReviewStepDataSchema,
  GoalStepDataSchema,
  GoalSetStepDataSchema,
  InformationalStepDataSchema,
  InformationalPalmistryStepDataSchema,
  InformationalNatalChartStepDataSchema,
  ImageInformationalStepDataSchema,
  LimitedChoiceStepDataSchema,
  NameStepDataSchema,
  CompoundedTitleInformationalStepDataSchema,
  OnboardingStepDataSchema,
  OnboardingIntroOfferStepDataSchema,
  OnboardingPalmistryStepDataSchema,
  PrelandingMoonCompatibilityStepDataSchema,
  PrelandingPalmistryStepDataSchema,
  ScanPreviewStepDataSchema,
  ScanStepDataSchema,
  ScanAnalysingStepDataSchema,
  ReportStepDataSchema,
  SingleZodiacInfoStepDataSchema,
  RelationshipZodiacInfoStepDataSchema,
  MentionedInStepDataSchema,
  UpsellsStepDataSchema,
  UpsellsV2StepDataSchema,
  UpsellsPalmistryStepDataSchema,
  UpsellsPalmistryV2StepDataSchema,
  UpsellsNatalChartStepDataSchema,
  UpsellsIntroOfferStepDataSchema,
  UpsellsEmailMarketingDataSchema,
  UnlimitedReadingsStepDataSchema,
  UnlimitedReadingsPalmistryStepDataSchema,
  UnlimitedReadingsEmailMarketingStepDataSchema,
  TrialCreditsStepDataSchema,
  TrialCreditsPalmistryStepDataSchema,
  TrialCreditsNatalChartStepDataSchema,
  TrialCreditsEmailMarketingStepDataSchema,
  CreditsStepDataSchema,
  CreditsPalmistryStepDataSchema,
  CreditsIntroOfferStepDataSchema,
  CreditsNatalChartStepDataSchema,
  EcomUpsellStepDataSchema,
  LoadingAskStepDataSchema,
  LoadingAskPalmistryStepDataSchema,
  LoadingAskNatalChartStepDataSchema,
  OnboardingAskPalmistryStepDataSchema,
  OnboardingAskPalmistryV2StepDataSchema,
  OnboardingAskV2StepDataSchema,
  OnboardingAskNatalChartStepDataSchema,
  QuestionsStepDataSchema,
  QuestionsPalmistryStepDataSchema,
  QuestionsNatalChartStepDataSchema,
  TalkWithExpertStepDataSchema,
  TalkWithExpertPalmistryStepDataSchema,
  TalkWithExpertNatalChartStepDataSchema,
  TrialChoiceStepDataSchema,
  TrialChoiceNatalChartStepDataSchema,
  TrialChoicePalmistryStepDataSchema,
  TrialChoicePalmistryV2StepDataSchema,
  TrialPaymentWithDiscountStepDataSchema,
  TrialPaymentWithDiscountPalmistryStepDataSchema,
  TrialPaymentWithDiscountPalmistryV2StepDataSchema,
  TrialPaymentWithDiscountIntroOfferStepDataSchema,
  TrialPaymentStepDataSchema,
  TrialPaymentPalmistryStepDataSchema,
  TrialPaymentNatalChartStepDataSchema,
  TrialPaymentNatalChartV2StepDataSchema,
  TrialPaymentIntroOfferStepDataSchema,
  TrialPaymentIntroOfferV2StepDataSchema,
  PhoneNumberStepDataSchema,
  PhoneNumberPalmistryStepDataSchema,
  PhoneNumberEmailMarketingStepDataSchema,
  AdditionalDiscountStepDataSchema,
  AdditionalDiscountPalmistryStepDataSchema,
  AdditionalDiscountPalmistryV2StepDataSchema,
  ThankYouStepDataSchema,
  ThankYouWithRedirectStepDataSchema,
  GenderMoonCompatibilityStepDataSchema,
  NameMoonCompatibilityStepDataSchema,
  NameMoonCompatibilityV2StepDataSchema,
  TrialPaymentMoonCompatibilityStepDataSchema,
  LoadingMoonCompatibilityStepDataSchema,
  HelloMoonCompatibilityStepDataSchema,
  PartnerMoonPhaseStepDataSchema,
  DateNatalChartV2StepDataSchema,
  AnswerNatalChartV2StepDataSchema,
  AnswerHorizontalOrientationV2StepDataSchema,
  AnswerHorizontalOrientationV3StepDataSchema,
  EmailSecuredStepDataSchema,
  EmailSecuredPalmistryStepDataSchema,
  UnlimitedReadingsPalmistryV2StepDataSchema,
  TrialCreditsV2StepDataSchema,
  TrialCreditsPalmistryV2StepDataSchema,
  TrialPaymentWitchPowerStepDataSchema,
  TrialPaymentPalmistryV2StepDataSchema,
  TrialPaymentPalmistryV3StepDataSchema,
  MoonPhaseStepDataSchema,
  UpsellNatalChartSingleStepDataSchema,
  EmailBlurredStepDataSchema,
  WhichFigureStepDataSchema,
  UpsellBlackMoonSingleStepDataSchema,
  UserTypeHumanDesignStepDataSchema,
  TrialPaymentHumanDesignStepDataSchema,
  TrialPaymentHumanDesignV2StepDataSchema,
  PlaceHumanDesignStepDataSchema,
  BasePageDataSchema,
  OnboardingReadingStepDataSchema,
  NameHumanDesignStepDataSchema,
  AppDownloadStepDataSchema,
  RelationshipProgressStepDataSchema,
  TrialPaymentAttachmentStyleStepDataSchema,
  GenderV2StepDataSchema,
  TrialPaymentAttachmentStyleWithDiscountStepDataSchema,
  EmailMarketingCompatibilityStepDataSchema,
  EmailMarketingNatalChartStepDataSchema,
  EmailMarketingPalmistryStepDataSchema,
  EmailMarketingWitchPowerStepDataSchema,
  TrialPaymentWithDiscountPalmistryV3StepDataSchema,
  InformationalV2StepDataSchema,
  SocialProofStepDataSchema,
  AnswerImageStepDataSchema,
  AnswerImageMultipleStepDataSchema,
  UpsellConsultationStepDataSchema,
  UpsellConsultationWithDiscountStepDataSchema,
  UpsellReadingsPackStepDataSchema,
  UpsellReadingsPackWithDiscountStepDataSchema,
  TrialPaymentPalmistryV4StepDataSchema,
  TrialPaymentWithDiscountPalmistryV4StepDataSchema,
  UpsellsV3StepDataSchema,
  TimelineStepDataSchema,
  LoaderWithReviewsStepDataSchema,
  TrialPaymentOneTimeOfferHumanDesignStepDataSchema,
  SummaryStepDataSchema,
  SummaryV2StepDataSchema,
  EmailMarketingPaywallDataSchema,
  TrialPaymentAttachmentStyleV2StepDataSchema,
  TrialPaymentAttachmentStyleV3StepDataSchema,
  TrialPaymentAttachmentStyleWithDiscountV2StepDataSchema,
  TrialPaymentAttachmentStyleWithDiscountV3StepDataSchema,
]);

export const AttributesSchema = validator.record(validator.string(), validator.unknown());

export const FlowSchema = validator.object({
  id: validator.string(),
  name: validator.string(),
  initialScreen: validator.string(),
  offers: validator.object({
    attributes: validator.array(OffersByCategorySchema),
  }),
  screens: validator.array(QuizStepSchema),
  translations: validator.record(validator.string(), validator.unknown()).optional(),
  attributes: AttributesSchema,
  language: validator.nativeEnum(Languages),
});

export type Flow = Omit<validator.infer<typeof FlowSchema>, 'attributes'> & {
  attributes: FlowAttributes;
};

export const FunnelConfigSchema = validator.object({
  name: validator.string(),
  flows: validator.array(FlowSchema),
  link: validator.string(),
  analytics: FunnelAnalyticsSchema,
});

export type FunnelConfig = validator.infer<typeof FunnelConfigSchema>;

export enum RelationshipStatuses {
  SINGLE = 'single',
  IN_A_RELATIONSHIP = 'in a relationship',
  MARRIED = 'married',
  COMPLICATED = 'complicated',
  UNSURE = 'unsure/other',
}

export const RelationshipTypesToStatusesMap: Record<RelationshipTypes, RelationshipStatuses[]> = {
  [RelationshipTypes.PARTNER]: [
    RelationshipStatuses.IN_A_RELATIONSHIP,
    RelationshipStatuses.MARRIED,
  ],
  [RelationshipTypes.SINGLE]: [
    RelationshipStatuses.SINGLE,
    RelationshipStatuses.COMPLICATED,
    RelationshipStatuses.UNSURE,
  ],
};

export const getRelationshipType = (relationshipStatus: RelationshipStatuses) =>
  RelationshipTypesToStatusesMap.single.includes(relationshipStatus)
    ? RelationshipTypes.SINGLE
    : RelationshipTypes.PARTNER;

export type ScreenAttributes = BaseQuizAttributes | BasePageAttributesWithMappedSections;
