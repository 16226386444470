import { LoadingStatuses } from 'core/common/entities';
import { HumanDesignReport } from '../entities';

export type HumanDesignState = {
  reportLoadingStatus: LoadingStatuses;
  report: HumanDesignReport | null;
};

export const humanDesignInitialState: HumanDesignState = {
  reportLoadingStatus: LoadingStatuses.IDLE,
  report: null,
};
