import { useServices } from './useServices';

export const useIsApple = () => {
  const { appEnvironment } = useServices();

  return {
    isApple: appEnvironment.isApple(),
    isAppleMobile: appEnvironment.isAppleMobile(),
    isApplePaySupported: appEnvironment.isApplePaySupported(),
  };
};
