import { YEAR_IN_DAYS } from 'core/common/constants';
import { normalizeError } from 'core/common/errors';
import { CookiesService } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { parseQueryParams } from 'core/common/utils/getQueryParams';
import { UtmSource, UtmTags } from 'core/user/entities';
import { POSSIBLE_UTM_TAGS, UtmTagsService } from './UtmTagsService';

export class UtmTagsClientService implements UtmTagsService {
  private readonly cookiesService: CookiesService;

  private readonly logger: Logger;

  private readonly storageKey = 'nebula_utm_tags';

  private constructor(cookiesService: CookiesService, logger: Logger) {
    this.cookiesService = cookiesService;
    this.logger = logger;
  }

  getUtmTags() {
    return this.getTagsFromCache();
  }

  getUtmSource(): UtmSource {
    return this.getUtmTags().utm_source || UtmSource.NO_SOURCE;
  }

  getUtmAdName(): string {
    return this.getUtmTags().utm_ad_name || '';
  }

  static crateFromQueryParams(cookiesService: CookiesService, logger: Logger) {
    const utmService = new UtmTagsClientService(cookiesService, logger);
    utmService.loadFormQueryParams();

    return utmService;
  }

  updateUtmTags(utmTags: UtmTags) {
    const oldUtmTags = this.getUtmTags();
    const newUtmTags = { ...oldUtmTags, ...utmTags };

    this.saveToCache(newUtmTags);

    return this.getUtmTags();
  }

  private loadFormQueryParams() {
    const newUtmTags: Record<string, string> = {};

    const parsedQs = parseQueryParams(window.location.search);

    POSSIBLE_UTM_TAGS.forEach((utmTag) => {
      if (utmTag in parsedQs) {
        newUtmTags[utmTag] = this.extractUtmTagFromParsedQs(parsedQs[utmTag]);
      }
    });

    this.updateUtmTags(newUtmTags);
  }

  private saveToCache(utmTags: UtmTags) {
    try {
      const stringifiedTags = JSON.stringify(utmTags);

      this.cookiesService.set(this.storageKey, stringifiedTags, {
        expires: YEAR_IN_DAYS,
      });
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
    }
  }

  private getTagsFromCache(): UtmTags {
    try {
      const rawUtmTags = this.cookiesService.get(this.storageKey);
      const utmTags = rawUtmTags ? JSON.parse(rawUtmTags) : {};

      return this.excludeUtmTags(utmTags, ['quiz']);
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);
      return {};
    }
  }

  private extractUtmTagFromParsedQs(parsedQs: undefined | string | string[]): string {
    if (!parsedQs) return '';

    return Array.isArray(parsedQs) ? parsedQs[0] : parsedQs;
  }

  private excludeUtmTags(utmTags: UtmTags, excludeList: Array<string>): UtmTags {
    const newUtmTags: Record<string, string> = {};

    Object.entries(utmTags).forEach(([utmTag, utmValue]) => {
      if (excludeList.includes(utmTag)) return;

      newUtmTags[utmTag] = utmValue;
    });

    return newUtmTags;
  }
}
