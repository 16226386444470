import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { parseQueryParams } from 'core/common/utils/getQueryParams';
import { Locales, Regions, UserLocation } from 'core/user/entities';
import { LocationService } from './LocationService';

export class LocationClientService extends BaseService implements LocationService {
  private overriddenLocation: Partial<UserLocation> = {};

  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient, logger);
    this.loadFromQueryParams();
  }

  async getLocation(): Promise<UserLocation> {
    try {
      const { data: location } = await this.httpClient.get<UserLocation>('/user/locale');

      return {
        locale: this.overriddenLocation.locale ?? location.locale,
        region: this.overriddenLocation.region ?? location.region,
        country: this.overriddenLocation.country ?? location.country,
      };
    } catch (err) {
      return {
        locale: Locales.OTHER,
        country: '',
        region: Regions.OTHER,
      };
    }
  }

  private loadFromQueryParams() {
    const queries = parseQueryParams<UserLocation>(window.location.search);

    this.overriddenLocation.locale = queries.locale;
    this.overriddenLocation.country = queries.country;
    this.overriddenLocation.region = queries.region;
  }
}
