import { Languages } from 'core/localization/entities';
import { FlowStatus, FunnelStatus } from './FunnelData';

export enum RevalidationSubject {
  FEATURE_FLAGS = 'FEATURE_FLAGS',
  FUNNELS = 'FUNNELS',
}

export enum RevalidationAction {
  CREATE_FLOW = 'create.flow',
  UPDATE_FLOW = 'update.flow',
  DELETE_FLOW = 'delete.flow',
  CREATE_FUNNEL = 'create.funnel',
  UPDATE_FUNNEL = 'update.funnel',
  DELETE_FUNNEL = 'delete.funnel',
}

type Flow = {
  id: string;
  name: string;
  status: FlowStatus;
  language: Languages;
  initial_screen: string;
  description: string;
  screens: Array<{ id: string }> | [];
};

type Funnel = {
  id: string;
  name: string;
  status: FunnelStatus;
  description: string;
  flow?: Flow;
  flows?: Flow[];
};

type Metadata = {
  version: string;
};

type FunnelWithOneFlowPayload = {
  funnel: Funnel & { flow: Flow };
};

type FunnelWithAllFlowsPayload = {
  funnel: Funnel & { flows: Flow[] };
};

export type RequestFunnelWithAllFlows = {
  id: string;
  action: RevalidationAction;
  timestamp: string;
  payload: FunnelWithAllFlowsPayload;
  metadata: Metadata;
};

export type RequestFunnelWithOneFlow = {
  id: string;
  action: RevalidationAction;
  timestamp: string;
  payload: FunnelWithOneFlowPayload;
  metadata: Metadata;
};
