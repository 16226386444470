import { getSystemLanguage, RootInitialState } from 'core/common/store';
import { getHumanDesignReport } from 'core/human-design/store/selectors';
import {
  getMoonPhase,
  getMoonSign,
  getPartnerMoonPhase,
  getPartnerMoonSign,
} from 'core/moon-compatibility/store/selectors';
import {
  getConsultationUpsellOffer,
  getDiscountConsultationUpsellOffer,
  getSelectedOffer,
} from 'core/offers/store/selectors';
import {
  getUserCurrency,
  getUserInformation,
  getUserLocation,
  getUtmAdName,
  getUtmSource,
} from 'core/user/store/selectors';

export const getFunnelConfig = (state: RootInitialState) => {
  return state.funnelConfig;
};

export const getFunnelName = (state: RootInitialState) => {
  const config = getFunnelConfig(state).config;

  if (config) {
    return config.name;
  }

  return '';
};

export const getConditionAttributes = (state: RootInitialState) => {
  const utmSource = getUtmSource(state);
  const utmAdName = getUtmAdName(state);
  const userProfile = getUserInformation(state);
  const language = getSystemLanguage(state);
  const currencyData = getUserCurrency(state);
  const selectedOffer = getSelectedOffer(state);
  const consultationUpsellOffer = getConsultationUpsellOffer(state);
  const discountConsultationUpsellOffer = getDiscountConsultationUpsellOffer(state);
  const moonPhase = getMoonPhase(state);
  const moonSign = getMoonSign(state);
  const partnerMoonPhase = getPartnerMoonPhase(state);
  const partnerMoonSign = getPartnerMoonSign(state);
  const humanDesignReport = getHumanDesignReport(state);
  const { locale } = getUserLocation(state);

  // TODO remove redundant keys (some of offers)
  return {
    language,
    utmSource,
    utmAdName,
    selectedOfferName: selectedOffer?.offerId ?? '',
    selectedOfferTrialPriceWithCurrency: selectedOffer?.pricesWithCurrency?.trialPriceWithVAT ?? '',
    selectedOfferPriceWithCurrency: selectedOffer?.pricesWithCurrency?.priceWithVAT ?? '',
    selectedOfferTrialPriceWithCurrencySymbol:
      selectedOffer?.pricesWithCurrencySymbol?.trialPriceWithVAT ?? '',
    selectedOfferPriceWithCurrencySymbol:
      selectedOffer?.pricesWithCurrencySymbol?.priceWithVAT ?? '',
    consultationUpsellOfferPriceWithCurrencySymbol:
      consultationUpsellOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '',
    consultationUpsellOfferTrialPriceWithCurrencySymbol:
      consultationUpsellOffer?.pricesWithCurrencySymbol.trialPriceWithVAT ?? '',
    discountConsultationUpsellOfferPriceWithCurrencySymbol:
      discountConsultationUpsellOffer?.pricesWithCurrencySymbol.priceWithVAT ?? '',
    discountConsultationUpsellOfferTrialPriceWithCurrencySymbol:
      discountConsultationUpsellOffer?.pricesWithCurrencySymbol.trialPriceWithVAT ?? '',
    currency: currencyData.currency,
    currencySymbol: currencyData.currencySymbol,
    locationLocale: locale,
    moonPhase,
    moonSign,
    partnerMoonPhase,
    partnerMoonSign,
    humanDesignReport: humanDesignReport?.chartData ?? {},
    ...userProfile,
  };
};
