/* eslint-disable max-len */
/**
 * @warning
 * Use only as a runtime object.
 * On a type level, use a string type instead
 */
export enum PurchaseProductName {
  NONE = '',
  TAROT_SPREAD_FOR_2022 = 'tarot_spread_for_2022',
  NUMEROLOGY_CALCULATOR = 'numerology_calculator',
  TAROT_099 = 'tarot_0.99',
  NUMEROLOGY_099 = 'numerology_0.99',
  PALMISTRY_099 = 'palmistry_0.99',
  TAROT_10 = 'tarot_10',
  NUMEROLOGY_10 = 'numerology_10',
  PALMISTRY_10 = 'palmistry_10',
  NUMEROLOGY_4_99 = 'numerology_4.99',
  TAROT_4_99 = 'tarot_4.99',
  NUMEROLOGY_TAROT_5_99 = 'numerology_tarot_5.99',
  NUMEROLOGY_REPORT_14_99 = 'numerology_report_14.99',
  NUMEROLOGY_REPORT_14_99_EUR = 'numerology_report_14.99_EUR',
  TAROT_REPORT_19_99 = 'tarot_report_19.99',
  TAROT_REPORT_19_99_EUR = 'tarot_report_19.99_EUR',
  PALMISTRY_REPORT_19_99 = 'palmistry_report_19.99',
  PALMISTRY_REPORT_19_99_EUR = 'palmistry_report_19.99_EUR',
  ULTRA_PACK_5_REPORTS_49_99 = 'ultra_pack_5_reports_49.99',
  ULTRA_PACK_5_REPORTS_49_99_EUR = 'ultra_pack_5_reports_49.99_EUR',
  ULTRA_PACK_4_REPORTS_29_99 = 'ultra_pack_4_reports_29.99',
  ULTRA_PACK_4_REPORTS_29_99_EUR = 'ultra_pack_4_reports_29.99_EUR',

  NUMEROLOGY_SUBSCRIPTION_14_99 = '14.99_USD_upsell_NUMEROLOGY_3pcs_30d',
  NUMEROLOGY_SUBSCRIPTION_14_99_EUR = '14.99_EUR_upsell_NUMEROLOGY_3pcs_30d',
  TAROT_SUBSCRIPTION_19_99 = '19.99_USD_upsell_TAROT_3pcs_30d',
  TAROT_SUBSCRIPTION_19_99_EUR = '19.99_EUR_upsell_TAROT_3pcs_30d',
  PALMISTRY_SUBSCRIPTION_19_99 = '19.99_USD_upsell_PALMISTRY_3pcs_30d',
  PALMISTRY_SUBSCRIPTION_19_99_EUR = '19.99_EUR_upsell_PALMISTRY_3pcs_30d',
  ULTRA_PACK_5_SUBSCRIPTION_49_99 = '49.99_USD_upsell_ULTRAPACK_3pcs_30d',
  ULTRA_PACK_5_SUBSCRIPTION_49_99_EUR = '49.99_EUR_upsell_ULTRAPACK_3pcs_30d',

  // Premium upsells
  PREMIUM_UPSELL_14_59_USD_1M = '14_59_USD_1m_appnebula_premium_upsell',
  PREMIUM_UPSELL_14_59_EUR_1M = '14_59_EUR_1m_appnebula_premium_upsell',
  PREMIUM_UPSELL_9_59_USD_2W = '9_59_USD_2w_appnebula_premium_upsell',
  PREMIUM_UPSELL_9_59_EUR_2W = '9_59_EUR_2w_appnebula_premium_upsell',

  CARD_TAROT_DECK = 'сard_tarot_deck',

  INTRO_APPNEBULA_UPSELL_SUBSCRIPTION_USD_49_99_900_CREDITS = '49.99_USD_upsell_CONSULTATIONS_30d',
  INTRO_APPNEBULA_UPSELL_SUBSCRIPTION_EUR_49_99_900_CREDITS = '49.99_EUR_upsell_CONSULTATIONS_30d',

  INTRO_APPNEBULA_UPSELL_ONE_TIME_USD_49_99_900_CREDITS = 'intro_appnebula_upsell_one_time_USD_49.99_900credits',
  INTRO_APPNEBULA_UPSELL_ONE_TIME_EUR_49_99_900_CREDITS = 'intro_appnebula_upsell_one_time_EUR_49.99_900credits',

  APP_SUBSCRIPTION_1WEEK_VS_1MONTH_9_99 = 'subscription_nebula_premium_1w_9.99_1m_28.56',
  APP_SUBSCRIPTION_1WEEK_VS_1MONTH_14_99 = 'subscription_nebula_premium_1m_14.99_1m_28.56',
  APP_SUBSCRIPTION_1WEEK_VS_1MONTH_19_99 = 'subscription_nebula_premium_1m_19.99_1m_28.56',
  APP_SUBSCRIPTION_1WEEK_VS_1MONTH_28_56 = 'subscription_nebula_premium_1m_28.56',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_6M_39_99 = 'subscription_one_dollar_7d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_3M_29_99 = 'subscription_one_dollar_7d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_1M_19_99 = 'subscription_one_dollar_7d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_1W_9_99 = 'subscription_one_dollar_7d_trial_premium_1w_9.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_7D_TRIAL_PREMIUM_1W_9_99 = 'subscription_ten_dollar_7d_trial_premium_1w_9.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_7D_TRIAL_PREMIUM_1M_19_99 = 'subscription_ten_dollar_7d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_7D_TRIAL_PREMIUM_3M_29_99 = 'subscription_ten_dollar_7d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_7D_TRIAL_PREMIUM_6M_39_99 = 'subscription_ten_dollar_7d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_9_99_7D_TRIAL_PREMIUM_1M_19_99 = 'subscription_9.99_7d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_19_99_7D_TRIAL_PREMIUM_1M_19_99 = 'subscription_19.99_7d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_9_99_7D_TRIAL_PREMIUM_3M_29_99 = 'subscription_9.99_7d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_19_99_7D_TRIAL_PREMIUM_3M_29_99 = 'subscription_19.99_7d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_9_99_7D_TRIAL_PREMIUM_6M_39_99 = 'subscription_9.99_7d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_19_99_7D_TRIAL_PREMIUM_6M_39_99 = 'subscription_19.99_7d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_PREMIUM_1W_4_99 = 'subscription_premium_1w_4.99',
  APP_SUBSCRIPTION_PREMIUM_1W_11_97 = 'subscription_premium_1w_11.97',
  APP_SUBSCRIPTION_NEBULA_PREMIUM_1W_40 = 'subscription_nebula_premium_1w_40',
  APP_SUBSCRIPTION_NEBULA_PREMIUM_1W_9_99 = 'subscription_nebula_premium_1w_9.99',
  APP_SUBSCRIPTION_NEBULA_PREMIUM_1W_4_99 = 'subscription_nebula_premium_1w_4.99_9.99',
  APP_SUBSCRIPTION_NEBULA_PREMIUM_1W_0 = 'subscription_nebula_premium_1w_0_9.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_3D_TRIAL_PREMIUM_6M_39_99 = 'subscription_one_dollar_3d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_3D_TRIAL_PREMIUM_3M_29_99 = 'subscription_one_dollar_3d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_3D_TRIAL_PREMIUM_1M_19_99 = 'subscription_one_dollar_3d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_3D_TRIAL_PREMIUM_6M_39_99 = 'subscription_ten_dollar_3d_trial_premium_6m_39.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_3D_TRIAL_PREMIUM_3M_29_99 = 'subscription_ten_dollar_3d_trial_premium_3m_29.99',
  APP_SUBSCRIPTION_TEN_DOLLAR_3D_TRIAL_PREMIUM_1M_19_99 = 'subscription_ten_dollar_3d_trial_premium_1m_19.99',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_2W_19 = '1_USD_7d_trial_2w_19',
  APP_SUBSCRIPTION_ONE_EUR_7D_TRIAL_PREMIUM_2W_19 = 'subscription_1_EUR_7d_trial_premium_2w_19',
  APP_SUBSCRIPTION_FIVE_DOLLAR_7D_TRIAL_PREMIUM_2W_19 = '5_USD_7d_trial_2w_19',
  APP_SUBSCRIPTION_FIVE_EUR_7D_TRIAL_PREMIUM_2W_19 = 'subscription_5_EUR_7d_trial_premium_2w_19',
  APP_SUBSCRIPTION_NINE_DOLLAR_7D_TRIAL_PREMIUM_2W_19 = '9_USD_7d_trial_2w_19',
  APP_SUBSCRIPTION_NINE_EUR_7D_TRIAL_PREMIUM_2W_19 = 'subscription_9_EUR_7d_trial_premium_2w_19',
  APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19 = '13.67_USD_7d_trial_2w_19',
  APP_SUBSCRIPTION_THIRTEEN_EUR_7D_TRIAL_PREMIUM_2W_19 = 'subscription_13.67_EUR_7d_trial_premium_2w_19',

  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_1W_19 = '1_dollar_7d_Trial_Premium_1w_19',
  APP_SUBSCRIPTION_ONE_EUR_7D_TRIAL_PREMIUM_1W_19 = '1_EUR_7d_Trial_Premium_1w_19_VAT',
  APP_SUBSCRIPTION_FIVE_DOLLAR_7D_TRIAL_PREMIUM_1W_19 = '5_dollar_7d_Trial_Premium_1w_19',
  APP_SUBSCRIPTION_FIVE_EUR_7D_TRIAL_PREMIUM_1W_19 = '5_EUR_7d_Trial_Premium_1w_19_VAT',
  APP_SUBSCRIPTION_NINE_DOLLAR_7D_TRIAL_PREMIUM_1W_19 = '9_dollar_7d_Trial_Premium_1w_19',
  APP_SUBSCRIPTION_NINE_EUR_7D_TRIAL_PREMIUM_1W_19 = '9_EUR_7d_Trial_Premium_1w_19_VAT',
  APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_1W_19 = '13.67_dollar_7d_Trial_Premium_1w_19',
  APP_SUBSCRIPTION_THIRTEEN_EUR_7D_TRIAL_PREMIUM_1W_19 = '13.67_EUR_7d_Trial_Premium_1w_19_VAT',
  APP_SUBSCRIPTION_USD_7D_TRIAL_PREMIUM_1W_9_99_OFFER = '1_USD_7d_Trial_Premium_1w_9.99_special_offer',
  APP_SUBSCRIPTION_EUR_7D_TRIAL_PREMIUM_1W_9_99_OFFER = '1_EUR_7d_Trial_Premium_1w_9.99_VAT_special_offer',
  APP_SUBSCRIPTION_USD_PREMIUM_1W_29 = 'USD_Premium_1w_29',
  APP_SUBSCRIPTION_EUR_PREMIUM_1W_29 = 'EUR_Premium_1w_29',

  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_1_dollar_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_ONE_EUR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_1_EUR_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_FIVE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_5_dollar_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_FIVE_EUR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_5_EUR_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_NINE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_9_dollar_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_NINE_EUR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_9_EUR_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_13.67_dollar_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_THIRTEEN_EUR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_13.67_EUR_7d_trial_premium_2w_19_email',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_0_USD_7d_Trial_Premium_2w_19_email',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_PREMIUM_2W_19_EMAIL = 'subscription_0_EUR_7d_Trial_Premium_2w_19_email',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_PREMIUM_2W_9_99_EMAIL = 'subscription_0_EUR_7d_Trial_Premium_2w_9.99_email',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_PREMIUM_2W_9_99_EMAIL = 'subscription_0_USD_7d_Trial_Premium_2w_9.99_email',
  APP_SUBSCRIPTION_USD_PREMIUM_2W_29 = 'subscription_USD_Premium_2w_29',
  APP_SUBSCRIPTION_EUR_PREMIUM_2W_29 = 'subscription_EUR_Premium_2w_29',
  APP_SUBSCRIPTION_2_99_USD_UPSELL_10PCS_7D = 'subscription_2.99_USD_upsell_10pcs_7d',
  APP_SUBSCRIPTION_2_99_EUR_UPSELL_10PCS_7D = 'subscription_2.99_EUR_upsell_10pcs_7d',
  APP_SUBSCRIPTION_3_99_USD_UPSELL_3PCS_7D = 'subscription_3.99_USD_upsell_3pcs_7d',
  APP_SUBSCRIPTION_3_99_EUR_UPSELL_3PCS_7D = 'subscription_3.99_EUR_upsell_3pcs_7d',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_PREMIUM_2W_9_99 = '1_USD_7d_trial_2w_9.99',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_PREMIUM_2W_9_99 = 'subscription_1_EUR_7d_Trial_Premium_2w_9.99',
  APP_SUBSCRIPTION_EUR_PREMIUM_2W_29_EMAIL = 'subscription_EUR_Premium_2w_29_email',
  APP_SUBSCRIPTION_USD_PREMIUM_2W_29_EMAIL = 'subscription_USD_Premium_2w_29_email',
  APP_SUBSCRIPTION_19_USD_14D_TRIAL_2W_29 = 'subscription_19_usd_14d_trial_2w_29',
  APP_SUBSCRIPTION_19_EUR_14D_TRIAL_2W_29 = 'subscription_19_eur_14d_trial_2w_29',
  APP_SUBSCRIPTION_ONE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_1_dollar_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_ONE_EUR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_1_EUR_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_FIVE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_5_dollar_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_FIVE_EUR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_5_EUR_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_NINE_DOLLAR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_9_dollar_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_NINE_EUR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_9_EUR_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_THIRTEEN_DOLLAR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_13.67_dollar_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_THIRTEEN_EUR_7D_TRIAL_PREMIUM_2W_19_29 = 'subscription_13.67_EUR_7d_Trial_Premium_2w_19_29',
  APP_SUBSCRIPTION_APPNEBULA_PREMIUM_2W_17 = 'appnebula_premium_2w_17',
  APP_SUBSCRIPTION_APPNEBULA_PREMIUM_2W_17_EUR = 'appnebula_premium_2w_17_EUR',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_PREMIUM_2W_19_VAT = '1_EUR_7d_trial_2w_19_VAT_20',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_PREMIUM_2W_19_VAT = '5_EUR_7d_trial_2w_19_VAT_20',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_PREMIUM_2W_19_VAT = '9_EUR_7d_trial_2w_19_VAT',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_PREMIUM_2W_19_VAT = '13.67_EUR_7d_trial_2w_19_VAT_20',
  APP_SUBSCRIPTION_11_DOLLAR_7D_TRIAL_PREMIUM_2W_19 = '11_dollar_7d_Trial_Premium_2w_19',
  APP_SUBSCRIPTION_16_41_DOLLAR_7D_TRIAL_PREMIUM_2W_19_VAT = '16.41_dollar_7d_Trial_Premium_2w_19',
  APP_SUBSCRIPTION_1_EUR_7d_Trial_Premium_2w_9_99_VAT = '1_EUR_7d_trial_2w_9.99_VAT_20',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_PREMIUM_2W_19_HIDDEN = '0_USD_7d_Trial_Premium_2w_19_hidden',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_PREMIUM_2W_22_8_VAT_HIDDEN = '0_EUR_7d_Trial_Premium_2w_22.8_VAT_hidden',

  APP_SUBSCRIPTION_1_DOLLAR_7D_TRIAL_PREMIUM_2W_29 = '1_USD_7d_trial_2w_29',
  APP_SUBSCRIPTION_5_DOLLAR_7D_TRIAL_PREMIUM_2W_29 = '5_USD_7d_trial_2w_29',
  APP_SUBSCRIPTION_9_DOLLAR_7D_TRIAL_PREMIUM_2W_29 = '9_USD_7d_trial_2w_29',
  APP_SUBSCRIPTION_13_67_DOLLAR_7D_TRIAL_PREMIUM_2W_29 = '13.67_USD_7d_trial_2w_29',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_PREMIUM_2W_29_VAT = '1_EUR_7d_trial_2w_29_VAT_20',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_PREMIUM_2W_29_VAT = '5_EUR_7d_trial_2w_29_VAT_20',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_PREMIUM_2W_29_VAT = '9_EUR_7d_trial_2w_29_VAT_20',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_PREMIUM_2W_29_VAT = '13.67_EUR_7d_trial_2w_29_VAT_20',

  APP_SUBSCRIPTION_0_50_USD_NATAL_CHART_TRIAL_1W_1M_29_90 = '0_50_USD_natal-chart_trial_1w_1m_29_90',
  APP_SUBSCRIPTION_0_50_EUR_NATAL_CHART_TRIAL_1W_1M_29_90 = '0_50_EUR_natal-chart_trial_1w_1m_29_90',
  APP_SUBSCRIPTION_29_90_USD_NATAL_CHART_1M_29_90_TIMER = '29_90_USD_natal-chart_1m_29_90_timer',
  APP_SUBSCRIPTION_29_90_EUR_NATAL_CHART_1M_29_90_TIMER = '29_90_EUR_natal-chart_1m_29_90_timer',

  APP_SUBSCRIPTION_6_93_USD_intro_offer_1w_4w_39_99 = '6_93_USD_intro-offer_1w_4w_39_99',
  APP_SUBSCRIPTION_15_19_USD_intro_offer_4w_4w_39_99 = '15_19_USD_intro-offer_4w_4w_39_99',
  APP_SUBSCRIPTION_25_99_USD_intro_offer_12w_12w_69_99 = '25_99_USD_intro-offer_12w_12w_69_99',
  APP_SUBSCRIPTION_13_50_USD_intro_offer_1w_4w_39_99_timer = '13_50_USD_intro-offer_1w_4w_39_99_timer',
  APP_SUBSCRIPTION_39_99_USD_intro_offer_4w_4w_39_99_timer = '39_99_USD_intro-offer_4w_4w_39_99_timer',
  APP_SUBSCRIPTION_69_99_USD_intro_offer_12w_12w_69_99_timer = '69_99_USD_intro-offer_12w_12w_69_99_timer',
  APP_SUBSCRIPTION_1_USD_intro_offer_1w_4w_23_99_secret_discount = '1_USD_intro-offer_1w_4w_23_99_secret_discount',
  APP_SUBSCRIPTION_6_93_EUR_intro_offer_1w_4w_39_99 = '6_93_EUR_intro-offer_1w_4w_39_99',
  APP_SUBSCRIPTION_15_19_EUR_intro_offer_4w_4w_39_99 = '15_19_EUR_intro-offer_4w_4w_39_99',
  APP_SUBSCRIPTION_25_99_EUR_intro_offer_12w_12w_69_99 = '25_99_EUR_intro-offer_12w_12w_69_99',
  APP_SUBSCRIPTION_13_50_EUR_intro_offer_1w_4w_39_99_timer = '13_50_EUR_intro-offer_1w_4w_39_99_timer',
  APP_SUBSCRIPTION_39_99_EUR_intro_offer_4w_4w_39_99_timer = '39_99_EUR_intro-offer_4w_4w_39_99_timer',
  APP_SUBSCRIPTION_69_99_EUR_intro_offer_12w_12w_69_99_timer = '69_99_EUR_intro-offer_12w_12w_69_99_timer',
  APP_SUBSCRIPTION_1_EUR_intro_offer_1w_4w_23_99_secret_discount = '1_EUR_intro-offer_1w_4w_23_99_secret_discount',

  // USA plans (trial)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_45 = '1_USD_7d_trial_30d_45',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_45_0_INTRO_CREDITS = '1_USD_7d_trial_30d_45_0_intro_credits',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_45 = '5_USD_7d_trial_30d_45',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_45_0_INTRO_CREDITS = '5_USD_7d_trial_30d_45_0_intro_credits',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45 = '9_USD_7d_trial_30d_45',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_45_0_INTRO_CREDITS = '9_USD_7d_trial_30d_45_0_intro_credits',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45 = '13_67_USD_7d_trial_30d_45',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_45_0_INTRO_CREDITS = '13_67_USD_7d_trial_30d_45_0_intro_credits',

  // USA plans (timer)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_65 = '1_USD_7d_trial_30d_65',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_65_0_INTRO_CREDITS = '1_USD_7d_trial_30d_65_0_intro_credits',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_65 = '5_USD_7d_trial_30d_65',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_65_0_INTRO_CREDITS = '5_USD_7d_trial_30d_65_0_intro_credits',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65 = '9_USD_7d_trial_30d_65',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_65_0_INTRO_CREDITS = '9_USD_7d_trial_30d_65_0_intro_credits',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65 = '13_67_USD_7d_trial_30d_65',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_65_0_INTRO_CREDITS = '13_67_USD_7d_trial_30d_65_0_intro_credits',

  // USA plans (secret-discount)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT = '1_USD_7d_trial_30d_19_secret_discount',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT_0_INTRO_CREDITS = '1_USD_7d_trial_30d_19_secret_discount_0_intro_credits',

  // Canada, Australia ... plans (trial)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_39 = '1_USD_7d_trial_30d_39',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_39_0_INTRO_CREDITS = '1_USD_7d_trial_30d_39_0_intro_credits',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_39 = '5_USD_7d_trial_30d_39',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_39_0_INTRO_CREDITS = '5_USD_7d_trial_30d_39_0_intro_credits',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_39 = '9_USD_7d_trial_30d_39',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_39 = '13_67_USD_7d_trial_30d_39',

  // Canada, Australia ... plans (timer)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_55 = '1_USD_7d_trial_30d_55',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_55_0_INTRO_CREDITS = '1_USD_7d_trial_30d_55_0_intro_credits',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_55 = '5_USD_7d_trial_30d_55',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_55_0_INTRO_CREDITS = '5_USD_7d_trial_30d_55_0_intro_credits',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_55 = '9_USD_7d_trial_30d_55',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_55 = '13_67_USD_7d_trial_30d_55',

  // Canada, Australia ... plans (secret-discount)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT_TIER_1 = '1_USD_7d_trial_30d_19_secret_discount_tier_1',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_19_SECRET_DISCOUNT_TIER_1_0_INTRO_CREDITS = '1_USD_7d_trial_30d_19_secret_discount_tier_1_0_intro_credits',

  // Europe + GB (trial)
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_39 = '1_EUR_7d_trial_30d_39',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_39_0_INTRO_CREDITS = '1_EUR_7d_trial_30d_39_0_intro_credits',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_39 = '5_EUR_7d_trial_30d_39',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_39_0_INTRO_CREDITS = '5_EUR_7d_trial_30d_39_0_intro_credits',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_39 = '9_EUR_7d_trial_30d_39',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_39 = '13_67_EUR_7d_trial_30d_39',

  // Europe + GB (trial) - experiment
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_45 = '9_EUR_7d_trial_30d_45',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_45_0_INTRO_CREDITS = '9_EUR_7d_trial_30d_45_0_intro_credits',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_45 = '13_67_EUR_7d_trial_30d_45',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_45_0_INTRO_CREDITS = '13_67_EUR_7d_trial_30d_45_0_intro_credits',

  // Europe + GB (timer) - experiment
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_65 = '9_EUR_7d_trial_30d_65',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_65_0_INTRO_CREDITS = '9_EUR_7d_trial_30d_65_0_intro_credits',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_65 = '13_67_EUR_7d_trial_30d_65',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_65_0_INTRO_CREDITS = '13_67_EUR_7d_trial_30d_65_0_intro_credits',

  // Europe + GB (timer)
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_55 = '1_EUR_7d_trial_30d_55',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_55_0_INTRO_CREDITS = '1_EUR_7d_trial_30d_55_0_intro_credits',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_55 = '5_EUR_7d_trial_30d_55',
  APP_SUBSCRIPTION_5_EUR_7D_TRIAL_30D_55_0_INTRO_CREDITS = '5_EUR_7d_trial_30d_55_0_intro_credits',
  APP_SUBSCRIPTION_9_EUR_7D_TRIAL_30D_55 = '9_EUR_7d_trial_30d_55',
  APP_SUBSCRIPTION_13_67_EUR_7D_TRIAL_30D_55 = '13_67_EUR_7d_trial_30d_55',

  // Europe + GB (secret-discount)
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_19_SECRET_DISCOUNT = '1_EUR_7d_trial_30d_19_secret_discount',
  APP_SUBSCRIPTION_1_EUR_7D_TRIAL_30D_19_SECRET_DISCOUNT_0_INTRO_CREDITS = '1_EUR_7d_trial_30d_19_secret_discount_0_intro_credits',

  // Other countries plans (trial)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_29 = '1_USD_7d_trial_30d_29',
  APP_SUBSCRIPTION_5_USD_7D_TRIAL_30D_29 = '5_USD_7d_trial_30d_29',
  APP_SUBSCRIPTION_9_USD_7D_TRIAL_30D_29 = '9_USD_7d_trial_30d_29',
  APP_SUBSCRIPTION_13_67_USD_7D_TRIAL_30D_29 = '13_67_USD_7d_trial_30d_29',

  // Other countries plans (secret-discount)
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_9_SECRET_DISCOUNT = '1_USD_7d_trial_30d_9_secret_discount',
  APP_SUBSCRIPTION_1_USD_7D_TRIAL_30D_9_SECRET_DISCOUNT_0_INTRO_CREDITS = '1_USD_7d_trial_30d_9_secret_discount_0_intro_credits',

  // Email marketing
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_30D_39_EMAIL = '0_USD_7d_trial_30d_39_email',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_30D_39_EMAIL_0_INTRO_OFFER = '0_USD_7d_trial_30d_39_email_0_intro_credits',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_30D_39_EMAIL_0_INTRO_OFFER = '0_EUR_7d_trial_30d_39_email_0_intro_credits',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_30D_39_EMAIL_PALM = '0_USD_7d_trial_30d_39_email_palm',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_30D_39_EMAIL_WITCH = '0_USD_7d_trial_30d_39_email_witch',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_30D_39_EMAIL = '0_EUR_7d_trial_30d_39_email',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_30D_39_EMAIL_PALM = '0_EUR_7d_trial_30d_39_email_palm',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_30D_39_EMAIL_WITCH = '0_EUR_7d_trial_30d_39_email_witch',
  APP_SUBSCRIPTION_0_USD_7D_TRIAL_30D_55_EMAIL = '0_USD_7d_trial_30d_55_email',
  APP_SUBSCRIPTION_0_EUR_7D_TRIAL_30D_55_EMAIL = '0_EUR_7d_trial_30d_55_email',
  APP_SUBSCRIPTION_0_USD_NATAL_CHART_TRIAL_1W_1M_29_90_EMAIL = '0_USD_natal-chart_trial_1w_1m_29_90_email',
  APP_SUBSCRIPTION_0_EUR_NATAL_CHART_TRIAL_1W_1M_29_90_EMAIL = '0_EUR_natal-chart_trial_1w_1m_29_90_email',

  // Natal Chart Upsells
  NATAL_CHART_READING_EUR_39_99_UPSELL = 'natal_chart_reading_EUR_39_99_upsell',
  NATAL_CHART_READING_USD_39_99_UPSELL = 'natal_chart_reading_USD_39_99_upsell',

  // Black Moon Upsells
  BLACK_MOON_REPORT_EUR_14_90_UPSELL = 'black_moon_report_EUR_14_90_upsell',
  BLACK_MOON_REPORT_USD_14_90_UPSELL = 'black_moon_report_USD_14_90_upsell',
}
