import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  cancelUserSubscriptionFailed,
  cancelUserSubscriptionPending,
  cancelUserSubscriptionSucceed,
  fetchUserSubscriptionsFailed,
  fetchUserSubscriptionsPending,
  fetchUserSubscriptionsSucceed,
} from './actions';
import { userSubscriptionsInitialState } from './state';

export const userSubscriptionsReducer = createReducer(userSubscriptionsInitialState, (builder) => {
  builder
    .addCase(fetchUserSubscriptionsPending, () => ({
      ...userSubscriptionsInitialState,
      loadingStatus: LoadingStatuses.PENDING,
    }))
    .addCase(fetchUserSubscriptionsSucceed, (state, { payload }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;
      state.entities = payload.reduce(
        (acc, subscription) => ({ ...acc, [subscription.id]: subscription }),
        {},
      );
    })
    .addCase(fetchUserSubscriptionsFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(cancelUserSubscriptionPending, (state) => {
      state.cancelLoadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(cancelUserSubscriptionSucceed, (state, { payload }) => {
      if (state.entities[payload]) {
        state.entities[payload].status = 'cancelled';
      }

      state.cancelLoadingStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(cancelUserSubscriptionFailed, (state) => {
      state.cancelLoadingStatus = LoadingStatuses.FAILED;
    });
});
