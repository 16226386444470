import { GrowthBook } from '@growthbook/growthbook';
import { Config } from 'core/common/contexts';
import { JSONValue } from 'core/common/entities';
import { normalizeError } from 'core/common/errors';
import { AppEnvironment } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import {
  ExperimentDescription,
  ExperimentRawResult,
  FeatureDefinition,
  FeatureFlagsAttributes,
  FeatureFlagsConfig,
  FeatureRawResult,
  FeatureValue,
} from '../entities';
import { FeatureFlags } from './FeatureFlags';

export class GrowthBookFeatureFlags implements FeatureFlags {
  private growthBookInstance: GrowthBook;

  private appEnvironment: AppEnvironment;

  private appConfig: Config;

  private logger: Logger;

  private trackExperimentListeners: Array<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (experiment: ExperimentDescription<any>, result: ExperimentRawResult<any>) => void
  > = [];

  private featuresUpdateListeners: Array<(features: Record<string, FeatureDefinition>) => void> =
    [];

  private featuresLoadingFailedListeners: Array<(loadingError: Error) => void> = [];

  private featuresLoadedListeners: Array<(features: Record<string, FeatureDefinition>) => void> =
    [];

  constructor(
    passedConfig: FeatureFlagsConfig = {},
    appEnvironment: AppEnvironment,
    appConfig: Config,
    logger: Logger,
  ) {
    const notifyTrackExperimentCallback = this.notifyTrackExperimentListeners.bind(this);

    this.appConfig = appConfig;
    this.logger = logger;
    this.appEnvironment = appEnvironment;

    const config = {
      ...passedConfig,
      apiHost: this.appConfig.growthbook.apiHost,
      clientKey: this.appConfig.growthbook.clientKey,
      // Skip all experiments
      qaMode: this.appEnvironment.isTestingEnv(),
    };

    this.growthBookInstance = new GrowthBook({
      ...config,
      trackingCallback: notifyTrackExperimentCallback,
    });
  }

  async loadFeatures(): Promise<{ [x: string]: FeatureDefinition<JSONValue> } | undefined> {
    try {
      const defaultFeatures = this.getFeatures();

      await this.growthBookInstance.loadFeatures();

      const loadedFeatures = this.getFeatures();

      const features = {
        ...defaultFeatures,
        ...loadedFeatures,
      };

      this.setFeatures(features);
      this.notifyFeaturesLoadedListeners(features);

      return features;
    } catch (e) {
      const error = normalizeError(e);
      this.logger.error(error);

      this.notifyFeaturesLoadingFailedListeners(error);
    }
  }

  setFeatures(features: Record<string, FeatureDefinition>) {
    this.growthBookInstance.setFeatures(features);
    this.notifyFeaturesUpdateListeners(features);
  }

  private notifyFeaturesUpdateListeners(features: Record<string, FeatureDefinition>) {
    this.featuresUpdateListeners.forEach((listener) => {
      try {
        listener(features);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyFeaturesLoadingFailedListeners(loadingError: Error) {
    this.featuresLoadingFailedListeners.forEach((listener) => {
      try {
        listener(loadingError);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  private notifyFeaturesLoadedListeners(features: Record<string, FeatureDefinition>) {
    this.featuresLoadedListeners.forEach((listener) => {
      try {
        listener(features);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }

  getFeatures(): FeatureFlagsConfig['features'] {
    return this.growthBookInstance.getFeatures() as FeatureFlagsConfig['features'];
  }

  getSplitId(): string {
    const attributes = this.growthBookInstance.getAttributes();

    const splitId = attributes.id;

    return splitId;
  }

  setForcedFeatureValues(featureValues: Record<string, unknown>) {
    const map = new Map(Object.entries(featureValues));
    this.growthBookInstance.setForcedFeatures(map);
  }

  getAttributes(): FeatureFlagsAttributes {
    return this.growthBookInstance.getAttributes();
  }

  setAttributes(attributes: FeatureFlagsAttributes): void {
    const currentAttributes = this.getAttributes();
    const newAttributes = {
      ...currentAttributes,
      ...attributes,
    };
    this.growthBookInstance.setAttributes(newAttributes);
  }

  setIdentifier(identifier: string) {
    this.setAttributes({
      id: identifier,
    });
  }

  setStateUpdateHandler(callback: () => void) {
    this.growthBookInstance.setRenderer(callback);
  }

  isFeatureOn(featureName: string) {
    return this.growthBookInstance.isOn(featureName);
  }

  isFeatureOff(featureName: string) {
    return this.growthBookInstance.isOff(featureName);
  }

  getFeatureValue<T extends FeatureValue = FeatureValue>(featureName: string, defaultValue: T): T {
    return this.growthBookInstance.getFeatureValue<T>(featureName, defaultValue) as T;
  }

  getFeatureRawResult<T extends FeatureValue>(featureName: string): FeatureRawResult<T | null> {
    return this.growthBookInstance.evalFeature(featureName);
  }

  getExperimentGroup<T extends FeatureValue = string>(experimentName: string): T | null {
    const result = this.getFeatureRawResult<T>(experimentName);

    if (!result || !result.value) {
      this.logger.warn('Experiment not found', {
        data: experimentName,
      });
      return null;
    }

    return result.value;
  }

  onTrackExperiment<T extends FeatureValue = FeatureValue>(
    callback: (experiment: ExperimentDescription<T>, result: ExperimentRawResult<T>) => void,
  ) {
    this.trackExperimentListeners.push(callback);
  }

  onFeaturesUpdated(callback: (features: Record<string, FeatureDefinition>) => void) {
    this.featuresUpdateListeners.push(callback);
  }

  onFeaturesLoaded(callback: (features: Record<string, FeatureDefinition>) => void) {
    this.featuresLoadedListeners.push(callback);
  }

  onFeaturesLoadingFailed(error: (error: Error) => void) {
    this.featuresLoadingFailedListeners.push(error);
  }

  private notifyTrackExperimentListeners(
    experiment: ExperimentDescription,
    result: ExperimentRawResult,
  ) {
    const features = this.getFeatures();

    const preventTrack =
      !!features && features[experiment.key] && features[experiment.key].shouldNotTrack;

    if (preventTrack) return;

    this.trackExperimentListeners.forEach((listener) => {
      try {
        listener(experiment, result);
      } catch (err) {
        const error = normalizeError(err);
        this.logger.error(error);
      }
    });
  }
}
