import { FormikHelpers } from 'formik';
import { GetServerSidePropsContext } from 'next';
import validator from 'core/common/utils/validator';
import { Languages } from 'core/localization/entities';

export type PlaceListData = {
  geometry: {
    coordinates: number[];
  };
  properties: {
    id: string;
    country: string;
    name: string;
    region: string;
  };
};

export type EmailFormHelpers = FormikHelpers<{
  email: string;
}>;

export const LoaderContentDataSchema = validator.object({
  start: validator.string(),
  middle: validator.string(),
  finish: validator.string(),
  question: validator.string(),
  popupName: validator.enum(['alone', 'adventurous', 'remedies']),
});

export type LoaderContentData = validator.infer<typeof LoaderContentDataSchema>;

export type StaticProps = {
  params: {
    id?: string;
    funnel: string;
  };
  locale: Languages;
};

export type ServerSideProps = {
  params: {
    id?: string;
    funnel: string;
  };
  locale: Languages;
  res: GetServerSidePropsContext['res'];
};

export type DraftFlowSSRProps = {
  params: {
    options: [string, string, string];
  };
  locale: Languages;
};

export type StaticPathProps = {
  locales: Languages[];
  defaultLanguage: Languages;
};

export const FunnelImageSchema = validator.object({
  src: validator.string(),
  alt: validator.string(),
});

export type FunnelImage = validator.infer<typeof FunnelImageSchema>;

export const TextWithEmojiSchema = validator.object({
  text: validator.string(),
  emoji: validator.string(),
});

export type TextWithEmoji = validator.infer<typeof TextWithEmojiSchema>;

export const UnlimitedReadingsSlideImagesSchema = validator.array(
  FunnelImageSchema,
  FunnelImageSchema,
);

export const PalmGuideItemSchema = validator.object({
  image: FunnelImageSchema,
  description: validator.string(),
  line: validator.string().optional(),
});

export type PalmistryDiscountPlan = {
  productId: string;
  displayPrice: string;
  discountPercent: number;
  oldPrice: number;
};

export type PalmistryUpsell = {
  price: string;
  oldPriceDescription: string;
  oldPrice: string;
  discount: string;
  title: string;
  emoji: string;
  value: string;
  order: number;
};

export type PalmistryPlan = {
  oldPrice: string;
  discount: string;
  value: string;
};

export const FunnelReviewSchema = validator.object({
  id: validator.number(),
  nickname: validator.string(),
  date: validator.string(),
  text: validator.string(),
  initials: validator.string(),
  percent: validator.number().optional(),
});

export const PalmistryDiscountPlanSchema = validator.object({
  productId: validator.string(),
  displayPrice: validator.string(),
  discountPercent: validator.number(),
  oldPrice: validator.number(),
});

export type FunnelReview = validator.infer<typeof FunnelReviewSchema>;

export const DeckSliderCardSchema = validator.object({
  image: FunnelImageSchema.optional(),
  title: validator.string(),
  description: validator.string(),
  position: validator.number(),
  progressBarOptions: validator
    .array(
      validator.object({
        icon: validator.string(),
        label: validator.string(),
        blurred: validator.boolean().optional(),
        percantageRange: validator.object({
          min: validator.number(),
          max: validator.number(),
        }),
      }),
    )
    .optional(),
});

export type DeckSliderCard = validator.infer<typeof DeckSliderCardSchema>;

export enum ProgressBarVariants {
  DEFAULT = 'default',
  NAVIGATION = 'navigation',
  STEPPER = 'stepper',
}

const ProgressBarDefaultSchema = validator.object({
  variant: validator.literal(ProgressBarVariants.DEFAULT),
  totalSteps: validator.number(),
});

const ProgressBarDefaultNavSchema = validator.object({
  variant: validator.literal(ProgressBarVariants.NAVIGATION),
  title: validator.string(),
  totalSteps: validator.number(),
});

const ProgressBarStepperSchema = validator.object({
  variant: validator.literal(ProgressBarVariants.STEPPER),
  title: validator.string(),
  totalSteps: validator.number(),
  progressBarName: validator.enum(['profile', 'personality', 'relationship']),
  totalProfileSteps: validator.number(),
  totalPersonalitySteps: validator.number(),
  totalRelationshipSteps: validator.number(),
});

export const ProgressBarSchema = validator.union([
  ProgressBarDefaultSchema,
  ProgressBarDefaultNavSchema,
  ProgressBarStepperSchema,
]);

export type ProgressBarUnion = validator.infer<typeof ProgressBarSchema>;

export enum FunnelStatus {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
}

export enum FlowStatus {
  ACTIVE = 'active',
  NOT_ACTIVE = 'not_active',
}

export type FunnelRequestFilters = {
  funnelName: string;
  funnelStatus: FunnelStatus;
  language: Languages;
  flowName: string;
  flowStatus: FlowStatus;
};
