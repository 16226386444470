import { BaseService } from 'core/common/services';
import { AuthData } from '../entities';
import { AuthDtoMapper } from '../mappers';
import { AuthDataDto } from './dto';

export class AuthService extends BaseService {
  async signInByToken(token: string): Promise<string> {
    // TODO: remove "any" after the back end fixes
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data = (await this.httpClient.post(`/auth/authenticate-by-token/${token}`)) as any;

    return data.customer.properties.id;
  }

  async getAskNebulaAuthenticationToken(): Promise<AuthData> {
    const { data } = await this.httpClient.get<AuthDataDto>('/user/asknebula');

    const dtoMapper = new AuthDtoMapper();

    return dtoMapper.toEntity(data);
  }
}
