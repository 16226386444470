import { BaseService } from 'core/common/services';
import { Expert } from 'core/experts/entities';

export class ExpertService extends BaseService {
  async getRandomExpert(): Promise<Expert> {
    const { data } = await this.httpClient.get('/experts/random');

    return data;
  }
}
