import { Locales, Regions, UserLocation } from 'core/user/entities';
import { LocationService } from './LocationService';

export class LocationServerService implements LocationService {
  async getLocation(): Promise<UserLocation> {
    return {
      country: '',
      locale: Locales.US,
      region: Regions.OTHER,
    };
  }
}
