import { createContext, ReactNode } from 'react';
import { createTheme } from '@mui/material/styles';
import { StylesProvider, ThemeProvider as MuiProvider } from '@mui/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeTypes } from '../entities';
import { getTheme } from '../utils/getTheme';

export const ThemeContext = createContext<ThemeTypes>(ThemeTypes.DEFAULT);

export type ThemeProviderProps = {
  children?: ReactNode;
  themeType?: ThemeTypes;
};

const ThemeProvider = ({ children, themeType = ThemeTypes.DEFAULT }: ThemeProviderProps) => {
  const theme = getTheme(themeType);
  const themeMUI = createTheme(theme);

  return (
    <ThemeContext.Provider value={themeType}>
      <StylesProvider injectFirst>
        <MuiProvider theme={themeMUI}>
          <StyledThemeProvider theme={themeMUI}>{children}</StyledThemeProvider>
        </MuiProvider>
      </StylesProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
