import { Refinement, RefinementContext, ValidatorIssues } from 'core/common/entities';

export const createStringifiedIntegerRefinement =
  (propertyName: string): Refinement =>
  (value: string, context: RefinementContext) => {
    const number = Number(value);

    if (Number.isNaN(number) || value.length === 0) {
      context.addIssue({
        code: ValidatorIssues.custom,
        message: `'${propertyName}' must only contain digits`,
      });
    }

    if (!Number.isFinite(number) || number <= 0) {
      context.addIssue({
        code: ValidatorIssues.custom,
        message: `Not a valid '${propertyName}'`,
      });
    }
  };
