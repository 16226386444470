import { normalizeError } from 'core/common/errors';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { CookiesService } from 'core/common/services';
import { TikTokEvent, TiktokParams, TikTokPaymentData } from '../../entities';
import { TikTokService } from '../../interfaces';

export class HttpTikTokService implements TikTokService {
  constructor(
    private readonly cookiesService: CookiesService,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {}

  private get ttq() {
    return window.ttq;
  }

  funnelOpened() {
    return this.track('ViewContent');
  }

  addBirthdate() {
    return this.track('SubmitForm');
  }

  addEmail(email: string, userId: string) {
    return this.track('CompleteRegistration', { email, event_id: userId });
  }

  addToCard({ email, orderId, productId, price, currency }: TikTokPaymentData) {
    return this.track('AddToCart', {
      email,
      value: price,
      event_id: orderId,
      currency,
      product_id: productId,
    });
  }

  initiateCheckout({ email, orderId, productId, price, currency }: TikTokPaymentData) {
    return this.track('InitiateCheckout', {
      email,
      value: price,
      event_id: orderId,
      currency,
      product_id: productId,
    });
  }

  paymentSuccess({ email, orderId, productId, price, currency, ltvPredict }: TikTokPaymentData) {
    return this.track('CompletePayment', {
      email,
      price,
      value: ltvPredict,
      event_id: orderId,
      currency,
      product_id: productId,
    });
  }

  track(eventName: TikTokEvent, params?: TiktokParams) {
    if (!this.ttq) {
      return;
    }

    const ttp = this.cookiesService.get('_ttp'); // Cookie id
    const ttclid = this.cookiesService.get('ttclid'); // TikTok Click ID

    this.matchUserByEmail(params);

    const span = this.observabilitySystem.startSpan('track_analytics_tik_tok', {
      attributes: { eventName, ...toSpanAttributes(params || {}) },
    });

    try {
      span.addEvent('Pending');
      this.ttq.track(eventName, { ttp, ttclid, ...params });

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }

  // Advanced Matching
  // https://business-api.tiktok.com/portal/docs?rid=5ipocbxyw8v&id=1701890972946433
  private matchUserByEmail(params?: TiktokParams) {
    if (!this.ttq) {
      return;
    }

    if (params?.email && typeof params.email === 'string') {
      const email = params.email.trim().toLowerCase();

      this.ttq.identify({ email });
    }
  }
}
