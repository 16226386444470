import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { getSystemLanguage, AppThunk } from 'core/common/store';
import { FunnelConfig } from 'core/funnel/entities';

export const updateConfig = createAction<FunnelConfig>('funnelConfig/UPDATE');

export const loadFunnelConfigPending = createAction('funnelConfig/LOAD_CONFIG/pending');
export const loadFunnelConfigSucceed = createAction<FunnelConfig | null>(
  'funnelConfig/LOAD_CONFIG/succeed',
);
export const loadFunnelConfigFailed = createAction('funnelConfig/LOAD_CONFIG/failed');

export const loadFunnelConfig =
  (funnelId: string): AppThunk =>
  async (dispatch, getState, context) => {
    const { loggingService, funnelService } = context;

    const language = getSystemLanguage(getState());

    try {
      dispatch(loadFunnelConfigPending());

      const funnels = await funnelService.getFunnels({ funnelName: funnelId, language });

      if (!funnels) {
        dispatch(loadFunnelConfigFailed());
        return;
      }

      const currentFunnel = funnels[0];

      dispatch(loadFunnelConfigSucceed(currentFunnel));
    } catch (e) {
      const err = normalizeError(e);
      loggingService.error(err);

      dispatch(loadFunnelConfigFailed());
    }
  };
