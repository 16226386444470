import { useCallback } from 'react';
import { useDispatch } from 'core/common/hooks';
import { syncUtmTags } from '../store';

export function useUtmTags() {
  const dispatch = useDispatch();

  const doSyncUtmTags = useCallback(
    (tags?: Record<string, string>) => {
      dispatch(syncUtmTags(tags));
    },
    [dispatch],
  );

  return {
    syncUtmTags: doSyncUtmTags,
  };
}
