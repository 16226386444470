import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { LtvPredictService } from '../../interfaces';
import { LtvPredictServiceDecorator } from './LtvPredictServiceDecorator';

export class LtvPredictServiceWithObservability extends LtvPredictServiceDecorator {
  constructor(
    ltvPredictService: LtvPredictService,
    private readonly observabilitySystem: ObservabilitySystem,
  ) {
    super(ltvPredictService);
  }

  async getLTVPredict(productName: string) {
    const span = this.observabilitySystem.startSpan('get_ltv_predict', {
      attributes: {
        productName,
      },
    });

    try {
      span.addEvent('Pending');
      const result = await super.getLTVPredict(productName);

      span.addEvent('Succeed');
      span.end();
      return result;
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();

      throw err;
    }
  }
}
