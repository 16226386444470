import { AttributeValue } from '@opentelemetry/api';
import { serializeError } from 'core/common/errors';
import { isPrimitive } from 'core/common/utils/isPrimitive';
import { isUndefined } from 'core/common/utils/isUndefined';

export type { Span, SpanOptions } from '@opentelemetry/api';

export { SpanStatusCode } from '@opentelemetry/api';

export type SpanAttribute = AttributeValue;

export type SpanAttributes = Record<string, SpanAttribute>;

export function toSpanAttributes(data: Record<string, unknown>): SpanAttributes {
  const spanAttributes: SpanAttributes = {};

  Object.entries(data).forEach(([key, value]) => {
    if (isUndefined(value)) return;

    if (isPrimitive(value)) {
      spanAttributes[key] = value as SpanAttribute;
      return;
    }

    if (value instanceof Error) {
      const serializedError = serializeError(value);

      spanAttributes[key] = JSON.stringify(serializedError);
      return;
    }

    if (Array.isArray(value)) {
      spanAttributes[key] = value.map((arrValue) =>
        isPrimitive(arrValue) ? arrValue : JSON.stringify(arrValue),
      ) as SpanAttribute;
      return;
    }

    spanAttributes[key] = JSON.stringify(value);
  });

  return spanAttributes;
}
