import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { getOrderData } from 'core/user/store';
import { PaymentError } from '../../entities';
import { ResignError } from '../../errors';
import { initializeResignFormRequest } from './resignForm';

export const recurringPaymentPending = createAction('payments/RECURRING_PAYMENT/pending');

export const recurringPaymentSucceed = createAction('payments/RECURRING_PAYMENT/succeed');

export const recurringPaymentFailed = createAction<PaymentError>(
  'payments/RECURRING_PAYMENT/failed',
);

export const recurringPaymentRequest =
  (offerId: string): AppThunk =>
  async (dispatch, getState, context) => {
    const {
      purchaseService,
      analyticsService,
      loggingService: logger,
      observabilitySystem,
    } = context;

    const orderData = getOrderData(getState());

    dispatch(recurringPaymentPending());

    const span = observabilitySystem.startSpan('recurring_payment_request');
    span.addEvent('Pending');

    try {
      await purchaseService.purchaseReport(offerId, orderData);

      analyticsService.purchaseSucceed(offerId);
      dispatch(recurringPaymentSucceed());

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      analyticsService.purchaseError(offerId);
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();

      logger.error(error);

      if (err instanceof ResignError) {
        dispatch(initializeResignFormRequest(offerId, err.tokenId));
        return;
      }

      dispatch(recurringPaymentFailed(error));
      analyticsService.trackEvent('recurring_payment_failed');
    }
  };
