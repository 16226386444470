import { BaseService } from 'core/common/services';
import { BackendAnalyticsService } from '../../interfaces';

export class HttpBackendAnalyticsService extends BaseService implements BackendAnalyticsService {
  async setSplitExperimentProperties(props: { experimentName: string; groupName: string }) {
    const { experimentName, groupName } = props;

    await this.httpClient.post('/user/split/group', {
      data: {
        test_name: experimentName,
        group_name: groupName,
      },
    });
  }
}
