import { createReducer } from '@reduxjs/toolkit';
import { PopUpTypes } from '../entities';
import { closePopUp, openCameraAccessAlertPopUp, openScanProcessPopUp } from './actions';
import { popUpsInitialState } from './state';

export const popUpsReducer = createReducer(popUpsInitialState, (builder) => {
  builder
    .addCase(closePopUp, (state) => {
      state.type = PopUpTypes.NONE;
    })
    .addCase(openCameraAccessAlertPopUp, (state) => {
      state.type = PopUpTypes.CAMERA_ACCESS_ALERT;
    })
    .addCase(openScanProcessPopUp, (state) => {
      state.type = PopUpTypes.SCAN_PROCESS;
    });
});
