import { CacheService } from 'core/cache/services/CacheService';
import { normalizeApiError } from 'core/common/errors';
import { Logger } from 'core/common/services/logger/interfaces';
import { FunnelConfig, FunnelRequestFilters } from '../../entities';
import { FunnelService } from '../../interfaces';
import { FunnelServiceDecorator } from './FunnelServiceDecorator';

export class FunnelServiceWithCaching extends FunnelServiceDecorator {
  private readonly cachingProvider: CacheService;
  private readonly logger: Logger;

  constructor(cachingProvider: CacheService, funnelService: FunnelService, logger: Logger) {
    super(funnelService);
    this.logger = logger;
    this.cachingProvider = cachingProvider;
  }

  async getFunnels(params: FunnelRequestFilters) {
    const cacheKey = `funnels-${params.funnelName}-${params.language}`;

    const foundInCache = await this.cachingProvider.has(cacheKey);

    if (foundInCache) {
      try {
        return await this.cachingProvider.get<Array<FunnelConfig> | null>(cacheKey);
      } catch (err) {
        const error = normalizeApiError(err);
        this.logger.error(`get cache error - ${error}`);
      }
    }

    const funnels = await super.getFunnels(params);

    await this.cachingProvider.put(funnels, cacheKey);

    return funnels;
  }
}
