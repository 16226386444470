import { removePrimitiveDuplicatesFromArray } from 'core/common/utils/removePrimitiveDuplicatesFromArray';
import { FunnelAnalyticsType } from 'core/funnel/entities';

export const getAnalyticsIds = (
  analyticsGuard: (funnelAnalytics: FunnelAnalyticsType[number]) => boolean,
  funnelAnalytics?: FunnelAnalyticsType,
): Array<string> => {
  if (!funnelAnalytics || !Array.isArray(funnelAnalytics)) return [];

  const analyticsObject = funnelAnalytics.find(analyticsGuard);

  if (!analyticsObject) return [];

  const idsWithoutCondition = analyticsObject.attributes
    .filter((attribute) => !attribute.condition)
    .map((attribute) => attribute.id);

  return removePrimitiveDuplicatesFromArray([...idsWithoutCondition]);
};
