import { normalizeError } from 'core/common/errors';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store';
import { updateUserInformation } from 'core/user/store/actions';

export const fetchOrderByOfferIdRequest =
  (offerId: string): AppThunk =>
  async (dispatch, _, context) => {
    const { purchaseService, loggingService: logger, observabilitySystem } = context;

    dispatch(updateUserInformation('orderId', ''));

    const span = observabilitySystem.startSpan('create_order_from_email_upsells');
    span.addEvent('Pending');

    try {
      const data = await purchaseService.createOrderByOfferId(offerId);

      dispatch(updateUserInformation('orderId', data.orderId));

      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);
      span.recordException(error);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();

      logger.error(error);
    }
  };
