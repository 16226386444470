export enum FunnelName {
  APP_SUBSCRIPTION_4_7D_TRIAL_PER_DAY = 'app-subscription-4-7d-trial-per-day',
  APP_SUBSCRIPTION_INTRO = 'app-subscription-intro',
  PALMISTRY = 'palmistry',
  NATAL_CHART = 'natal-chart',
  WITCH_POWER = 'witch-power',
  MOON_COMPATIBILITY = 'moon-compatibility',
  HUMAN_DESIGN = 'human-design',
  ATTACHMENT_STYLE = 'attachment-style',
  ATTACHMENT_STYLE_WITH_DISCOUNT = 'attachment-style-with-discount',
}
