import { deserializeError, serializeError } from 'core/common/errors';
import { generateUUID } from 'core/common/utils/generateUUID';
import { LogExtra } from './LogExtra';
import { LogId } from './LogId';
import { LogLevel } from './LogLevel';
import { LogMessage } from './LogMessage';
import { SerializedLog } from './SerializedLog';

type LogProperties = {
  level: LogLevel;
  id: LogId;
  createdAt: number;
  message: LogMessage;
  extra: LogExtra;
};

export class Log {
  private constructor(private properties: LogProperties) {}

  static create(level: LogLevel, message: string | Error, extra: LogExtra = {}): Log {
    return new Log({
      id: generateUUID(),
      createdAt: Date.now(),
      level,
      message,
      extra,
    });
  }

  static deserialize(data: SerializedLog): Log {
    return new Log({
      ...data,
      message: Log.deserializeMessage(data.message),
    });
  }

  static deserializeMessage(message: SerializedLog['message']) {
    return typeof message === 'string' ? message : deserializeError(message);
  }

  getLevel() {
    return this.properties.level;
  }

  getId() {
    return this.properties.id;
  }

  getCreatedAt() {
    return this.properties.createdAt;
  }

  getMessage() {
    return this.properties.message;
  }

  getExtra() {
    return this.properties.extra;
  }

  isError() {
    return this.properties.level === 'error';
  }

  isWarn() {
    return this.properties.level === 'warn';
  }

  static sort(logs: Array<Log> = []) {
    return logs.sort((log1, log2) => log1.getCreatedAt() - log2.getCreatedAt());
  }

  serialize(): SerializedLog {
    return {
      level: this.getLevel(),
      id: this.getId(),
      createdAt: this.getCreatedAt(),
      message: this.getSerializeMessage(),
      extra: this.getExtra(),
    };
  }

  toString() {
    return JSON.stringify(this.serialize());
  }

  private getSerializeMessage() {
    const message = this.getMessage();
    return message instanceof Error ? serializeError(message) : message;
  }
}
