export function transformToMinutesFormat(minutesNumberArray: Array<number | string>) {
  const minutesStringArray: Array<string> = minutesNumberArray.map((minute) => String(minute));

  return minutesStringArray.map((minute) => {
    if (minute.split('').length === 1) {
      return `0${minute}`;
    }
    return minute;
  });
}
