import {
  deserializeError as libDeserialize,
  serializeError as libSerialize,
} from 'serialize-error';
import { UnknownError } from './UnknownError';

export function normalizeError(err: unknown): Error {
  return err instanceof Error ? err : new UnknownError(err);
}

export function serializeError(error: Error) {
  return libSerialize(error);
}

export function deserializeError(errorObject: unknown) {
  return libDeserialize(errorObject);
}
