import { mergeDeep } from 'core/common/utils/mergeDeep';
import { Log, LogExtra, LogLevel } from './entities';
import { Logger, LoggerTransport } from './interfaces';

type Config = Partial<{
  transports: Array<LoggerTransport>;
  context: Record<string, unknown>;
}>;

export class ConsolaLogger implements Logger {
  private readonly transports: Array<LoggerTransport>;

  private context: Record<string, unknown> = {};

  constructor(config: Config = {}) {
    this.transports = config.transports || [];
  }

  addTransport(transport: LoggerTransport) {
    this.transports.push(transport);
  }

  updateContext(context: Record<string, unknown>) {
    this.context = mergeDeep(context, this.context);
  }

  debug(message: string, extra: LogExtra = {}): void {
    this.log('debug', message, extra);
  }

  info(message: string, extra: LogExtra = {}): void {
    this.log('info', message, extra);
  }

  warn(message: string, extra?: LogExtra): void {
    this.log('warn', message, extra);
  }

  error(message: Error | string, extra?: LogExtra): void {
    this.log('error', message, extra);
  }

  private log(level: LogLevel, message: string | Error, extra: Record<string, unknown> = {}) {
    const mergedContext = mergeDeep(extra, this.context);
    const log = Log.create(level, message, mergedContext);

    this.transports.forEach((transport) => transport.send(log));
  }
}
