import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  loadFunnelConfigFailed,
  loadFunnelConfigPending,
  loadFunnelConfigSucceed,
  updateConfig,
} from './actions';
import { funnelConfigInitialState } from './state';

export const funnelConfigReducer = createReducer(funnelConfigInitialState, (builder) => {
  builder
    .addCase(updateConfig, (state, { payload }) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.config = payload;
      state.funnelName = payload.name;
    })
    .addCase(loadFunnelConfigPending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(loadFunnelConfigSucceed, (state, { payload }) => {
      state.loadingStatus = LoadingStatuses.FULFILLED;

      if (payload) {
        state.config = payload;
        state.funnelName = payload.name;
      }
    })
    .addCase(loadFunnelConfigFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    });
});
