import { ReactNode } from 'react';
import PageLayout from 'core/common/components/PageLayout';
import { useApp } from './useApp';

type AppProps = {
  children: ReactNode;
};

const App = ({ children }: AppProps) => {
  const { config, appStore, services, featureFlags, theme } = useApp();

  return (
    <PageLayout
      config={config}
      store={appStore}
      featureFlags={featureFlags}
      services={services}
      theme={theme}
    >
      {children}
    </PageLayout>
  );
};

export default App;
