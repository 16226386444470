import { useCallback } from 'react';
import { fetchNebulaAppLink, getUserInformation } from 'core/user/store';
import { useDispatch } from './useDispatch';
import { useSelector } from './useSelector';

export const useAppDownloadLink = () => {
  const dispatch = useDispatch();

  const { downloadAppLink, downloadAppLinkLoadingStatus } = useSelector(getUserInformation);

  const fetchAppDownloadLink = useCallback(() => {
    dispatch(fetchNebulaAppLink());
  }, [dispatch]);

  return { fetchAppDownloadLink, downloadAppLink, downloadAppLinkLoadingStatus };
};
