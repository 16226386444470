import { ConsoleLoggerTransport } from './ConsoleLoggerTransport';
import { CoreLogger } from './CoreLogger';
import { BatchDestination, BatchLoggerTransport } from './batch-logger-transport';
import { Logger, LogsBatchCache } from './interfaces';

export class LoggerBuilder {
  private logger: Logger;

  constructor() {
    this.logger = new CoreLogger();
  }

  addConsoleTransport() {
    this.logger.addTransport(new ConsoleLoggerTransport());
    return this;
  }

  addBatchTransport(destination: BatchDestination, cache: LogsBatchCache) {
    this.logger.addTransport(new BatchLoggerTransport(destination, cache));
    return this;
  }

  addContext(context: Record<string, unknown> = {}) {
    this.logger.updateContext(context);
    return this;
  }

  getResult(): Logger {
    return this.logger;
  }
}
