import { BackendAnalyticsService } from '../../interfaces';

export class BackendAnalyticsServiceDecorator implements BackendAnalyticsService {
  constructor(private readonly backendAnalyticsService: BackendAnalyticsService) {}

  setSplitExperimentProperties(props: {
    experimentName: string;
    groupName: string;
  }): Promise<void> {
    return this.backendAnalyticsService.setSplitExperimentProperties(props);
  }
}
