import { HttpClient } from 'core/common/services/httpСlient';
import { GrafanaLoggerLog } from '../entites';

export class GrafanaLoggerService {
  protected httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  async pushLogs(logs: Array<GrafanaLoggerLog>) {
    return this.httpClient.post('/', { data: logs });
  }
}
