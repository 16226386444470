/* eslint-disable max-len */

export const de = {
  translation: {
    'app-subscription-4-7d-trial-per-day': {
      salesScreen: {
        charge: {
          trialUS:
            'Sie melden sich für ein monatliches Abonnement des NEBULA-Dienstes an. Wenn Sie fortfahren, erklären' +
            ' Sie sich damit einverstanden, dass, wenn Sie nicht vor dem Ende der 7-tägigen Testphase für' +
            ' {{trialPrice}} kündigen, Ihnen automatisch alle 30 Tage {{fullPrice}} berechnet werden, bis Sie in den' +
            ' Einstellungen kündigen. Die Gebühr wird auf Ihrer Rechnung als {{domainUrl}} erscheinen. Erfahren Sie' +
            ' mehr über die Kündigungs- und Rückerstattungsrichtlinien in den ',
        },
        description: {
          terms: 'Abonnementrichtlinien',
        },
      },
      payment: {
        title: 'Zahlungsmethode wählen',
        charge: {
          trial:
            '<bold>Für Ihre 7 Tage Testphase wird Ihnen nur  {{price}}</bold> berechnet. Wir <bold>schicken Ihnen eine Erinnerung per E-Mail,</bold> bevor Ihr Testzeitraum endet. Jederzeit kündigen.',
          trialUS:
            'Der <bold>{{price}} wird Ihnen nur für Ihren 7-tägigen Testzeitraum berechnet.' +
            'Wir <bold>schicken Ihnen eine E-Mail-Erinnerung</bold> vor dem Ende Ihres Testzeitraums. ' +
            'Sie können jederzeit kündigen. Die Gebühr erscheint auf Ihrer Rechnung als {{domainUrl}}.',
          full: 'Für Ihre <bold>7 Tage Testphase wird Ihnen nur {{price}} berechnet.</bold>',
        },
        resources: 'Garantierte Sicherheitszahlungen',
      },
      trialPaymentWithDiscount: {
        descriptionExperiment: `Wenn Sie fortfahren, erklären Sie sich damit einverstanden, dass Ihnen automatisch {{ discountPrice }} für die 30-tägige Einführungsphase berechnet wird, wenn Sie nicht vor Ablauf der 7-tägigen Probezeit kündigen, und dann zum Standardsatz von {{ fullPrice }} alle 30 Tage, bis Sie in den Einstellungen kündigen. Weitere Informationen zu den Stornierungs- und Rückerstattungsrichtlinien finden Sie unter`,
        terms: 'Bedingungen für die Anmeldung',
      },
    },
    'natal-chart': {
      progressBar: 'Nehmen Sie Ihr Leben',
      billingScreen: {
        description:
          'Wenn Sie fortfahren, erklären Sie sich damit einverstanden, dass Sie jeden Monat automatisch mit {{price}} belastet werden, bis Sie in den Einstellungen kündigen. Erfahren Sie mehr über die Stornierungs- und Erstattungsrichtlinien unter ',
        trialDescription:
          'Sie melden sich für ein <bold>7-Tage-Abonnement</bold> für den NEBULA-Dienst an. Wenn Sie fortfahren, erklären Sie sich damit einverstanden, dass Sie, falls Sie nicht vor Ablauf der <bold>7-Tage-Testversion für {{trialPrice}}</bold> kündigen, automatisch jeden Monat mit {{price}} belastet werden, bis Sie in den Einstellungen kündigen. Erfahren Sie mehr über die Stornierungs- und Erstattungsrichtlinien unter',
        terms: 'Abonnementbedingungen.',
      },
    },
  },
};
