export const formatPrice = (price: number, floatNumber = false): string => {
  const formattedPrice = price.toFixed(2);

  if (formattedPrice.endsWith('.00')) {
    return formattedPrice.slice(0, -3);
  }
  if (floatNumber) {
    return `${parseFloat(formattedPrice)}`;
  }
  return formattedPrice;
};
