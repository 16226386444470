import { createReducer } from '@reduxjs/toolkit';
import { Languages } from 'core/localization/entities';
import { getCurrentLanguage } from 'core/localization/utils/getCurrentLanguage';
import { UtmSource } from 'core/user/entities';
import { changeSystemLanguage } from './actions';

export type SystemState = {
  isTestingEnv: boolean;
  language: Languages;
  isAppleMobile: boolean;
  isApplePaySupported: boolean;
  isMobileDevice: boolean;
  source: UtmSource;
};

export const systemInitialState: SystemState = {
  isTestingEnv: false,
  language: getCurrentLanguage(),
  isApplePaySupported: false,
  isAppleMobile: false,
  isMobileDevice: false,
  source: UtmSource.NO_SOURCE,
};

export const systemReducer = createReducer(systemInitialState, (builder) => {
  builder.addCase(changeSystemLanguage, (state, { payload }) => {
    state.language = payload;
  });
});
