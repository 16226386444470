import { RootInitialState } from 'core/common/store/rootReducer';
import {
  getIsAppleMobile,
  getIsApplePaySupported,
  getIsMobileDevice,
  getIsTestingEnv,
  getSystemLanguage,
  getUtmSource,
} from 'core/common/store/system';
import { getGender, getUserLocation } from 'core/user/store/selectors';
import { FeatureFlagsAttributes } from '../entities';

export const getExperiments = (state: RootInitialState) => {
  return state.featureFlags.experiments;
};

export const getFeatureFlagsAttributes = (state: RootInitialState): FeatureFlagsAttributes => {
  const gender = getGender(state);
  const isTestingEnv = getIsTestingEnv(state);
  const language = getSystemLanguage(state);
  const { locale } = getUserLocation(state);
  const isApplePaySupported = getIsApplePaySupported(state);
  const isAppleMobile = getIsAppleMobile(state);
  const isMobileDevice = getIsMobileDevice(state);
  const source = getUtmSource(state);

  return {
    gender,
    isTestingEnv,
    language,
    isAppleMobile,
    isApplePaySupported,
    isMobileDevice,
    locale,
    source,
  };
};
