import { getQueryParams } from 'core/common/utils/getQueryParams';
import { FeatureFlags } from './FeatureFlags';
import { FeatureFlagsDecorator } from './FeatureFlagsDecorator';

export class QuerySettingFeatureFlagsDecorator extends FeatureFlagsDecorator {
  constructor(featureFlags: FeatureFlags) {
    super(featureFlags);
    this.loadFromQueries();
  }

  private loadFromQueries() {
    const queries = getQueryParams();

    if (!Object.keys(queries).length) return;

    super.setForcedFeatureValues(queries);
  }
}
