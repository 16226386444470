import { LtvPredictService } from '../../interfaces';

export class LtvPredictServiceDecorator implements LtvPredictService {
  constructor(private readonly ltvPredictService: LtvPredictService) {}

  setCurrentQuizName(quiz: string) {
    this.ltvPredictService.setCurrentQuizName(quiz);
  }
  async getLTVPredict(productName: string) {
    return await this.ltvPredictService.getLTVPredict(productName);
  }
}
