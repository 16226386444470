export enum PaymentErrorCode {
  GENERAL_DECLINE = '0.01',
  ORDER_EXPIRED = '0.02',
  ILLEGAL_OPERATION = '0.03',
  AUTHENTICATION_FAILED = '1.01',
  INVALID_DATA = '2.01',
  INVALID_AMOUNT = '2.02',
  INVALID_CURRENCY = '2.03',
  ORDER_NOT_FOUND = '2.05',
  INVALID_CVV2_CODE = '2.06',
  REQUEST_IS_EMPTY = '2.07',
  INVALID_CARD_NUMBER = '2.08',
  INVALID_EXPIRATION_DATE = '2.09',
  INVALID_3DS_FLOW_ON_THE_MERCHANT_SIDE = '2.10',
  INVALID_3DS_FLOW_ON_THE_BANK_SIDE = '2.11',
  INVALID_3DS_FLOW = '2.12',
  INVALID_IP = '2.13',
  SUBSCRIPTION_ERROR = '2.14',
  SCA_REQUIRE_3D_AUTHENTICATION = '2.15',
  SUBSCRIPTION_IS_LOCKED = '2.16',
  CARD_IS_BLOCKED = '3.01',
  INSUFFICIENT_FUNDS = '3.02',
  PAYMENT_AMOUNT_LIMIT_EXCESS = '3.03',
  THE_TRANSACTION_IS_DECLINED_BY_THE_ISSUER = '3.04',
  CALL_YOU_BANK = '3.05',
  DEBIT_CARD_NOT_SUPPORTED = '3.06',
  CARD_BRAND_IS_NOT_SUPPORTED = '3.07',
  DO_NOT_HONOR = '3.08',
  SECURE_3D_AUTHENTICATION_REQUIRED = '3.09',
  SUSPECTED_FRAUD = '3.10',
  CARD_IS_IN_A_BLACK_LIST = '4.01',
  STOLEN_CARD = '4.02',
  RESTRICTED_CARD = '4.03',
  LOST_CARD = '4.04',
  SUSPECTED_FRAUD2 = 'Suspected fraud',
  BLOCKED_BY_COUNTRY_IP = '4.06',
  TRUSTED_ANTIFRAUD_SYSTEM = '4.07',
  AVS_MISMATCH = '4.08',
  SOLID_ANTIFRAUD_ENGINE = '4.09',
  SECURE_3D_VERIFICATION_FAILED = '5.01',
  INVALID_CARD_TOKEN = '5.02',
  APPLICATION_ERROR = '5.03',
  MERCHANT_IS_NOT_CONFIGURED_CORRECTLY = '5.04',
  MERCHANT_IS_NOT_ACTIVATED_YET = ' 5.05',
  DUPLICATE_ORDER = '5.06',
  EXCEEDED_API_CALLS_LIMITS = '5.07',
  INVALID_TRANSACTION = '5.08',
  MERCHANT_NOT_FOUND = '5.09',
  PROCESSOR_DOES_NOT_SUPPORT_REQUEST_API_METHOD = '5.10',
  INVALID_ROUTING = '5.11',
  UNKNOWN_DECLINE_CODE = '6.01',
  CONNECTION_ERROR = '6.02',
  CARD_TOKEN_NOT_FOUND = '7.01',
  GOOGLE_PAYMENT_ERROR = '7.02',
  SMART_CASCADE_DECLINE = '7.03',
  CASCADE_3DS_TO_2D = '7.04',
  APPLE_ONLINE_PAYMENT_ERROR = '7.05',
  TOKEN_GENERATION_ERROR = '7.06',
}

export type PaymentError = {
  message: string;
  code?: PaymentErrorCode;
  details?: Record<string, unknown>;
};

export type PaypalProcessingError = {
  code?: PaymentErrorCode;
  messages: string[];
  recommended_message_for_user: string;
};
