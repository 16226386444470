import { NextApiResponse } from 'next';
import { Webhook } from 'standardwebhooks';
import { normalizeError } from 'core/common/errors';
import { RevalidationError, RevalidationErrorReason } from 'core/common/errors/RevalidationError';
import { Logger } from 'core/common/services/logger/interfaces';
import { uniq } from 'core/common/utils/uniq';
import {
  RevalidationSubject,
  RequestFunnelWithOneFlow,
  RequestFunnelWithAllFlows,
  FlowSchema,
  FunnelConfigSchema,
} from 'core/funnel/entities';
import { Languages } from 'core/localization/entities';

type RevalidateFunnelParams = {
  res: NextApiResponse;
  locale: Languages;
  funnelName: string;
  screenNames: Array<string>;
};

type VerifySignaturesParams = {
  secret: string;
  payload: string | Buffer;
  headers: {
    'webhook-id': string;
    'webhook-timestamp': string;
    'webhook-signature': string;
  };
};

export type RevalidationStrategyParams = {
  res: NextApiResponse;
  data: RequestFunnelWithAllFlows | RequestFunnelWithOneFlow;
};

export class RevalidationService {
  constructor(private readonly logger: Logger) {
    this.logger = logger;
  }

  isTokenValid(tokenType: RevalidationSubject, token?: string | string[]) {
    if (tokenType === RevalidationSubject.FUNNELS) {
      return !!token && token === process.env.NEXT_PUBLIC_FUNNELS_REVALIDATION_SECRET;
    }

    return !!token && token === process.env.NEXT_PUBLIC_REVALIDATION_SECRET;
  }

  verifySignature({ secret, payload, headers }: VerifySignaturesParams) {
    const wh = new Webhook(secret);

    return wh.verify(payload, headers);
  }

  private async retryPromise<T>(promiseFn: () => Promise<T>, retries = 5): Promise<T> {
    for (let i = 0; i < retries; i++) {
      try {
        return await promiseFn();
      } catch (error) {
        if (i === retries - 1) {
          const err = normalizeError(error);

          this.logger.error(`retryPromise — ${err}`);
        }
      }
    }

    throw new Error('Retry revalidateFunnel failed unexpectedly');
  }

  async revalidateFunnel({ res, locale, funnelName, screenNames }: RevalidateFunnelParams) {
    const requests = screenNames.map((screenName) => {
      return this.retryPromise(() => {
        return res.revalidate(`/${locale}/${funnelName}/${screenName}`);
      });
    });

    try {
      await Promise.all(requests);

      this.logger.info(
        `Successful revalidation of all "${locale}" screens in funnel "${funnelName}"`,
      );
    } catch (error) {
      throw error;
    }
  }

  revalidateFlow = async ({ res, data }: RevalidationStrategyParams) => {
    const flow = data.payload.funnel?.flow;
    const funnel = data.payload.funnel;

    if (!flow) {
      this.logger.error(RevalidationErrorReason.EMPTY_FLOW);

      throw new RevalidationError(RevalidationErrorReason.EMPTY_FLOW);
    }

    const flowValidationResult = FlowSchema.safeParse(flow);

    if (!flowValidationResult.success) {
      this.logger.error(RevalidationErrorReason.MALFORMED_FLOW_CONFIG, {
        data: `${funnel.name}/${flow.name}`,
      });
    }

    const screenNames = uniq(flow.screens.map((screen) => screen.id));

    this.revalidateFunnel({
      res,
      locale: flow.language,
      funnelName: funnel.name,
      screenNames,
    });
  };

  revalidateWholeFunnel = async ({ res, data }: RevalidationStrategyParams) => {
    const flows = data.payload.funnel?.flows;
    const funnel = data.payload.funnel;

    if (!flows?.length) {
      this.logger.error(RevalidationErrorReason.EMPTY_FLOW);

      throw new RevalidationError(RevalidationErrorReason.EMPTY_FLOW);
    }

    const funnelValidationResult = FunnelConfigSchema.safeParse(funnel);

    if (!funnelValidationResult.success) {
      this.logger.error(RevalidationErrorReason.MALFORMED_FUNNEL_CONFIG, {
        data: `${funnel.name}`,
      });
    }

    for (const flow of flows) {
      const screenNames = uniq(flows.flatMap(({ screens }) => screens?.map(({ id }) => id)));

      this.revalidateFunnel({
        res,
        locale: flow.language,
        funnelName: funnel.name,
        screenNames,
      });
    }
  };
}
