/**
 * Returns the nearest attractive price for a given price value.
 * Depending on the value of the input price, the function selects from a set of
 * attractive postfixes (e.g., -0.01, 4.99, or 9.99). The function then determines
 * which "attractive price" is nearest to the given price and returns it.
 *
 * @example
 * - 2.10  -> 4.99
 * - 10.77 -> 9.99
 * - 12.57 -> 14.99
 * - 13.77 -> 14.99
 * - 18.77 -> 19.99
 * - 36.77 -> 34.99
 *
 * @param {number} price - The input price to determine its nearest attractive price.
 * @returns {number} - The nearest attractive price.
 */
export const getAttractivePrice = (price: number) => {
  const extra = Math.floor(price % 10);
  const roundedPrice = Math.floor(price) - extra;
  const attractivePostfixes = price > 10 ? [4.99, 9.99] : [-0.01, 4.99, 9.99];

  const attractivePrices: Record<number, number> = {};

  for (const attractivePostfix of attractivePostfixes) {
    const attractivePrice = roundedPrice + attractivePostfix;
    attractivePrices[attractivePrice] = Math.abs(attractivePrice - price);
  }

  let nearestAttractivePrice = +Object.keys(attractivePrices)[0];
  let distanceToAttractivePrice = attractivePrices[nearestAttractivePrice];

  for (const [attractivePrice, distanceToPrice] of Object.entries(attractivePrices)) {
    if (distanceToAttractivePrice > distanceToPrice) {
      distanceToAttractivePrice = distanceToPrice;
      nearestAttractivePrice = +attractivePrice;
    }
  }

  return +nearestAttractivePrice.toFixed(2);
};
