import { useRouter } from 'next/router';
import { ThemeTypes } from '../entities';

const themes: Record<string, ThemeTypes> = {
  email: ThemeTypes.EMAIL,
  'app-subscription-4-7d-trial-per-day': ThemeTypes.DEFAULT,
  palmistry: ThemeTypes.PALMISTRY,
  'natal-chart': ThemeTypes.NATAL_CHART,
  'witch-power': ThemeTypes.WITCH_POWER,
  'palmistry-quiz': ThemeTypes.PALMISTRY_QUIZ,
  'compatibility-quiz': ThemeTypes.COMPATIBILITY_QUIZ,
  'moon-compatibility': ThemeTypes.MOON_COMPATIBILITY,
  'feminine-archetypes': ThemeTypes.NATAL_CHART,
  'human-design': ThemeTypes.NATAL_CHART,
  draft: ThemeTypes.DEFAULT,
  shamans: ThemeTypes.PALMISTRY,
  'app-sub-intro': ThemeTypes.APP_SUBSCRIPTION_INTRO,
};

export const getThemeType = (urlLocation: ReturnType<typeof useRouter>) => {
  const flow = urlLocation.asPath.split('/')[1];
  const theme = themes[flow] || ThemeTypes.DEFAULT;

  return theme;
};
