import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getUserInformation } from 'core/user/store';

export function useGoToInbox() {
  const { email } = useSelector(getUserInformation);

  const goToInbox = useCallback(() => {
    const emailDomain = email.split('@').pop();
    window.open(`https://${emailDomain}`, '_self');
  }, [email]);

  return {
    goToInbox,
  };
}
