import { RootInitialState } from 'core/common/store';
import { CurrencyService } from 'core/user/services';

export const getUserLocation = (state: RootInitialState) => {
  return state.userLocation.location;
};

export const getUserLocationLoadingStatus = (state: RootInitialState) => {
  return state.userLocation.loadingStatus;
};

export const getUserCurrency = (state: RootInitialState) => {
  const location = getUserLocation(state);

  return CurrencyService.getUserCurrency(location.locale);
};
