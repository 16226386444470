import { LoadingStatuses } from 'core/common/entities';
import { Locales, Regions, UserLocation } from '../../entities';

export type UserLocationState = {
  location: UserLocation;
  loadingStatus: LoadingStatuses;
};

export const userLocationInitialState: UserLocationState = {
  location: {
    locale: Locales.OTHER,
    country: '',
    region: Regions.OTHER,
  },
  loadingStatus: LoadingStatuses.IDLE,
};
