import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { HumanDesignReportError } from 'core/common/errors/HumanDesignReportError';
import { SpanStatusCode } from 'core/common/observability/entities';
import { AppThunk } from 'core/common/store/types';
import { HumanDesignReport } from 'core/human-design/entities';
import { getUserInformation } from 'core/user/store';

const humanDesignReportAlias = 'humanDesignReport';

export const fetchReportPending = createAction(`${humanDesignReportAlias}/FETCH_REPORT/pending`);
export const fetchReportSucceed = createAction<HumanDesignReport>(
  `${humanDesignReportAlias}/FETCH_REPORT/succeed`,
);
export const fetchReportFailed = createAction(`${humanDesignReportAlias}/FETCH_REPORT/failed`);

export const fetchHumanDesignReport = (): AppThunk => async (dispatch, getState, services) => {
  const { humanDesignReportService, loggingService: logger, observabilitySystem } = services;

  const { name, time, place, location, date } = getUserInformation(getState());

  dispatch(fetchReportPending());

  const span = observabilitySystem.startSpan('generate_human_design_report');
  span.addEvent('Pending');

  try {
    if (!name || !date || !place.length || !location) {
      throw new HumanDesignReportError('Mandatory data for report generating is missing');
    }

    const report = await humanDesignReportService.generateReport({
      name,
      birthDate: date,
      birthPlace: {
        location,
        longitude: place[0],
        latitude: place[1],
      },
      birthTime: time ?? '00:00:00',
    });

    dispatch(fetchReportSucceed(report));

    span.addEvent('Succeed');
    span.end();
  } catch (e) {
    const err = normalizeError(e);

    logger.error(err);
    dispatch(fetchReportFailed());

    span.addEvent('Failed');
    span.recordException(err);
    span.setStatus({ code: SpanStatusCode.ERROR, message: err.message });
    span.end();
  }
};
