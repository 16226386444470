import { EqualityConditionSchema } from 'core/common/entities';
import validator from 'core/common/utils/validator';
import {
  createStringifiedIntegerRefinement,
  createUniqueAttributePropertyRefinement,
} from '../utils/schemaRefinements';

const AnalyticsConditionSchema = validator.record(validator.string(), EqualityConditionSchema);

export enum AnalyticsType {
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  BING_ADS = 'BING_ADS',
  TIK_TOK = 'TIK_TOK',
}

const AnalyticsNumericalIdSchema = validator
  .string()
  .superRefine(createStringifiedIntegerRefinement('id'));

export const FacebookPixelAnalyticsSchema = validator.object({
  type: validator.literal(AnalyticsType.FACEBOOK_PIXEL),
  attributes: validator
    .array(
      validator.object({
        id: AnalyticsNumericalIdSchema,
        condition: AnalyticsConditionSchema.optional(),
      }),
    )
    .superRefine(createUniqueAttributePropertyRefinement('id')),
});

export const BingAdsAnalyticsSchema = validator.object({
  type: validator.literal(AnalyticsType.BING_ADS),
  attributes: validator
    .array(
      validator.object({
        id: AnalyticsNumericalIdSchema,
        condition: AnalyticsConditionSchema.optional(),
      }),
    )
    .superRefine(createUniqueAttributePropertyRefinement('id')),
});

export const TikTokAnalyticsSchema = validator.object({
  type: validator.literal(AnalyticsType.TIK_TOK),
  attributes: validator
    .array(
      validator.object({
        id: validator.string(),
        condition: AnalyticsConditionSchema.optional(),
      }),
    )
    .superRefine(createUniqueAttributePropertyRefinement('id')),
});

export const FunnelAnalyticsSchema = validator
  .array(
    validator.union([FacebookPixelAnalyticsSchema, BingAdsAnalyticsSchema, TikTokAnalyticsSchema]),
  )
  .superRefine(createUniqueAttributePropertyRefinement('type'));

export type FunnelAnalyticsType = validator.infer<typeof FunnelAnalyticsSchema>;

export type FacebookAnalyticsType = validator.infer<typeof FacebookPixelAnalyticsSchema>;

export type BingAdsAnalyticsType = validator.infer<typeof BingAdsAnalyticsSchema>;

export type TikTokAnalyticsType = validator.infer<typeof TikTokAnalyticsSchema>;

export const isFacebookPixel = (item: FunnelAnalyticsType[number]): item is FacebookAnalyticsType =>
  item.type === AnalyticsType.FACEBOOK_PIXEL;

export const isBingAds = (item: FunnelAnalyticsType[number]): item is BingAdsAnalyticsType =>
  item.type === AnalyticsType.BING_ADS;

export const isTikTok = (item: FunnelAnalyticsType[number]): item is TikTokAnalyticsType =>
  item.type === AnalyticsType.TIK_TOK;
