import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { Languages } from 'core/localization/entities';
import resources from 'core/localization/resources';

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['path', 'querystring', 'navigator'] },
    resources,
    fallbackLng: Languages.EN,
    supportedLngs: Object.values(Languages),
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
