import { BaseService } from 'core/common/services';
import { checkIsClientSide } from 'core/common/utils/checkIsClientSide';
import { createDate } from 'core/common/utils/date';
import { IconNames } from 'core/svg-sprites/entities';
import { ZodiacTypes } from 'core/zodiac/entities';

export class ZodiacService extends BaseService {
  static getZodiacSignByDate(date: string) {
    if (!checkIsClientSide()) return ZodiacTypes.LEO;

    const month = +createDate(date).format('M');
    const day = +createDate(date).format('DD');

    const zodiacSigns = [
      ZodiacTypes.CAPRICORN,
      ZodiacTypes.AQUARIUS,
      ZodiacTypes.PISCES,
      ZodiacTypes.ARIES,
      ZodiacTypes.TAURUS,
      ZodiacTypes.GEMINI,
      ZodiacTypes.CANCER,
      ZodiacTypes.LEO,
      ZodiacTypes.VIRGO,
      ZodiacTypes.LIBRA,
      ZodiacTypes.SCORPIO,
      ZodiacTypes.SAGITTARIUS,
    ];

    const zodiacBoundDates = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21];

    const monthIndex = month - 1;
    const signMonthIndex = day <= zodiacBoundDates[monthIndex] ? monthIndex : (monthIndex + 1) % 12;
    return zodiacSigns[signMonthIndex];
  }

  getZodiacSignByDate(date: string) {
    return ZodiacService.getZodiacSignByDate(date);
  }

  static getZodiacData(zodiacSign: ZodiacTypes) {
    const zodiacsData: {
      [key: string]: Record<string, string>;
    } = {
      [ZodiacTypes.AQUARIUS]: {
        users: '1,444,310',
        female: '2,031,052',
        male: '642,634',
      },
      [ZodiacTypes.ARIES]: {
        users: '1,416,641',
        female: '1,997,775',
        male: '637,429',
      },
      [ZodiacTypes.CANCER]: {
        users: '1,634,319',
        female: '2,312,566',
        male: '716,792',
      },
      [ZodiacTypes.CAPRICORN]: {
        users: '1,626,321',
        female: '2,281,424',
        male: '730,877',
      },
      [ZodiacTypes.GEMINI]: {
        users: '1,483,598',
        female: '2,098,881',
        male: '667,337',
      },
      [ZodiacTypes.LEO]: {
        users: '1,582,733',
        female: '2,218,630',
        male: '691,414',
      },
      [ZodiacTypes.LIBRA]: {
        users: '1,559,139',
        female: '2,207,757',
        male: '682,108',
      },
      [ZodiacTypes.PISCES]: {
        users: '1,442,670',
        female: '2,067,113',
        male: '649,347',
      },
      [ZodiacTypes.SAGITTARIUS]: {
        users: '1,457,274',
        female: '2,025,665',
        male: '651,003',
      },
      [ZodiacTypes.SCORPIO]: {
        users: '1,497,797',
        female: '2,119,108',
        male: '699,647',
      },
      [ZodiacTypes.TAURUS]: {
        users: '1,473,168',
        female: '2,092,544',
        male: '653,754',
      },
      [ZodiacTypes.VIRGO]: {
        users: '1,579,784',
        female: '2,235,493',
        male: '689,464',
      },
    };

    return zodiacsData[zodiacSign];
  }

  static getZodiacSignIcon(zodiacSign: ZodiacTypes) {
    const zodiacIcons: Record<ZodiacTypes, IconNames> = {
      [ZodiacTypes.AQUARIUS]: 'aquarius',
      [ZodiacTypes.ARIES]: 'aries',
      [ZodiacTypes.CANCER]: 'cancer',
      [ZodiacTypes.CAPRICORN]: 'capricorn',
      [ZodiacTypes.GEMINI]: 'gemini',
      [ZodiacTypes.LEO]: 'leo',
      [ZodiacTypes.LIBRA]: 'libra',
      [ZodiacTypes.PISCES]: 'pisces',
      [ZodiacTypes.SAGITTARIUS]: 'sagittarius',
      [ZodiacTypes.SCORPIO]: 'scorpio',
      [ZodiacTypes.TAURUS]: 'taurus',
      [ZodiacTypes.VIRGO]: 'virgo',
    };

    return zodiacIcons[zodiacSign];
  }
}
