import { LoadingStatuses } from 'core/common/entities';

export type AuthState = {
  token: string;
  loadingStatus: LoadingStatuses;
};

export const authInitialState: AuthState = {
  token: '',
  loadingStatus: LoadingStatuses.IDLE,
};
