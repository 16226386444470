import { ToEntity } from 'core/common/mappers';
import { UserSubscription } from '../entities';
import { SubscriptionDto } from '../services/dto';

export class SubscriptionsDtoMapper implements ToEntity<UserSubscription, SubscriptionDto> {
  toEntity(data: SubscriptionDto): UserSubscription {
    return {
      id: data.subscription.id,
      status: data.subscription.status,
      paymentType: data.subscription.payment_type,
      period: data.product.subscription_period,
      amount: data.product.float_amount,
    };
  }
}
