import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  fetchNebulaAppLinkFailed,
  fetchNebulaAppLinkPending,
  fetchNebulaAppLinkSucceed,
  updateUserInformation,
  updateUserPending,
  updateUserPreferences,
  updateUserSucceed,
} from './actions';
import { userProfileInitialState } from './state';

export const userProfile = createReducer(userProfileInitialState, (builder) => {
  builder
    .addCase(updateUserInformation, (state, { payload }) => ({
      ...state,
      [payload.name]: payload.value,
    }))
    .addCase(updateUserPending, (state) => ({
      ...state,
      updateUserInformationStatus: LoadingStatuses.PENDING,
    }))
    .addCase(updateUserSucceed, (state) => ({
      ...state,
      updateUserInformationStatus: LoadingStatuses.FULFILLED,
    }))
    .addCase(fetchNebulaAppLinkPending, (state) => ({
      ...state,
      downloadAppLinkLoadingStatus: LoadingStatuses.PENDING,
    }))
    .addCase(fetchNebulaAppLinkFailed, (state) => ({
      ...state,
      downloadAppLinkLoadingStatus: LoadingStatuses.FAILED,
    }))
    .addCase(fetchNebulaAppLinkSucceed, (state, { payload }) => ({
      ...state,
      downloadAppLink: payload,
      downloadAppLinkLoadingStatus: LoadingStatuses.FULFILLED,
    }))
    .addCase(updateUserPreferences, (state, { payload }) => ({
      ...state,
      ...payload,
    }));
});
