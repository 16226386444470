import SmartLookClient from 'smartlook-client';

export class SessionRecorder {
  private apiKey: string;

  private isInitialized = false;

  private userIdentifier?: string;

  constructor(apiKey: string) {
    this.apiKey = apiKey;
  }

  start() {
    if (this.isInitialized) return;

    SmartLookClient.init(this.apiKey);
    this.isInitialized = true;

    // Check if user identifier passed before and identify user
    if (this.userIdentifier) {
      SmartLookClient.identify(this.userIdentifier, {});
    }
  }

  identifyUser(id: string, properties: Record<string, string | number | boolean> = {}) {
    this.userIdentifier = id;

    // Can do this only if delayed initialization finished
    if (this.isInitialized) {
      SmartLookClient.identify(this.userIdentifier, properties);
    }
  }
}
