import validator from 'core/common/utils/validator';

export const ComparisonConditionSchema = validator.union([
  validator.intersection(
    validator.union([
      validator.object({ $gt: validator.unknown(), $gte: validator.never().optional() }),
      validator.object({ $gte: validator.unknown(), $gt: validator.never().optional() }),
    ]),
    validator.union([
      validator.object({ $lt: validator.unknown().optional(), $lte: validator.never().optional() }),
      validator.object({ $lte: validator.unknown().optional(), $lt: validator.never().optional() }),
    ]),
  ),
  validator.intersection(
    validator.union([
      validator.object({ $lt: validator.unknown(), $lte: validator.never().optional() }),
      validator.object({ $lte: validator.unknown(), $lt: validator.never().optional() }),
    ]),
    validator.union([
      validator.object({ $gt: validator.unknown().optional(), $gte: validator.never().optional() }),
      validator.object({ $gte: validator.unknown().optional(), $gt: validator.never().optional() }),
    ]),
  ),
]);

export const EqualityConditionSchema = validator.union([
  validator.object({
    $eq: validator.unknown(),
    $ne: validator.never().optional(),
    $gt: validator.never().optional(),
    $lt: validator.never().optional(),
    $lte: validator.never().optional(),
    $gte: validator.never().optional(),
  }),
  validator.object({
    $eq: validator.never().optional(),
    $ne: validator.unknown(),
    $gt: validator.never().optional(),
    $lt: validator.never().optional(),
    $lte: validator.never().optional(),
    $gte: validator.never().optional(),
  }),
]);

export const InclusionConditionSchema = validator.union([
  validator.object({
    $in: validator.array(validator.unknown()),
    $nin: validator.never().optional(),
    $eq: validator.never().optional(),
    $ne: validator.never().optional(),
    $gt: validator.never().optional(),
    $lt: validator.never().optional(),
    $lte: validator.never().optional(),
    $gte: validator.never().optional(),
  }),
  validator.object({
    $in: validator.never().optional(),
    $nin: validator.array(validator.unknown()),
    $eq: validator.never().optional(),
    $ne: validator.never().optional(),
    $gt: validator.never().optional(),
    $lt: validator.never().optional(),
    $lte: validator.never().optional(),
    $gte: validator.never().optional(),
  }),
]);

export const UnionConditionsSchema = validator.union([
  ComparisonConditionSchema,
  EqualityConditionSchema,
  InclusionConditionSchema,
]);
