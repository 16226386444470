import { OrderDTO } from '../services/dto';

export const orderDtoToEntity = ({ order_id, user_id, offer_name }: OrderDTO) => {
  return {
    orderId: order_id,
    userId: user_id,
    offerName: offer_name,
  };
};

export const mapOrderQuizDtoToEntity = (orderDTO: OrderDTO) => {
  const { order_quiz_data } = orderDTO;

  const order = {
    ...orderDTO,
    order_quiz_data: {
      ...order_quiz_data,
      partnerDate: order_quiz_data.partner_birth_date,
      partnerGender: order_quiz_data.partner_gender,
      date: order_quiz_data.birth_date,
      time: order_quiz_data.birth_time,
      location: order_quiz_data.birth_place,
      partnerLocation: order_quiz_data.partner_birth_place,
    },
  };

  return order;
};
