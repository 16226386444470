import { combineReducers } from 'redux';
import { authReducer } from 'core/auth/store';
import { expertsReducer } from 'core/experts/store';
import { featureFlagsReducer } from 'core/feature-flags/store/reducer';
import { funnelConfigReducer } from 'core/funnel/store';
import { humanDesignReducer } from 'core/human-design/store';
import { offersReducer } from 'core/offers/store';
import { paymentsReducer } from 'core/payments/store/reducer';
import { popUpsReducer } from 'core/popUp/store';
import {
  userLocationReducer,
  userProfile,
  userSubscriptionsReducer,
  userUtmTagsReducer,
} from 'core/user/store/reducer';
import { systemReducer } from './system';

const rootReducer = combineReducers({
  userProfile,
  userUtmTags: userUtmTagsReducer,
  system: systemReducer,
  experts: expertsReducer,
  popUps: popUpsReducer,
  auth: authReducer,
  offers: offersReducer,
  payments: paymentsReducer,
  funnelConfig: funnelConfigReducer,
  humanDesign: humanDesignReducer,
  featureFlags: featureFlagsReducer,
  subscriptions: userSubscriptionsReducer,
  userLocation: userLocationReducer,
});

export type RootInitialState = ReturnType<typeof rootReducer>;

export default rootReducer;
