import fullMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/fullMoon.png';
import lastQuarterMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/lastQuarterMoon.png';
import newMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/newMoon.png';
import quarterMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/quarterMoon.png';
import aquariusImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/aquarius.svg';
import ariesImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/aries.svg';
import cancerImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/cancer.svg';
import capricornImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/capricorn.svg';
import geminiImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/gemini.svg';
import leoImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/leo.svg';
import libraImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/libra.svg';
import piscesImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/pisces.svg';
import sagittariusImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/sagittarius.svg';
import scorpioImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/scorpio.svg';
import taurusImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/taurus.svg';
import virgoImage from 'core/common/assets/images/moonCompatibilityQuiz/signs/virgo.svg';
import waningCrescentMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/waningCrescentMoon.png';
import waningGibbousMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/waningGibbousMoon.png';
import waxingCrescentMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/waxingCrescentMoon.png';
import waxingGibbousMoonImage from 'core/common/assets/images/moonCompatibilityQuiz/waxingGibbousMoon.png';
import { MoonPhases } from 'core/moon-compatibility/entities';
import { ZodiacTypes } from 'core/zodiac/entities';

const YEAR_IN_DAYS = 365.25;
const MONTH_IN_DAYS = 30.6;
const DAYS_ELAPSED_SINCE_ZERO = 694039.09;
const MOON_CYCLE_IN_DAYS = 29.5305882;
const SCALE_FRACTION = 8;
const MARCH_INDEX = 3;
const YEAR_IN_MONTHS = 12;
const DEFAULT_DATE = '1970-01-01';

// Note that the order matters!
const moonPhases = [
  MoonPhases.NEW_MOON,
  MoonPhases.WAXING_CRESCENT_MOON,
  MoonPhases.QUARTER_MOON,
  MoonPhases.WAXING_GIBBOUS_MOON,
  MoonPhases.FULL_MOON,
  MoonPhases.WANING_GIBBOUS_MOON,
  MoonPhases.LAST_QUARTER_MOON,
  MoonPhases.WANING_CRESCENT_MOON,
];

const moonImages = {
  [MoonPhases.NEW_MOON]: {
    src: newMoonImage,
    alt: 'New Moon',
  },
  [MoonPhases.WAXING_CRESCENT_MOON]: {
    src: waxingCrescentMoonImage,
    alt: 'Waxing Crescent Moon',
  },
  [MoonPhases.QUARTER_MOON]: {
    src: quarterMoonImage,
    alt: 'Waxing Crescent Moon',
  },
  [MoonPhases.WAXING_GIBBOUS_MOON]: {
    src: waxingGibbousMoonImage,
    alt: 'Waxing Gibbous Moon',
  },
  [MoonPhases.FULL_MOON]: {
    src: fullMoonImage,
    alt: 'Full Moon',
  },
  [MoonPhases.WANING_GIBBOUS_MOON]: {
    src: waningGibbousMoonImage,
    alt: 'Waning Gibbous Moon',
  },
  [MoonPhases.LAST_QUARTER_MOON]: {
    src: lastQuarterMoonImage,
    alt: 'Last Quarter Moon',
  },
  [MoonPhases.WANING_CRESCENT_MOON]: {
    src: waningCrescentMoonImage,
    alt: 'Waning Crescent Moon',
  },
};

const normalizeNumber = (value: number) => {
  value = value - Math.floor(value);

  if (value < 0) {
    value = value + 1;
  }

  return value;
};

export class MoonPhaseService {
  static getDateAccordingToMoonCalendar(date: string) {
    const birthDate = new Date(date || DEFAULT_DATE);

    const day = birthDate.getDate();
    let month = birthDate.getMonth() + 1;
    let year = birthDate.getFullYear();

    if (month < MARCH_INDEX) {
      year--;
      month += YEAR_IN_MONTHS;
    }

    ++month;

    return { day, month, year };
  }

  static getMoonPhaseByDate(date: string): MoonPhases {
    const { day, month, year } = MoonPhaseService.getDateAccordingToMoonCalendar(date);

    const daysFromElapsedYears = YEAR_IN_DAYS * year;
    const daysFromElapsedMonths = MONTH_IN_DAYS * month;

    const totalDaysElapsed =
      (daysFromElapsedYears + daysFromElapsedMonths + day - DAYS_ELAPSED_SINCE_ZERO) /
      MOON_CYCLE_IN_DAYS;

    const fullDaysOfTotalElapsedDays = Math.trunc(totalDaysElapsed);

    const residualPartOfElapsedDays = totalDaysElapsed - fullDaysOfTotalElapsedDays;

    const moonPhase = Math.round(residualPartOfElapsedDays * SCALE_FRACTION);

    return moonPhases[moonPhase >= SCALE_FRACTION ? 0 : moonPhase];
  }

  static getMoonImageByDate(date: string) {
    const phase = MoonPhaseService.getMoonPhaseByDate(date);

    return moonImages[phase];
  }

  static getMoonSign(birthDate: string) {
    const date = new Date(birthDate);

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const SYNODIC_MONTH = 29.530588853;
    const DRACONIC_MONTH = 27.55454988;
    const ANOMALISTIC_MONTH = 27.321582241;
    const GREGORIAN_START_JULIAN_DAY = 2299160;
    const REFERENCE_NEW_MOON = 2451550.1;
    const REFERENCE_ASCENDING_NODE = 2451562.2;
    const REFERENCE_PERIGEE = 2451555.8;
    const JULIAN_DAY_BASE_YEAR = 4712;

    let adjustedMonth = month + 9;
    let julianDay = 0;
    let normalizedPhase = 0;

    const adjustedYear = year - Math.floor((YEAR_IN_MONTHS - month) / 10);

    if (adjustedMonth >= YEAR_IN_MONTHS) {
      adjustedMonth = adjustedMonth - YEAR_IN_MONTHS;
    }

    const julianDayYear = Math.floor(YEAR_IN_DAYS * (adjustedYear + JULIAN_DAY_BASE_YEAR));
    const julianDayMonth = Math.floor(MONTH_IN_DAYS * adjustedMonth + 0.5);
    const julianDayCentury = Math.floor(Math.floor(adjustedYear / 100 + 49) * 0.75) - 38;

    julianDay = julianDayYear + julianDayMonth + day + 59;
    if (julianDay > GREGORIAN_START_JULIAN_DAY) {
      julianDay = julianDay - julianDayCentury;
    }

    normalizedPhase = normalizeNumber((julianDay - REFERENCE_NEW_MOON) / SYNODIC_MONTH);
    normalizedPhase = normalizedPhase * 2 * Math.PI;

    const normalizedLunarDay =
      2 * Math.PI * normalizeNumber((julianDay - REFERENCE_ASCENDING_NODE) / DRACONIC_MONTH);

    const normalizedLongitude = normalizeNumber(
      (julianDay - REFERENCE_PERIGEE) / ANOMALISTIC_MONTH,
    );

    const longitude =
      360 * normalizedLongitude +
      6.3 * Math.sin(normalizedLunarDay) +
      1.3 * Math.sin(2 * normalizedPhase - normalizedLunarDay) +
      0.7 * Math.sin(2 * normalizedPhase);

    const moonSignBoundaries = [
      { limit: 33.18, sign: ZodiacTypes.ARIES },
      { limit: 51.16, sign: ZodiacTypes.TAURUS },
      { limit: 93.44, sign: ZodiacTypes.GEMINI },
      { limit: 119.48, sign: ZodiacTypes.CANCER },
      { limit: 135.3, sign: ZodiacTypes.LEO },
      { limit: 173.34, sign: ZodiacTypes.VIRGO },
      { limit: 224.17, sign: ZodiacTypes.LIBRA },
      { limit: 242.57, sign: ZodiacTypes.SCORPIO },
      { limit: 271.26, sign: ZodiacTypes.SAGITTARIUS },
      { limit: 302.49, sign: ZodiacTypes.CAPRICORN },
      { limit: 311.72, sign: ZodiacTypes.AQUARIUS },
      { limit: 348.58, sign: ZodiacTypes.PISCES },
      { limit: 360.0, sign: ZodiacTypes.ARIES },
    ];

    for (const boundary of moonSignBoundaries) {
      if (longitude < boundary.limit) {
        return boundary.sign;
      }
    }

    return moonSignBoundaries[0].sign;
  }

  static getMoonSignImage(date: string) {
    const moonSign = MoonPhaseService.getMoonSign(date);

    const moonSignImages = {
      [ZodiacTypes.ARIES]: {
        src: ariesImage,
        alt: 'Aries',
      },
      [ZodiacTypes.TAURUS]: {
        src: taurusImage,
        alt: 'Taurus',
      },
      [ZodiacTypes.GEMINI]: {
        src: geminiImage,
        alt: 'Gemini',
      },
      [ZodiacTypes.CANCER]: {
        src: cancerImage,
        alt: 'Cancer',
      },
      [ZodiacTypes.LEO]: {
        src: leoImage,
        alt: 'Leo',
      },
      [ZodiacTypes.VIRGO]: {
        src: virgoImage,
        alt: 'Virgo',
      },
      [ZodiacTypes.LIBRA]: {
        src: libraImage,
        alt: 'Libra',
      },
      [ZodiacTypes.SCORPIO]: {
        src: scorpioImage,
        alt: 'Scorpio',
      },
      [ZodiacTypes.SAGITTARIUS]: {
        src: sagittariusImage,
        alt: 'Sagittarius',
      },
      [ZodiacTypes.CAPRICORN]: {
        src: capricornImage,
        alt: 'Capricorn',
      },
      [ZodiacTypes.AQUARIUS]: {
        src: aquariusImage,
        alt: 'Aquarius',
      },
      [ZodiacTypes.PISCES]: {
        src: piscesImage,
        alt: 'Pisces',
      },
    };

    return moonSignImages[moonSign];
  }
}
