/* eslint-disable @typescript-eslint/no-unused-vars */
import { useRouter as useNextRouter } from 'next/router';

export const useRouter = () => {
  const router = useNextRouter();

  const navigate = (path: string, force = true) => {
    router.push(path);
  };

  const goBack = () => {
    router.back();
  };

  return {
    navigate,
    goBack,
    ...router,
  };
};
