import Handlebars from 'handlebars';
import { capitalize } from 'core/common/utils/capitalize';
import { TemplateEngine } from 'core/funnel/interfaces';
import { t } from 'core/localization/utils/getCurrentLanguage';

export class HandlebarsTemplateEngine implements TemplateEngine {
  private handlebarsInstance = Handlebars;

  constructor() {
    this.registerHelpers();
  }

  compile(value: string) {
    return this.handlebarsInstance.compile(value, { noEscape: true });
  }

  private registerHelpers() {
    this.handlebarsInstance.registerHelper('translate', function (...args) {
      const value = args.slice(0, args.length - 1).join('.');

      return t(value);
    });

    this.handlebarsInstance.registerHelper('capitalize', (value: string) => {
      return capitalize(value);
    });
  }
}
