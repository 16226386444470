import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { AppThunk } from 'core/common/store/types';
import { UtmSource, UtmTags } from 'core/user/entities';

export const syncUtmTags =
  (utm?: Record<string, string>): AppThunk =>
  async (dispatch, getState, services) => {
    const { utmTagsService, userService, loggingService: logger } = services;

    try {
      const utmTags = utmTagsService.getUtmTags();
      await userService.updateUtmTags({ ...utmTags, ...utm });
    } catch (e) {
      const err = normalizeError(e);
      logger.error(err);
    }
  };

export const userUtmTagsUpdated = createAction<UtmTags>('userUtmTags/UPDATED');

export const markUtmSourceAsOrganic = (): AppThunk => async (dispatch, getState, services) => {
  const { utmTagsService, loggingService } = services;
  const isUtmSourceAlreadyExist = !!utmTagsService.getUtmSource();

  if (isUtmSourceAlreadyExist) return;

  try {
    const updatedUtmTags = utmTagsService.updateUtmTags({ utm_source: UtmSource.ORGANIC });

    dispatch(userUtmTagsUpdated(updatedUtmTags));
  } catch (err) {
    const error = normalizeError(err);

    loggingService.error(error);
  }
};
