export enum CurrencySymbol {
  USD = '$',
  EUR = '€',
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
}

export const currencyToCurrencySymbol: Record<Currency, CurrencySymbol> = {
  [Currency.USD]: CurrencySymbol.USD,
  [Currency.EUR]: CurrencySymbol.EUR,
};

export type UserCurrency = {
  currency: Currency;
  currencySymbol: CurrencySymbol;
};
