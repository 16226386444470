import { useEffect, useState } from 'react';
import { SECOND } from '../constants';
import { getRandomNumberWithinRange } from '../utils/getRandomNumberWithinRange';
import { useLocalStorage } from './useLocalStorage';

const visibleItemsLength = 3;

type UseAnimatedVisibleItemsProps = {
  list: string[];
  storageKey: string;
  minRandomNumber?: number;
  maxRandomNumber?: number;
};

export function useAnimatedVisibleItems({
  list,
  storageKey,
  minRandomNumber = 700,
  maxRandomNumber = 775,
}: UseAnimatedVisibleItemsProps) {
  const [users, setUsers] = useLocalStorage(
    storageKey,
    getRandomNumberWithinRange(minRandomNumber, maxRandomNumber),
  );

  const [visibleItems, setVisibleItems] = useState(list.slice(0, visibleItemsLength));
  const [nextIndex, setNextIndex] = useState(visibleItemsLength);
  const [animateIndex, setAnimateIndex] = useState<number | null>(null);

  const addRemainingItemsWithTimeout = () => {
    if (nextIndex < list.length) {
      setVisibleItems((items) => [list[nextIndex], ...items]);
      setNextIndex((prev) => prev + 1);
      setAnimateIndex(0); // Start the animation with the first item in the visibleItems array
      setTimeout(() => {
        setAnimateIndex(null); // Stop the animation by setting animateIndex to null
      }, SECOND / 2);
    }
  };

  useEffect(() => {
    const intervalShowNode = setInterval(() => {
      setUsers(+users + 1);
      addRemainingItemsWithTimeout();
    }, 5 * SECOND);
    return () => {
      clearInterval(intervalShowNode);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextIndex, list]);

  useEffect(() => {
    setVisibleItems(list.slice(0, visibleItemsLength));
    setNextIndex(visibleItemsLength);
    setAnimateIndex(null);
  }, [list]);

  return {
    animateIndex,
    visibleItems,
    users,
  };
}
