import { Flow, FunnelConfig } from '../entities';
import { FlowDto, FunnelDto } from '../services';

const flowDtoToEntity = (flowDto: Array<FlowDto>): Array<Flow> => {
  return flowDto.map((flow) => ({
    id: flow.id,
    name: flow.name,
    initialScreen: flow.initial_screen,
    language: flow.language,
    offers: flow.offers,
    screens: flow.screens,
    translations: flow.translations,
    attributes: flow.attributes,
  }));
};

export const funnelsDtoToEntity = (funnelsDto: {
  funnels: Array<FunnelDto>;
}): Array<FunnelConfig> => {
  return funnelsDto.funnels.map((funnel) => ({
    link: funnel.link,
    flows: flowDtoToEntity(funnel.flows),
    name: funnel.name,
    analytics: funnel.analytics,
  }));
};
