import { createAction } from '@reduxjs/toolkit';
import { normalizeError } from 'core/common/errors';
import { AppThunk } from 'core/common/store/types';
import { Offer, OffersByCategory, StrictOffersMapByCategory } from 'core/offers/entities';
import { getUserLocation } from 'core/user/store/selectors';
import { fetchedOffersToOffersMap } from '../mappers';

export const selectedOfferUpdated = createAction<Offer>('offers/SELECTED_OFFER_UPDATED');

export const fetchOffersPending = createAction('offers/FETCH_OFFERS/pending');

export const fetchOffersSucceed = createAction<StrictOffersMapByCategory>(
  'offers/FETCH_OFFERS/succeed',
);

export const fetchOffersFailed = createAction('offers/FETCH_OFFERS/failed');

export const fetchOffersByCategory =
  (offersByCategory: OffersByCategory): AppThunk =>
  async (dispatch, getState, context) => {
    const { offersService, loggingService } = context;

    const { locale } = getUserLocation(getState());

    dispatch(fetchOffersPending());

    try {
      const offerIds = Object.values(offersByCategory).flatMap((offerId) => offerId);

      const offers = await offersService.getOffers(offerIds, locale);

      const offersMapByCategory = fetchedOffersToOffersMap(offers, offersByCategory);
      dispatch(fetchOffersSucceed(offersMapByCategory));
    } catch (e) {
      const err = normalizeError(e);

      loggingService.error(err);
      dispatch(fetchOffersFailed());
    }
  };
