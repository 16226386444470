import { ThemeTypes } from '../entities';
import { defaultTheme } from '../resources/defaultTheme';
import { lightTheme } from '../resources/lightTheme';

export const getTheme = (themeType: ThemeTypes) => {
  switch (themeType) {
    case ThemeTypes.PALMISTRY:
      return lightTheme;
    case ThemeTypes.NATAL_CHART:
      return lightTheme;
    case ThemeTypes.WITCH_POWER:
      return lightTheme;
    case ThemeTypes.PALMISTRY_QUIZ:
      return lightTheme;
    case ThemeTypes.COMPATIBILITY_QUIZ:
      return lightTheme;
    case ThemeTypes.MOON_COMPATIBILITY:
      return lightTheme;
    case ThemeTypes.EMAIL:
      return lightTheme;
    case ThemeTypes.APP_SUBSCRIPTION_INTRO:
      return lightTheme;
    default:
      return defaultTheme;
  }
};
