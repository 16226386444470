import { LoadingStatuses } from 'core/common/entities';
import { FunnelConfig } from '../entities';

export type FunnelConfigState = {
  config: FunnelConfig | null;
  loadingStatus: LoadingStatuses;
  funnelName: string;
};

export const funnelConfigInitialState: FunnelConfigState = {
  config: null,
  loadingStatus: LoadingStatuses.IDLE,
  funnelName: '',
};
