import { getAttractivePrice } from 'core/common/utils/getAttractivePrice';
import { Offer, PurchaseProductName, Upsell, UpsellV2 } from '../entities';

export const getCalculatedUpsell = (
  initUpsell: Omit<Upsell, 'offerId'>,
  upsellData: Offer | null,
): Upsell => {
  if (!upsellData) {
    return {
      ...initUpsell,
      price: '',
      oldPrice: '',
      offerId: PurchaseProductName.NONE,
    };
  }

  const { prices, currencySymbol } = upsellData;

  const oldPrice =
    currencySymbol + getAttractivePrice(prices.priceWithVAT / (1 - initUpsell.discountPercentage));

  return {
    ...initUpsell,
    price: upsellData.pricesWithCurrencySymbol.priceWithVAT,
    oldPrice,
    offerId: upsellData.offerId,
  };
};

export const getCalculatedUpsellV2 = (
  initUpsell: Omit<UpsellV2, 'offerId' | 'price' | 'oldPrice'>,
  upsellData: Offer | null,
): UpsellV2 => {
  if (!upsellData) {
    return {
      ...initUpsell,
      price: '',
      oldPrice: '',
      offerId: PurchaseProductName.NONE,
    };
  }

  const { prices, currencySymbol } = upsellData;

  const oldPrice =
    currencySymbol + getAttractivePrice(prices.priceWithVAT / (1 - initUpsell.discount.value));

  return {
    ...initUpsell,
    oldPrice,
    offerId: upsellData.offerId,
    price: upsellData.pricesWithCurrencySymbol.priceWithVAT,
  };
};
