/* eslint-disable @typescript-eslint/no-explicit-any */
import { generateUUID } from 'core/common/utils/generateUUID';
import { HttpRequestConfig } from './HttpClient';
import { HttpClientDecorator } from './HttpClientDecorator';

export class WithRequestId extends HttpClientDecorator {
  get<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return super.get<ResponseData, ResponseMeta>(url, {
      ...config,
      headers: {
        'x-request-id': generateUUID(),
        ...config.headers,
      },
    });
  }

  post<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return super.post<ResponseData, ResponseMeta>(url, {
      ...config,
      headers: {
        'x-request-id': generateUUID(),
        ...config.headers,
      },
    });
  }

  put<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return super.put<ResponseData, ResponseMeta>(url, {
      ...config,
      headers: {
        'x-request-id': generateUUID(),
        ...config.headers,
      },
    });
  }

  delete<ResponseData = any, ResponseMeta = any>(
    url: string,
    config: HttpRequestConfig = {},
  ): Promise<{ data: ResponseData; meta: ResponseMeta; success: boolean }> {
    return super.delete<ResponseData, ResponseMeta>(url, {
      ...config,
      headers: {
        'x-request-id': generateUUID(),
        ...config.headers,
      },
    });
  }
}
