import { checkIsClientSide } from '../utils/checkIsClientSide';
import { RootInitialState } from './rootReducer';

export const loadProgressFromStorage = ():
  | Omit<
      RootInitialState,
      | 'cookiesAgreement'
      | 'query'
      | 'system'
      | 'userUtmTags'
      | 'popUps'
      | 'predictions'
      | 'payments'
      | 'funnelConfig'
    >
  | undefined => {
  try {
    const serializedStorage = localStorage.getItem('nebula');
    if (serializedStorage === null) {
      return undefined;
    }
    const {
      userProfile,
      experts,
      offers,
      humanDesign,
      featureFlags,
      subscriptions,
      auth,
      userLocation,
    }: RootInitialState = JSON.parse(serializedStorage);
    return {
      userProfile,
      experts,
      offers,
      humanDesign,
      featureFlags,
      subscriptions,
      auth,
      userLocation,
    };
  } catch (err) {
    return undefined;
  }
};

export const saveProgressToStorage = (store: Omit<RootInitialState, 'cookiesAgreement'>): void => {
  if (!checkIsClientSide()) {
    return;
  }

  try {
    const serializedStorage = JSON.stringify({
      userProfile: store.userProfile,
      experts: store.experts,
      offers: store.offers,
      auth: store.auth,
      humanDesign: store.humanDesign,
      featureFlags: store.featureFlags,
    });

    localStorage.setItem('nebula', serializedStorage);
  } catch (e) {
    throw new Error('store serialization failed');
  }
};

export const parseQuery = (searchParams: URLSearchParams) => {
  const queries: Record<string, string> = {};
  for (const [key, value] of searchParams.entries()) {
    queries[key] = value;
  }

  return queries;
};
