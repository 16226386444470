import { createSelector } from '@reduxjs/toolkit';
import { RootInitialState } from 'core/common/store/rootReducer';
import { sortOffers } from '../entities';

export const getOffersLoadingStatus = (state: RootInitialState) => {
  return state.offers.loadingStatus;
};

export const getOffersMap = (state: RootInitialState) => {
  return state.offers.offersMapByCurrency;
};

export const getSelectedOffer = (state: RootInitialState) => {
  return state.offers.selectedOffer;
};

export const getOfferDetailsLoadingStatus = (state: RootInitialState) => {
  return state.offers.offerDetailsLoadingStatus;
};

export const getPalmistrySelectedOffer = (state: RootInitialState) => {
  return state.userProfile.plan;
};

export const getDefaultOffers = createSelector(
  [(state: RootInitialState) => getOffersMap(state).defaultOffers],
  (offers) => sortOffers(offers),
);

export const getTimeUpOffers = createSelector(
  [(state: RootInitialState) => getOffersMap(state).timeUpOffers],
  (offers) => sortOffers(offers),
);

export const getDiscountDefaultOffers = createSelector(
  [(state: RootInitialState) => getOffersMap(state).discountDefaultOffers],
  (offers) => sortOffers(offers),
);

export const getTimeUpOffer = (state: RootInitialState) => {
  const selectedOffer = getSelectedOffer(state);
  const timeUpOffers = getOffersMap(state).timeUpOffers;

  if (!timeUpOffers.length) {
    return null;
  }

  const filteredOffer = timeUpOffers.filter(
    (item) => !!selectedOffer && item.prices.trialPrice === selectedOffer.prices.trialPrice,
  );

  return filteredOffer[0] || timeUpOffers[0];
};

export const getTarotUpsellsOffer = (state: RootInitialState) => {
  return getOffersMap(state).tarotReadingUpsell;
};

export const getPalmistryUpsellsOffer = (state: RootInitialState) => {
  return getOffersMap(state).palmistryGuideUpsell;
};

export const getNumerologyUpsellsOffer = (state: RootInitialState) => {
  return getOffersMap(state).numerologyAnalysisUpsell;
};

export const getTrialCreditsOffer = (state: RootInitialState) => {
  return getOffersMap(state).trialCreditsUpsell;
};

export const getUltraPackOffer = (state: RootInitialState) => {
  return getOffersMap(state).ultraPackUpsell;
};

export const getDiscountUltraPackOffer = (state: RootInitialState) => {
  return getOffersMap(state).discountUltraPackUpsell;
};

export const getDiscountOffer = (state: RootInitialState) => {
  return getOffersMap(state).discountOffer;
};

export const getPremiumUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).premiumUpsell;
};

export const getNatalChartUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).natalChartUpsell;
};

export const getBlackMoonUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).blackMoonUpsell;
};

export const getConsultationUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).consultationUpsell;
};

export const getDiscountConsultationUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).discountConsultationUpsell;
};

export const getReadingsPackUpsellOffer = (state: RootInitialState) => {
  return getOffersMap(state).readingsPackUpsellOffer;
};

export const getCheapestOffer = (state: RootInitialState) => {
  const cheapestDefaultOffer = getDefaultOffers(state)[0];

  return cheapestDefaultOffer;
};
