import { JSONValue } from 'core/common/entities';
import {
  ExperimentDescription,
  ExperimentRawResult,
  FeatureDefinition,
  FeatureFlagsAttributes,
  FeatureFlagsConfig,
  FeatureRawResult,
  FeatureValue,
} from '../entities';
import { FeatureFlags } from './FeatureFlags';

export class FeatureFlagsDecorator implements FeatureFlags {
  private featureFlags: FeatureFlags;

  constructor(featureFlags: FeatureFlags) {
    this.featureFlags = featureFlags;
  }

  async loadFeatures(): Promise<{ [x: string]: FeatureDefinition<JSONValue> } | undefined> {
    return this.featureFlags.loadFeatures();
  }

  getSplitId(): string {
    return this.featureFlags.getSplitId();
  }

  getFeatures(): FeatureFlagsConfig['features'] {
    return this.featureFlags.getFeatures();
  }

  setForcedFeatureValues(featureValues: Record<string, unknown>) {
    this.featureFlags.setForcedFeatureValues(featureValues);
  }

  setFeatures(features: Record<string, FeatureDefinition>) {
    this.featureFlags.setFeatures(features);
  }

  getAttributes(): FeatureFlagsAttributes {
    return this.featureFlags.getAttributes();
  }

  getExperimentGroup<T extends FeatureValue>(experimentName: string): T | null {
    return this.featureFlags.getExperimentGroup(experimentName);
  }

  getFeatureRawResult<T extends FeatureValue>(featureName: string): FeatureRawResult<T | null> {
    return this.featureFlags.getFeatureRawResult(featureName);
  }

  getFeatureValue<T extends FeatureValue = FeatureValue>(featureName: string, defaultValue: T): T {
    return this.featureFlags.getFeatureValue(featureName, defaultValue);
  }

  isFeatureOff(featureName: string): boolean {
    return this.featureFlags.isFeatureOff(featureName);
  }

  isFeatureOn(featureName: string): boolean {
    return this.featureFlags.isFeatureOn(featureName);
  }

  setAttributes(attributes: FeatureFlagsAttributes): void {
    this.featureFlags.setAttributes(attributes);
  }

  setStateUpdateHandler(callback: () => void): void {
    this.featureFlags.setStateUpdateHandler(callback);
  }

  setIdentifier(identifier: string): void {
    this.featureFlags.setIdentifier(identifier);
  }

  onTrackExperiment<T extends FeatureValue = string>(
    callback: (experiment: ExperimentDescription<T>, result: ExperimentRawResult<T>) => void,
  ) {
    this.featureFlags.onTrackExperiment<T>(callback);
  }

  onFeaturesUpdated(callback: (features: Record<string, FeatureDefinition>) => void) {
    this.featureFlags.onFeaturesUpdated(callback);
  }

  onFeaturesLoaded(callback: (features: Record<string, FeatureDefinition>) => void): void {
    this.featureFlags.onFeaturesLoaded(callback);
  }

  onFeaturesLoadingFailed(error: (error: Error) => void): void {
    this.featureFlags.onFeaturesLoadingFailed(error);
  }
}
