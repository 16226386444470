export enum UtmSource {
  FACEBOOK = 'fb',
  GOOGLE = 'google',
  TIKTOK = 'tiktok',
  EMAIL = 'email',
  ORGANIC = 'organic',
  NO_SOURCE = '',
}
export type UtmTags = Partial<{
  utm_source: UtmSource;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
  utm_campaign_id: string;
  utm_campaignid: string;
  utm_campaign: string;
  utm_adset_id: string;
  utm_adsetid: string;
  utm_adset: string;
  utm_ad_id: string;
  utm_adid: string;
  utm_ad: string;
  gclid: string;
  iterable_message_id: string;
  fbp: string;
  fbc: string;
  fbclid: string;
  ga: string;
  everflow_transaction_id: string;
  utm_source_retention: string;
  utm_adset_name: string;
  utm_placement: string;
  utm_ad_name: string;
  ttclid: string;
  quizz: string;
  oid: string;
  _ef_transaction_id: string;
  ad_group: string;
  fb_test_event_code: string;
}>;

export const notOrganicUtmSources = Object.values(UtmSource).filter(
  (source) => source && source !== UtmSource.ORGANIC,
);
