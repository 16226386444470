import { createReducer } from '@reduxjs/toolkit';
import { LoadingStatuses } from 'core/common/entities';
import {
  fetchOffersFailed,
  fetchOffersPending,
  fetchOffersSucceed,
  selectedOfferUpdated,
} from './actions';
import { offersInitialState } from './state';

export const offersReducer = createReducer(offersInitialState, (builder) => {
  builder
    .addCase(fetchOffersPending, (state) => {
      state.loadingStatus = LoadingStatuses.PENDING;
    })
    .addCase(fetchOffersSucceed, (state, { payload }) => {
      state.offersMapByCurrency = payload;
      state.loadingStatus = LoadingStatuses.FULFILLED;
    })
    .addCase(fetchOffersFailed, (state) => {
      state.loadingStatus = LoadingStatuses.FAILED;
    })
    .addCase(selectedOfferUpdated, (state, { payload }) => {
      state.selectedOffer = payload;
    });
});
