import { RootInitialState } from 'core/common/store';

export const getUserSubscriptionsLoadingStatus = (state: RootInitialState) => {
  return state.subscriptions.loadingStatus;
};

export const getUserSubscriptionsCancelLoadingStatus = (state: RootInitialState) => {
  return state.subscriptions.cancelLoadingStatus;
};

export const getAllUserSubscriptionsMap = (state: RootInitialState) => {
  return state.subscriptions.entities;
};

export const getUserSubscriptions = (state: RootInitialState) => {
  return Object.values(getAllUserSubscriptionsMap(state));
};
