import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { FeatureFlags } from '../services';

export const FeatureFlagsContext = createContext<{ featureFlags: FeatureFlags }>(null!);

export type FeatureFlagsProviderProps = {
  children?: ReactNode;
  featureFlags: FeatureFlags;
};

export const FeatureFlagsProvider = ({ children, featureFlags }: FeatureFlagsProviderProps) => {
  const [updateCount, setStateUpdateCount] = useState(0);

  useEffect(() => {
    const noop = () => {};

    if (!featureFlags) return noop();
    featureFlags.setStateUpdateHandler(() => {
      setStateUpdateCount((value) => value + 1);
    });

    return () => {
      featureFlags.setStateUpdateHandler(noop);
    };
  }, [featureFlags, setStateUpdateCount]);

  const providerValue = useMemo(() => {
    return { featureFlags };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, updateCount]);

  return (
    <FeatureFlagsContext.Provider value={providerValue}>{children}</FeatureFlagsContext.Provider>
  );
};
