export * from './routes';
export * from './shopify';
export * from './dates';
export * from './time';
export * from './emails';
export * from './advertisementData';

export const PAYMENT_ID = 'payment';
export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const TEN_MINUTES_IN_MS = 10 * 60 * SECOND;
export const YEAR_IN_DAYS = 365;
