import { RootInitialState } from 'core/common/store';
import { MoonPhaseService } from '../services';

export const getMoonData = (state: RootInitialState) => {
  const moonPhase = getMoonPhase(state);
  const moonSign = getMoonSign(state);
  const partnerMoonPhase = getPartnerMoonPhase(state);
  const partnerMoonSign = getPartnerMoonSign(state);
  const moonImage = getMoonImage(state);
  const moonSignImage = getMoonSignImage(state);
  const partnerMoonImage = getPartnerMoonImage(state);
  const partnerMoonSignImage = getPartnerMoonSignImage(state);

  return {
    moonPhase,
    moonSign,
    partnerMoonPhase,
    partnerMoonSign,
    moonImage,
    moonSignImage,
    partnerMoonImage,
    partnerMoonSignImage,
  };
};

export const getMoonImage = (state: RootInitialState) => {
  return MoonPhaseService.getMoonImageByDate(state.userProfile.date);
};

export const getMoonSignImage = (state: RootInitialState) => {
  return MoonPhaseService.getMoonSignImage(state.userProfile.date);
};

export const getPartnerMoonImage = (state: RootInitialState) => {
  return MoonPhaseService.getMoonImageByDate(state.userProfile.partnerDate);
};

export const getPartnerMoonSignImage = (state: RootInitialState) => {
  return MoonPhaseService.getMoonSignImage(state.userProfile.partnerDate);
};

export const getMoonPhase = (state: RootInitialState) => {
  return MoonPhaseService.getMoonPhaseByDate(state.userProfile.date);
};

export const getMoonSign = (state: RootInitialState) => {
  return MoonPhaseService.getMoonSign(state.userProfile.date);
};

export const getPartnerMoonPhase = (state: RootInitialState) => {
  return MoonPhaseService.getMoonPhaseByDate(state.userProfile.partnerDate);
};

export const getPartnerMoonSign = (state: RootInitialState) => {
  return MoonPhaseService.getMoonSign(state.userProfile.partnerDate);
};
