import { normalizeError } from 'core/common/errors';
import { SpanStatusCode, toSpanAttributes } from 'core/common/observability/entities';
import { ObservabilitySystem } from 'core/common/observability/interfaces';
import { removeCharactersBetweenPlusAndAt, removePeriodsBeforeAt } from 'core/common/utils/email';
import { hash256 } from 'core/common/utils/hash';
import {
  removeAccents,
  removeLeadingTrailingPeriods,
  removeWhitespaces,
  toLowerCase,
} from 'core/common/utils/string';
import {
  BingAdsAddToCartData,
  BingAdsAddToCartParams,
  BingAdsBeginCheckoutData,
  BingAdsCheckoutParams,
  BingAdsEventTypes,
  BingAdsPurchaseData,
  BingAdsPurchaseParams,
} from '../../entities';
import { BingAdsService } from '../../interfaces';

export class HttpBingAdsService implements BingAdsService {
  constructor(private readonly observabilitySystem: ObservabilitySystem) {}

  private get uetq() {
    return window.uetq;
  }

  addToCart({ trialPrice, productId, email, currency, orderId }: BingAdsAddToCartParams) {
    const data: BingAdsAddToCartData = {
      currency,
      ecomm_pagetype: 'product',
      ecomm_prodid: [orderId],
      ecomm_totalvalue: trialPrice,
      items: [
        {
          id: productId,
          price: trialPrice,
          quantity: 1,
        },
      ],
      pid: { em: this.prepareEmail(email) },
      revenue_value: trialPrice,
    };

    this.track('add_to_cart', data);
  }

  beginCheckout({ trialPrice, productId, email, currency, orderId }: BingAdsCheckoutParams) {
    const data: BingAdsBeginCheckoutData = {
      currency,
      ecomm_pagetype: 'cart',
      ecomm_prodid: [orderId],
      ecomm_totalvalue: trialPrice,
      items: [
        {
          id: productId,
          quantity: 1,
          price: trialPrice,
        },
      ],
      pid: { em: this.prepareEmail(email) },
      revenue_value: trialPrice,
    };

    this.track('begin_checkout', data);
  }

  purchase({ price, productId, email, currency, orderId, ltvPredict }: BingAdsPurchaseParams) {
    const data: BingAdsPurchaseData = {
      currency,
      ecomm_pagetype: 'purchase',
      ecomm_prodid: [orderId],
      ecomm_totalvalue: ltvPredict,
      items: [
        {
          id: productId,
          price: price,
          quantity: 1,
        },
      ],
      pid: { em: this.prepareEmail(email) },
      revenue_value: ltvPredict,
      transaction_id: orderId,
    };

    this.track('purchase', data);
  }

  private track(eventName: BingAdsEventTypes, data: Record<string, unknown>) {
    if (!this.uetq) {
      return;
    }

    const span = this.observabilitySystem.startSpan('track_analytics_bing_ads', {
      attributes: { ...toSpanAttributes({ event: eventName, ...data }) },
    });

    try {
      span.addEvent('Pending');
      this.uetq.push('event', eventName, data);
      span.addEvent('Succeed');
      span.end();
    } catch (err) {
      const error = normalizeError(err);

      span.addEvent('Failed');
      span.recordException(error);
      span.setStatus({ code: SpanStatusCode.ERROR, message: error.message });
      span.end();
    }
  }

  private prepareEmail(email: string) {
    return [
      removeWhitespaces,
      toLowerCase,
      removeCharactersBetweenPlusAndAt,
      removePeriodsBeforeAt,
      removeLeadingTrailingPeriods,
      removeAccents,
      hash256,
    ].reduce((acc, fn) => fn(acc), email);
  }
}
