import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { stringifyQueryParams } from 'core/common/utils/getQueryParams';
import { centsToDollars } from 'core/common/utils/money';
import { UtmTagsService } from 'core/user/services';
import { LtvPredictService } from '../../interfaces';

export class HttpLtvPredictService extends BaseService implements LtvPredictService {
  private quiz = '';
  private utmTagsService: UtmTagsService;

  constructor(httpClient: HttpClient, logger: Logger, utmTagsService: UtmTagsService) {
    super(httpClient, logger);
    this.utmTagsService = utmTagsService;
  }

  setCurrentQuizName(quiz: string) {
    this.quiz = quiz;
  }

  async getLTVPredict(productName: string) {
    const mediaSource = this.utmTagsService.getUtmSource();

    const queryParams = stringifyQueryParams({
      product_name: productName,
      ...(this.quiz ? { quiz: this.quiz } : {}),
      ...(mediaSource ? { media_source: mediaSource } : {}),
    });

    const { data } = await this.httpClient.get(`/analytics/ltv-predict?${queryParams}`);

    return centsToDollars(data);
  }
}
