import { normalizeError } from 'core/common/errors';
import { BaseService, HttpClient } from 'core/common/services';
import { Logger } from 'core/common/services/logger/interfaces';
import { stringifyQueryParams } from 'core/common/utils/getQueryParams';
import { uniq } from 'core/common/utils/uniq';
import { uniqBy } from 'core/common/utils/uniqBy';
import {
  FunnelConfig,
  FunnelRequestFilters,
  QuizStepTypes,
  StaticProps,
} from 'core/funnel/entities';
import { FunnelService } from '../../interfaces';
import { funnelsDtoToEntity } from '../../mappers';
import { FunnelDto } from './dto';

export class HttpFunnelService extends BaseService implements FunnelService {
  constructor(httpClient: HttpClient, logger: Logger) {
    super(httpClient, logger);
  }

  async getFunnels(filters?: Partial<FunnelRequestFilters>): Promise<Array<FunnelConfig> | null> {
    try {
      const queryParams = stringifyQueryParams({
        funnel_name: filters?.funnelName,
        funnel_status: filters?.funnelStatus,
        flow_name: filters?.flowName,
        flow_status: filters?.flowStatus,
        language: filters?.language,
      });

      const { data } = await this.httpClient.get<{ funnels: Array<FunnelDto> }>(
        `/external/funnels?${queryParams}`,
      );

      return funnelsDtoToEntity(data);
    } catch (err) {
      const error = normalizeError(err);
      this.logger.error(error);

      return null;
    }
  }

  getFunnelsPaths(funnels: Array<FunnelConfig>) {
    const paths: StaticProps[] = [];

    funnels.forEach((funnel) => {
      funnel.flows.forEach((flow) => {
        paths.push({
          params: { funnel: funnel.name },
          locale: flow.language,
        });
      });
    });

    return paths;
  }

  getFunnelsPathsWithSubRouts(funnels: Array<FunnelConfig>) {
    const paths: StaticProps[] = [];

    funnels.forEach((funnel) => {
      const { flows } = funnel;

      const funnelScreens = uniqBy(
        flows.flatMap(({ screens }) => screens),
        'id',
      );

      const availableLocales = uniq(flows.map(({ language }) => language));

      const pathsCombined = availableLocales.flatMap((locale) =>
        funnelScreens
          .filter((step) => {
            return step.attributes[0].screenType !== QuizStepTypes.SPLIT;
          })
          .map((step) => ({
            params: {
              id: step.id,
              funnel: funnel.name,
            },
            locale,
          })),
      );

      paths.push(...pathsCombined);
    });

    return paths;
  }
}
