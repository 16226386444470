import { RootInitialState } from 'core/common/store/rootReducer';

export const getUtmAdGroup = (state: RootInitialState) => {
  return state.userUtmTags.tags.ad_group;
};

export const getUtmSource = (state: RootInitialState) => {
  return state.userUtmTags.tags.utm_source;
};

export const getUtmTags = (state: RootInitialState) => {
  return state.userUtmTags.tags;
};

export const getUtmAdName = (state: RootInitialState) => {
  return state.userUtmTags.tags.utm_ad_name;
};
