import { FeatureFlagsConfig } from './entities';

export const designPaymentFormExperimentName = 'ab_gs_nc_offer_design_payment_form_bm_102024';

export enum DesignPaymentFormExperimentNameGroup {
  CONTROL = 'control',
  SIMPLE_PAYMENT = 'simple_payment',
  SIMPLE_PAYMENT_BANNER = 'simple_payment_banner',
}

export const featureFlagsConfig: FeatureFlagsConfig = {
  features: {
    [designPaymentFormExperimentName]: {
      defaultValue: DesignPaymentFormExperimentNameGroup.CONTROL,
    },
  },
};
