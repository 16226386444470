import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useUrlLocation = () => {
  const [previousLocation, setPreviousLocation] = useState('');

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setPreviousLocation(router.asPath);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  return {
    location: router,
    previousLocation,
  };
};
