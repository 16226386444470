import { roundNumber } from './math';

export const convertCredits = (amount: string | number): number => Math.round(+amount * 10);

export const convertCentsToCredit = (amount: string | number): number => {
  if (!amount) return 0;

  return Math.round(+amount / 10);
};

export const centsToDollars = (amount: string | number): number => {
  if (!amount) return 0;
  return roundNumber(+amount / 100, -2);
};
