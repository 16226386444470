import { Currency } from 'core/user/entities';

export type PurchaseParams = {
  ltvPredict: number;
  orderId: string;
  currency: Currency;
};

export enum Events {
  PURCHASE = 'Purchase',
  ADD_TO_CARD = 'AddToCart',
  INITIATE_CHECKOUT = 'InitiateCheckout',
}

export interface FacebookService {
  purchase(data: PurchaseParams): void;
  addToCard(orderId: string): void;
  initiateCheckout(orderId: string): void;
  track(name: Events, orderId: string, params?: Record<string, unknown>): void;
}
