import { ToEntity } from 'core/common/mappers';
import { ResignFormData } from '../entities';
import { ResignFormDataDTO } from '../services/dto';

export class ResignFormDataDtoMapper implements ToEntity<ResignFormData, ResignFormDataDTO> {
  toEntity(dto: ResignFormDataDTO): ResignFormData {
    return {
      merchant: dto.resign_form.merchant,
      resignIntent: dto.resign_form.resign_intent,
      signature: dto.resign_form.signature,
    };
  }
}
