import {
  Currency,
  CurrencySymbol,
  currencyToCurrencySymbol,
  europeanCountries,
  Locales,
  UserCurrency,
} from 'core/user/entities';

export class CurrencyService {
  getCurrencySymbol(currency: Currency): CurrencySymbol {
    return currencyToCurrencySymbol[currency];
  }

  static getUserCurrency(locale: Locales): UserCurrency {
    if (europeanCountries.includes(locale)) {
      return {
        currency: Currency.EUR,
        currencySymbol: CurrencySymbol.EUR,
      };
    }

    return {
      currency: Currency.USD,
      currencySymbol: CurrencySymbol.USD,
    };
  }
}
